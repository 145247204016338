import React, { Component } from 'react';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import BreadCrumb from '../../components/Navs/Breadcrumb';
import moment from 'moment';

import { 
  Box,
  Grid,
  IconButton,
  CircularProgress,
} from '@material-ui/core';

import Icon from '@material-ui/core/Icon';

import { ReactComponent as JobsIcon } from '../../assets/img/left_menu/jobs.svg';
import { ReactComponent as SearchIcon } from '../../assets/img/icons/search.svg';
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';
import { ReactComponent as ExportIcon } from '../../assets/img/icons/export.svg';
import { ReactComponent as ImportIcon } from '../../assets/img/icons/import.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import { ReactComponent as CopyIcon } from '../../assets/img/icons/copy.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faEye, faBarcode, faFile, faCopy, faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import {RoleAccessService} from '../../data/role-access'; 
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import Table from '../../components/Table/Table.jsx';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker.jsx';
import CustomCheckbox from '../../components/CustomFields/CustomCheckbox.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import GroupButton from '../../components/GroupButton/GroupButton.jsx';
import CircleIcon from '../../components/CircleIcon/CircleIcon.jsx';
import StatusList from '../../components/Status/StatusList.jsx';
import StatusItem from '../../components/Status/StatusItem.jsx';
import DropdownMenu from '../../components/Dropdown/DropdownMenu.jsx';
import CustomAutosuggest from '../../components/CustomFields/CustomAutosuggest.jsx';
import CustomReadMore from '../../components/Truncate/CustomReadMore.jsx';
import CustomUploadButton from '../../components/CustomFields/CustomUploadButton.jsx';

import JobPreviewDialog from './Partial/JobPreviewDialog.jsx';
import JobImportDialog from './Partial/JobImportDialog.jsx';
import HtmlParser from 'react-html-parser';

const { 
  REACT_APP_LDS_API_URL,
} = process.env;


export class JobVendorList extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.pageName = 'jobVendorList';

    this.isCustomerRequired = false;

    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();

    let rangeDate = {
      startDate: moment(),
      endDate: moment(),
    };

    let rangeDateItem = localStorage.getItem(this.pageName + '_rangeDate');
    if(rangeDateItem && rangeDateItem !== ''){
      try {
        rangeDate = JSON.parse(rangeDateItem);
      }catch(e){}
    }

    let hiddenColumns = [];
    let columnHiddenSettingsId = 0;
    let columnHiddenSettings = JSON.parse(localStorage.getItem('job_column_settings'));

    if (columnHiddenSettings !== null) {
      hiddenColumns = columnHiddenSettings.json_data.replace(/[^0-9a-zA-Z-_.,]/g, "").split(",");
      columnHiddenSettingsId = columnHiddenSettings.id ? columnHiddenSettings.id : 0;
    }

    this.state = {
      grantedAccess : new RoleAccessService(),
      currentRole: window.localStorage.getItem('current_role') || null,
      jobTab: 0,
      jobTabTable: 1,
      grantedAccess : new RoleAccessService(),
      currentRole: window.localStorage.getItem('current_role') || null,
      dateRangePickerIsOpen: false,
      rangeDate: rangeDate,
      startDate: rangeDate.startDate,
      endDate: rangeDate.endDate,

      isLoadingStatus: false,
      status: [],
      tableStatus: [],
      statusSelected: null,
      order_status: null,

      isLoading: false,
      grouping: true,
      limit: 50,
      data: [],
      total: 0,
      page: 1,

      openDialog: false,
      openDialogItem: null,

      openPreviewDialog: false,
      openPreviewDialogItem: null,
      
      openImportDialog: false,
      openImportDialogItem: null,
      openImportDialogIsLoading: false,

      defaultGroupOrder: -1,

      hiddenColumnSettingId: columnHiddenSettingsId,
      hiddenColumns: hiddenColumns,

      copyDialogOpen: false,
      copyData: {
        id: 0,

        drop_off_date: true,
        drop_off_time_planned: true,
        drop_off_description: true,
        
        drop_off_name: true,
        drop_off_contact_email: true,
        drop_off_contact_no: true,
        item_tracking_number: true,

        order_details: true,
        job_steps: true,
        drop_off_worker: true,
        amount: true
      }
    }
  }
  

  componentDidMount() {
    const { isCustomerRequired } = this.context;
    this.isCustomerRequired = isCustomerRequired;
    
    this.loadStatusApi(() => {
      this.callReadApi();
    });
  }


  callInitApis = () => {
    this.loadStatusApi();
    this.callReadApi();
  }
  loadStatusApi = (callback = null) => {
    this.callStatusApi((data, total) => {
      if(data && data.length > 0){
        let status = data.map((item, i) => {
          return {
            id: item.status_details.id,
            status: item.status_details.status,
            text: item.status_details.status,
            total: item.total_job,
          };
        });
  
        let tableStatus = status;

        status.unshift({
          id: 0,
          status: 'Total Orders',
          total: total,
        });
  
        this.setState({
          status: status,
          tableStatus: tableStatus,
        }, () => {
          if(callback) {
            callback();
          }
        });
      } else {
        if(callback) {
          callback();
        }
      }
    });
  }


  /* API */
  callStatusApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoadingStatus: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
      };

      ldsApi.read('orders/job/vendor_job_stats', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              let total = data.total_delivery;
              let result = Object.keys(data.result).map((k) => data.result[k]);
              
              if(result && result.length > 0){
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(result, total);
                  }
                });
              } else {
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(null, 0);
                  }
                });
              }
            } else {
              this.setState({
                isLoadingStatus: false,
              }, () => {
                if(callback){
                  callback(null, 0);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoadingStatus: false,
            }, () => {
              if(callback){
                callback(null, 0);
              }
            });
          });
      });
    });
  }
  
  callReadApi = (search = '') => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        page: this.state.page,
        take: this.state.limit,
        search: search,
        start_date: startDate,
        end_date: endDate,
        order_status: this.state.order_status,
        is_in: true,
        sort: "latest"
      };

      ldsApi.read('orders/vendor_list', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              data: (data.result && data.result.length > 0) ? data.result : [],
              total: data.total,
              isLoading: false,
            });

            this.loadStatusApi();
          }, (error, type) => {
            this.setState({
              data: [],
              total: 0,
              isLoading: false,
            });
          });
      });
    });
  }

  callUpdateIsActiveApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.update('orders/' + row.id, row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callDeleteApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      let param = {
        destroy_all: true,
      };

      ldsApi.delete('delete/orders/multiple?id=' + row.id, param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callUpdateStatusApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('orders/assign/order', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result[0] : null;
            this.loadStatusApi();
            if(result){
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(result);
                }
                this.callStatusApi();
              });
            } else {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END API */


  /* WORKER API */
  callWorkerDriverReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    let param = {
      page: 1,
      take: apiUtil.getDefaultPageSize(),
      is_active: true,
      search: search,
      is_truck_driver: true,
      require_worker_only: true,
    };

    api.read('workers', param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result && data.result.length > 0) ? data.result : [];

          let arr = [];
          if(result && result.length > 0){
            arr = result.map((item, i) => {
              let value = item.id;
              let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

              if(arr.findIndex(x => x.value === value) === -1){
                return {
                  value: value,
                  label: label,
                  item: item
                }
              } else {
                return null;
              }
            });
    
            if(callback){
              callback(arr);
            }
          } else {
            if(callback){
              callback([]);
            }
          }
        }, (error, type) => {
          if(callback){
            callback([]);
          }
        });
    });
  }

  callManpowerDriverReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    let param = {
      page: 1,
      take: apiUtil.getDefaultPageSize(),
      is_active: true,
      search: search,
      is_man_power: true,
      require_worker_only: true,
    };

    api.read('workers', param, accessToken)
    .then((result) => {
      apiUtil.parseResult(result, (data) => {
        let result = (data.result && data.result.length > 0) ? data.result : [];

        let arr = [];
        if(result && result.length > 0){
          arr = result.map((item, i) => {
            let value = item.id;
            let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

            if(arr.findIndex(x => x.value === value) === -1){
              return {
                value: value,
                label: label,
                item: item
              }
            } else {
              return null;
            }
          });
  
          if(callback){
            callback(arr);
          }
        } else {
          if(callback){
            callback([]);
          }
        }
      }, (error, type) => {
        if(callback){
          callback([]);
        }
      });
    });
  }
  /* END WORKER API */

  
  /* EXPORT API */
  callExportXLSApi = () => {
    const { accessToken } = this.context;

    this.setState({
        downloadIsLoading: true
    }, () => {
      let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
      };

      ldsApi.exportXLS('admin/api/orders/delivery/export', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (response) => {
            apiUtil.saveToFile(response, `Jobs ${moment(param.startDate).format('YYYY-MM-DD')} - ${moment(param.endDate).format('YYYY-MM-DD')}.xls`);
    
            this.setState({
              downloadIsLoading: false,
            });
          }, (error, type) => {
            this.setState({
              downloadIsLoading: false,
            });
          });
      });
    });
  }
  /* END EXPORT API */
  

  /* IMPORT API */
  callImportApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('create/orders/multiple', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END IMPORT API */


  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
        <Box clone pr={{ xs: 0, md: 1 }} pb={{ xs: 2, md: 0 }}>
          <Grid item xs={12} md={'auto'}>
            <CustomDateRangePicker
              range={this.state.rangeDate}
              onChange={(range) => {
                localStorage.setItem(this.pageName + '_rangeDate', JSON.stringify(range));
                
                this.setState({
                  rangeDate: range,
                }, () => {
                  this.callInitApis();
                });
              }}
            />
          </Grid>
        </Box>
        <Box clone pr={1}>
          <Grid item xs={'auto'}>
            <CustomUploadButton 
              color={'primary'}
              accept={'.xls, .xlsx'}
              onChange={(files) => {
                if(files && files.length > 0){
                  this.setState({
                    openImportDialog: true,
                    openImportDialogItem: files[0],
                  });
                }
              }}
            >
              {/* <ImportIcon /> */}
              Import Jobs
            </CustomUploadButton>
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={'auto'}>
            <CustomButton 
              color={'secondary'}
              onClick={() => {
                this.props.history.push('/jobs-form');
              }}
            >
              + New Job
            </CustomButton>
          </Grid>
        </Box>
        <Box clone pt={{ xs: 4, md: 0 }}>
          <Grid item xs={12}>
            <GroupButton
              className={'head-tabs'}
              color={'secondary'}
              selected={this.state.jobTab}
              buttons={[ "Job List", "Job Schedule" ]}
              onClick={(selected, btn) => {
                if(selected === 1){
                  this.props.history.push('/job-schedule');
                }
              }}
            />
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */


  /* STATUS */
  customStatus = () => {
    return <Grid container alignItems={'center'}>
      <Box clone mb={1}>
        <Grid item xs={'auto'}>
          <CircleIcon>
            <JobsIcon />
          </CircleIcon>
        </Grid>
      </Box>
      <Box clone pl={3} pr={3}>
        <Grid item xs={'auto'}>
          <CustomLabel
            className={'no-margin'}
            label={'Jobs List'}
            weight={'bold'}
            size={'md'}
          />
          <CustomLabel
            label={'Based on recent activities'}
            size={'xs'}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12} lg={true}>
          <Box className={'custom-status-list-wrapper'}>
            <StatusList
              selected={(this.state.statusSelected > 0) ? this.state.statusSelected : 0}
              items={this.state.status.map((item, i) => {
                return <StatusItem
                  text={<Box>
                  <FontAwesomeIcon icon={faCircle} component={'i'} className={'custom-dot status ' + (item.status.replace(' ', '-').trim().toLowerCase())}/>
                    {/* <Icon component={'i'} className={'custom-dot status ' + (item.status.replace(' ', '-').trim().toLowerCase())}>lens</Icon> */}
                    <span>{item.status}</span>
                  </Box>}
                  count={item.total}
                />
              })}
              onClick={(index) => {
                let defaultGroupOrder = -1;
                if(index > 1){
                  defaultGroupOrder = 0;
                }

                this.setState({
                  statusSelected: index,
                  order_status: (index && index > 0) ? this.state.status[index].id : null,
                  defaultGroupOrder: defaultGroupOrder,
                }, () => {
                  this.callInitApis();
                });
              }}
            />
          </Box>
        </Grid>
      </Box>
    </Grid>
  }
  /* END STATUS */


  /* TABLE */
  customTable = () => {
    const { isCustomerRequired } = this.context;

    if(!this.state.isLoading) {
      return <Table 
            ref={this.refTable}
            className={'job-table-list v-top table-last-child'}
            isLoading={this.state.isLoading}
            isDraggable={false}
            grouping={this.state.grouping}
            limit={this.state.limit}
            page={this.state.page}
            total={this.state.total}
            data={this.state.data}
            defaultExpanded={true}
            onPageChange={(page, size) => {
              this.state.limit = size;
              this.setState({
                page: page,
              }, () => {
                this.callReadApi(this.refTableSearch.current.state.value);
              });
            }}
            Toolbar={this.customToolbar}
            // Row={this.customRow}
            columns={[
              { 
                title: "Job Number",
                field: "order_number",
                cellStyle: {
                  minWidth: 240,
                  maxWidth: 240            
                },
                hidden: this.state.hiddenColumns.includes('order_number'),
                render: (row, type) => {
                  
                  if(type === 'row'){
                    if(this.state.grantedAccess.admin_roles_with_customer.includes(this.state.currentRole)) {
                    return <Box>
                      {(row.order_sequence && row.order_sequence !== null) ?
                      <span className="sequence-circle">{row.order_sequence}</span>: ""}
                      <div className={row.order_sequence !== null ? "float-right" : ""}>
                        <Box pb={2}>
                          <CustomButton
                            className={'underline'}
                            color={'primary'}
                            href={'/'}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();

                              this.setState({
                                openPreviewDialog: true,
                                openPreviewDialogItem: row.id,
                                // openPreviewDialogItem: row.drop_off_worker_id,
                                // openPreviewDialogItem: row.drop_off_worker.first_name
                              });
                            }}
                          >
                            {(row && row.order_number) ? row.order_number : ''}
                          </CustomButton>
                        </Box>
                          <Box>
                          <DropdownMenu 
                            isShouldEnable={this.state.grantedAccess.isShouldShowJobAccess(this.state.currentRole, 'job_status')}
                            className={'status-btn ' + apiUtil.statusToClassName((row.order_status && row.order_status.status && row.order_status.status !== '') ? row.order_status.status : '')}
                            text={(row && row.order_status && row.order_status.status) ? row.order_status.status+'   ' : ''}
                            rightIcon={<ArrowDownIcon />}
                            items={this.state.tableStatus}
                            onClick={(item) => {
                              if(row.drop_off_worker_id && row.drop_off_worker_id > 0 || item.text.toString().toLowerCase().trim() === 'cancelled' || item.text.toString().toLowerCase().trim() === 'not assigned'){
                                let data = {
                                  data: [
                                    {
                                      id: [ row.id ],
                                      job_steps: (row.job_steps && row.job_steps.length > 0) ? row.job_steps.map(step => {
                                        step.job_step_id = step.id;
                                        if (item.text.toString().toLowerCase().trim() === "completed") {
                                          step.job_step_status_id = 3;
                                        } else {
                                          step.job_step_status_id = 1;
                                        }
                                        return step
                                      }): row.job_steps,
                                      order_status_id: item.id,
                                      send_notification_to_customer: true,
                                    }
                                  ]
                                };

                                if(item.text.toString().toLowerCase().trim() === 'not assigned'){
                                  data.data[0]['drop_off_worker_id'] = null;
                                  data.data[0]['extra_worker_ids'] = null;
                                }

                                this.callUpdateStatusApi(data, (newRow) => {
                                  if(newRow){
                                    let newData = apiUtil.updateRow(this.state.data, newRow, 'id');
                                    this.setState({
                                      data: newData,
                                    });
                                  }
                                });
                              } else {
                                apiUtil.toast('You must first assign a driver!', 'warning', 'error'); 
                              }
                            }}
                          />          
                        </Box>
                      
                      </div>
                    </Box>;
                    } else {
                      return <Box>
                      {(row.order_sequence && row.order_sequence !== null) ?
                      <span className="sequence-circle">{row.order_sequence}</span>: ""}
                      <div className={row.order_sequence !== null ? "float-right" : ""}>
                        <Box pb={2}>
                          <CustomButton
                            className={'underline'}
                            color={'primary'}
                            href={'/'}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();

                              this.setState({
                                openPreviewDialog: true,
                                openPreviewDialogItem: row.id,
                                // openPreviewDialogItem: row.drop_off_worker_id,
                                // openPreviewDialogItem: row.drop_off_worker.first_name
                              });
                            }}
                          >
                            {(row && row.order_number) ? row.order_number : ''}
                          </CustomButton>
                        </Box>
                          <Box>
                          <CustomButton
                            isShouldEnable={this.state.grantedAccess.isShouldShowJobAccess(this.state.currentRole, 'job_status')}
                            className={'custom-status status-btn ' + apiUtil.statusToClassName((row.order_status && row.order_status.status && row.order_status.status !== '') ? row.order_status.status : '')}
                            text={(row && row.order_status && row.order_status.status) ? row.order_status.status+'   ' : ''}
                            items={this.state.tableStatus}
                            
                          >  {row.order_status.status} </CustomButton>       
                        </Box>
                      </div>
                    </Box>;
                    }  
                } else {
                    return row;
                  }
                }
              },
              { 
                title: "Description", 
                field: "drop_off_description",
                cellStyle: {
                  minWidth: 350,
                  maxWidth: 350
                },
                hidden: this.state.hiddenColumns.includes('drop_off_description'),
                render: (row, type) => {
                  if(type === 'row'){
                    // return (row && row.drop_off_description && row.drop_off_description !== '') ? HtmlParser(row.drop_off_description.substring(0, 160))+'...' : '';
                    //return (row && row.drop_off_description && row.drop_off_description !== '') ?<span style={{ width:350, overflowWrap: 'break-word', display:'inline-block'}}> {row.drop_off_description }</span>  : '';
                    return (row && row.drop_off_description && row.drop_off_description !== '') ? HtmlParser(row.drop_off_description) : '';

                  } else {
                    return row;
                  }
                }
              },
              { 
                title: "DO Number", 
                field: "do_number",
                cellStyle: {
                  minWidth: 150,
                  maxWidth: 150
                },
                hidden: this.state.hiddenColumns.includes('do_number'),
                render: (row, type) => {
                  if(type === 'row'){
                    return (row && row.do_number && row.do_number !== '') ? HtmlParser(row.do_number) : '';
                  } else {
                    return row;
                  }
                }
              },
              ... this.state.currentRole !== "Customer Template Role"  && this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver Payout')? [{ 
                title: "Driver Payout", 
                field: "driver_payout",
                cellStyle: {
                  minWidth: 150,
                  maxWidth: 150
                },
                hidden: this.state.hiddenColumns.includes('driver_payout'),
                render: (row, type) => {
                  if(type === 'row'){
                    return (row && row.driver_payout && row.driver_payout !== '') ? HtmlParser(row.driver_payout) : '';
                  } else {
                    return row;
                  }
                }
              }] : [],
              { 
                title: "Driver", 
                field: "drop_off_worker_name",
                cellStyle: {
                  minWidth: 150,
                  maxWidth: 150            
                },
                hidden: this.state.hiddenColumns.includes('driver'),
                defaultGroupOrder: this.state.defaultGroupOrder,
                render: (row, type) => {
                  if(type === 'row'){
                    if(row.drop_off_worker_id && row.drop_off_worker_id > 0){
                      if(!row.drop_off_worker.last_name){
                        row.workerName = row.drop_off_worker.first_name;
                      }else{
                        row.workerName = row.drop_off_worker.first_name +" "+ row.drop_off_worker.last_name;
                      }
                    }

                    return <Box>
                      <CustomAutosuggest 
                        disabled = {this.state.grantedAccess.isShouldShowJobAccess(this.state.currentRole, 'job_status') == false || (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver & Manpower Listing')) == false ? true: false}
                        className={'no-margin'}
                        placeholder={'Assign Driver'}
                        value={row.workerName}
                        onChange={(value) => {
                          let status = '';
        
                          if(value){
                            row.workerName = value.label;
                            row.workerId = value.value;
                            row.workerItem = value.item;
        
                            status = 'assigned';
                          } else {
                            row.workerName = '';
                            row.workerId = null;
                            row.workerItem = null;
        
                            status = 'not assigned';
                          }

                          // let orderStatusItem = apiUtil.customFilter(this.state.status, 'job_assign', status);}
                          let orderStatusItem = apiUtil.customFilter(this.state.status, 'status', status);
                          let data = {
                            data: [
                              {
                                id: [ row.id ],
                                job_steps: row.job_steps ? row.job_steps.map(item=> {
                                  item.job_step_id = item.id
                                  return item
                                }): row.job_steps,
                                order_status_id: (orderStatusItem) ? orderStatusItem.id : '',
                                send_notification_to_customer: true,
                                drop_off_worker_id: row.workerId,
                              }
                            ]
                          };
                          this.callUpdateStatusApi(data, (newRow) => {
                            if(newRow){
                              let newData = apiUtil.updateRow(this.state.data, newRow, 'id');
                              this.setState({
                                data: newData,
                              });
                            }
                          });
                        }}
                        onSearch={(value, e) => {
                          row.workerName = value;
        
                          this.callWorkerDriverReadApi(value, (arr) => {
                            e.showNewSuggestions(arr);
                          });
                        }}
                        onClick={(value, e) => {
                          this.callWorkerDriverReadApi(value, (arr) => {
                            e.showNewSuggestions(arr);
                          });
                        }}
                      />
                    </Box>;
                  } else {
                    return row;
                  }
                }
              },
              { 
                title: "Manpower", 
                field: "extra_workers[0].first_name",
                hidden: this.state.hiddenColumns.includes('manpower'),
                render: (row, type) => {
                  if(type === 'row'){
                    let label = '';
                    let manpowerName = (row.extra_workers && row.extra_workers.length > 0) ? row.extra_workers[0] : null;

                    if(manpowerName){
                      label = ((manpowerName.first_name && manpowerName.first_name !== '') ? manpowerName.first_name : '') + ' ' + ((manpowerName.last_name && manpowerName.last_name !== '') ? manpowerName.last_name : '');
                    }

                    row.manpowerName = label;

                    return <Box>
                      <CustomAutosuggest 
                        disabled = {this.state.grantedAccess.isShouldShowJobAccess(this.state.currentRole, 'job_status') == false || (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver & Manpower Listing')) == false ? true: false}
                        className={'no-margin'}
                        placeholder={'Assign Manpower'}
                        value={row.manpowerName}
                        onChange={(value) => {
                          if(value){
                            row.manpowerName = value.label;
                            row.manpowerId = value.value;
                            row.manpowerItem = value.item;
                          } else {
                            row.manpowerName = '';
                            row.manpowerId = null;
                            row.manpowerItem = null;
                          }

                          if (row.job_steps === undefined) {
                            row.job_steps = [];
                          }

                          let data = {
                            data: [
                              {
                                id: [ row.id ],
                                job_steps: row.job_steps.length > 0 ? row.job_steps.map(item=> {
                                  item.job_step_id = item.id
                                  return item
                                }): row.job_steps,
                                order_status_id: row.order_status_id,
                                send_notification_to_customer: true,
                                extra_worker_ids: (row.manpowerId) ? row.manpowerId.toString() : null,
                              }
                            ]
                          };
                          this.callUpdateStatusApi(data, (newRow) => {
                            if(newRow){
                              let newData = apiUtil.updateRow(this.state.data, newRow, 'id');
                              this.setState({
                                data: newData,
                              });
                            }
                          });
                        }}
                        onSearch={(value, e) => {
                          row.manpowerName = value;


                          this.callManpowerDriverReadApi(value, (arr) => {
                            e.showNewSuggestions(arr);
                          });
                        }}
                        onClick={(value, e) => {
                          this.callManpowerDriverReadApi(value, (arr) => {
                            e.showNewSuggestions(arr);
                          });
                        }}
                      />
                    </Box>;
                  } else {
                    return row;
                  }
                }
              },
              { 
                title: "Vendor Name", 
                field: "vendor_company_name",
                cellStyle: {
                  minWidth: 200,
                  maxWidth: 200            
                },
                hidden: this.state.hiddenColumns.includes('vendor_company_name'),
                render: (row, type) => {
                  if(type === 'row'){
                    return (row && row.vendor && row.vendor.application_company.company_name )? row.vendor.application_company.company_name : '';
                  } else {
                    return row;
                  }
                }
              },
              { 
                title: "POD Time", 
                field: "Completed_drop_off_date",
                cellStyle: {
                  minWidth: 150,
                  maxWidth: 150            
                },
                hidden: this.state.hiddenColumns.includes('drop_off_time'),
                render: (row, type) => {
                  if(type === 'row'){
                    return (row.order_status && row.order_status.status && row.order_status.status.toString().toLowerCase().trim().replace(' ', '-') === 'completed') ? HtmlParser(row.drop_off_time) : '';
                  } else {
                    return row;
                  }
                }
              },
              { 
                title: "Date", 
                field: "drop_off_date",
                cellStyle: {
                  minWidth: 150,
                  maxWidth: 150            
                },
                hidden: this.state.hiddenColumns.includes('drop_off_date'),
                render: (row, type) => {
                  if(type === 'row'){
                    // console.log("yse", row.drop_off_date.split(" "))
                    // console.log("yse", splitdate[0])
                    if(row && row.drop_off_date && row.drop_off_date !== '') {
                      var splitdate = row.drop_off_date.split(" ")
                      return splitdate[0];
                    } else {
                      return '';
                    }
                  } else {
                    return row;
                  }
                }
              },
              { 
                title: "Time Slot", 
                field: "drop_off_time_planned",
                hidden: this.state.hiddenColumns.includes('drop_off_time_planned'),
                render: (row, type) => {
                  if(type === 'row'){
                    return (row && row.drop_off_time_planned && row.drop_off_time_planned !== '') ? HtmlParser(row.drop_off_time_planned) : '';
                  } else {
                    return row;
                  }
                }
              },
              { 
                title: "Company Name",
                field: "drop_off_contact_name",
                cellStyle: {
                  minWidth: 150,
                  maxWidth: 150            
                },
                hidden: this.state.hiddenColumns.includes('drop_off_contact_name'),
                render: (row, type) => {
                  if(type === 'row'){
                    // return (row && row.drop_off_contact_name && row.drop_off_contact_name !== '') ? HtmlParser(row.drop_off_contact_name) : '';
                    return (row && row.drop_off_contact_name && row.drop_off_contact_name !== '') ? <span style={{ width:150, overflowWrap: 'break-word', display:'inline-block'}}> {row.drop_off_contact_name }</span>: '';

                  } else {
                    return row;
                  }
                }
              },
              { 
                title: "Receipient Name", 
                field: "drop_off_name",
                hidden: this.state.hiddenColumns.includes('drop_off_name'),
                render: (row, type) => {
                  if(type === 'row'){
                    // return (row && row.drop_off_name && row.drop_off_name !== '') ? HtmlParser(row.drop_off_name) : '';
                    return (row && row.drop_off_name && row.drop_off_name !== '') ? <span style={{ width:150, overflowWrap: 'break-word', display:'inline-block'}}> {row.drop_off_name}</span> : '';

                  } else {
                    return row;
                  }
                }
              },
              { 
                title: "Phone Number", 
                field: "drop_off_contact_no",
                hidden: this.state.hiddenColumns.includes('drop_off_contact_no'),
                render: (row, type) => {
                  if(type === 'row'){
                    // return (row && row.drop_off_contact_no && row.drop_off_contact_no !== '') ? HtmlParser(row.drop_off_contact_no) : '';
                    return (row && row.drop_off_contact_no && row.drop_off_contact_no !== '') ? <span style={{ width:150, overflowWrap: 'break-word', display:'inline-block'}}> {row.drop_off_contact_no}</span> : '';

                  } else {
                    return row;
                  }
                }
              },
              { 
                title: "Notify Email", 
                field: "notification_email",
                hidden: this.state.hiddenColumns.includes('notification_email'),
                render: (row, type) => {
                  if(type === 'row'){
                    // return (row && row.notification_email && row.notification_email !== '') ? row.notification_email : '';
                    return (row && row.notification_email && row.notification_email !== '') ? <span style={{ width:250, overflowWrap: 'break-word', display:'inline-block'}}> {row.notification_email }</span>: '';
                  } else {
                    return row;
                  }
                }
              },
              { 
                title: "Postal Code", 
                field: "drop_off_postal_code",
                hidden: this.state.hiddenColumns.includes('drop_off_postal_code'),
                render: (row, type) => {
                  if(type === 'row'){
                    return (row && row.drop_off_postal_code && row.drop_off_postal_code !== '') ? HtmlParser(row.drop_off_postal_code) : '';
                  } else {
                    return row;
                  }
                }
              },

              { 
                title: "Delivery Address", 
                field: "drop_off_address",
                cellStyle: {
                  minWidth: 150,
                  maxWidth: 150
                },
                hidden: this.state.hiddenColumns.includes('drop_off_address'),
                render: (row, type) => {
                  if(type === 'row'){
                    // return (row && row.drop_off_address && row.drop_off_address !== '') ? HtmlParser(row.drop_off_address) : '';
                    return (row && row.drop_off_address && row.drop_off_address !== '') ? <span style={{ width:250, overflowWrap: 'break-word', display:'inline-block'}}> {row.drop_off_address}</span> : '';
                  } else {
                    return row;
                  }
                }
              },
              { 
                title: "Document", 
                field: "document",
                cellStyle: {
                  minWidth: 50,
                  maxWidth: 50
                },
                hidden: this.state.hiddenColumns.includes('document'),
                render: (row, type) => {
                  if(type === 'row'){
                    return (row && row.extra_data && row.extra_data !== '') ? JSON.parse(row.extra_data).document : '';
                  } else {
                    return row;
                  }
                }
              },
              { 
                title: "Boxes", 
                field: "boxes",
                cellStyle: {
                  minWidth: 50,
                  maxWidth: 50
                },
                hidden: this.state.hiddenColumns.includes('boxes'),
                render: (row, type) => {
                  if(type === 'row'){
                    return (row && row.extra_data && row.extra_data !== '') ? JSON.parse(row.extra_data).boxes : '';
                  } else {
                    return row;
                  }
                }
              },
              { 
                title: "Pallets", 
                field: "pallets",
                cellStyle: {
                  minWidth: 50,
                  maxWidth: 50
                },
                hidden: this.state.hiddenColumns.includes('pallets'),
                render: (row, type) => {
                  if(type === 'row'){
                    return (row && row.extra_data && row.extra_data !== '') ? JSON.parse(row.extra_data).pallets : '';
                  } else {
                    return row;
                  }
                }
              },
              { 
                title: "Cartons",
                field: "cartons",
                cellStyle: {
                  minWidth: 50,
                  maxWidth: 50
                },
                hidden: this.state.hiddenColumns.includes('cartons'),
                render: (row, type) => {
                  if(type === 'row'){
                    return (row && row.extra_data && row.extra_data !== '') ? JSON.parse(row.extra_data).cartons : '';
                  } else {
                    return row;
                  }
                }
              },
              
              // { 
              //   title: (isCustomerRequired) ? "Company" : 'Recipient', 
              //   field: (isCustomerRequired) ? "company_name" : 'drop_off_name',
              //   hidden: (this.state.hiddenColumns.includes('drop_off_contact_name') || this.state.hiddenColumns.includes('drop_off_name')),
              //   render: (row, type) => {
              //     let date = (row && row.drop_off_date && row.order_status) ? moment(row.drop_off_date).format(apiUtil.getDefaultDateFormat()) : '';
              //     let time = (date && date !== '') ? (row && row.drop_off_time_planned && row.drop_off_time_planned) ? moment(row.drop_off_time_planned).format(apiUtil.getDefaultTimeFormat()) : '' : '';
                  
              //     let dateTime = '';
              //     if(date && time){
              //       dateTime = date + ' - ' + time;
              //     } else if (date){
              //       dateTime = date;
              //     } else if (time){
              //       dateTime = time;
              //     }

              //     if(type === 'row'){
              //       return <Box>
              //         <Box pb={2}>
              //           {isCustomerRequired ? row.company_name : row.drop_off_name}
              //         </Box>
              //         <Box>
              //           <Box>
              //             {dateTime}
              //           </Box>
              //           <Box>{row.job_steps && row.job_steps.length > 0 ? row.job_steps[0].location : ''}</Box>
              //           {row.amount !== null && <Box>Price: ${Number.parseFloat(row.amount).toLocaleString()}</Box>}
              //         </Box>
              //       </Box>;
              //     } else {
              //       return row;
              //     }
              //   }
              // },
              
      
              /*{ 
                title: "Delivery Info", 
                field: "drop_off_time_planned",
                hidden: this.state.hiddenColumns.includes('delivery_info'),
                render: (row, type) => {
                  if(type === 'row'){
                    let date = (row && row.drop_off_date && row.order_status) ? moment(row.drop_off_date).format(apiUtil.getDefaultDateFormat()) : '';
                    let time = (date && date !== '') ? (row && row.drop_off_time_planned && row.drop_off_time_planned) ? moment(row.drop_off_time_planned).format(apiUtil.getDefaultTimeFormat()) : '' : '';
                    
                    let dateTime = '';
                    if(date && time){
                      dateTime = date + ' - ' + time;
                    } else if (date){
                      dateTime = date;
                    } else if (time){
                      dateTime = time;
                    }
                    
                    return <div>
                    <Box>
                    {dateTime}
                    </Box>
                    <p>{row.job_steps && row.job_steps.length > 0 ? row.job_steps[0].location : ''}</p>
                    {row.amount !== null && <p>Price: ${Number.parseFloat(row.amount).toLocaleString()}</p>}
                    </div>;
                  } else {
                    return row;
                  }
                }
              },*/
              { 
                title: "Job Type",
                field: "job_type",
                hidden: this.state.hiddenColumns.includes('job_type'),
              },
              { 
                title: "Notes to Driver-Partner",
                field: "driver_notes",
                cellStyle: {
                  minWidth: 150,
                  maxWidth: 150
                },
                hidden: this.state.hiddenColumns.includes('driver_notes'),
              },
              { 
                title: "Uploader",
                field: "uploader",
                cellStyle: {
                  minWidth: 50,
                  maxWidth: 50
                },
                hidden: this.state.hiddenColumns.includes('uploader'),
                render: (row, type) => {
                  if(type === 'row'){
                    return (row && row.uploader && row.uploader !== '') ? `${row.uploader.first_name ? row.uploader.first_name : ''}  ${row.uploader.last_name ? row.uploader.last_name: ''}` : '';
                  } else {
                    return row;
                  }
                }
              },
              ... this.state.grantedAccess.admin_roles.includes(this.state.currentRole)  ? [{ 
                title: "Price", 
                field: "price",
                cellStyle: {
                  minWidth: 100,
                  maxWidth: 100
                },
                hidden: this.state.hiddenColumns.includes('price'),
                render: (row, type) => {
                  if(type === 'row'){
                    return (row && row.amount && row.amount !== '') ? HtmlParser(row.amount) : '';
                  } else {
                    return row;
                  }
                }
              }] : [],
              { 
                title: "ACTION", 
                cellStyle: {
                  minWidth: 180,
                  maxWidth: 180,
                  position: 'sticky',
                  right:0,
                  zIndex:1,
                  backgroundColor: '#FFFFFF',
                  
                },
                // align: 'center',
                sorting: false,
                render: (row) => {
                  return <Box>
                    <Grid container justify={'center'}>
                    <Box clone>
                        <Grid item xs={'auto'}>
                        {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')) &&<IconButton                 
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
        
                                this.setState({
                                  openPreviewDialog: true,
                                  openPreviewDialogItem: row.id,
                                });
                              }}
                          >
                            <span  data-tooltip="view job" data-tooltip-conf="danger">
                            <FontAwesomeIcon icon={faCircle} component={'i'} style={{ fontSize: '18px', color: '#4e4e4e' }} className="tooltip"/>
                              {/* <Icon component={'i'} style={{ fontSize: '20px', color: '#4e4e4e' }} className="tooltip">remove_red_eye_icon</Icon> */}
                            </span>
                          </IconButton>
                }
                        </Grid>

                      </Box><Box clone>
                        <Grid item xs={'auto'}>
                          <IconButton
                            onClick={() => {
                              this.generatePDF(row);
                            }}
                          >
                            <span  data-tooltip="generate label" data-tooltip-conf="danger">
                            <FontAwesomeIcon icon={faBarcode} component={'i'} style={{ fontSize: '18px', color: '#4e4e4e' }} className="tooltip"/>
                            {/* <Icon component={'i'} style={{ fontSize: '20px', color: '#4e4e4e' }} className="tooltip">view_week</Icon> */}
                            </span>
                          </IconButton>
                        </Grid>
                      </Box>

                      <Box clone>
                        <Grid item xs={'auto'}>
                          <IconButton
                            onClick={() => {
                              window.open(REACT_APP_LDS_API_URL + '/dynamic_delivery_orders_steps/' + row.id + '.pdf','_blank');
                            }}
                          >
                            <span  data-tooltip="download pod" data-tooltip-conf="danger">
                            <FontAwesomeIcon icon={faFile} component={'i'}  style={{ fontSize: '18px', color: '#4e4e4e' }} className="tooltip"/>
                              {/* <Icon component={'i'} style={{ fontSize: '20px', color: '#4e4e4e' }} className="tooltip">insert_drive_file</Icon> */}
                            </span>
                          </IconButton>
                        </Grid>
                      </Box>

                      <Box clone>
                        <Grid item xs={'auto'}>
                          <IconButton
                            onClick={() => {
                              let copyData = this.state.copyData;
                              copyData.id = row.id;
                              this.setState({
                                copyDialogOpen: true,
                                copyDataId: copyData
                              });
                            }}
                          >
                            <span  data-tooltip="duplicate job" data-tooltip-conf="danger">
                            <FontAwesomeIcon icon={faCopy} component={'i'}style={{ fontSize: '18px', color: '#4e4e4e' }} className="tooltip"/>
                              {/* <CopyIcon /> */}
                          </span>
                          </IconButton>
                        </Grid>
                      </Box>
                      
                      <Box clone>
                        <Grid item xs={'auto'}>
                        {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Edit')) &&<IconButton
                            onClick={() => {
                              this.props.history.push({
                                pathname: '/jobs-form',
                                state: {
                                  id: row.id
                                }
                              });
                            }}
                          >
                            <span  data-tooltip="edit job" data-tooltip-conf="danger">
                            <FontAwesomeIcon icon={faPen} component={'i'}style={{ fontSize: '18px', color: '#4e4e4e' }} className="tooltip"/>
                              {/* <EditIcon className="tooltip"/> */}
                              </span>
                          </IconButton>
                        }
                        </Grid>
                      </Box>
                      <Box clone>
                        <Grid item xs={'auto'}>
                        {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Delete')) &&<IconButton
                            onClick={() => {
                              this.setState({
                                openDialog: true,
                                openDialogItem: row,
                              });
                            }}
                          >
                            <span  data-tooltip="delete job" data-tooltip-conf="danger">
                            <FontAwesomeIcon icon={faTrashCan} component={'i'}style={{ fontSize: '18px', color: '#CF3E3E' }} className="tooltip"/>
                              {/* <DeleteIcon className="tooltip"/> */}
                            </span>
                          </IconButton>
                    }
                        </Grid>
                      </Box>
                    </Grid>
                  </Box>
                }
              },
            ]}
          />
    } else {
      return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
        <CircularProgress size={35} />
      </Box>
    }

    
  }

  customToolbar = () => {
    return <Box>
      <Grid container>
        <Box clone order={{ xs: 3, md: 1}}>
          <Grid item xs={12} md={3}>
            <CustomInput
              ref={this.refTableSearch}
              placeholder={'Search job'}
              endAdornment={
                <IconButton
                  onClick={(e) => {
                    this.callReadApi(this.refTableSearch.current.state.value);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              }
              onPressEnter={(e) => {
                this.callReadApi(e.target.value);
              }}
            />
          </Grid>
        </Box>
        <Box clone pb={{ xs: 2, md: 0}} order={{ xs: 2, md: 2}} pr={{ xs: 0, md: 2}} textAlign={'right'}>
          <Grid item xs={12} md={true}>
            <GroupButton
              color={'secondary'}
              selected={this.state.jobTabTable}
              buttons={[ "Own Jobs", "Vendor Jobs", "Table Column" ]}
              onClick={(selected, buttons) => {
                if (selected === 2) {
                  this.setState({
                    openSettingsDialog: true
                  });
                } else if (selected === 0){
                  this.setState({
                    jobTabTable: 0,
                  }, () => {
                    this.props.history.push('/job-list');
                  });
                }
              }}
            />
          </Grid>
        </Box>
        <Box clone textAlign={'right'} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}}>
          <Grid item xs={12} md={'auto'}>
              <CustomButton 
                color={'primary'}
                onClick={() => {
                  this.callExportXLSApi();
                }}
              >
                <ExportIcon />
                <Box display={'inline-block'} pl={1}>Export To Excel</Box>
              </CustomButton>
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  
  customRow = (e, row) => {
    // let desc = (row && row.drop_off_description && row.drop_off_description !== '') ? row.drop_off_description : '';

    let date = (row && row.drop_off_date && row.order_status) ? moment(row.drop_off_date).format(apiUtil.getDefaultDateFormat()) : '';
    let time = (date && date !== '') ? (row && row.drop_off_time_planned && row.drop_off_time_planned) ? moment(row.drop_off_time_planned).format(apiUtil.getDefaultTimeFormat()) : '' : '';
    
    let dateTime = '';
    if(date && time){
      dateTime = date + ' - ' + time;
    } else if (date){
      dateTime = date;
    } else if (time){
      dateTime = time;
    }

    return <tr>
      <td 
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >
        <Box pb={1}>
          <DropdownMenu
            className={'status-btn ' + apiUtil.statusToClassName((row.order_status && row.order_status.status && row.order_status.status !== '') ? row.order_status.status : '')}
            text={(row && row.order_status && row.order_status.status) ? row.order_status.status : ''}
            rightIcon={<ArrowDownIcon />}
            items={this.state.tableStatus}
            onClick={(item) => {
              if(row.drop_off_worker_id && row.drop_off_worker_id > 0){
                let data = {
                  data: [
                    {
                      id: [ row.id ],
                      job_steps: (row.job_steps && row.job_steps.length > 0) ? row.job_steps.map(step => {
                        step.job_step_id = step.id;
                        if (item.text.toString().toLowerCase().trim() === "completed") {
                          step.job_step_status_id = 3;
                        } else {
                          step.job_step_status_id = 1;
                        }
                        return step
                      }): row.job_steps,
                      order_status_id: item.id,
                      send_notification_to_customer: true,
                    }
                  ]
                };

                if(item.text.toString().toLowerCase().trim() === 'not assigned'){
                  data.data[0]['drop_off_worker_id'] = null;
                  data.data[0]['extra_worker_ids'] = null;
                }

                this.callUpdateStatusApi(data, (newRow) => {
                  if(newRow){
                    let newData = apiUtil.updateRow(this.state.data, newRow, 'id');
                    this.setState({
                      data: newData,
                    });
                  }
                });
              } else {
                apiUtil.toast('You must first assign a driver!', 'warning', 'error');
              }
            }}
          />
        </Box>
      </td>
      <td 
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >
        <Box pl={2}>
          <Box>
            {dateTime}
          </Box>
          <Box>{row.job_steps && row.job_steps.length > 0 ? row.job_steps[0].location : ''}</Box>
          {row.amount !== null && <Box>Price: ${Number.parseFloat(row.amount).toLocaleString()}</Box>}
        </Box>
      </td>
      <td 
        colSpan={6}
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >&nbsp;</td>
      {/* <td 
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >&nbsp;</td>
      <td 
        colSpan={5}
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >
        {(desc !== '') && <Box>
          <Box pl={2} pb={2}>
            <CustomReadMore className={'lightGray'} maxLine={2} text={(row && row.drop_off_description && row.drop_off_description !== '') ? row.drop_off_description : ''} />
          </Box>  
        </Box>}
      </td> */}
      <td 
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >&nbsp;</td>
    </tr>
  }
  /* END TABLE */
  

  /* DIALOG */
  customDialog = () => {
    return <CustomDialog 
      open={this.state.openDialog}
      title={'Delete'}
      onClose={() => {
        this.setState({
          openDialog: false,
          openDialogItem: null,
        });
      }}
      onOk={() => {
        let row = this.state.openDialogItem;
        this.callDeleteApi(row, () => {
          this.setState({
            openDialog: false,
            openDialogItem: null,
          }, () => {
            this.callReadApi(this.refTableSearch.current.state.value);
            apiUtil.toast('Successfully Deleted', 'check_circle');
          });
        });
      }}
    >
      <Box>Are you sure you want to delete?</Box>
    </CustomDialog>
  }

  toggleColumn = (checked, columns) => {
    let hiddenColumns = this.state.hiddenColumns;

    if (columns.length > 0) {
      if (checked === true) { // hide column
        hiddenColumns = hiddenColumns.concat(columns);
      } else { // show column
        columns.forEach(column => {
          let index = hiddenColumns.indexOf(column);
          if (index !== -1) {
            hiddenColumns.splice(index, 1);
          }
        });
      }
    }

    this.setState({
      hiddenColumns: hiddenColumns
    });
  }

  columnSettingsDialog = () => {
    const { accessToken } = this.context;
    let hiddenColumns = this.state.hiddenColumns;

    return <CustomDialog
      title={'Column Settings'}
      btnOkText={'Save Settings'}
      open={this.state.openSettingsDialog}
      onOk={() => {
        this.setState({
          isLoading: true,
          hiddenColumns: hiddenColumns
        });

        if (this.state.hiddenColumnSettingId > 0) {
          api.update('application_company_settings/' + this.state.hiddenColumnSettingId, {
            json_data: JSON.stringify(hiddenColumns)
          }, accessToken)
            .then(result => {
              let hiddenColumnSettingId = 0;
              if (result.data.data.result.id) {
                hiddenColumnSettingId = result.data.data.result.id;
                localStorage.setItem("job_column_settings", JSON.stringify(result.data.data.result));
              }

              this.setState({
                openSettingsDialog: false,
                hiddenColumnSettingId: hiddenColumnSettingId,
                isLoading: false
              })
          });
        } else {
          api.create('application_company_settings', {
            enabled: true,
            rule: "job_column_settings",
            json_data: JSON.stringify(hiddenColumns)
          }, accessToken)
            .then(result => {
              let hiddenColumnSettingId = 0;
              if (result.data.data.result.id) {
                hiddenColumnSettingId = result.data.data.result.id;
                localStorage.setItem("job_column_settings", JSON.stringify(result.data.data.result));
              }

              this.setState({
                openSettingsDialog: false,
                hiddenColumnSettingId: hiddenColumnSettingId,
                isLoading: false
              })
          });
        }
      }}
      onClose={() => {
        this.setState({
          openSettingsDialog: false
        })
      }}
    >
     <CustomCheckbox checked={this.state.hiddenColumns.includes('order_number')} label={'Hide Job Number'} onChange={(checked) => this.toggleColumn(checked, ['order_number'])} />
     <CustomCheckbox checked={(this.state.hiddenColumns.includes('drop_off_contact_name') || this.state.hiddenColumns.includes('drop_off_name'))} label={'Hide Company'} onChange={(checked) => this.toggleColumn(checked, ['drop_off_contact_name', 'drop_off_name'])} />
     {/* <CustomCheckbox checked={this.state.hiddenColumns.includes('order_status')} label={'Hide Status'} onChange={(checked) => this.toggleColumn(checked, ['order_status'])} /> */}
     <CustomCheckbox checked={this.state.hiddenColumns.includes('driver')} label={'Hide Driver'} onChange={(checked) => this.toggleColumn(checked, ['driver'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('manpower')} label={'Hide Manpower'} onChange={(checked) => this.toggleColumn(checked, ['manpower'])} />
     {/* <CustomCheckbox checked={this.state.hiddenColumns.includes('delivery_info')} label={'Hide Delivery Info'} onChange={(checked) => this.toggleColumn(checked, ['delivery_info'])} /> */}
     <CustomCheckbox checked={this.state.hiddenColumns.includes('vendor_company_name')} label={'Hide Vendor Name'} onChange={(checked) => this.toggleColumn(checked, ['vendor_company_name'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('do_number')} label={'Hide Do Number'} onChange={(checked) => this.toggleColumn(checked, ['do_number'])} /> 
     <CustomCheckbox checked={this.state.hiddenColumns.includes('drop_off_description')} label={'Hide Description'} onChange={(checked) => this.toggleColumn(checked, ['drop_off_description'])} /> 
     <CustomCheckbox checked={this.state.hiddenColumns.includes('drop_off_time')} label={'Hide POD Time'} onChange={(checked) => this.toggleColumn(checked, ['drop_off_time'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('drop_off_date')} label={'Hide Date'} onChange={(checked) => this.toggleColumn(checked, ['drop_off_date'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('drop_off_time_planned')} label={'Hide Time Slot'} onChange={(checked) => this.toggleColumn(checked, ['drop_off_time_planned'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('drop_off_contact_name')} label={'Hide Company Name'} onChange={(checked) => this.toggleColumn(checked, ['drop_off_contact_name'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('drop_off_name')} label={'Hide Receipient Name'} onChange={(checked) => this.toggleColumn(checked, ['drop_off_name'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('drop_off_contact_no')} label={'Hide Phone Number'} onChange={(checked) => this.toggleColumn(checked, ['drop_off_contact_no'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('notification_email')} label={'Hide Notify Email'} onChange={(checked) => this.toggleColumn(checked, ['notification_email'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('drop_off_postal_code')} label={'Hide Postal Code'} onChange={(checked) => this.toggleColumn(checked, ['drop_off_postal_code'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('drop_off_address')} label={'Hide Delivery Address'} onChange={(checked) => this.toggleColumn(checked, ['drop_off_address'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('document')} label={'Hide Document'} onChange={(checked) => this.toggleColumn(checked, ['document'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('boxes')} label={'Hide Boxes'} onChange={(checked) => this.toggleColumn(checked, ['boxes'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('pallets')} label={'Hide Pallets'} onChange={(checked) => this.toggleColumn(checked, ['pallets'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('cartons')} label={'Hide Cartons'} onChange={(checked) => this.toggleColumn(checked, ['cartons'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('job_type')} label={'Hide Job Type'} onChange={(checked) => this.toggleColumn(checked, ['job_type'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('driver_notes')} label={'Hide Driver Notes'} onChange={(checked) => this.toggleColumn(checked, ['driver_notes'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('uploader')} label={'Hide Uploader'} onChange={(checked) => this.toggleColumn(checked, ['uploader'])} />
     {this.state.currentRole !== "Customer Template Role"  && this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver Payout') && <CustomCheckbox checked={this.state.hiddenColumns.includes('driver_payout')} label={'Hide Driver Payout'} onChange={(checked) => this.toggleColumn(checked, ['driver_payout'])} /> }
     {this.state.grantedAccess.admin_roles.includes(this.state.currentRole) && <CustomCheckbox checked={this.state.hiddenColumns.includes('price')} label={'Hide Price'} onChange={(checked) => this.toggleColumn(checked, ['price'])} /> }
    </CustomDialog>
  }

  previewDialog = () => {
    if(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')){
    return <CustomDialog 
      open={this.state.openPreviewDialog}
      title={'Order Details'}
      maxWidth={'lg'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openPreviewDialog: false,
          openPreviewDialogItem: null,
        });
      }}
    >
      <JobPreviewDialog
        id={this.state.openPreviewDialogItem}
        onClose={() => {
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
        }}
      />
    </CustomDialog>
    }
  }

  importDialog = () => {
    return <CustomDialog 
      open={this.state.openImportDialog}
      title={'Import Jobs'}
      maxWidth={'xl'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openImportDialog: false,
          openImportDialogItem: null,
        });
      }}
    >
      <JobImportDialog
        file={this.state.openImportDialogItem}
        isLoading={this.state.openImportDialogIsLoading}
        onClose={() => {
          this.setState({
            openImportDialog: false,
            openImportDialogItem: null,
          });
        }}
        onImport={(form) => {
          this.setState({
            openImportDialogIsLoading: true
          }, () => {
            this.callImportApi(form, (data) => {
              let all_jobs = (form && form.length > 0) ? form.length : 0;
              let failed_orders = (data && data.failed_orders && data.failed_orders.length > 0) ? data.failed_orders.length : 0;
              let invalid_company_code_orders = (data && data.invalid_company_code_orders && data.invalid_company_code_orders.length > 0) ? data.invalid_company_code_orders.length : 0;
              let saved_orders = (data && data.saved_orders && data.saved_orders.length > 0) ? data.saved_orders.length : 0;
              
              if(failed_orders > 0){
                apiUtil.toast(failed_orders + ' of ' + all_jobs + ' jobs have not successfully imported', 'check_circle', 'error');
              }

              if(invalid_company_code_orders > 0){
                apiUtil.toast(invalid_company_code_orders + ' of ' + all_jobs + ' company code does not match, jobs have not successfully imported', 'check_circle', 'error');
              }
  
              if(saved_orders > 0){
                apiUtil.toast(saved_orders + ' of ' + all_jobs + ' Jobs Successfully Imported', 'check_circle', 'success');
              }
  
              this.callReadApi(this.refTableSearch.current.state.value);
  
              this.setState({
                openImportDialog: false,
                openImportDialogItem: null,
                openImportDialogIsLoading: false,
              });
            });
          });
        }}
      />
    </CustomDialog>
  }

  toggleCopyData = (checked, column) => {
    let copyData = this.state.copyData;
    copyData[column] = checked;
    this.setState({
      copyData: copyData
    });
  }

  copyDialog = () => {
    return <CustomDialog
      title={'Duplicate Job'}
      btnOkText={'Duplicate Job'}
      open={this.state.copyDialogOpen}
      onOk={() => {
        this.props.history.push({
          pathname: "/jobs-form",
          copyData: this.state.copyData
        });
      }}
    >
      <p>Which items you want to duplicate as a new job?</p>
      <p className="modal-subtitle">Job Details</p>
      <CustomCheckbox label={'Job Date'} checked={this.state.copyData.drop_off_date} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_date');
      })} />
      <CustomCheckbox label={'Job Description'} checked={this.state.copyData.drop_off_description} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_description');
      })} />
      <CustomCheckbox label={'Job Time'} checked={this.state.copyData.drop_off_time_planned} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_time_planned');
      })} />

      <p className="modal-subtitle">Recipient Details</p>
      <CustomCheckbox label={'Name'} checked={this.state.copyData.drop_off_name} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_name');
      })} />
      <CustomCheckbox label={'Email'} checked={this.state.copyData.drop_off_contact_email} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_contact_email');
      })} />
      <CustomCheckbox label={'Phone No'} checked={this.state.copyData.drop_off_contact_no} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_contact_no');
      })} />
      <CustomCheckbox label={'Tracking Number'} checked={this.state.copyData.item_tracking_number} onChange={((checked) => {
        this.toggleCopyData(checked, 'item_tracking_number');
      })} />

      <p className="modal-subtitle">Additional Details</p>
      <CustomCheckbox label={'Items in this job'} checked={this.state.copyData.order_details} onChange={((checked) => {
        this.toggleCopyData(checked, 'order_details');
      })} />
      <CustomCheckbox label={'Assign Workers'} checked={this.state.copyData.drop_off_worker} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_worker');
      })} />
      <CustomCheckbox label={'Job Steps'} checked={this.state.copyData.job_steps} onChange={((checked) => {
        this.toggleCopyData(checked, 'job_steps');
      })} />
      <CustomCheckbox label={'Advanced Options'} checked={this.state.copyData.amount} onChange={((checked) => {
        this.toggleCopyData(checked, 'amount');
      })} />
    </CustomDialog>
  }
  /* END DIALOG */


  render() {
    return <Box className="job-list-page" >
      <StickyPageHeader isSmall={false}>
        {this.customStickyPageHeader()}
      </StickyPageHeader>
      
      <Card className={'pb-0'}>
        {this.customStatus()}
      </Card>

      <Card>
        {this.customTable()}
      </Card>
      
      {this.customDialog()}
      {this.previewDialog()}
      {this.importDialog()}
      {this.columnSettingsDialog()}
      {this.copyDialog()}
    </Box>;
  }
}