import React, { Component } from 'react';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import BreadCrumb from "../../components/Navs/Breadcrumb"
import { 
  Box,
  Grid,
  Select,
  CircularProgress,
  Menu,
  MenuItem,
} from '@material-ui/core';
import _ from 'lodash';
import apiUtil from "../../api/apiUtil.jsx";
import { withRouter, Link } from 'react-router-dom';
import api from "../../api/api.jsx";
import wmsApi from "../../api/wmsApi.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import { Container, Row, Col, Button, Card, Modal } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket, faDownload, faFileLines } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import JobImportDialog from '../Jobs/Partial/JobImportDialog.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import OrderTemplate from "../../assets/files/OrderFormforAdmin.xlsx"
import ClientOrderTemplate from "../../assets/files/OrderFormforClient.xlsx"
import { ReactComponent as CalendarIcon } from "../../assets/img/icons/calendar.svg";
import {RoleAccessService} from '../../data/role-access'; 
import { saveAs } from "file-saver";
import DropdownMenu from '../../components/Dropdown/DropdownMenuFilter.jsx';
import CustomGoogleMapSearch from "../../components/GoogleMap/CustomGoogleMapSearch.jsx";
import CustomInput from "../../components/CustomFields/CustomInput.jsx";
import Api from "../../api/api.jsx";

class AdminNewOrder extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);
    this.fileInput = React.createRef();
    this.state = {
      grantedAccess : new RoleAccessService(),
      openImportDialog : false,
      openImportDialogItem: null,
      data_form : [],
      currentRole: window.localStorage.getItem('current_role'),
      address: [],
      deepCloneAddress:[],
      drop_off_address:null,
      downloadMenu: false,
      drop_off_address_id: null,
      drop_off_latitude:null,
      drop_off_longitude:null,
      other_detail:null,
      do_number:null,
      key: false,
      is_admin_address: false,
      drop_off_contact_name: null,
      building_no: null,
      drop_off_contact_no: null,
      drop_off_date: null,
      from_time: '9:00',
      to_time: '18:00',
      drop_off_contact_no_validated: false,
      openConfirmDialog: false,
      customerItems: [],
      selected_customer_id: null,
      excle_file: '',
      collection_data : {
        drop_off_address: null,
        drop_off_postal_code: null,
        drop_off_latitude:null,
        drop_off_longitude: null,
        assign_type: 'Own',
        other_detail: null,
        do_number: null,
        drop_off_contact_name: null,
        drop_off_contact_no: null,
        drop_off_date: null,
        from_time: '9:00',
        to_time: '18:00',
        period_of_delivery: 'Same Day',
        is_project: false,
        job_type: 'Collection',
        is_notify_customer: true,
        is_tracking_link_required: true,
        order_status_id: null,
        is_customer_id: null,
      }
    }
  }
  componentDidMount() {
    this.callReadSettingsApi();
    this.callDoApi(moment().format('YYYY-MM-DD'));
    this.callAddressApi();
    this.callCustomerReadApi();
    
  }

   /* CUSTOMER API */
   callCustomerReadApi = (callback = null) => {
    const { accessToken, isOnBoarding, onBoardingCustomer } = this.context;
        let param = {
          is_active: true,
          invoice_light_data: true
        };
        this.setState({
            customerItemsIsLoading : true
        })            
        wmsApi.read('customers', param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              let result = (data.result && data.result.length > 0) ? data.result : [];
  
              let arr = [];
              if(result && result.length > 0){
                
                for(let i = 0; i < result.length; i++){
                  let item = result[i];
                  
                  let value = item.id;
                  let label = item.company_name;

                  if(arr.findIndex(x => x.value === value) === -1){
                    arr.push({
                      value: value,
                      label: label,
                      item: item
                    });
                  }
                }

                this.setState({
                  customerItemsIsLoading: false,
                  customerItems: arr.sort((a, b) => {
                    const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                  
                    // names must be equal
                    return 0;
                  }),
                }, () => {
                    if(callback){
                      callback();
                    }
                  });

              } else {
                this.setState({
                  customerItemsIsLoading: false,
                  customerItems: [],
                }, () => {
                  if(callback){
                    callback([]);
                  }
                });
              }
            }, (error, type) => {
              this.setState({
                customerItemsIsLoading: false,
                customerItems: [],
              }, () => {
                if(callback){
                  callback([]);
                }
              });
            });
        });
  }

  callReadSettingsApi = () => {
    const { accessToken } = this.context;
    let company = window.localStorage.getItem('company_id');
    Api.read('profile/admins', {}, accessToken)
    .then(result => {
        // console.log(result)
        apiUtil.parseResult(result, data => {
            // const res = (data.result && Object.keys(data.result).length > 0 ) ? data.result : {}
            // setCurrentMeasurementData(res);
            if(data.result && Object.keys(data.result).length > 0) {

                let user = JSON.parse(window.localStorage.getItem("user"));
                user.owner_info = data.result;
                window.localStorage.setItem('user', JSON.stringify(user));

            }
        }, (error, type) => {
        })
    })
}

  callAddressApi = () => {
    const { accessToken } = this.context;
   
    this.setState({
        isLoading: true,
      }, () => {
        let param = {
          sort: 'latest'
        };
  
        wmsApi.read('warehouses', param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              let address = (data.result && data.result.length > 0) ? data.result : []
              let user_customer_id = this.state.user && this.state.user.owner_info && this.state.user.owner_info.customers && this.state.user.owner_info.customers.length > 0 && this.state.user.owner_info.customers[0].id ? this.state.user.owner_info.customers[0].id : null
              let user = JSON.parse(window.localStorage.getItem('user'));
              let inner_user_id = user && user.owner_info.customers && user.owner_info.customers.length > 0 && user.owner_info.customers[0].id ? user.owner_info.customers[0].id : null

              if (this.state.currentRole === "Customer Template Role") {
                address = address.filter(dd => dd.customer_id === null || dd.customer_id === user_customer_id || dd.customer_id === inner_user_id);
              } else {
                address = address.filter(dd => dd.customer_id === null);
              }
              this.setState({
                address: address,
                deepCloneAddress:_.cloneDeep(address),
                total: data.total,
                isLoading: false,
              });
            }, (error, type) => {
              this.setState({
                data: [],
                total: 0,
                isLoading: false,
              });
            });
        });
      });
}
callDoApiNoLoading = (date = null) => {
  const { accessToken } = this.context;
 
  this.setState({
    }, () => {
      let param = {
        date: date
      };

      ldsApi.read('do_number', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {      
            this.setState({
              do_number: data ? data.do_number : null,
              isLoading: false,
            },()=> {
              // console.log('do_number', this.state.do_number, data)
            });
          }, (error, type) => {
            this.setState({
              data: [],
              isLoading: false,
            });
          });
      });
    });
}

callDoApi = (date = null) => {
  const { accessToken } = this.context;
 
  this.setState({
    isLoading: true,
    }, () => {
      let param = {
        date: date
      };

      ldsApi.read('do_number', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {      
            this.setState({
              do_number: data ? data.do_number : null,
              isLoading: false,
            },()=> {
              // console.log('do_number', this.state.do_number, data)
            });
          }, (error, type) => {
            this.setState({
              data: [],
              isLoading: false,
            });
          });
      });
    });
}
  /* IMPORT API */
  callImportApi = (row, callback = null) => {

  if(this.state.from_time !== '' && this.state.to_time  !== '') {
      let start_time = moment(this.state.from_time,'hh:mm');
      let end_time = moment(this.state.to_time,'hh:mm');
      let diff_time = end_time.diff(start_time, 'hours',true);
      // console.log(diff_time);
      if (diff_time < 0 ||this.state.from_time == this.state.to_time || isNaN(diff_time)) {
          apiUtil.toast("Between and And time is invalid!!")
          this.setState({
            isLoading: false
          })
          return
      }
      
  }

    const { accessToken } = this.context;
    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('create/orders/multiple', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
              drop_off_address:null,
              drop_off_address_id:null,
              drop_off_latitude:null,
              drop_off_longitude:null,
              other_detail:null,
              do_number:null,
              drop_off_contact_name: null,
              drop_off_contact_no: null,
              drop_off_date: null,
              excle_file: '',
              openImportDialogItem: null,
              from_time: '9:00',
              to_time: '18:00',
            }, () => {
              this.fileInput.current.value = '';
              if(callback){
                callback(data);
              } else {
                apiUtil.toast("Jobs Successfully Imported", 'check_circle')
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
              drop_off_address:null,
              drop_off_address_id:null,
              drop_off_latitude:null,
              drop_off_longitude:null,
              other_detail:null,
              do_number:null,
              drop_off_contact_name: null,
              drop_off_contact_no: null,
              drop_off_date: null,
              excle_file: '',
              openImportDialogItem: null,
              from_time: '9:00',
              to_time: '18:00',
            }, () => {
              this.fileInput.current.value = '';
              apiUtil.toast(
                error,
                "check_circle"
              );
            });
          });
      });
    });
  }
  /* END IMPORT API */

  confirmDialog = () => {
    return <Modal centered show={this.state.openConfirmDialog} onHide={() => {
      this.setState({
        openConfirmDialog: false,
      });
      }}>
      <Modal.Header className="p-4" closeButton>
      <div className="text-center font-medium fs-5 d-flex justify-content-center w-100">Are you Sure?</div>
      </Modal.Header>
      
      <Container fluid>
        <Row>
          <Col className="p-4 text-center">
          The deliveries you have uploaded will be collected from <strong>{this.state.drop_off_address} {this.state.building_no}</strong> on <strong>{this.state.drop_off_date ? moment(this.state.drop_off_date).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')}</strong>
          </Col>
        </Row>
      </Container>
        <div className="justify-content-center p-4">
        <div className="d-flex justify-content-center">
          <Button variant="secondary" className="mx-2 px-4 py-2 border-delete-b" onClick={() => {
                this.setState({
                  openConfirmDialog: false,
                });
              }}>
                Cancel
          </Button>
          <Button variant="primary" className="mx-2 px-4 py-2" style={{borderRadius:'50px'}}
            onClick={() => {
              this.setState({
                openConfirmDialog: false,
              }, () => {
                if (
                  this.state.drop_off_contact_no &&
                  !apiUtil.phoneValidationWithPlus(this.state.drop_off_contact_no)
                ) {
                  this.setState({
                    drop_off_contact_no_validated: true,
                  });
                  return
                }

                this.setState({
                  isLoading: true 
                });
                let form = this.state.data_form && this.state.data_form.filter(dd => dd.job_type != 'Collection');
                let param = []
                if (this.state.drop_off_address == null || this.state.drop_off_address == '' ) {
                  param = this.state.data_form
                } else {
                  form.map((dd, index)=> {
                    form[index]['drop_off_date'] = this.state.drop_off_date == null || this.state.drop_off_date == '' ? moment().format('YYYY-MM-DD') : this.state.drop_off_date
                    form[index]['job_type'] = 'Delivery'
                    form[index]['collection_reference'] = this.state.do_number
                  })
                  let data = [{
                    is_manual: this.state.is_customer_id != null ? true : false,
                    drop_off_contact_no: this.state.drop_off_contact_no,
                    drop_off_alternate_contact: null,
                    drop_off_date: this.state.drop_off_date == null || this.state.drop_off_date == '' ? moment().format('YYYY-MM-DD') : this.state.drop_off_date,
                    drop_off_from_time: this.state.from_time,
                    drop_off_to_time: this.state.to_time,
                    customer_id: this.state.currentRole == 'Customer Template Role'? this.state.customerItems[0].item.id : null,
                    selected_customer_id: this.state.selected_customer_id,
                    drop_off_name: null,
                    drop_off_contact_name: this.state.drop_off_contact_name,
                    drop_off_description: null,
                    drop_off_address: this.state.drop_off_address + (this.state.building_no ? ', ' + this.state.building_no : '') + (this.state.other_detail != null ? ', ' + this.state.other_detail : ''),
                    drop_off_latitude: this.state.drop_off_latitude,
                    drop_off_longitude: this.state.drop_off_longitude,
                    job_type: "Collection",
                    is_calculate: true,
                    company_code: "",
                    do_number: this.state.do_number,
                    is_project: "FALSE",
                    collection_reference: null,
                    notification_email: null,
                    department_id: null,
                    driver_notes: null,
                    pallets: null,
                    drop_off_postal_code: this.state.postal_code,
                    zone_id: null,
                    is_notify_customer: true,
                    is_tracking_link_required: true,
                    xs_packages: null,
                    s_packages: null,
                    m_packages: null,
                    l_packages: null,
                    oversized_packages: null,
                    period_of_delivery: "Same Day",
                    extra_data: JSON.stringify({collection_mergable:true})
                  }]
                  param = data.concat(form)
                }
                let duplicateDOs = param.map(e => e.do_number && e.do_number.trim())
                               .map((e, i, final) => final.indexOf(e) !== i && i)
                               .filter(obj=> param[obj])
                               .map(e => param[e]["do_number"] && param[e]["do_number"].trim())
    
                if (duplicateDOs.length > 0) {
                    apiUtil.toast("D.O Number. number must be unique", 'check_cricle')
                    this.setState({
                      isLoading: false,
                    })
                    return
                }
                if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Add')) {
                  param.length > 0 ? this.callImportApi(param) :  apiUtil.toast("Please add orders first", "check_circle")  
                }
              }) 
            }}
            >
            Save Changes
          </Button>
        </div>
        </div>
    </Modal>
  }

  render() {
    let user = JSON.parse(window.localStorage.getItem('user'));
    return <Container fluid>
        <Row className="p-4">
            <Col>
                <Card className="cd-shadow">
                    <div className="p-3 font-medium fs-5">Order multiple locations at one go!</div>
                    <hr className="p-0 m-0"/>
                    <div className="p-3">
                    <div>Steps:</div>
                    <ol>
                      {this.state.currentRole == 'Customer Template Role' ?
                        <li>Liaise with our Customer Success team to get a <button onClick={()=> saveAs(
                            ClientOrderTemplate,
                            "Client_Order_Form_Template.xlsx"
                          )} className="download-order-template">customised CSV template order form <FontAwesomeIcon icon={faDownload} /></button></li>
                          :<li>Liaise with our Customer Success team to get a <button onClick={()=> saveAs(
                            OrderTemplate,
                            "Admin_Order_Form_Template.xlsx"
                          )} className="download-order-template">customised CSV template order form <FontAwesomeIcon icon={faDownload} /></button></li>
                        }
                        <li>Fill up the relevant information needed (e.g. date, time, address)</li>
                        <li>Upload your completed form below!</li>
                    </ol>
                    </div>
                    <div className="p-3">
                    <div>Notes:</div>
                    <ul>
                        <li>Make sure the date format filled is DD/MM/YYYY</li>
                        <li>Any special instructions directed for Driver-Partners should be put in the ‘Notes to Driver’ column</li>
                    </ul>
                    </div>
                </Card>
            </Col>
        </Row>
        <Row className="px-4 py-3">
            <Col>
            { !this.state.isLoading ? <Card className="cd-shadow">
              <Row className="px-4 py-3">
                <Col xs={12} md={6} className="max-xs-width-control-900 py-2 mt-2">
                <div className="max-xs-width-control-900 w-100 d-flex align-items-center justify-content-between">
                  <div className="max-xs-width-control-900 w-50">
                    Pick Up Point
                  </div>
                  <div className="max-xs-width-control-900 text-end-style w-50">
                  <div className="dropdown">
                    <div className="dropdown-header d-flex justilfy-content-center align-items-center">
                      <span className="fs-6">Saved Address</span>
                      <span className="dropdown-arrow"></span>
                    </div>
                    <div className="dropdown-content address-overflow">
                    <CustomInput
                      placeholder={"Search Address"}
                      type={"text"}
                      onChange={(e) => {
                        
                        let address = this.state.deepCloneAddress
                        address = address.filter(dd => dd.address && (dd.address.includes(e.target.value) || dd.address.toLowerCase().includes(e.target.value) || dd.address.toLowerCase().includes(e.target.value.toLowerCase().trim())  || (dd.address.toLowerCase() + ', ' + dd.building_no?.toLowerCase()).includes(e.target.value.toLowerCase())))

                        this.setState({
                          address : address,
                          is_customer_id : address.customer_id,
                          key: !this.state.key,
                        })
                      }}
                    />
                    {this.state.isLoading ? <Card className="cd-shadow d-flex justify-content-center align-items-center p-4"><CircularProgress size={35} /></Card>
                    : this.state.address && this.state.address.map((dd)=> {
                    return <div className={this.state.drop_off_address_id == dd.id ? 'p-2 pointer-cursor active-address address-text-left' : 'p-2 pointer-cursor address-text-left'}  onClick={(e) => { 
                      let value = this.state.deepCloneAddress.filter(address => address.id == dd.id)
                      this.callDoApi(moment().format('YYYY-MM-DD'))
                      if (value[0]) {
                        this.setState({
                          drop_off_address: value[0].address,
                          key: !this.state.key,
                          is_customer_id : value[0].customer_id,
                          drop_off_address_id: value[0].id,
                          building_no: value[0].building_no,
                          selected_customer_id: value[0].customer_id ? value[0].customer_id : 'admin',
                          drop_off_latitude: value[0].latitude,
                          drop_off_longitude: value[0].longitude,
                          drop_off_date: this.state.drop_off_date ? this.state.drop_off_date : moment().format('YYYY-MM-DD'),
                          address: this.state.deepCloneAddress,
                          deepCloneAddress: _.cloneDeep(this.state.deepCloneAddress),
                        });
                      } else {
                        this.setState({
                          drop_off_address: null,
                          key: !this.state.key,
                          drop_off_address_id: null,
                          building_no:null,
                          selected_customer_id: null,
                          drop_off_latitude: null,
                          drop_off_longitude: null,
                          drop_off_date: null,
                          openImportDialogItem: null,
                          excle_file: '',
                          data_form: null,
                          address: this.state.deepCloneAddress,
                          deepCloneAddress: _.cloneDeep(this.state.deepCloneAddress),
                        })
                        this.fileInput.current.value = '';
                      }
                    }}>
                      <div className="font-medium">{dd.name}</div>
                      <div>{dd.address}{dd.building_no ? ', ' + dd.building_no : ''}</div>
                    </div>
                    })}
                    </div>
                  </div>
                  </div>
                </div>
                <div className="max-xs-width-control-900 w-100 pb-4 mt-2" style={{pointerEvents: this.state.drop_off_address_id != null ? 'none': 'auto' }}>
                <CustomGoogleMapSearch
                  placeholder={"Pick Up Address"}
                  value={this.state.drop_off_address_id ? (this.state.drop_off_address +  (this.state.building_no ? ', ' + this.state.building_no : '' )) : this.state.drop_off_address }
                  disabled={this.state.drop_off_address_id ? true : false}
                  required={true}
                  onChange={(e) => {
                    if (this.state.do_number == null || this.state.do_number == '') {
                      this.callDoApiNoLoading(moment().format('YYYY-MM-DD'))
                    }
                    if (e.target.value == null || e.target.value == '') {
                      this.setState({
                        drop_off_address: null,
                        key: !this.state.key,
                        drop_off_date: null,
                        excle_file: '',
                        openImportDialogItem: '',
                        selected_customer_id: null,
                      });
                      this.fileInput.current.value = '';
                    } else {
                      let address = apiUtil.getGmapAddress(e.target.value);
                      this.setState({
                        drop_off_address: e.target.value,
                        selected_customer_id: null,
                        drop_off_date: this.state.drop_off_date ? this.state.drop_off_date : moment().format('YYYY-MM-DD')
                      });
                    }
                  }}
                  onPlacesChanged={(place, e) => {
                    if (this.state.do_number == null || this.state.do_number == '') {
                      this.callDoApi(moment().format('YYYY-MM-DD'))
                    }
                    let address = apiUtil.getGmapAddress(place);
                    this.setState({
                      selected_customer_id: null,
                      key: !this.state.key,
                      drop_off_address: address,
                      drop_off_latitude: place.geometry.location.lat(),
                      drop_off_longitude: place.geometry.location.lng(),
                      drop_off_date: this.state.drop_off_date ? this.state.drop_off_date : moment().format('YYYY-MM-DD')
                    });
                  }}
                />
                </div>
                <div className="max-xs-width-control-900 w-100 py-2 pt-3">
                    <CustomInput
                      placeholder={"Other details (eg unit number)"}
                      type={"text"}
                      value={this.state.other_detail}
                      onChange={(e) => {
                        this.setState({
                          other_detail : e.target.value
                        })
                      }}
                    />
                  </div>
                </Col>
                <Col xs={12} md={3} className="max-xs-width-control-900 py-2 position-relative">
                  <div className="max-xs-width-control-900 w-100 py-2">
                    Contact
                  </div>
                  <div className="max-xs-width-control-900 w-100 pb-4">
                    <CustomInput
                      placeholder={"Contact Name"}
                      type={"text"}
                      value={this.state.drop_off_contact_name}
                      onChange={(e) => {
                        this.setState({
                          drop_off_contact_name : e.target.value
                        })
                      }}
                    />
                  </div>
                  <div className="max-xs-width-control-900 w-100 py-2 pt-3">
                    <CustomInput
                      placeholder={"Contact No."}
                      type={"text"}
                      value={this.state.drop_off_contact_no}
                      error={this.state.drop_off_contact_no_validated}
                      onChange={(e) => {
                        this.setState({
                          drop_off_contact_no : e.target.value.replaceAll(/\s/g, '')
                        })
                      }}
                    />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={3} className="max-xs-width-control-900 py-2">
                <div className="max-xs-width-control-900 w-100 py-2">
                    Pick Up Date
                  </div>
                  <div className="max-xs-width-control-900 w-100">
                    <CustomInput
                     placeholder={"DD/MM/YYYY"}
                     color={"secondary"}
                     type={"date"}
                     endAdornment={(obj) => {
                       return (
                         <Box
                           onClick={(e) => {
                             e.stopPropagation();
                             e.preventDefault();
                           }}
                         >
                           <CalendarIcon />
                         </Box>
                       );
                     }}
                      value={this.state.drop_off_date}
                      onChange={(e) => {
                        this.callDoApi(e.target.value);
                        this.setState({
                          drop_off_date : e.target.value,
                          key: !this.state.key,
                        })
                      }}
                    />
                  </div>
                  <div className="max-xs-width-control-900 w-100 py-2 padding-under-900">
                    Pick Up timing
                  </div>
                  <div className="max-xs-width-control-900 w-100 d-flex" >
                  {this.state.currentRole == 'Customer Template Role' && (this.state.selected_customer_id != null && this.state.selected_customer_id != (user && user.owner_info && user.owner_info.customers && user.owner_info.customers.length > 0 && user.owner_info.customers[0].id)) ? 
                    <Select
                      className="select-width-control-100 custom-input-component margin-under-900"
                      style={{
                        width: "100%",
                        border: "1px solid #E0E1E4",
                        borderRadius: "4px",
                        padding: "",
                        backgroundColor: "transparent",
                      }}
                      placeholder={"From"}
                      native
                      disableUnderline
                      value={this.state.from_time}
                      onChange={(e) => {
                        this.setState({
                          from_time : e.target.value
                        })
                      }}
                    >
                      <option value="9:00"> 9:00 </option>
                    </Select> : 
                     <Select
                     className="select-width-control-100 custom-input-component  margin-under-900"
                     style={{
                       width: "100%",
                       border: "1px solid #E0E1E4",
                       borderRadius: "4px",
                       padding: "",
                       backgroundColor: "transparent",
                     }}
                     placeholder={"From"}
                     native
                     disableUnderline
                     value={this.state.from_time}
                     onChange={(e) => {
                       this.setState({
                         from_time : e.target.value
                       })
                     }}
                   >
                     <option value="0:00"> 0:00 </option>
                     <option value="0:30"> 0:30 </option>
                     <option value="1:00"> 1:00 </option>
                     <option value="1:30"> 1:30 </option>
                     <option value="2:00"> 2:00 </option>
                     <option value="2:30"> 2:30 </option>
                     <option value="3:00"> 3:00 </option>
                     <option value="3:30"> 3:30 </option>
                     <option value="4:00"> 4:00 </option>
                     <option value="4:30"> 4:30 </option>
                     <option value="5:00"> 5:00 </option>
                     <option value="5:30"> 5:30 </option>
                     <option value="6:00"> 6:00 </option>
                     <option value="6:30"> 6:30 </option>
                     <option value="7:00"> 7:00 </option>
                     <option value="7:30"> 7:30 </option>
                     <option value="8:00"> 8:00 </option>
                     <option value="8:30"> 8:30 </option>
                     <option value="9:00"> 9:00 </option>
                     <option value="9:30"> 9:30</option>
                     <option value="10:00"> 10:00 </option>
                     <option value="10:30"> 10:30 </option>
                     <option value="11:00"> 11:00 </option>
                     <option value="11:30"> 11:30 </option>
                     <option value="12:00"> 12:00 </option>
                     <option value="12:30"> 12:30 </option>
                     <option value="13:00"> 13:00 </option>
                     <option value="13:30"> 13:30 </option>
                     <option value="14:00"> 14:00 </option>
                     <option value="14:30"> 14:30 </option>
                     <option value="15:00"> 15:00 </option>
                     <option value="15:30"> 15:30 </option>
                     <option value="16:00"> 16:00 </option>
                     <option value="16:30"> 16:30 </option>
                     <option value="17:00"> 17:00 </option>
                     <option value="17:30"> 17:30 </option>
                     <option value="18:00"> 18:00 </option>
                     <option value="18:30"> 18:30 </option>
                     <option value="19:00"> 19:00 </option>
                     <option value="19:30"> 19:30 </option>
                     <option value="20:00"> 20:00 </option>
                     <option value="20:30"> 20:30 </option>
                     <option value="21:00"> 21:00 </option>
                     <option value="21:30"> 21:30 </option>
                     <option value="22:00"> 22:00 </option>
                     <option value="22:30"> 22:30 </option>
                     <option value="23:00"> 23:00 </option>
                     <option value="23:30"> 23:30 </option>
                   </Select>  }
                   {this.state.currentRole == 'Customer Template Role' && this.state.selected_customer_id != null && this.state.selected_customer_id != (user && user.owner_info && user.owner_info.customers && user.owner_info.customers.length > 0 && user.owner_info.customers[0].id) ?
                    <Select
                      className="select-width-control-100 custom-input-component  margin-under-900"
                      style={{
                        width: "100%",
                        border: "1px solid #E0E1E4",
                        borderRadius: "4px",
                        marginLeft: "4px",
                        backgroundColor: "transparent",
                      }}
                      placeholder={"To"}
                      native
                      disableUnderline
                      value={this.state.to_time}
                      onChange={(e) => {
                        this.setState({
                          to_time : e.target.value
                        })
                      }}
                    >
                      <option value="18:00"> 18:00 </option>
                    </Select> :
                     <Select
                     className="select-width-control-100 custom-input-component  margin-under-900"
                     style={{
                       width: "100%",
                       border: "1px solid #E0E1E4",
                       borderRadius: "4px",
                       marginLeft: "4px",
                       backgroundColor: "transparent",
                     }}
                     placeholder={"To"}
                     native
                     disableUnderline
                     value={this.state.to_time}
                     onChange={(e) => {
                       this.setState({
                         to_time : e.target.value
                       })
                     }}
                   >
                     <option value="0:00"> 0:00 </option>
                     <option value="0:30"> 0:30 </option>
                     <option value="1:00"> 1:00 </option>
                     <option value="1:30"> 1:30 </option>
                     <option value="2:00"> 2:00 </option>
                     <option value="2:30"> 2:30 </option>
                     <option value="3:00"> 3:00 </option>
                     <option value="3:30"> 3:30 </option>
                     <option value="4:00"> 4:00 </option>
                     <option value="4:30"> 4:30 </option>
                     <option value="5:00"> 5:00 </option>
                     <option value="5:30"> 5:30 </option>
                     <option value="6:00"> 6:00 </option>
                     <option value="6:30"> 6:30 </option>
                     <option value="7:00"> 7:00 </option>
                     <option value="7:30"> 7:30 </option>
                     <option value="8:00"> 8:00 </option>
                     <option value="8:30"> 8:30 </option>
                     <option value="9:00"> 9:00 </option>
                     <option value="9:30"> 9:30</option>
                     <option value="10:00"> 10:00 </option>
                     <option value="10:30"> 10:30 </option>
                     <option value="11:00"> 11:00 </option>
                     <option value="11:30"> 11:30 </option>
                     <option value="12:00"> 12:00 </option>
                     <option value="12:30"> 12:30 </option>
                     <option value="13:00"> 13:00 </option>
                     <option value="13:30"> 13:30 </option>
                     <option value="14:00"> 14:00 </option>
                     <option value="14:30"> 14:30 </option>
                     <option value="15:00"> 15:00 </option>
                     <option value="15:30"> 15:30 </option>
                     <option value="16:00"> 16:00 </option>
                     <option value="16:30"> 16:30 </option>
                     <option value="17:00"> 17:00 </option>
                     <option value="17:30"> 17:30 </option>
                     <option value="18:00"> 18:00 </option>
                     <option value="18:30"> 18:30 </option>
                     <option value="19:00"> 19:00 </option>
                     <option value="19:30"> 19:30 </option>
                     <option value="20:00"> 20:00 </option>
                     <option value="20:30"> 20:30 </option>
                     <option value="21:00"> 21:00 </option>
                     <option value="21:30"> 21:30 </option>
                     <option value="22:00"> 22:00 </option>
                     <option value="22:30"> 22:30 </option>
                     <option value="23:00"> 23:00 </option>
                     <option value="23:30"> 23:30 </option>
                   </Select>  }
                  </div>
                </Col>
              </Row>
            </Card> : <Card className="cd-shadow d-flex justify-content-center align-items-center p-4"><CircularProgress size={35} /></Card>}
            </Col>
        </Row>
        <Row className="px-4 py-3">
            <Col>
            
            <Card className="cd-shadow">
                <div className="row d-flex flex-row justify-content-center align-items-center w-75 max-xs-width-control-900 p-3">
                    <div className="col-12 col-xs-12 col-md-4 col-lg-3 text-end max-xs-width-control-900">Upload CSV or Excel file:</div>
                    <div className="col-12 col-xs-12 col-md-8 col-lg-9 max-xs-width-control-900"> 
                    <input type="file" 
                    accept=".xls, .xlsx, .csv"
                    ref={this.fileInput}
                    className="form-control flip-form"
                    disabled = {this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Add') ? false : true}
                    onChange={(e) => {
                      let files = e.target.files
                      if (files && files.length > 0){
                        this.setState({
                          openImportDialog: true,
                          openImportDialogItem: files[0],
                          excle_file: e.target.value
                        });
                      } else {
                        this.setState({
                          openImportDialogItem: null,
                          excle_file: ''
                        });
                      }
                    }}
                    />
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center p-3">
                    {/* <Button className="upload-style"
                    isLoading={this.state.isLoading}
                    disabled = {!this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Add') || this.state.excle_file == '' || this.state.excle_file == null || this.state.isLoading ? true : false}
                    onClick={()=>{
                      if (this.state.drop_off_address == null || this.state.drop_off_address == '') {
                        this.setState({
                          isLoading: true 
                        });
                        let form = this.state.data_form
                        // console.log("this.state.data_form", this.state.data_form)
                        if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Add')) {
                          this.state.data_form.length > 0 ?
                          this.callImportApi(form, (data) => {
                            // let all_jobs = (form && form.length > 0) ? form.length : 0;
                            // let failed_orders = (data && data.failed_orders && data.failed_orders.length > 0) ? data.failed_orders.length : 0;
                            // let invalid_company_code_orders = (data && data.invalid_company_code_orders && data.invalid_company_code_orders.length > 0) ? data.invalid_company_code_orders.length : 0;
                            // let saved_orders = (data && data.saved_orders && data.saved_orders.length > 0) ? data.saved_orders.length : 0;
                            apiUtil.toast('Jobs Successfully Imported', 'check_circle', 'success');

                            // if(failed_orders > 0){
                            //   apiUtil.toast(failed_orders + ' of ' + all_jobs + ' jobs have not successfully imported', 'check_circle', 'error');
                            // }
                            // if(invalid_company_code_orders > 0){
                            //   apiUtil.toast(invalid_company_code_orders + ' of ' + all_jobs + ' company code does not match, jobs have not successfully imported', 'check_circle', 'error');
                            // }
                
                            // if(saved_orders > 0){
                            //   apiUtil.toast(saved_orders + ' of ' + all_jobs + ' Jobs Successfully Imported', 'check_circle', 'success');
                            // }
                
                            this.setState({
                              openImportDialog: false,
                              openImportDialogItem: null,
                              openImportDialogIsLoading: false,
                              data_form: [],
                              excle_file: '',
                            });
                          }) :
                          apiUtil.toast("Please select file first", "check_circle") 
                        }
                      } else {
                        this.setState({
                          openConfirmDialog: true 
                        })
                      }
                    }}
                    >{this.state.isLoading? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <><FontAwesomeIcon icon={faArrowUpFromBracket} className="text-white" />&nbsp; Upload New Order</>}</Button> */}
                    {this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Add')?
                    <NavLink to={this.state.currentRole == 'Customer Template Role' ? "/new-order-form" : "/dashboards/new-order-form"} className="text-decoration-none"> Or create an individual order here</NavLink>
                    :<div className="text-decoration-none"> Or create an individual order here</div>
                    }

                </div>
            </Card>
            </Col>
        </Row>

       {this.state.openImportDialogItem && <Row className="px-4 py-3">
            <Col>
            <Card className="cd-shadow">
            <JobImportDialog
              isLoading={this.state.isLoading}
              key={this.state.key}
              collection_data={this.state.drop_off_address == null || this.state.drop_off_address == '' ? false : true }
              drop_off_address={this.state.drop_off_address}
              building_no={this.state.building_no}
              drop_off_date={this.state.drop_off_date}
              file={this.state.openImportDialogItem}
              onClose={() => {
                this.setState({
                  openImportDialog: false,
                  openImportDialogItem: null,
                  openImportDialogIsLoading: false,
                  data_form: [],
                  excle_file: '',
                });
                this.fileInput.current.value = '';
              }}
              onImport={(form) => {
                if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Add')) {
                  if (form.length > 0) {
                  if (
                    this.state.drop_off_contact_no &&
                    !apiUtil.phoneValidationWithPlus(this.state.drop_off_contact_no)
                  ) {
                    this.setState({
                      drop_off_contact_no_validated: true,
                    });
                    return
                  }

                  this.setState({
                    isLoading: true 
                  });
                  // let form = this.state.data_form && this.state.data_form.filter(dd => dd.job_type != 'Collection');
                  let param = []
                  if (this.state.drop_off_address == null || this.state.drop_off_address == '' ) {
                    param = form
                  } else {
                    let drop_off_worker_id = form[0].drop_off_worker_id ? form[0].drop_off_worker_id : null;
                    let vendor_id = form[0].vendor_id ? form[0].vendor_id : null;
                    let order_status_id = form[0].order_status_id ? form[0].order_status_id : null;
                    form.map((dd, index)=> {
                      form[index]['drop_off_date'] = this.state.drop_off_date == null || this.state.drop_off_date == '' ? moment().format('YYYY-MM-DD') : this.state.drop_off_date
                      form[index]['job_type'] = 'Delivery'
                      form[index]['collection_reference'] = this.state.do_number
                    })
                    let data = [{
                      is_manual: this.state.is_customer_id != null ? true : false,
                      drop_off_contact_no: this.state.drop_off_contact_no && this.state.drop_off_contact_no.length === 8 ? "+65"+ this.state.drop_off_contact_no : this.state.drop_off_contact_no,
                      drop_off_alternate_contact: null,
                      drop_off_date: this.state.drop_off_date == null || this.state.drop_off_date == '' ? moment().format('YYYY-MM-DD') : this.state.drop_off_date,
                      drop_off_from_time: this.state.from_time,
                      drop_off_to_time: this.state.to_time,
                      customer_id: this.state.currentRole == 'Customer Template Role'? this.state.customerItems[0].item.id : null,
                      selected_customer_id: this.state.selected_customer_id,
                      drop_off_name: null,
                      drop_off_contact_name: this.state.drop_off_contact_name,
                      drop_off_description: null,
                      drop_off_address: this.state.drop_off_address + (this.state.building_no ? ', ' + this.state.building_no : '') + (this.state.other_detail != null ? ', ' + this.state.other_detail : ''),
                      drop_off_latitude: this.state.drop_off_latitude,
                      drop_off_longitude: this.state.drop_off_longitude,
                      job_type: "Collection",
                      is_calculate: true,
                      company_code: "",
                      drop_off_worker_id: drop_off_worker_id,
                      vendor_id: vendor_id,
                      order_status_id: order_status_id,
                      do_number: this.state.do_number,
                      is_project: false,
                      collection_reference: null,
                      notification_email: null,
                      department_id: null,
                      driver_notes: null,
                      pallets: null,
                      drop_off_postal_code: this.state.postal_code,
                      zone_id: null,
                      is_notify_customer: true,
                      is_tracking_link_required: true,
                      xs_packages: null,
                      s_packages: null,
                      m_packages: null,
                      l_packages: null,
                      oversized_packages: null,
                      period_of_delivery: "Same Day",
                      extra_data: JSON.stringify({collection_mergable:true})
                    }]
                    param = data.concat(form)
                  }
                  let duplicateDOs = param.map(e => e.do_number && e.do_number.trim())
                                .map((e, i, final) => final.indexOf(e) !== i && i)
                                .filter(obj=> param[obj])
                                .map(e => param[e]["do_number"] && param[e]["do_number"].trim())
      
                  if (duplicateDOs.length > 0) {
                      apiUtil.toast("D.O Number. number must be unique", 'check_cricle')
                      this.setState({
                        isLoading: false,
                      })
                      return
                  }
                  
                  this.callImportApi(param, () => {
                    apiUtil.toast('Jobs Successfully Imported', 'check_circle');
        
                    this.setState({
                      openImportDialog: false,
                      openImportDialogItem: null,
                      openImportDialogIsLoading: false,
                      isLoading: false,
                      data_form: [],
                      excle_file: '',
                    });
                  }) } else {
                    apiUtil.toast("No Input data", "check_circle") 
                  }
                }
              }}
            /></Card>
        </Col>
        </Row>}
        {this.confirmDialog()}
        </Container>
  }
}

export default AdminNewOrder;
