import React, { Component, forwardRef } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { fromJS } from "immutable";
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";
import _ from 'lodash';
import ldsApi from "../../api/ldsApi.jsx";
import wmsApi from '../../api/wmsApi.jsx';
import BreadCrumb from '../../components/Navs/Breadcrumb.js';
import moment from 'moment';
import jsPDF from "jspdf";
import QRCode  from "qrcode.react";
// import Label from "../../components/Label/label.jsx"
import EVFYLogo from '../../assets/img/evfy-logo2.png';
import location from '../../assets/img/Vector.png';
import building from '../../assets/img/building.png';
import box from '../../assets/img/box.png';
import comment from '../../assets/img/comment.png';
import person from '../../assets/img/person.png';
import time from '../../assets/img/access_time.png';
import phone from '../../assets/img/local_phone.png';
import bottomTxt from '../../assets/img/bottom-txt.png';
import EVFYLogoForLabel from '../../assets/img/EVFY_Logo.png';
import ReactDOM from 'react-dom';
import Barcode from 'react-barcode';
import OrderDetailForm from './OrderDetailForm.jsx';
import CSVfile from "../../assets/files/DeliveryTemplate.xlsx";
import { Container, Row, Col, Button, Card, ToggleButton, ToggleButtonGroup, Form, Modal } from 'react-bootstrap';
import ClientCSVfile from "../../assets/files/DeliveryTemplateClient.xlsx"
import AdminCSVfile from "../../assets/files/DeliveryTemplateAdmin.xlsx"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { faDownload, faMagnifyingGlass, faFilter, faSort, faEllipsisVertical, faPlusCircle, faTrashAlt, faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons'
import { 
  Box,
  Grid,
  IconButton,
  Tab,
  Tabs,
  InputLabel,
  Select,
  CircularProgress,
  Menu,
  MenuItem,
} from '@material-ui/core';
// import { PDFDownloadLink, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import {Verdana} from '../../components/Fonts/Verdana.jsx'
import { Anmollipi } from '../../components/Fonts/Anmollipi.jsx';
import { InterLight } from '../../components/Fonts/InterLight.jsx';
import { InterBold } from '../../components/Fonts/InterBold.jsx';
import Icon from '@material-ui/core/Icon';
import axios from 'axios';
import { ReactComponent as JobsIcon } from '../../assets/img/left_menu/jobs.svg';
import { ReactComponent as SearchIcon } from '../../assets/img/icons/search.svg';
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';
import { ReactComponent as ExportIcon } from '../../assets/img/icons/export.svg';
import { ReactComponent as ImportIcon } from '../../assets/img/icons/import.svg';
import { ReactComponent as CopyIcon } from '../../assets/img/icons/copy.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import { ReactComponent as CalendarIcon } from '../../assets/img/icons/calendar.svg';
import { ReactComponent as AutoIcon } from '../../assets/img/icons/flash.svg';
import { ReactComponent as LabelIcon } from '../../assets/img/icons/view_week.svg';
import Settingimg from '../../assets/img/icons/setting-balck.svg';
import SettingWhite from '../../assets/img/icons/setting.svg';
import Filterimg from '../../assets/img/icons/filter.png';
import FilterIcon from '../../assets/img/filter.svg';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
// import Card from '../../components/Card/Card.jsx';
import Table from '../../components/Table/Table.jsx';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker.jsx';

import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomCheckbox from '../../components/CustomFields/CustomCheckbox.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import CustomDialog2 from '../../components/Dialog/CustomDialog2.jsx';
import CustomFormDialog from '../../components/Dialog/CustomFormDialog.jsx';
import GroupButton from '../../components/GroupButton/GroupButton.jsx';
import CircleIcon from '../../components/CircleIcon/CircleIcon.jsx';
import StatusList from '../../components/Status/StatusList.jsx';
import StatusItem from '../../components/Status/StatusItem.jsx';
import DropdownMenu from '../../components/Dropdown/DropdownMenuFilter.jsx';
import CustomAutosuggest from '../../components/CustomFields/CustomAutosuggest.jsx';
import CustomUploadButton from '../../components/CustomFields/CustomUploadButton.jsx';
import CustomGuideStepper from '../../components/Stepper/CustomGuideStepper.jsx';

import JobPreviewDialog from '../Jobs/Partial/JobPreviewDialog.jsx';
import JobImportDialog from '../Jobs/Partial/JobImportDialog.jsx';
import BatchUpdateForm from '../Jobs/Partial/BatchUpdateForm.jsx';
import BatchUpdateAssignForm from '../Jobs/Partial/BatchUpdateAssignForm.jsx';
import BatchUpdateJobStatusForm from '../Jobs/Partial/BatchUpdateJobStatusForm.jsx';
import AutoAssignForm from '../Jobs/Partial/AutoAssignForm.jsx';

import socketIOClient from "socket.io-client";
import { Observable } from 'rxjs/Observable';
import {RoleAccessService} from '../../data/role-access.js'; 
import Tour from 'reactour';
import HtmlParser from 'react-html-parser';
import BulkUpdateForm from './Partial/BulkUpdateForm.jsx';
import { TimeGrid } from 'react-big-calendar';
import { Html5Entities } from 'html-entities';
import ReactSelect  from 'react-select';

const { 
  REACT_APP_JOB_UPDATE_SOCKET_URL,
  REACT_APP_LDS_API_URL,
} = process.env;

const exceptThisSymbols = ["e", "E", "+", "-", "."];
const exceptThisSymbolsPayout = ["e", "E", "+", "-"];

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  };
}

let loadApis = true;
let statusData = '';
let status_total = 0;
let orderData = [];
let duplicateRowData = [];
let total = 0;
let orders_logo = [];
let search_param_global = {};
let status = false;
let is_searched = true;
class TrashBin extends Component {
  static contextType = AuthContext;
  rowData =null;
  pages = 1;
  clickedId = null;
  limits = 50;
  search = '';
  do_number = '';
  uploder_id = '';

  constructor(props){
    super(props); 
    this.pageName = 'jobList';
    let initStatusSelected = (this.props.location && this.props.location.state && this.props.location.state.index && this.props.location.state.index > 0) ? this.props.location.state.index : null;
    let initOrderStatus = (this.props.location && this.props.location.state && this.props.location.state.order_status && this.props.location.state.order_status > 0) ? this.props.location.state.order_status : null;
    let isShouldDisableSocketToast = (this.props.location && this.props.location.state && this.props.location.state.isShouldDisableSocketToast && this.props.location.state.isShouldDisableSocketToast === true) ? this.props.location.state.isShouldDisableSocketToast: false;
    let created_order = this.props.location && this.props.location.state && this.props.location.state.created_order? this.props.location.state.created_order : {};
    loadApis = this.props.location && this.props.location.state && this.props.location.state.loadApis? this.props.location.state.loadApis : loadApis;
    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();
    this.refTableDoSearch = React.createRef();
    this.refTableUploaderSearch = React.createRef();
    this.refTableAdvSearch = React.createRef();
    let rangeDate = {
      startDate: moment(),
      // endDate: moment().add(2,'days')
      endDate: moment()
    };
    // let rowData = null;
    let rangeDateItem = localStorage.getItem(this.pageName + '_rangeDate');
    if(rangeDateItem && rangeDateItem !== ''){
      try {
        rangeDate = JSON.parse(rangeDateItem);
      }catch(e){}
    }
    // var selectRow = [];

    // const grantedAccess = new RoleAccessService();
    // const currentRole = window.localStorage.getItem('current_role') || null;
    this.state = {
      grantedAccess : new RoleAccessService(),
      currentRole: window.localStorage.getItem('current_role') || null,
      jobTab: 0,
      jobTabTable: 0,
      current_status_name: "",
      total_current_status_job: 0,
      dateRangePickerIsOpen: false,
      isShouldDisableSocketToast: isShouldDisableSocketToast,
      rangeDate: rangeDate,
      startDate: rangeDate.startDate,
      endDate: rangeDate.endDate,
      created_order: created_order,
      isLoadingStatus: true,
      status: [],
      tableStatus: [],
      isLoadingDelete: false,
      statusSelected: initStatusSelected,
      order_status: initOrderStatus,
      is_search: false,
      isLoading: true,
      grouping: true,
      data: [],
      activeSuggestion: null,
      state_copy_data: [],
      status_total: 0,
      total: 0,
      selectedRow:[],
      duplicateOrders: [],
      openDuplicateDialog: false,
      openDialogBatchUpdate:false,
      openDialog: false,
      openDialogMassDelete: false,
      openDialogEmptyRecycle: false,
      openDialogMassRestore: false,
      openDialogUnassign: false,
      openDialogItem: null,
      openDialogAutoAssign:false,
      openDialogJobAssign: false,
      openDialogChangeStatus: false,
      isLoadingDuplicate: false,
      invalidAddress:[],
      company_logo: '',
      orders_logo: [],
      user: JSON.parse(localStorage.getItem('user')),
      openPreviewDialog: false,
      openPreviewDialogItem: null,

      openImportDialog: false,
      openImportDialogItem: null,
      openImportDialogIsLoading: false,
      openBatchUpdateisLoading: false,

      openSettingsDialog: false,
      autoAssign: false,
      defaultGroupOrder: -1,

      isTourOpen: false,
      openSkipDialog: false,

      duplicateDatas: [],
      hiddenColumnSettingId: 0,
      hiddenColumns: [],
      value: 1,
      tabValue: 0,
      uploaders: [],
      oldUploaders: [],
      selected_uploader: '',
      copyDialogOpen: false,
      search_text: '',
      workers: [],
      oldWorkers: [],
      order_status: null,
      search_do_number: '',
      search_worker_id: [],
      search_delivery_number: '',
      search_time_slot: '',
      search_delivery_type: '',
      search_recipient_name: '',
      search_recipient_contact: '',
      search_recipient_email: '',
      search_item_desc: '',
      search_delivery_address: '',
      search_postal_code: '',
      search_uploader: [],
      allocation_groups: [],
      search_group_id: '',
      search_vendor_id: '',
      hover: false,
      customerItems: [],
      customerName: '',
      customerId: '',
      sortColumn: [],
      stateRowData: false,
      order_status_list: [],
      old_order_status_list: [],
      search_status_id: [],
      empty_recycle: false,
      is_date_change: false,
      copyData: {
        id: 0,

        drop_off_date: true,
        drop_off_time_planned: true,
        drop_off_description: true,
        
        drop_off_name: true,
        drop_off_contact_email: true,
        drop_off_contact_no: true,
        item_tracking_number: true,
        
        order_details: true,
        job_steps: true,
        drop_off_worker: true,
        amount: true,
        drop_off_contact_name:true,
        order_number: true,
        do_number: true,
        drop_off_postal_code: true,
        drop_off_address: true,
        driver_notes: true,
        driver_payout: true,
        document: true,
        boxes: true,
        pallets: true,
        cartons:true,
        package_type:true,
        package_quantity:true,
        vendors: [],
        vendorItemsIsLoading: false,

        search_to_time: "",
        search_from_time: "",
        workerItems: [],
        oldWorkerItems: [],
        manPowerItems: [],
        change_driver_payout: null,
      }
    }
  }
  

  componentDidMount() {
    const { isOnBoarding, toLoad, setToLoad } = this.context;
    var path = window.localStorage.getItem('access_token');
    if(path == null){
      if( ! (window.location.pathname == "/Sign-in"  || window.location.pathname == "/Sign-up"  || window.location.pathname == "/privacy_policy" ||  window.location.pathname == "/terms_and_conditions"  ||  window.location.pathname == ("/sign-up-success") || window.location.pathname.includes("/login")) ) {
        window.location.href ="Sign-in";
      }
    }
    
    // console.log("render");
    if(isOnBoarding){
      // console.log("render in if function");
      this.setState({
        isTourOpen: true,
      });
    }
  if( toLoad || is_searched == true) {
    let driver = [];
    this.callCustomerReadApi('');
    this.callManpowerDriverReadApi('', (arr) => {
      driver.push(arr);
      this.callVendorReadApi('', (arr) => {
        driver.push(arr);
        var result = [].concat.apply([],Object.values(driver));
        this.setState({
          workerItems: result.sort((a, b) => {
            const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
            const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          
            // names must be equal
            return 0;
          })
        },() => {
          this.setState({
            oldWorkerItems: this.state.workerItems
          });              
        });
      });
    });
    this.callManpowerReadApi('', (arr) => {
      this.setState({
        manPowerItems: arr
      });
    });
    //this.callAllocationGroupApi()
    //this.callVendorReadApi();
    this.callVendorInitReadApi();
    this.loadStatusApi(() => {
      this.callReadApi();
    }); 
  } else {
    setToLoad(true);
    if (Object.keys(this.state.created_order).length !== 0) {
      if (orderData.length == 50){  
        orderData.pop()  
      }

      this.state.created_order.map ((dd) => {
      if(moment(dd.drop_off_date).format('YYYY-MM-DD') >= moment(this.state.startDate).format('YYYY-MM-DD') && moment(dd.drop_off_date).format('YYYY-MM-DD') <= moment(this.state.startDate).format('YYYY-MM-DD')){
        orderData.unshift(dd)
      } else {
        console.log("date not match")
      }
    })
      
    } 
    this.setColumnSetting();
    let driver = [];
    this.callManpowerDriverReadApi('', (arr) => {
      driver.push(arr);
      this.callVendorReadApi('', (arr) => {
        driver.push(arr);
        var result = [].concat.apply([],Object.values(driver));
        this.setState({
          workerItems: result.sort((a, b) => {
            const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
            const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          
            // names must be equal
            return 0;
          }),
        },() => {
        },() => {
          this.setState({
            oldWorkerItems: this.state.workerItems
          });     
        });
      });
    });
    this.callManpowerReadApi('', (arr) => {
      this.setState({
        manPowerItems: arr
      });
    });
    //this.callAllocationGroupApi()
    this.callVendorInitReadApi();
      status_total = status_total + 2;
      total = total + 2;
      this.loadStatusApi(()=> {
        if (orderData.length > 2 && !status) {
          this.setState({
            orders_logo: orders_logo,
            status: statusData,
            status_total: status_total,
            data: fromJS(orderData),
            total: total,
            isLoading: false,
          })
        } else {
          this.callReadApi();
      }
      });
   }
    this.callRefreshPage()
  }
  
  setColumnSetting = () => {
    const { accessToken } = this.context;
    let hiddenColumns = [];
    let columnHiddenSettingsId = 0;
    let columnHiddenSettings = JSON.parse(localStorage.getItem('job_column_settings'));

    if (columnHiddenSettings !== null) {
      hiddenColumns = columnHiddenSettings.json_data ? columnHiddenSettings.json_data.replace(/[^0-9a-zA-Z-_.,]/g, "").split(",") : [];
      columnHiddenSettingsId = columnHiddenSettings && columnHiddenSettings.id ? columnHiddenSettings.id : 0;
    }
    
    this.setState({
      hiddenColumns: hiddenColumns,
      columnHiddenSettingsId: columnHiddenSettingsId,
    },() => {
      const jobColumnOrder = JSON.parse(localStorage.getItem('job_column_order'));
      let originalColumn = this.getColumns(false).filter((obj) => obj.hidden == false);
      let columnOrder = jobColumnOrder ? JSON.parse(jobColumnOrder.json_data) : []
      // console.log("columnOrder", columnOrder)
      let foundHideColumn = false
      let searchColumns = this.getColumns(false);
      this.state.hiddenColumns.map((val) => {
        if(val != '' && !searchColumns.filter((obj) => obj.field == val).length > 0) {
          foundHideColumn = true;   
        }
      });
      if(columnOrder.length !== originalColumn.length || foundHideColumn) {
        originalColumn = this.getColumns(false);
        columnOrder = Array.from(Array(originalColumn.length).keys()); 
        let findIndex = originalColumn.map((obj,index) => obj.hidden == true ? index : '' ).filter(String);
        if(findIndex.length > 0) {
          let removeIndex;
          findIndex.map((num) => {
            removeIndex = columnOrder.findIndex((id) => id == num);
            columnOrder.splice(removeIndex,1); 
          });              
        }
        api.create('application_company_settings', {
          enabled: true,
          rule: "job_column_settings",
          json_data: JSON.stringify([''])
        }, accessToken)
          .then(result => {
            if (result.data.data.result.id) {
              localStorage.setItem("job_column_settings", JSON.stringify(result.data.data.result));
            }
        });
        this.callCreateColumnOrder(columnOrder);
      }

      this.setState({
        sortColumn: columnOrder
      });         
    });   
    
  }

  callRefreshPage = () => {
    setTimeout(()=> {
      this.loadStatusApi(() => {
        //this.callRefreshReadApi()
      })
      //this.callRefreshPage()
    }, 300000)
  }

  /* VENDOR API */
  callVendorReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

      let param = {
        page: 1,
        take: apiUtil.getDefaultPageSize(),
        is_active: true,
      };

      api.read('vendors/active_vendor', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result : [];

            let arr = [];
            if(result && result.length > 0){
              arr = result.map((item, i) => {
                let value = item.company_id;
                let label = item.company_name;

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              // this.setState({
              //   vendors: arr,
              // }, () => {
                if(callback){
                  callback(arr);
                }
              // });
            } else {
              // this.setState({
              //   vendorItems: [],
              // }, () => {
                if(callback){
                  callback([]);
                }
              // });
            }
          }, (error, type) => {
            // this.setState({
            //   vendorItems: [],
            // }, () => {
              if(callback){
                callback([]);
              }
            // });
          });
      });
  }
  /* END VENDOR API */

    /* VENDOR API */
  callVendorInitReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

      let param = {
        page: 1,
        take: apiUtil.getDefaultPageSize(),
        is_active: true,
      };

      api.read('vendors/active_vendor', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result : [];

            let arr = [];
            if(result && result.length > 0){
              arr = result.map((item, i) => {
                let value = item.company_id;
                let label = item.company_name;

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              this.setState({
                vendors: arr.sort((a, b) => {
                  const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                  const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                
                  // names must be equal
                  return 0;
                }),
              }, () => {
                if(callback){
                  callback(arr);
                }
              });
            } else {
              // this.setState({
              //   vendorItems: [],
              // }, () => {
                if(callback){
                  callback([]);
                }
              // });
            }
          }, (error, type) => {
            // this.setState({
            //   vendorItems: [],
            // }, () => {
              if(callback){
                callback([]);
              }
            // });
          });
      });
  }
  /* END VENDOR API */

  callInitApis = () => {
    this.loadStatusApi(()=> {
        this.callReadApi();
    });
  }
  
  /* CUSTOMER API */
  callCustomerReadApi = (search = "", callback = null) => {
    const { accessToken, isOnBoarding, onBoardingCustomer } = this.context;

    this.setState(
      {
        customerItems: [],
      },
      () => {
        let param = {
          page: 1,
          // take: apiUtil.getDefaultPageSize(),
          is_active: true,
          search: search ? search.trim() : search,
          light_data_customer_rate: true,
        };

        wmsApi.read("customers", param, accessToken).then((result) => {
          apiUtil.parseResult(
            result,
            (data) => {
              let result =
                data.result && data.result.length > 0 ? data.result : [];

              let arr = [];
              if (result && result.length > 0) {
                for (let i = 0; i < result.length; i++) {
                  let item = result[i];

                  let value = item.id;
                  let label = item.company_name;

                  if (arr.findIndex((x) => x.value === value) === -1) {
                    arr.push({
                      value: value,
                      label: label,
                      item: item,
                    });
                  }
                }
                this.setState(
                  {
                    customerItems: arr.sort((a, b) => {
                      const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                      const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }
                    
                      // names must be equal
                      return 0;
                    }),
                  },
                  () => {
                    if (callback) {
                      callback(arr);
                    }
                  }
                );
              } else {
                this.setState(
                  {
                    customerItems: [],
                  },
                  () => {
                    if (callback) {
                      callback([]);
                    }
                  }
                );
              }
            },
            (error, type) => {
              this.setState(
                {
                  customerItems: [],
                },
                () => {
                  if (callback) {
                    callback([]);
                  }
                }
              );
            }
          );
        });
      }
    );
  };

  loadStatusApi = (callback = null) => {
    this.callStatusApi((data, total) => {
      if(data && data.length > 0){
        let status = [];
       data.map((item, i) => {
          if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "assign-pending" || item.status_details.status.replace(' ', '-').trim().toLowerCase() == "on-hold") {
            return status.splice(1, 0, {
              order: 7,
              id: item.status_details.id,
              status: "On Hold",
              text: "On Hold",
              total: item.total_job,
            });
            // status[0].push(objectVal)
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "not-assigned") {
            return status.splice(2, 0, {
              order: 1,
              id: item.status_details.id,
              status: "Pending",
              text: "Pending",
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "assigned") {
            return status.splice(3, 0, {
              order: 3,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "acknowledged") {
            return status.splice(4, 0, {
              order: 4,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "in-progress") {
            return status.splice(5, 0, {
              order: 5,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "completed") {
            return status.splice(6, 0, {
              order: 6,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "failed") {
            return status.splice(7, 0, {
              order: 8,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "cancelled") {
            return status.splice(8, 0, {
              order: 9,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "self-collect") {
            return status.splice(9, 0, {
              order: 10,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replaceAll(' ', '-').trim().toLowerCase() == "assigned-to-vendor") {
            return status.splice(10, 0, {
              order: 2,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          }
        });

        status = status.sort(function(a, b) {
          return (a.order - b.order);
        });
        let tableStatus = status;
        
        let search_order_status = [];
        status.map((obj) => {
          search_order_status.push({
            value: obj.id,
            label: obj.text
          });              
        });
        
        status.unshift({
          id: 0,
          status: 'Total Orders',
          total: total,
        });

        this.setState({
          status: status,
          order_status_list: search_order_status,
          old_order_status_list: search_order_status,
          status_total: total,
          tableStatus: tableStatus,
        }, () => {
          statusData = status;
          status_total =  total;
          if(callback) {
            callback();
          }
        });
      } else {
        if(callback) {
          callback();
        }
      }
    });
  }
  
  callCreateColumnOrder = (sort) => {
    const { accessToken } = this.context;
    api.create('application_company_settings', {
      enabled: true,
      rule: "job_column_order",
      json_data: JSON.stringify(sort)
    }, accessToken)
      .then(result => {
        if (result.data && result.data.data.result.id) {
          localStorage.setItem("job_column_order", JSON.stringify(result.data.data.result));
          this.setState({
            sortColumn: this.state.sortColumn
          });
        }
    });   
  }

  callCreateApi = (row, callback = null) => {
    const { accessToken } = this.context;
    let sortedData = [];
    let colList = row.filter( c => c.collection_reference == null);
    if(colList.length > 0) {
      colList.map(cjob => {
        if(!cjob.is_duplicate_collection_only) {
          delete cjob['order_number'];
        }
        sortedData.push(cjob);
        row.map(d => {
          if(d.collection_reference == cjob.do_number) {
            delete d['order_number'];
            sortedData.push(d);
          }
        })
      });
      row.map((obj) => {
        if(!obj.is_duplicate_collection_only) {
          delete obj['order_number'];
        }
        if(!sortedData.filter((s) => s.do_number == obj.do_number).length > 0) {
         sortedData.push(obj);
        }
      });
    } else {
      row.map((obj) => {
        delete obj['order_number'];
        sortedData.push(obj);
      }); 
    }

    this.setState({
      isLoadingDuplicate: true
    }, () => {
      ldsApi.create('duplicate/orders/multiple', sortedData, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.loadStatusApi();
            this.setState({
              duplicateDatas: (data.saved_orders && data.saved_orders.length > 0) ? data.saved_orders : [],
              isLoadingDuplicate: false,
            }, () => {
              let mergeData = [];
              if(this.state.statusSelected == null || this.state.statusSelected == 0 || this.state.statusSelected == 'undefined' || this.state.statusSelected == 1) {
                mergeData = apiUtil.addDuplicateRow(this.state.duplicateDatas, this.state.state_copy_data)
                mergeData = mergeData.filter(obj => moment(obj.drop_off_date).format('YYYY-MM-DD') >= moment(this.state.startDate).format('YYYY-MM-DD') && moment(obj.drop_off_date).format('YYYY-MM-DD') <= moment(this.state.endDate).format('YYYY-MM-DD'));
              } else {
                mergeData = this.state.state_copy_data;
              }
              let status = this.state.status.map((item) => {
                  if (item.status.replace(' ', '-').trim().toLowerCase() == "total-orders" || item.status.replace(' ', '-').trim().toLowerCase() == "pending") {
                    return { ...item, total: item.total + this.state.duplicateDatas.length};
                  } else {
                    return { ...item, total: item.total};
                  }
              })
              let colLength = data.saved_orders.filter(obj=> obj.job_type == 'Collection').length;
              let deliLength = data.saved_orders.filter(obj=> obj.job_type == 'Delivery').length;

              this.setState({
                data: fromJS(mergeData).toJS(),
                state_copy_data: mergeData,
                total: this.state.total + data.saved_orders.length,
                status: status,
              })
              if(callback){
                apiUtil.toast(' Successfully duplicated " '+ (colLength > 0 && colLength + ' Pick Up job(s)')+ (deliLength > 0 ? ' & ' + deliLength + " Delivery job(s) \" " : '"') , 'check_circle');
                callback(data.result);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoadingDuplicate: false,
            }, () => {
              apiUtil.toast(
                error,
                "check_circle"
              );
            });
          });
      });
    });
  }

  duplicateDialog = () => {
    let company_name = this.DoDialogOrder &&  this.DoDialogOrder['0'].customer &&  this.DoDialogOrder['0'].customer.company_name
    return <Modal centered show={this.state.openDuplicateDialog} onHide={() => {
            this.rowData = null;
            this.setState({
              openDuplicateDialog : false,
              duplicateOrders: [],
            });
            }}>
            <Modal.Header className="p-4" closeButton>
            <div className="text-center font-medium fs-5 d-flex justify-content-start w-100">Duplicate Order(s)</div>
            </Modal.Header>
            <div className="justify-content-center p-4">
              <div className="p-3 bg-warning text-dark text-center my-2">Only Delivery Order(s) will be duplicate their related Pick up Order</div>
            <div className="text-center mb-4">
              {this.previewTable(this.state.duplicateOrders)}
            </div>
            <div className="d-flex justify-content-center">
              <Button variant="secondary" className="mx-2 px-4 py-2 buttom-primary-dailog-cancel" onClick={() => {
                this.rowData = null;
                this.setState({
                  openDuplicateDialog: false,
                  duplicateOrders: [],
                });
              }}>
                Cancel
              </Button>
              <Button variant="primary" className="mx-2 px-4 py-2 buttom-primary-dailog" 
                disabled = {this.state.isLoadingDuplicate? true:false}
                onClick={() => {
                  let row = [];
                  const duplicateOrders = this.state.duplicateOrders;
                  row = duplicateOrders;
                  row.map((dd) => {
                    if (dd.job_type == "Delivery") {
                      let collection_row = row.filter( rowfilter =>{ 
                        if (rowfilter.order_number == dd.collection_reference && rowfilter.job_type == "Collection") {
                          return rowfilter.order_number
                        } 
                        return null
                      })
                      if (collection_row.length > 0) {
                        // ['order_status_id', 'drop_off_worker_id', 'vendor_id', 'extra_worker_ids'].forEach(e => delete dd[e]);
                        dd.collection_reference = collection_row['0'].do_number
                      } else {
                        dd.is_duplicate_deli_only = true
                      }
                    } else if (dd.job_type == "Collection") {
                      let delivery_row = duplicateOrders.filter( rowfilter =>{ 
                        if ((rowfilter.collection_reference == dd.order_number || rowfilter.collection_reference == dd.do_number) && rowfilter.job_type == "Delivery") {
                          return rowfilter.do_number
                        } 
                        return null
                      })
                      if (delivery_row.length > 0) {
                        dd.collection_reference = null
                      } else {
                        dd.is_duplicate_collection_only = true
                      }
                    }
                    if (dd.is_duplicate_collection_only == true) {
                      ['id','order_status', 'extra_workers','uploader','reference_no', 'department', 'order_status_id', 'drop_off_worker_id', 'vendor_id', 'extra_worker_ids'].forEach(e => delete dd[e]);
                    } else {
                      ['id','order_status','extra_workers','uploader','reference_no', 'department', 'order_status_id', 'drop_off_worker_id', 'vendor_id', 'extra_worker_ids'].forEach(e => delete dd[e]);
                    }
                    dd.is_calculate = true;

                    dd.order_packages.map((pack, index) => {
                      if (pack.package_id === 1) {
                        delete pack['created_at'];
                        delete pack['updated_at'];
                        delete pack['application_company_id'];
                        delete pack['order_id'];
                        delete pack['id'];
                        dd.order_packages[index]['name'] = 'XS'
                        dd.xs_packages =  dd.order_packages[index]['package_quantity']
                      }
                      if (pack.package_id === 2) {
                        delete pack['created_at'];
                        delete pack['updated_at'];
                        delete pack['application_company_id'];
                        delete pack['order_id'];
                        delete pack['id'];
                        dd.order_packages[index]['name'] = 'S'
                        dd.s_packages =  dd.order_packages[index]['package_quantity']
                      }
                      if (pack.package_id === 3) {
                        delete pack['created_at'];
                        delete pack['updated_at'];
                        delete pack['application_company_id'];
                        delete pack['order_id'];
                        delete pack['id'];
                        dd.order_packages[index]['name'] = 'M'
                        dd.m_packages =  dd.order_packages[index]['package_quantity']
                      }
                      if (pack.package_id === 4) {
                        delete pack['created_at'];
                        delete pack['updated_at'];
                        delete pack['application_company_id'];
                        delete pack['order_id'];
                        delete pack['id'];
                        dd.order_packages[index]['name'] = 'L'
                        dd.l_packages =  dd.order_packages[index]['package_quantity']
                      }
                      if (pack.package_id === 5) {
                        delete pack['created_at'];
                        delete pack['updated_at'];
                        delete pack['application_company_id'];
                        delete pack['order_id'];
                        delete pack['id'];
                        dd.order_packages[index]['name'] = 'Pallet'
                        dd.pallets =  dd.order_packages[index]['package_quantity']
                      }
                      if (pack.package_id === 6) {
                        delete pack['created_at'];
                        delete pack['updated_at'];
                        delete pack['application_company_id'];
                        delete pack['order_id'];
                        delete pack['id'];
                        dd.order_packages[index]['name'] = 'Oversized Package'
                        dd.oversized_packages =  dd.order_packages[index]['package_quantity']
                      }
                    })

                  })
                  
                  this.callCreateApi(row, () => {
                    this.rowData = null;
                    this.setState({
                      openDuplicateDialog: false,
                      DoDialogOrder: null,
                      duplicateOrders: [],
                    }, () => {
                      this.DoDialogOrder = null;
                    });
                  });
                }}>
                Confirm
              </Button>
            </div>
            </div>
          </Modal>
  }

  /* SOCKET */
  getMessages() { 
    const { user } = this.context;

    let application_company_id = apiUtil.getAppCompanyId(user);

    let observable = new Observable(observer => {
      this.socket = socketIOClient(REACT_APP_JOB_UPDATE_SOCKET_URL);

      this.socket.on('connect', () => {
        // console.log("SOCKET", 'connect', this.socket.connected);
      });

      this.socket.emit("join", "job_updated");

      this.socket.on(application_company_id.toString(), (data) => {
        observer.next(data);    
      });

      return () => {
        this.socket.disconnect();
      };  
    }) 
    return observable;
  }
  
  socketData(){
    this.connection = this.getMessages().subscribe(message => {
      
      this.dataMessage = message;
      this.dataMessage = this.dataMessage.order_history;
      if(this.dataMessage && !this.isArray(this.dataMessage)){
        let data = []; data.push(this.dataMessage);
        this.dataMessage= data;
      }
      if(this.dataMessage.length > 0){
        this.dataMessage.forEach(dataMessage=>{
          let actionText = ''
          let userName = ''
          if(dataMessage.admin_id){
            if(dataMessage.action === 'Created'){
              actionText = ' was created by ';
            }else if(dataMessage.action === 'Deleted Order'){
              actionText = ' was deleted by ';
            }else if(dataMessage.action === 'Updated'){
              if(dataMessage.attributes_updated && dataMessage.attributes_updated.includes('order_status_id') !== -1){
                actionText = ' status has been updated to ';
              }else{
                actionText = ' has been updated  by ';
              }
            }
            userName = dataMessage.admin.first_name

          }else if(dataMessage.worker_id){

            userName = dataMessage.worker.first_name
          }

          const icon =  dataMessage.admin_id ? "account_box" : "local_shipping";

          dataMessage.toShow = <div>
          <i className={'material-icons'}>{icon}</i>
          <b style={{marginRight:'5px'}}>{dataMessage.order_reference_no} </b>
          <span style={{marginRight:'5px'}}>{actionText}</span>
          {dataMessage.attributes_updated && dataMessage.attributes_updated.includes('order_status_id') !== -1 && 
          <b style={{marginRight:'5px'}}>{" " + dataMessage.current_status.status + " by "}</b>}
          <b>{userName}</b>
          </div>;
          if(this.state.isShouldDisableSocketToast === true){
            apiUtil.toast('', icon, '', 5000, dataMessage.toShow);
          }
          dataMessage.read_by_me = this.findRead(dataMessage.history_read_shown_markers);
          dataMessage.show_by_me = false;
          if(this.findNew(dataMessage.history_read_shown_markers)){ 
            dataMessage.show_by_me = true;
          }else{this.hadNew = true; }


          localStorage.setItem("doReload", JSON.stringify(dataMessage));
        });
      }
    });
  }
  

  isArray = (value) => {
    return value && typeof value === 'object' && value.constructor === Array;
  }

  findNew = (arrayList) => {
    let isNewItem = false;
    if(arrayList && arrayList.length > 0){
      arrayList.forEach(objItem=>{
        if(objItem.admin_id === localStorage.getItem("admin") && objItem.is_shown){
          isNewItem = true;
        }
      });
    }
    return isNewItem;
  }

  findRead = (arrayList) => {
    let isReadItem = false;
    if(arrayList && arrayList.length > 0){
      arrayList.forEach(objItem=>{
        if(objItem.admin_id === localStorage.getItem("admin") && objItem.is_read){
          isReadItem = true;
        }
      });
    }


    return isReadItem;
  }
  /* END SOCKET */


  /* API */
  callStatusApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoadingStatus: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
        deleted: true,
      };

      ldsApi.read('orders/job/stats', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              let total = data.total_delivery;
              let result = Object.keys(data.result).map((k) => data.result[k]);
              
              if(result && result.length > 0){
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(result, total);
                  }
                });
              } else {
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(null, 0);
                  }
                });
              }
            } else {
              this.setState({
                isLoadingStatus: false,
              }, () => {
                if(callback){
                  callback(null, 0);
                }
              });
            }
          }, (error, type) => {
            // type == 'failure' ? this.callStatusApi() : null;
            this.setState({
              isLoadingStatus: false,
            }, () => {
              if(callback){
                callback(null, 0);
              }
            });
          });
      });
    });
  }
  
  blobToDataURL = (blob, callback) => {
    if(blob.length != 0){
      
      var a = new FileReader();
      a.onload = function(e) {callback(e.target.result);}
      a.readAsDataURL(blob);
    }
  }

  callReadApi = (search = '', do_num = '', uploader = '', callback=null) => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoading: true,
      data: [],
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
      let searchAdv = false;
      if( this.state.search_do_number || 
          this.state.search_delivery_number || 
          this.state.search_time_slot || 
          this.state.search_recipient_name || 
          this.state.search_recipient_contact || 
          this.state.search_recipient_email || 
          this.state.search_item_desc || 
          this.state.search_delivery_address || 
          this.state.search_postal_code || 
          this.state.search_worker_id.length > 0 ||
          this.state.search_vendor_id ||
          this.state.customerId ||
          (this.state.search_from_time && this.state.search_to_time) ||
          this.state.search_uploader.length > 0
          )
      {
        searchAdv = true;
      }
      let param = null;
      
      if(this.state.search_text) {
        param = {
          deleted: true,
          page: this.pages,
          take: this.limits,
          search: this.state.search_text ? this.state.search_text.trim() : this.state.search_text,
          adv_search: searchAdv,
          start_date: startDate,
          end_date: endDate,
          order_status: this.state.order_status || this.state.search_status_id.map(obj => obj.value).join(','),
          do_number: !this.state.search_do_number ? this.state.search_do_number : this.state.search_do_number.trim(),
          worker_ids: this.state.search_worker_id.map(obj => typeof(obj.item.company_id) == 'undefined' ? obj.value : null).filter((item) => item !== null).join(this.state.search_worker_id.length > 1 ? ',' : ''),
          vendor_ids: this.state.search_worker_id.map(obj => typeof(obj.item.company_id) != 'undefined' ? obj.value : null).filter((item) => item !== null).join(this.state.search_worker_id.length > 1 ? ',' : ''),
          customer_id: !this.state.customerId ? this.state.customerId : this.state.customerId,
          order_number: !this.state.search_delivery_number ? this.state.search_delivery_number : this.state.search_delivery_number.trim(),
          time_slot: (this.state.search_from_time && this.state.search_to_time) ? (this.state.search_from_time + " - " + this.state.search_to_time) : '',
          delivery_type: !this.state.search_delivery_type ? this.state.search_delivery_type : this.state.search_delivery_type.trim(),
          recipient_name: !this.state.search_recipient_name ? this.state.search_recipient_name : this.state.search_recipient_name.trim(),
          recipient_contact: !this.state.search_recipient_contact ? this.state.search_recipient_contact : this.state.search_recipient_contact.trim(),
          recipient_email: !this.state.search_recipient_email ? this.state.search_recipient_email : this.state.search_recipient_email.trim(),
          item_description: !this.state.search_item_desc ? this.state.search_item_desc : this.state.search_item_desc.trim(),
          delivery_address: !this.state.search_delivery_address ? this.state.search_delivery_address : this.state.search_delivery_address.trim(),
          postal_code: !this.state.search_postal_code ? this.state.search_postal_code : this.state.search_postal_code.trim(),
          uploader: this.state.search_uploader.map(obj => obj.id).join(','),
          allocation_group: !this.state.search_group_id ? this.state.search_group_id : this.state.search_group_id.trim(),
          sort: "latest"
        };         
      } else {
        param = {
          deleted: true,
          page: this.pages,
          take: this.limits,
          adv_search: searchAdv,
          start_date: startDate,
          end_date: endDate,
          order_status: this.state.order_status,
          sort: "latest"
        };          
      }

      search_param_global = param ;
      status = this.state.order_status || this.state.search_status_id? true : false;
      // if(order)
      ldsApi.read('orders', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setColumnSetting();
            let sortJob = [];
            /*if(data.result && data.result.length > 0) {
              let colList = data.result.filter( c => c.collection_reference == null);
              if(colList.length > 0) {
                colList.map(cjob => {
                  data.result.map(d => {
                    if(d.collection_reference == cjob.order_number) {
                      sortJob.push(d);
                    }
                  })
                  sortJob.push(cjob);
                });
                data.result.map((obj) => {
                  if(!sortJob.filter((s) => s.order_number == obj.order_number).length > 0) {
                   sortJob.push(obj);
                  }
                });
              } else {
                sortJob = data.result;   
              }
            }*/
            this.setState({
              data: fromJS(data.result),
              state_copy_data: _.cloneDeep(data.result),
              total: data.total,
              company_logo : data.company_logo_base_64? data.company_logo_base_64 : '',
              orders_logo : data.orders_image_base64.length > 0? data.orders_image_base64 : [],
              isLoading: false,
            }, () => {
              this.setState({
                uploaders: data.uploaderse,
                oldUploaders: data.uploaders,
                is_date_change: false,
                is_search: false
              });       
              if(data.uploaders.length == 0) {
                this.setState({
                  search_uploader: []
                });                    
              }              
            });
            orders_logo = data.orders_image_base64.length > 0? data.orders_image_base64 : []
            orderData = (data.result && data.result.length > 0) ? data.result : []
            total =  data.total
            if(callback){
              callback(data);
            }
            // this.loadStatusApi();
          }, (error, type) => {
            if(callback) {
              callback([]);
            }
            this.setState({
              data: [],
              total: 0,
              uploaders: [],
              oldUploaders: [],
              isLoading: false,
            });
          });
      });
    });
  }

  callRefreshReadApi = (search = '', do_num = '', uploader = '') => {
    const { accessToken } = this.context;
    
    this.setState({
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
      let searchAdv = false;
      if( this.state.search_do_number || 
          this.state.search_delivery_number || 
          this.state.search_time_slot || 
          this.state.search_recipient_name || 
          this.state.search_recipient_contact || 
          this.state.search_recipient_email || 
          this.state.search_item_desc || 
          this.state.search_delivery_address || 
          this.state.search_postal_code || 
          this.state.search_uploader.length > 0 )
      {
        searchAdv = true;
      }
      let param = {
        page: this.pages,
        take: this.limits,
        search: this.state.search_text ? this.state.search_text.trim() : this.state.search_text,
        adv_search: searchAdv,
        start_date: startDate,
        end_date: endDate,
        order_status: this.state.order_status || this.state.search_status_id.map(obj => obj.value).join(','),
        do_number: this.state.search_do_number,
        worker_ids: this.state.search_worker_id.map(obj => typeof(obj.item.company_id) == 'undefined' ? obj.value : null).filter((item) => item !== null).join(this.state.search_worker_id.length > 1 ? ',' : ''),
        vendor_ids: this.state.search_worker_id.map(obj => typeof(obj.item.company_id) != 'undefined' ? obj.value : null).filter((item) => item !== null).join(this.state.search_worker_id.length > 1 ? ',' : ''),
        customer_id: this.state.customerId,
        order_number: this.state.search_delivery_number,
        time_slot: this.state.search_time_slot,
        delivery_type: this.state.search_delivery_type,
        recipient_name: this.state.search_recipient_name,
        recipient_contact: this.state.search_recipient_contact,
        recipient_email: this.state.search_recipient_email,
        item_description: this.state.search_item_desc,
        delivery_address: this.state.search_delivery_address,
        postal_code: this.state.search_postal_code,
        uploader: this.state.search_uploader.map(obj => obj.id).join(','),
        allocation_group: this.state.search_group_id,
        sort: "latest"
      };
      
      // if(order)
      ldsApi.read('orders', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let sortJob = [];
            /*if(data.result && data.result.length > 0) {
              let colList = data.result.filter( c => c.collection_reference == null);
              if(colList.length > 0) {
                colList.map(cjob => {
                  data.result.map(d => {
                    if(d.collection_reference == cjob.order_number) {
                      sortJob.push(d);
                    }
                  })
                  sortJob.push(cjob);
                });
                data.result.map((obj) => {
                  if(!sortJob.filter((s) => s.order_number == obj.order_number).length > 0) {
                   sortJob.push(obj);
                  }
                });
              } else {
                sortJob = data.result;   
              }
            }*/
            this.setState({
              data: fromJS(data.result),
              total: data.total,
              company_logo : data.company_logo_base_64? data.company_logo_base_64 : '',
              orders_logo : data.orders_image_base64.length > 0? data.orders_image_base64 : [],
              uploaders: (data.uploaders && data.uploaders.length > 0 && param.adv_search == false) ? data.uploaders : this.state.uploaders,
              // isLoading: false,
            },() => {
              this.setState({
                oldUploaders: this.state.uploaders
              });                 
            });
            total = data.total;
            // this.loadStatusApi();
          }, (error, type) => {
            this.setState({
              data: [],
              total: 0,
              uploaders: [],
              oldUploaders: [],
              // isLoading: false,
            });
          });
      });
    });
  }

  callAllocationGroupApi = (callback = null) => {
    const { accessToken } = this.context;
    
    this.setState({
    }, () => {
      let param = {
        sort: "latest",
        light_data: true,
      };
      
      // if(order)
      ldsApi.read('allocation_groups', param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result && data.result.length > 0) ? data.result : [];
  
          this.setState({
            allocation_groups: result
          })
        }, (error, type) => {
          if(callback){
            callback([]);
          }
        });
      });
    });
  }

  callUpdateIsActiveApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.update('orders/' + row.id, row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callDeleteApi = (row, callback = null) => {
    const { accessToken } = this.context;
    if (!(row.vendor_id != null && row.vendor_id == this.state.user.owner_info.application_company_id)) {
      this.setState({
        isLoadingDelete: true
      }, () => {
        let param = {
          destroy_all: true,
        };

        ldsApi.delete('delete/orders/multiple?id=' + row.id, param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              this.setState({
                isLoadingDelete: false,
                data:[],
              }, () => {
                if(callback){
                  callback();
                }
              });
            }, (error, type) => {
              apiUtil.toast(
                error,
                "check_circle"
              );
              this.setState({
                isLoadingDelete: false,
              }, () => {
                if(callback){
                  callback();
                }
              });
            });
        });
      });
    } else {
      apiUtil.toast('Please check with Operations for changes', 'warning', 'error');
    }
  }

  /* Start Mass Delete Api*/

  callMassDeleteApi = ( callback = null) => {
    const { accessToken } = this.context;
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
   
      let param = {
        start_date: startDate,
        end_date: endDate,
        destroy_all: true,
        deleted: true,
        empty_recycle: this.state.empty_recycle
      };
      let selectedRow = this.rowData;
      let ids = '';
      let vendor_order = '';
      let own_order = '';
      let check_vendor = '';
      if(selectedRow != null && selectedRow != '') {
        vendor_order = selectedRow.filter((item) => item.vendor_id != null && item.vendor_id == this.state.user.owner_info.application_company_id)
        own_order = selectedRow.filter((item) => item.vendor_id == null || (item.vendor_id != null && item.vendor_id != this.state.user.owner_info.application_company_id))
        own_order.map((item, i) => { ids += item.id +','; });

        vendor_order.map((item, i) => { check_vendor += item.order_number +','; });
        check_vendor = check_vendor.slice(0, -1);
      } else {
        if(this.state.empty_recycle == false ) {
          apiUtil.toast('You must select order!', 'warning', 'error');
          this.setState({
            openDialogMassDelete: false,
          })
          return
        }
      }

      ids = ids.slice(0, -1);
      if ((own_order.length > 0) || this.state.empty_recycle) {
        this.setState({
          isLoading: true
          }, () => {
        ldsApi.delete('delete/orders/multiple?id=' + ids, param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {

              this.loadStatusApi();
              this.callReadApi();            
              apiUtil.toast('Successfully Deleted', 'check_circle');
              this.rowData = null
              this.setState({
                openDialogMassDelete: false,
                openDialogEmptyRecycle: false,
                empty_recycle: false
              }, () => {
                if(callback){
                  callback();
                }
              });
            }, (error, type) => {
              apiUtil.toast(
                error,
                "check_circle"
              );
              this.setState({
                openDialogMassDelete: false,
                openDialogEmptyRecycle: false,
                empty_recycle: false
              })
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback();
                }
              });
            });
        });
        });
      } else {
        apiUtil.toast('Please check with Operations for changes', 'warning', 'error');
        this.setState({
          openDialogMassDelete: false,
          openDialogEmptyRecycle: false,
          empty_recycle: false
        })
      }


  }

  callMassRestoreApi = ( callback = null) => {
    const { accessToken } = this.context;

   
      let param = {
        destroy_all: true,
        deleted: true,
      };
      let selectedRow = this.rowData;
      let ids = '';
      let vendor_order = '';
      let own_order = '';
      let check_vendor = '';
      if(selectedRow != null && selectedRow != '') {
        vendor_order = selectedRow.filter((item) => item.vendor_id != null && item.vendor_id == this.state.user.owner_info.application_company_id)
        own_order = selectedRow.filter((item) => item.vendor_id == null || (item.vendor_id != null && item.vendor_id != this.state.user.owner_info.application_company_id))
        own_order.map((item, i) => { ids += item.id +','; });

        vendor_order.map((item, i) => { check_vendor += item.order_number +','; });
        check_vendor = check_vendor.slice(0, -1);
      } else {
        apiUtil.toast('You must select order!', 'warning', 'error');
        this.setState({
          openDialogMassRestore: false,
        })
        return
      }

      ids = ids.slice(0, -1);
      if ((own_order.length > 0)) {
        this.setState({
          isLoading: true
          }, () => {
        ldsApi.update('restore/orders/multiple?id=' + ids, param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {

              this.loadStatusApi();
              this.callReadApi();            
              apiUtil.toast('Successfully Restored', 'check_circle');
              this.rowData = null
              this.setState({
                openDialogMassRestore: false,
              }, () => {
                if(callback){
                  callback();
                }
              });
            }, (error, type) => {
              apiUtil.toast(
                error,
                "check_circle"
              );
              this.setState({
                openDialogMassRestore: false,
              })
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback();
                }
              });
            });
        });
        });
      } else {
        apiUtil.toast('vendor order can\'t be restore', 'warning', 'error');
        this.setState({
          openDialogMassRestore: false,
        })
      }


  }
  /*End Mass Delete Api*/

  callUpdateStatusApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('orders/assign/order', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result[0] : null;
            if(result){
              this.callInitApis()
              if(callback){
                callback(result);
              }
            } else {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
            apiUtil.toast(data.message != null ? data.message : "Successfully Updated", 'warning', 'error');
          }, (error, type) => {
            apiUtil.toast(error,  'warning', 'error')
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null)
              }
            });
          });
      });
    });
  }
  /* END API */

  callMassUpdateStatusApi = (row, callback = null) => {
    const { accessToken } = this.context;
    //console.log(row.data[0])
    if (!row.data[0].id.length > 0) {
      apiUtil.toast('You must select order!', 'warning', 'error');
      this.setState({
        change_driver_payout: null,
      })
      return
    }
    this.rowData = null
    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('orders/assign/order', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result : null;
            this.loadStatusApi()
            if(result){
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(result);
                  this.callReadApi();
                }
              });
            } else {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            if(error && error.email && error.email.length > 0){
                apiUtil.toast(error.email[0], 'warning', 'error');
              } else if(error && error !== ''){
                apiUtil.toast(error, 'warning', 'error');
              }
            this.setState({
              isLoading: false,
              openDialogChangePayout: false,
              openDialogUnassign: false
            }, () => {
            });
          });
      });
    });
  }

  callAutoAssignApi = (row, callback = null) => {
    const { accessToken } = this.context;
    this.setState({
      autoAssign: true
    }, () => {
      ldsApi.create('auto_assign/orders', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data.status){
              this.setState({
                autoAssign: false,
              }, () => {
                if(callback){
                  callback(data);
                }
              });
            } else {
              this.setState({
                autoAssign: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              autoAssign: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }

  /* WORKER API */
  callWorkerDriverReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    let param = {
      // page: 1,
      disabled: false,
      // take: apiUtil.getDefaultPageSize(),
      is_active: true,
      search: search ? search.trim() : search,
      is_truck_driver: true,
      // require_worker_only: true,
      light_data: true,
    };

    api.read('workers', param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result && data.result.length > 0) ? data.result : [];

          let arr = [];
          if(result && result.length > 0){
            arr = result.map((item, i) => {
              let value = item.id;
              let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

              if(arr.findIndex(x => x.value === value) === -1){
                return {
                  value: value,
                  label: label,
                  item: item
                }
              } else {
                return null;
              }
            });
            this.setState({
              workers: arr.sort((a, b) => {
                const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
              
                // names must be equal
                return 0;
              })
            }, () => {
              this.setState({
                oldWorkers: this.state.workers
              });                  
            })
            if(callback){
              callback(arr);
            }
            
          } else {
            if(callback){
              callback([]);
            }
          }
        }, (error, type) => {
          if(callback){
            callback([]);
          }
        });
    });
  }

  callManpowerDriverReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    let param = {
      // page: 1,
      // take: apiUtil.getDefaultPageSize(),
      disabled: false,
      is_active: true,
      search: search ? search.trim() : search,
      is_truck_driver: true,
      // require_worker_only: true,
      light_data: true,
    };

    api.read('workers', param, accessToken)
    .then((result) => {
      apiUtil.parseResult(result, (data) => {
        let result = (data.result && data.result.length > 0) ? data.result : [];

        let arr = [];
        if(result && result.length > 0){
          arr = result.map((item, i) => {
            let value = item.id;
            let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

            if(arr.findIndex(x => x.value === value) === -1){
              return {
                value: value,
                label: label,
                item: item
              }
            } else {
              return null;
            }
          });
  
          if(callback){
            callback(arr);
          }
        } else {
          if(callback){
            callback([]);
          }
        }
      }, (error, type) => {
        if(callback){
          callback([]);
        }
      });
    });
  }
  /* END WORKER API */

  callManpowerReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    let param = {
      // page: 1,
      // take: apiUtil.getDefaultPageSize(),
      disabled: false,
      is_active: true,
      search: search ? search.trim() : search,
      is_truck_driver: false,
      require_worker_only: true,
    };

    api.read('workers', param, accessToken)
    .then((result) => {
      apiUtil.parseResult(result, (data) => {
        let result = (data.result && data.result.length > 0) ? data.result : [];

        let arr = [];
        if(result && result.length > 0){
          arr = result.map((item, i) => {
            let value = item.id;
            let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

            if(arr.findIndex(x => x.value === value) === -1){
              return {
                value: value,
                label: label,
                item: item
              }
            } else {
              return null;
            }
          });
  
          if(callback){
            callback(arr);
          }
        } else {
          if(callback){
            callback([]);
          }
        }
      }, (error, type) => {
        if(callback){
          callback([]);
        }
      });
    });
  }
  /* END WORKER API */


  /* EXPORT API */
  callExportXLSApi = () => {
    const { accessToken } = this.context;

    this.setState({
        downloadIsLoading: true
    }, () => {
      let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());
      
      let selectedRow = this.rowData;
      let ids = '';
      if(selectedRow != null && selectedRow != '') {
        selectedRow.map((item, i) => { ids += item.id +','; });

        if(ids == "") {
          apiUtil.toast("There is no data ", "check_circle", "error")
          return         
        }
      } else {
        // this.state.data.map((item, i) => { ids += item.id +','; });
      }          

      let searchAdv = false;
      if( this.state.search_do_number || 
          this.state.search_delivery_number || 
          this.state.search_time_slot || 
          this.state.search_recipient_name || 
          this.state.search_recipient_contact || 
          this.state.search_recipient_email || 
          this.state.search_item_desc || 
          this.state.search_delivery_address || 
          this.state.search_postal_code || 
          this.state.search_uploader.length > 0 ||
          this.state.search_worker_id.length > 0 || 
          this.state.search_vendor_id ||
          this.state.customerId ||
          (this.state.search_from_time && this.state.search_to_time)          
          )
      {
        searchAdv = true;
      }
      let search_param = {
        order_ids: ids,
        search: this.state.search_text ? this.state.search_text.trim() : this.state.search_text,
        adv_search: searchAdv,
        start_date: startDate,
        end_date: endDate,
      };
      if (this.state.is_search == true) {
      let param = {
        order_ids: ids,
        search: this.state.search_text ? this.state.search_text.trim() : this.state.search_text,
        adv_search: searchAdv,
        start_date: startDate,
        end_date: endDate,
        order_status: this.state.order_status || this.state.search_status_id.map(obj => obj.value).join(','),
        do_number: !this.state.search_do_number ? this.state.search_do_number : this.state.search_do_number.trim(),
        worker_ids: this.state.search_worker_id.map(obj => typeof(obj.item.company_id) == 'undefined' ? obj.value : null).filter((item) => item !== null).join(this.state.search_worker_id.length > 1 ? ',' : ''),
        vendor_ids: this.state.search_worker_id.map(obj => typeof(obj.item.company_id) != 'undefined' ? obj.value : null).filter((item) => item !== null).join(this.state.search_worker_id.length > 1 ? ',' : ''),
        customer_id: !this.state.customerId ? this.state.customerId : this.state.customerId,
        order_number: !this.state.search_delivery_number ? this.state.search_delivery_number : this.state.search_delivery_number.trim(),
        time_slot: (this.state.search_from_time && this.state.search_to_time) ? (this.state.search_from_time + " - " + this.state.search_to_time) : '',
        delivery_type: !this.state.search_delivery_type ? this.state.search_delivery_type : this.state.search_delivery_type.trim(),
        recipient_name: !this.state.search_recipient_name ? this.state.search_recipient_name : this.state.search_recipient_name.trim(),
        recipient_contact: !this.state.search_recipient_contact ? this.state.search_recipient_contact : this.state.search_recipient_contact.trim(),
        recipient_email: !this.state.search_recipient_email ? this.state.search_recipient_email : this.state.search_recipient_email.trim(),
        item_description: !this.state.search_item_desc ? this.state.search_item_desc : this.state.search_item_desc.trim(),
        delivery_address: !this.state.search_delivery_address ? this.state.search_delivery_address : this.state.search_delivery_address.trim(),
        postal_code: !this.state.search_postal_code ? this.state.search_postal_code : this.state.search_postal_code.trim(),
        uploader: this.state.search_uploader.map(obj => obj.id).join(','),
        allocation_group: !this.state.search_group_id ? this.state.search_group_id : this.state.search_group_id.trim(),
      };
      search_param = param;
      search_param_global = param;
      } else {
        if (Object.keys(search_param_global).length !== 0){
          // console.log("search_param_global", search_param_global)
          search_param = search_param_global;
          if(ids) {
            search_param.order_ids = ids;
          }
        }
      }
      
      ldsApi.exportXLS('admin/api/orders/delivery/export', search_param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (response) => {
            apiUtil.saveToFile(response, `Jobs ${moment(startDate).format('DD-MM-YYYY')} - ${moment(endDate).format('DD-MM-YYYY')}.xls`);
      
            this.setState({
              downloadIsLoading: false,
            });
            search_param_global = {};
            this.rowData = null;
          }, (error, type) => {
            this.setState({
              data: fromJS(this.state.data).toJS(),
              downloadIsLoading: false,
            });
          });
      });
    });
  }
  /* END EXPORT API */

  /* IMPORT API */
  callImportApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('create/orders/multiple', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END IMPORT API */
  
  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    const {  isOnBoarding } = this.context;
    const currentRole = this.state.currentRole
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb xs={isOnBoarding ? 'auto' : true} />
        {isOnBoarding && <Box clone pr={1}>
          <Grid item xs={12} sm={true}>
            {this.setStepper()}
          </Grid>
        </Box>}
        <Box clone pr={{ xs: 0, md: 1 }} pb={{ xs: 2, md: 0 }}>
          <Grid item xs={12} md={'auto'}>
            <CustomDateRangePicker
              className={'select-dates-step'}
              range={this.state.rangeDate}
              onChange={(range) => {
                localStorage.setItem(this.pageName + '_rangeDate', JSON.stringify(range));

                this.setState({
                  rangeDate: range,
                  startDate: range.startDate,
                  endDate: range.endDate
                }, () => {
                  this.callInitApis();
                });
              }}
            />
          </Grid>
        </Box>
        <Box clone pr={1}>
          <Grid item xs={'auto'}>          
          <Link to={(currentRole == "Super Admin Role" || currentRole == "Admin Template Roles" || currentRole == "LDS Template Role") ? AdminCSVfile : ClientCSVfile} target="_blank" download style={{ textDecoration: 'none' }}>
            <CustomButton color={'Primary'} >
              <ImportIcon />
                  <Box display={'inline-block'} pl={1}>CSV Template</Box>
            </CustomButton>
            </Link>
          </Grid>
        </Box>
        <Box clone pr={1}>
          <Grid item xs={'auto'}>
          {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Add')) && <CustomUploadButton 
              color={'primary'}
              accept={'.xls, .xlsx'}
              onChange={(files) => {
                if (files && files.length > 0){
                  this.setState({
                    openImportDialog: true,
                    openImportDialogItem: files[0],
                  });
                }
              }}
            >
              {/* <ImportIcon /> */}
              Import Jobs
            </CustomUploadButton>
           }
          </Grid>
        </Box>
        <Box clone pr={1}>
          <Grid item xs={'auto'}>
          {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Add')) && <CustomButton 
              className={'new-job-step'}
              color={'secondary'}
              onClick={() => {
                this.props.history.push('/jobs-form');
              }}
            >
              + New Job
            </CustomButton>
          }
          </Grid>
        </Box> 
        { this.state.current_status_name === "Not Assigned" && moment(this.state.rangeDate.startDate).format("YYYY-MM-DD") === moment(this.state.rangeDate.endDate).format("YYYY-MM-DD") ?
          <Box clone>
            <Grid item xs={'auto'}>
              <CustomButton 
                color={'secondary'}
                onClick={() => {
                  this.props.history.push({
                    pathname: '/jobs/route-optimize',
                    state: {
                      total_current_status_job: this.state.total_current_status_job,
                      rangeDate: {
                        startDate: moment(this.state.rangeDate.startDate).format("YYYY-MM-DD"),
                        endDate: moment(this.state.rangeDate.endDate).format("YYYY-MM-DD")
                      }
                    }
                  });
                }}
              >
              Route Optimize
              </CustomButton>
            </Grid>
          </Box>: ""
        }
        
        <Box clone pt={{ xs: 4, md: 0 }}>
          <Grid item xs={12}>
            <GroupButton
              className={'head-tabs'}
              color={'secondary'}
              selected={this.state.jobTab}
              buttons={[ "Job List", "Job Schedule" ]}
              onClick={(selected, btn) => {
                if(selected === 1){
                  this.props.history.push('/job-schedule');
                }
              }}
            />
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */

  /* STATUS */
  customStatus = () => {
    return <Grid container alignItems={'center'} className="py-4">
      <Box clone>
        <Grid item xs={12} lg={true}>
          <Box className={'custom-status-list-wrapper'}>
            <StatusList
              selected={(this.state.statusSelected > 0) ? this.state.statusSelected : 0}
              items={this.state.status.map((item, i) => {
                return <StatusItem
                  text={<div style={{ width: 90, height: 90 }}>
                    <CircularProgressbar value={this.state.status_total == 0? 1 : item.total} maxValue={this.state.status_total == 0? 1 : this.state.status_total} strokeWidth={14} text={`${item.total}`} 
                    styles={buildStyles({
                      // Rotation of path and trail, in number of turns (0-1)
                      // rotation: 0.25,
                  
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: 'butt',

                      // Text size
                      textSize: '24px',
                  
                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 0.5,
                  
                      // Can specify path transition in more detail, or remove it entirely
                      // pathTransition: 'none',
                  
                      // Colors
                      pathColor: `${
                        item.status.replace(' ', '-').trim().toLowerCase() == 'not-assigned' ? '#75b8c8 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'info-received' ? '#0094ff ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'on-hold' ? '#BB7BFD ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'pending' ? '#FF2386 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'total-orders' ? '#007CDE ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'assign-pending'?'#777777 ' : 
                        item.status.replaceAll(' ', '-').trim().toLowerCase() == "assigned-to-vendor" ? '#F9CD4D ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'assigned'? '#F9CD4D ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'acknowledged'?'#a9629e ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'in-progress'?'#FF8906 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'completed' ? '#1ACE85 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'self-collect'?'#000000 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'cancelled'?'#696A79 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'failed'?' #F14254 ' : ' #000000'}`,
                      textColor:  `${
                        item.status.replace(' ', '-').trim().toLowerCase() == 'not-assigned' ? '#75b8c8 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'info-received' ? '#0094ff ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'on-hold' ? '#BB7BFD ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'pending' ? '#FF2386 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'total-orders' ? '#007CDE ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'assign-pending'?'#777777 ' : 
                        item.status.replaceAll(' ', '-').trim().toLowerCase() == "assigned-to-vendor" ? '#F9CD4D ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'assigned'? '#F9CD4D ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'acknowledged'?'#a9629e ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'in-progress'?'#FF8906 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'completed' ? '#1ACE85 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'self-collect'?'#000000 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'cancelled'?'#696A79 ' : 
                        item.status.replace(' ', '-').trim().toLowerCase() == 'failed'?' #F14254 ' : ' #000000'}`,
                      trailColor: '#c3c3c3',
                      backgroundColor: '#AE0264',
                    })}/>
                  </div>}
                  count={<span className={
                    'circle-progress-label ' + item.status.replaceAll(' ', '-').trim().toLowerCase()}>{item.status}</span>}
                />
              })}
              onClick={(index) => {
                this.pages = 1;
                let defaultGroupOrder = -1;
                if(index > 1){
                  defaultGroupOrder = 0;
                }
                this.setState({
                  statusSelected: index,
                  order_status: (index && index > 0) ? this.state.status[index].id : null,
                  current_status_name: (index && index > 0) ? this.state.status[index].status : null,
                  defaultGroupOrder: defaultGroupOrder,
                  total_current_status_job: (index && index > 0) ? this.state.status[index].total : 0,
                  is_search: false
                }, () => {
                  this.callInitApis();
                });
              }}
            />
          </Box>
        </Grid>
      </Box>
    </Grid>
  }
  /* END STATUS */

  handleMouseOver = (img) => {

      return this.state.hover && <div style={{zIndex: 300, backgroundColor: '#000000', width: "100vw", height: "100vh"}}>
        <img src={img} alt="preview image" width="300" height="auto"/>
      </div>
  };
  
  isCollectionFailed = (deliOrder) => {
    return deliOrder.failed_collection ? deliOrder.failed_collection : false;
  }

  handleMouseOut = () => {
    this.setState({
      hover: false
    })
  };
  
  /*
  *  Column field name and hidden name must same
  */

  getColumns = (isSort=true) => {
    
    let columns = [
      { 
        title: "Tracker Number",
        field: "delivery_number",
        customSort: (a,b) => (a.order_number? a.order_number: '').localeCompare(b.order_number? b.order_number : ''),
        hidden: this.state.hiddenColumns.includes('delivery_number'),
        render: (row, type) => {
          if(type === 'row'){
            return  (row && row.order_number && row.order_number !== '') ?  
            <span
            style={{display:'inline-block'}}  
            > {row.order_number} </span>: '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "Trip Date",
        field: "date",
        customSort: (a,b) => (a.drop_off_date? a.drop_off_date: '').localeCompare(b.drop_off_date? b.drop_off_date : ''),
        hidden: this.state.hiddenColumns.includes('date'),
        render: (row, type) => {
          if(type === 'row'){
            return (row && row.drop_off_date && row.drop_off_date !== '') ? 
            <span style={{overflowWrap: 'break-word', wordBreak: 'break-all',  display:'inline-block'}}>{moment(row.drop_off_date).format(apiUtil.getImportDateFormat()) }</span> : '';
          } else {
            return row;
          }
        }
      },
      ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'DO')) ? [{ 
        title: "D.O Number", 
        field: "do_number",
        cellStyle: {
          maxWidth: 160,
       },
        sorting: false,
        customSort: (a,b) => (a.do_number? a.do_number: '').localeCompare(b.do_number? b.do_number : ''),
        hidden: this.state.hiddenColumns.includes('do_number'),
        render: (row, type) => {
          if(type === 'row'){
            return (row && row.do_number && row.do_number !== '') ?<span style={{overflowWrap: 'break-word', wordBreak: 'break-word', display:'inline-block'}}> {row.do_number.replace(/\s/g, '\u00A0')}</span> : '';
          } else {
            return row;
          }
        }
      }] : [],
      { 
        title: "Job Type", 
        field: "delivery_type",
        sorting: false,
        customSort: (a,b) => (a.job_type? a.job_type: '').localeCompare(b.job_type? b.job_type : ''),
        hidden: this.state.hiddenColumns.includes('delivery_type'),
        render: (row, type) => {
          if(type === 'row'){
            return (row && row.job_type && row.job_type !== '') ? <span style={{ overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{row.job_type == "Collection" ? 'Pick Up' : 'Delivery'}</span> : '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "Project", 
        field: "is_project",
        sorting: false,
        customSort: (a,b) => (a.is_project !== null? a.is_project.toString(): '').localeCompare(b.is_project !== null? b.is_project.toString() : ''),
        hidden: this.state.hiddenColumns.includes('is_project'),
        render: (row, type) => {
          if(type === 'row'){
            return (row && row.is_project !== null) ? `${row.is_project == true ? "yes" : "No"}` : "No";
          } else {
            return row;
          }
        }
      },
      { 
        title: "Category", 
        field: "period_of_delivery",
        sorting: false,
        customSort: (a,b) => (a.period_of_delivery? a.period_of_delivery: '').localeCompare(b.period_of_delivery? b.period_of_delivery : ''),
        hidden: this.state.hiddenColumns.includes('period_of_delivery'),
        render: (row, type) => {
          if(type === 'row'){
            return (row && row.period_of_delivery !== null) ? `${row.period_of_delivery}` : "";
          } else {
            return row;
          }
        }
      },
      { 
        title: "Pick Up Ref (D.O)",
        field: "collection_reference",
        sorting: false,
        customSort: (a,b) => (a.collection_reference? a.collection_reference: '').localeCompare(b.collection_reference? b.collection_reference : ''),
        hidden: this.state.hiddenColumns.includes('collection_reference'),
        render: (row, type) => {
          if(type === 'row'){
            return (row && row.collection_reference && row.collection_reference !== '') ? <span style={{display:'inline-block'}}>{row.collection_reference}</span> : '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "Ordered By", 
        field: "order_by",
        cellStyle: {
          // minWidth: 150,
          maxWidth: 150
        },
        sorting: false,
        customSort: (a,b) => (a.uploader && a.uploader.first_name? a.uploader.first_name : '').localeCompare(b.uploader &&  b.uploader.first_name? b.uploader.first_name : ''),
        hidden: this.state.hiddenColumns.includes('order_by'),
        render: (row, type) => {
          if(type === 'row'){
            if(row.vendor_id && row.vendor_id == this.state.user.owner_info.application_company_id) {
              return (row && row.uploader && (row.uploader.application_company && row.uploader.application_company.company_name !== '')) ? <span style={{ width:'100%', overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{row.uploader.application_company.company_name}</span> : '';
            } else {
              return (row && row.uploader && (row.uploader.first_name !== '' || row.uploader.last_name !== '')) ? <span style={{ width:'100%', overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row.uploader.first_name? row.uploader.first_name: '') + ' ' + (row.uploader.last_name? row.uploader.last_name: '')}</span> : '';
            }
          } else {
            return row;
          }
        }
      },
      { 
        title: "Order Status",
        field: "order_status",
        cellStyle: {
          minWidth: 240,
          maxWidth: 240            
        },
        sorting: false,
        customSort: (a,b) =>(a.order_status.status == "Not Assigned" ? "Pending" : a.order_status.status == "Assigned Pending" ? "On Hold": a.order_status.status).toString().toLowerCase().trim().localeCompare((b.order_status.status == "Not Assigned" ? "Pending" : b.order_status.status == "Assigned Pending" ? "On Hold": b.order_status.status).toString().toLowerCase().trim()),
        hidden: this.state.hiddenColumns.includes('order_status'),
        render: (row, type) => {
          let item = [];
          if(type === 'row'){
            item = this.state.tableStatus;
            return <Box style={{zIndex:2000}}>
                  <Box>
                    <DropdownMenu 
                      isShouldEnable={true}
                      className={'status-btn ' + apiUtil.statusToClassName((row.order_status && row.order_status.status && row.order_status.status !== '') ?  row.order_status.status == "Not Assigned" ? "Pending" :  row.order_status.status == "Assign Pending" ? "On Hold" :  row.order_status.status : '')}
                      text={(row && row.order_status && row.order_status.status) ? row.order_status.status == "Not Assigned" ? "Pending" : row.order_status.status == "Assign Pending" ? "On Hold" : row.order_status.status : ''}
                      // rightIcon={<ArrowDownIcon />}
                      // items={this.state.tableStatus}
                    />        
                  </Box>
            </Box>;
        } else {
            return row;
          }
        }
      },
      { 
        title: "POD", 
        field: "POD",
        cellStyle: {
          // minWidth: 150,
          maxWidth: 150
        },
        hidden: this.state.hiddenColumns.includes('POD'),
        render: (row, type) => {
          if(type === 'row'){
            let jobStep = row.job_steps && row.job_steps[row.job_steps.length - 1]
            if(jobStep) {
            let signature_img = row.job_steps.length > 0 && jobStep.order_attempts && jobStep.order_attempts.length > 0 && jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.length > 0 ? jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.filter(dd => dd.is_signature == true) : []
            let proof_img =  row.job_steps.length > 0 && jobStep.order_attempts && jobStep.order_attempts.length > 0 && jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.length > 0 ? jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.filter(dd => dd.is_signature == false) : []
           
            return <div className='d-flex align-items-center justify-content-center'>
                    {proof_img.length ? <div className="image-preview d-flex align-items-center justify-content-between w-100">
                    <img src={proof_img[0]['image_url']} className="w-50" style={{margin: "auto"}}/>
                    <span>
                      <img src={proof_img[0]['image_url']} className="hovered-image" style={{margin: "auto"}}/>
                    </span> 
                    {jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.length > 1 ? 
                      <FontAwesomeIcon icon={faPlusCircle} className=" fs-5 svg-primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        // e.preventDefault();
                        if((this.isCollectionFailed(row) == false || (this.isCollectionFailed(row) == true && row.job_type == "Collection"))) {
                          if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')) {
                            this.setState({
                              openPreviewDialog: true,
                              openPreviewDialogItem: row.id,
                            });
                          }
                        }
                      }}/> : ''} </div> : signature_img.length ? <div className="image-preview d-flex align-items-center justify-content-between w-100">
                        <img src={signature_img[0]['image_url']} className="w-50" style={{margin: "auto"}}/>
                      <span>
                        <img src={signature_img[0]['image_url']} className="hovered-image" style={{margin: "auto"}}/>
                      </span>
                      {jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.length > 1 ? 
                        <FontAwesomeIcon icon={faPlusCircle} className=" fs-5 svg-primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          // e.preventDefault();
                          if((this.isCollectionFailed(row) == false || (this.isCollectionFailed(row) == true && row.job_type == "Collection"))) {
                            if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')) {
                              this.setState({
                                openPreviewDialog: true,
                                openPreviewDialogItem: row.id,
                              });
                            }
                          }
                        }}/> : ""}
                </div> : '' }
            </div>
            }
          } else {
            return row;
          }
        }
      },
      { 
        title: "Reason for Failure", 
        field: "reason_for_failure",
        cellStyle: {
          // minWidth: 150,
          maxWidth: 150
        },
        sorting: false,
        customSort: (a,b) => (a.job_steps.length > 0 && a.job_steps[0].order_attempts.length > 0 ? a.job_steps[0].order_attempts[0].reason: '').toString().toLowerCase().trim().localeCompare((b.job_steps.length > 0 && b.job_steps[0].order_attempts.length > 0 ? b.job_steps[0].order_attempts[0].reason : '').toString().toLowerCase().trim()),
        hidden: this.state.hiddenColumns.includes('reason_for_failure'),
        render: (row, type) => {
          if(type === 'row'){
            let jobStep = row.job_steps && row.job_steps[row.job_steps.length - 1]
            if(jobStep) {
            let failed_reason = row.job_steps.length > 0 && jobStep.order_attempts.length > 0  ? jobStep.order_attempts[jobStep.order_attempts.length - 1].reason : ''
            return row && row.order_status && row.order_status.status == "Failed" ? failed_reason : '';
            } 
          } else {
            return row;
          }
        }
      },
      ... (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Address')) ?[{ 
        title: "Delivery Address", 
        field: "delivery_address",
        cellStyle: {
          // minWidth: 190,
          maxWidth: 190
        },
        sorting: false,
        customSort: (a,b) => (a.drop_off_address? a.drop_off_address: '').localeCompare(b.drop_off_address? b.drop_off_address : ''),
        hidden: this.state.hiddenColumns.includes('delivery_address'),
        render: (row, type) => {
          if(type === 'row'){
            // return (row && row.drop_off_address && row.drop_off_address !== '') ? HtmlParser(row.drop_off_address) : '';
            return (row && row.drop_off_address && row.drop_off_address !== '') ?<span style={{ overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}> {row.drop_off_address }</span>  : '';
          } else {
            return row;
          }
        }
      }] : [],
      { 
        title: "ETA", 
        field: "arrival_time",
        cellStyle: {
          // minWidth: 100,
          maxWidth: 100
        },
        sorting: false,
        customSort: (a,b) => (a.route_entity && a.drop_off_latitude && a.drop_off_longitude ? a.route_entity.arrival: '').localeCompare(b.route_entity && b.drop_off_latitude && b.drop_off_longitude? b.route_entity.arrival : ''),
        hidden: this.state.hiddenColumns.includes('arrival_time'),
        render: (row, type) => {
          let today = moment().format('YYYY-MM-DD');
          let startDate = moment(row.drop_off_date).format('YYYY-MM-DD');
          if(type === 'row'){
            return (today == startDate && row && row.route_entity && row.route_entity.active && (row.order_status.status == "In Progress" || row.order_status.status == "Acknowledged")) && row.drop_off_latitude && row.drop_off_longitude ? moment.parseZone(row.route_entity.arrival).format('H:mm')  : '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "Postal Code", 
        field: "postal_code",
        cellStyle: {
          // minWidth: 100,
          maxWidth: 100
        },
        sorting: false,
        customSort: (a,b) => Number(a.drop_off_postal_code ? a.drop_off_postal_code: '') - Number(b.drop_off_postal_code ? b.drop_off_postal_code: ''),
        hidden: this.state.hiddenColumns.includes('postal_code'),
        render: (row, type) => {
          if(type === 'row'){
            return (row && row.drop_off_postal_code && row.drop_off_postal_code !== '') ? HtmlParser(row.drop_off_postal_code) : '';
          } else {
            return row;
          }
        }
      },
      ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Delivery Time')) ?[{ 
        title: "Between", 
        field: "time_slot",
        cellStyle: {
          // minWidth: 100,
          maxWidth: 100
        },
        sorting: false,
        customSort: (a,b) => (a.drop_off_time_planned && a.drop_off_time_planned !== '' ? a.drop_off_time_planned.split("-")[0]: '').localeCompare(b.drop_off_time_planned && b.drop_off_time_planned !== '' ? b.drop_off_time_planned.split("-")[0]: ''),
        hidden: this.state.hiddenColumns.includes('time_slot'),
        render: (row, type) => {
          if(type === 'row'){
            let drop_from_time = row && row.drop_off_time_planned && row.drop_off_time_planned !== '' ? row.drop_off_time_planned.split("-") : [];
            return drop_from_time.length > 0 && row.drop_off_time_planned != '9:00 - 18:00' ? drop_from_time[0] : '';
          } else {
            return row;
          }
        }
      }, 
      { 
        title: "And", 
        field: "time_slot",
        cellStyle: {
          // minWidth: 100,
          maxWidth: 100
        },
        sorting: false,
        customSort: (a,b) => (a.drop_off_time_planned && a.drop_off_time_planned !== '' ? a.drop_off_time_planned.split("-")[1]: '').localeCompare(b.drop_off_time_planned && b.drop_off_time_planned !== '' ? b.drop_off_time_planned.split("-")[1]: ''),
        hidden: this.state.hiddenColumns.includes('time_slot'),
        render: (row, type) => {
          if(type === 'row'){
            let drop_from_time = row && row.drop_off_time_planned && row.drop_off_time_planned !== '' ? row.drop_off_time_planned.split("-") : [];
            return drop_from_time.length > 0 && row.drop_off_time_planned != '9:00 - 18:00' ? drop_from_time[1] : '';
          } else {
            return row;
          }
        }
      }] : [],
      ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver') ||
          this.state.currentRole == 'Ops Admin' ||
          this.state.currentRole == 'Finance Admin' ||
          this.state.currentRole == 'BD Admin') ? [{
        title: "Driver-Partner", 
        field: "driver_partner",
        cellStyle: {
          // minWidth: 150,
          maxWidth: 150
        },
        sorting: false,
        customSort: (a,b) => (a.drop_off_worker ? a.drop_off_worker.first_name : '').toString().toLowerCase().trim().localeCompare((b.drop_off_worker ? b.drop_off_worker.first_name : '').toString().toLowerCase().trim()),
        hidden: this.state.hiddenColumns.includes('driver_partner'),
        render: (row, type) => {
          if(type === 'row'){
            if(row.drop_off_worker_id && row.drop_off_worker_id > 0 && row.drop_off_worker){
              
              row.workerName = row.drop_off_worker.first_name +" "+ row.drop_off_worker.last_name;
            }
            if(row.vendor_id && row.vendor_id != null){
              row.vendorName = ( row.vendor && row.vendor.company_name != null ? row.vendor.company_name : "") ;
            }
            return row.vendor_id ? row.vendor_id != this.state.user.owner_info.application_company_id ? row.vendorName : row.workerName : row.workerName
          } else {
            return row;
          }
        }
      }] : [],
      /*...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Man Power') ||
          this.state.currentRole == 'Ops Admin' ||
          this.state.currentRole == 'Finance Admin' ||
          this.state.currentRole == 'BD Admin') ? [{ 
        title: "Driver-Assistant", 
        field: "extra_workers[0].first_name",
        cellStyle: {
          // minWidth: 150,
          maxWidth: 150
        },
        customSort: (a,b) => (a.extra_workers && a.extra_workers.length > 0 && a.extra_workers[0].first_name ? a.extra_workers[0].first_name : '').toString().toLowerCase().trim().localeCompare((b.extra_workers && b.extra_workers.length > 0 && b.extra_workers[0].first_name ? b.extra_workers[0].first_name  : '').toString().toLowerCase().trim()),
        hidden: this.state.hiddenColumns.includes('driver_partner'),
        render: (row, type) => {
          if(type === 'row'){
            let label = '';
            let manpowerName = (row.extra_workers && row.extra_workers.length > 0) ? row.extra_workers[0] : null;

            if(manpowerName){
              label = ((manpowerName.first_name && manpowerName.first_name !== '') ? manpowerName.first_name : '') + ' ' + ((manpowerName.last_name && manpowerName.last_name !== '') ? manpowerName.last_name : '');
            }

            row.manpowerName = label;
            if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Man Power') ||
                this.state.currentRole == 'Ops Admin' ) {
            return <Box>
              <CustomAutosuggest 
                disabled={(row.order_status && row.order_status.status == "Completed"  || row.order_status && row.order_status.status == "Failed") ? true : ((this.isCollectionFailed(row) == false || (this.isCollectionFailed(row) == true && row.job_type == "Collection")) && (row.drop_off_worker_id || row.vendor_id)? ((row.vendor_id != null && row.vendor_id != this.state.user.owner_info.application_company_id)? true : ((row.vendor_id != null && row.vendor_id == this.state.user.owner_info.application_company_id) && !row.drop_off_worker_id? true : false)) : true)}
                className={'no-margin'}
                placeholder={'Assign Manpower'}
                value={row.manpowerName}
                items={this.state.manPowerItems}
                onChange={(value) => {
                  if(value){
                    row.manpowerName = value.label;
                    row.manpowerId = value.value;
                    row.manpowerItem = value.item;
                  } else {
                    row.manpowerName = '';
                    row.manpowerId = null;
                    row.manpowerItem = null;
                  }

                  if (row.job_steps === undefined) {
                    row.job_steps = [];
                  }

                  let data = {
                    data: [
                      {
                        id: [ row.id ],
                        job_steps: row.job_steps.length > 0 ? row.job_steps.map(item=> {
                          item.job_step_id = item.id
                          return item
                        }): row.job_steps,
                        order_status_id: row.order_status_id,
                        send_notification_to_customer: true,
                        extra_worker_ids: (row.manpowerId) ? row.manpowerId.toString() : null,
                      }
                    ]
                  };
                  this.callUpdateStatusApi(data, (newRow) => {
                    if(newRow){
                      let newData = apiUtil.updateRow(fromJS(this.state.data).toJS(), newRow, 'id');
                      this.setState({
                        data: newData,
                      });
                      this.callReadApi();
                    }
                  });
                }}
                onSearch={(value, e) => {
                  row.manpowerName = value;
                  let searchItems = [];
                  e.state.items.find( obj => {
                    let name = obj.label != null ? obj.label : '';
                    if(name.includes(value)) {
                      searchItems.push(obj)
                    }
                  });
                  searchItems = searchItems.length > 0 && value != '' ? searchItems : this.state.manPowerItems;
                  e.showNewSuggestions(searchItems);
                }}
                onClick={(value, e) => {
                  e.showAllSuggestions();
                }}
              />
            </Box>;
            } else {
              return row.manpowerName;
            }
          } else {
            return row;
          }
        }
      }] : [],*/
      ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Company')) ? [{ 
        title: "Client Company", 
        field: "recipent_company",
        cellStyle: {
          // minWidth: 130,
          maxWidth: 130,
       },
        hidden: this.state.hiddenColumns.includes('recipent_company'),
        sorting: false,
        customSort: (a,b) => (a.customer ? a.customer.company_name : '').toString().toLowerCase().trim().localeCompare((b.customer ? b.customer.company_name : '').toString().toLowerCase().trim()),
        render: (row, type) => {
          if(type === 'row'){
            return <span style={{ overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row && row.customer && row.customer.company_name !== '') ? HtmlParser(row.customer.company_name) : ''} </span>;
            // return (row && row.drop_off_description && row.drop_off_description !== '') ?<span style={{ width:350, overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}> {row.drop_off_description }</span>  : '';
          } else {
            return row;
          }
        }
      }] : [],
      { 
        title: "Department", 
        field: "department",
        cellStyle: {
          // minWidth: 130,
          maxWidth: 130,
       },
       sorting: false,
       customSort: (a,b) => (a.department && a.department.name? a.department.name: '').localeCompare(b.department && b.department.name? b.department.name : ''),
        hidden: this.state.hiddenColumns.includes('department'),
        render: (row, type) => {
          if(type === 'row'){
            return <span style={{ overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row && row.department && row.department.name ) ? HtmlParser(row.department.name) : ''}</span>;
          } else {
            return row;
          }
        }
      },
      { 
        title: "Recipient's Name", 
        field: "recipient_name",
        cellStyle: {
          // minWidth: 130,
          maxWidth: 130,
       },
       sorting: false,
       customSort: (a,b) => (a.drop_off_contact_name? a.drop_off_contact_name: '').localeCompare(b.drop_off_contact_name ? b.drop_off_contact_name : ''),
        hidden: this.state.hiddenColumns.includes('recipient_name'),
        render: (row, type) => {
          if(type === 'row'){
            return <span style={{ overflowWrap: 'break-word', wordBreak: 'break-all',  display:'inline-block'}}>{(row && row.drop_off_contact_name ) ? HtmlParser(row.drop_off_contact_name) : ''}</span>;
          } else {
            return row;
          }
        }
      },
      { 
        title: "Company", 
        field: "company",
        cellStyle: {
          // minWidth: 130,
          maxWidth: 130,
       },
       sorting: false,
       customSort: (a,b) => (a.drop_off_name? a.drop_off_name: '').localeCompare(b.drop_off_name ? b.drop_off_name : ''),
        hidden: this.state.hiddenColumns.includes('company'),
        render: (row, type) => {
          if(type === 'row'){
            return <span style={{ overflowWrap: 'break-word', wordBreak: 'break-all',  display:'inline-block'}}>{(row && row.drop_off_name ) ? HtmlParser(row.drop_off_name) : ''}</span>;
          } else {
            return row;
          }
        }
      },
      { 
        title: "Contact No.", 
        field: "recipient_contact",
        cellStyle: {
          // minWidth: 130,
          maxWidth: 130,
       },
       sorting: false,
       customSort: (a,b) => (a.drop_off_contact_no? a.drop_off_contact_no: '').localeCompare(b.drop_off_contact_no ? b.drop_off_contact_no : ''),
        hidden: this.state.hiddenColumns.includes('recipient_contact'),
        render: (row, type) => {
          if(type === 'row'){
            return <span style={{ overflowWrap: 'break-word', wordBreak: 'break-all',  display:'inline-block'}}>{(row && row.drop_off_contact_no) ? HtmlParser(row.drop_off_contact_no) : ''} </span>;
          } else {
            return row;
          }
        }
      },
      { 
        title: "Alternative Contact", 
        field: "alternate_contact",
        cellStyle: {
          // minWidth: 130,
          maxWidth: 130,
       },
       sorting: false,
       customSort: (a,b) => (a.drop_off_alternate_contact? a.drop_off_alternate_contact: '').localeCompare(b.drop_off_alternate_contact ? b.drop_off_alternate_contact : ''),
        hidden: this.state.hiddenColumns.includes('alternate_contact'),
        render: (row, type) => {
          if(type === 'row'){
            return <span style={{ overflowWrap: 'break-word', wordBreak: 'break-all',  display:'inline-block'}}>{(row && row.drop_off_alternate_contact) ? HtmlParser(row.drop_off_alternate_contact) : ''} </span>;
          } else {
            return row;
          }
        }
      },
      { 
        title: "Recipient Email", 
        field: "recipient_email",
        sorting: false,
        customSort: (a,b) => (a.notification_email? a.notification_email: '').localeCompare(b.notification_email ? b.notification_email : ''),
        hidden: this.state.hiddenColumns.includes('recipient_email'),
        cellStyle: {
          // minWidth: 120,
          maxWidth: 120,
       },
        render: (row, type) => {
          if(type === 'row'){
            return <span style={{ overflowWrap: 'break-word', wordBreak: 'break-all',  display:'inline-block'}}>{(row && row.notification_email ) ? HtmlParser(row.notification_email) : ''}</span>;
          } else {
            return row;
          }
        }
      },
      ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Description')) ?[{ 
        title: "Item Description", 
        field: "item_description",
        sorting: false,
        customSort: (a,b) => (a.drop_off_description? a.drop_off_description: '').localeCompare(b.drop_off_description ? b.drop_off_description : ''),
        cellStyle: {
          // minWidth: 190,
          maxWidth: 190
        },
        hidden: this.state.hiddenColumns.includes('item_description'),
        render: (row, type) => {
          if(type === 'row'){
            return <span className='none-backgroud-color' style={{ overflowWrap: 'break-word', wordBreak: 'break-all',  display:'inline-block'}}>{(row && row.drop_off_description && row.drop_off_description !== '') ? HtmlParser(row.drop_off_description) : ''}</span>;
            // return (row && row.drop_off_description && row.drop_off_description !== '') ?<span style={{ width:350, overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}> {row.drop_off_description }</span>  : '';
          } else {
            return row;
          }
        }
      }] : [],
      { 
        title: "XS", 
        field: "xs_packages",
        sorting: false,
        customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 1).length > 0 ? a.order_packages.filter(dd => dd.package_id == 1)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 1).length > 0 ? b.order_packages.filter(dd => dd.package_id == 1)[0].package_quantity: ''),
        hidden: this.state.hiddenColumns.includes('xs_packages'),
        render: (row, type) => {
          if(type === 'row'){
            let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 1) : []
            return (pack.length > 0) ? pack[0].package_quantity: '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "S", 
        field: "s_packages",
        sorting: false,
        customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 2).length > 0 ? a.order_packages.filter(dd => dd.package_id == 2)[0].package_quantity: '') - (b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 2).length > 0 ? b.order_packages.filter(dd => dd.package_id == 2)[0].package_quantity: ''),
        hidden: this.state.hiddenColumns.includes('s_packages'),
        render: (row, type) => {
          if(type === 'row'){
            let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 2) : []
            return (pack.length > 0) ? pack[0].package_quantity: '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "M", 
        field: "m_packages",
        sorting: false,
        customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 3).length > 0 ? a.order_packages.filter(dd => dd.package_id == 3)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 3).length > 0 ? b.order_packages.filter(dd => dd.package_id == 3)[0].package_quantity: ''),
        hidden: this.state.hiddenColumns.includes('m_packages'),
        render: (row, type) => {
          if(type === 'row'){
            let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 3) : []
            return (pack.length > 0) ? pack[0].package_quantity: '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "L", 
        field: "l_packages",
        sorting: false,
        customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 4).length > 0 ? a.order_packages.filter(dd => dd.package_id == 4)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 4).length > 0 ? b.order_packages.filter(dd => dd.package_id == 4)[0].package_quantity: ''),
        hidden: this.state.hiddenColumns.includes('l_packages'),
        render: (row, type) => {
          if(type === 'row'){
            let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 4) : []
            return (pack.length > 0) ? pack[0].package_quantity: '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "Oversized", 
        field: "oversized_packages",
        sorting: false,
        customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 6).length > 0 ? a.order_packages.filter(dd => dd.package_id == 6)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 6).length > 0 ? b.order_packages.filter(dd => dd.package_id == 6)[0].package_quantity: ''),
        hidden: this.state.hiddenColumns.includes('oversized_packages'),
        render: (row, type) => {
          if(type === 'row'){
            let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 6) : []
            return (pack.length > 0) ? pack[0].package_quantity: '';
          } else {
            return row;
          }
        }
      },
      // { 
      //   title: "Document", 
      //   field: "document",
      //   cellStyle: {
      //     // minWidth: 150,
      //     maxWidth: 150
      //   },
      //   customSort: (a,b) => Number(a.extra_data && JSON.parse(a.extra_data).document && !isNaN(JSON.parse(a.extra_data).document) ? JSON.parse(a.extra_data).document : '') - Number(b.extra_data && JSON.parse(b.extra_data).document && !isNaN(JSON.parse(b.extra_data).document) ? JSON.parse(b.extra_data).document : ''),
      //   hidden: this.state.hiddenColumns.includes('document'),
      //   render: (row, type) => {
      //     if(type === 'row'){
      //       let extra_data = JSON.parse(row.extra_data)
      //       return (extra_data && extra_data.document) ? extra_data.document : '';
      //     } else {
      //       return row;
      //     }
      //   }
      // },
      // { 
      //   title: "Boxes", 
      //   field: "boxes",
      //   cellStyle: {
      //     // minWidth: 150,
      //     maxWidth: 150
      //   },
      //   customSort: (a,b) => Number(a.extra_data && JSON.parse(a.extra_data).boxes && !isNaN(JSON.parse(a.extra_data).boxes) ? JSON.parse(a.extra_data).boxes : '') - Number(b.extra_data && JSON.parse(b.extra_data).boxes && !isNaN(JSON.parse(b.extra_data).boxes) ? JSON.parse(b.extra_data).boxes : ''),
      //   hidden: this.state.hiddenColumns.includes('boxes'),
      //   render: (row, type) => {
      //     if(type === 'row'){
      //       let extra_data = JSON.parse(row.extra_data)
      //       return (extra_data && extra_data.boxes) ? extra_data.boxes : '';
      //     } else {
      //       return row;
      //     }
      //   }
      // },
      // { 
      //   title: "Cartons", 
      //   field: "cartons",
      //   cellStyle: {
      //     // minWidth: 150,
      //     maxWidth: 150
      //   },
      //   customSort: (a,b) => Number(a.extra_data && JSON.parse(a.extra_data).cartons && !isNaN(JSON.parse(a.extra_data).cartons) ? JSON.parse(a.extra_data).cartons : '') - Number(b.extra_data && JSON.parse(b.extra_data).cartons && !isNaN(JSON.parse(b.extra_data).cartons) ? JSON.parse(b.extra_data).cartons : ''),
      //   hidden: this.state.hiddenColumns.includes('cartons'),
      //   render: (row, type) => {
      //     if(type === 'row'){
      //       let extra_data = JSON.parse(row.extra_data)
      //       return (extra_data && extra_data.cartons) ? extra_data.cartons : '';
      //     } else {
      //       return row;
      //     }
      //   }
      // },
      { 
        title: "Pallets", 
        field: "pallets",
        sorting: false,
        customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 5).length > 0 ? a.order_packages.filter(dd => dd.package_id == 5)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 5).length > 0 ? b.order_packages.filter(dd => dd.package_id == 5)[0].package_quantity: ''),
        hidden: this.state.hiddenColumns.includes('pallets'),
        render: (row, type) => {
          if(type === 'row'){
            let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 5) : []
            return (pack.length > 0) ? pack[0].package_quantity: '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "Depalletization Request", 
        field: "depalletization_request",
        sorting: false,
        customSort: (a,b) => (a.package_info && JSON.parse(a.package_info).depalletization_request ? JSON.parse(a.package_info).depalletization_request : '').toString().toLowerCase().trim().localeCompare((b.package_info && JSON.parse(b.package_info).depalletization_request ? JSON.parse(b.package_info).depalletization_request : '').toString().toLowerCase().trim()),
        hidden: this.state.hiddenColumns.includes('depalletization_request'),
        render: (row, type) => {
          if(type === 'row'){
            let package_info = row && row.package_info && JSON.parse(row.package_info)
            return (package_info && package_info.depalletization_request) ? package_info.depalletization_request  : '';
           } else {
            return row;
          }
        }
      },
      { 
        title: "Size in cm(L + W + H)", 
        field: "size_lwh",
        sorting: false,
        customSort: (a,b) => Number(a.package_info &&  JSON.parse(a.package_info).size_l_w_h  && !isNaN(JSON.parse(a.package_info).size_l_w_h) ? JSON.parse(a.package_info).size_l_w_h : '') - Number(b.package_info && JSON.parse(b.package_info).size_l_w_h && !isNaN(JSON.parse(b.package_info).size_l_w_h)? JSON.parse(b.package_info).size_l_w_h : ''),
        hidden: this.state.hiddenColumns.includes('size_lwh'),
        render: (row, type) => {
          if(type === 'row'){
            let package_info = row && row.package_info && JSON.parse(row.package_info)
            return (package_info && package_info.size_l_w_h) ? package_info.size_l_w_h : '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "Weight (KG)", 
        field: "weight",
        sorting: false,
        customSort: (a,b) => Number(a.package_info &&  JSON.parse(a.package_info).weight  && !isNaN(JSON.parse(a.package_info).weight) ? JSON.parse(a.package_info).weight : '') - Number(b.package_info && JSON.parse(b.package_info).weight && !isNaN(JSON.parse(b.package_info).weight)? JSON.parse(b.package_info).weight : ''),
        hidden: this.state.hiddenColumns.includes('weight'),
        render: (row, type) => {
          if(type === 'row'){
            let package_info = row && row.package_info &&  JSON.parse(row.package_info)
            return (package_info && package_info.weight) ? package_info.weight : '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "Waiting Time", 
        field: "waiting_time",
        sorting: false,
        customSort: (a,b) => (a.package_info && JSON.parse(a.package_info).waiting_time ? JSON.parse(a.package_info).waiting_time : '').toString().toLowerCase().trim().localeCompare((b.package_info && JSON.parse(b.package_info).waiting_time ? JSON.parse(b.package_info).waiting_time : '').toString().toLowerCase().trim()),
        hidden: this.state.hiddenColumns.includes('waiting_time'),
        render: (row, type) => {
          if(type === 'row'){
            let package_info = row && row.package_info && JSON.parse(row.package_info)
            return (package_info && package_info.waiting_time) ? package_info.waiting_time : '';
           } else {
            return row;
          }
        }
      },
      { 
        title: "Additional Manpower", 
        field: "no_of_movers",
        sorting: false,
        customSort: (a,b) => Number(a.package_info &&  JSON.parse(a.package_info).no_of_movers  && !isNaN(JSON.parse(a.package_info).no_of_movers) ? JSON.parse(a.package_info).no_of_movers : '') - Number(b.package_info && JSON.parse(b.package_info).no_of_movers && !isNaN(JSON.parse(b.package_info).no_of_movers)? JSON.parse(b.package_info).no_of_movers : ''),
        hidden: this.state.hiddenColumns.includes('no_of_movers'),
        render: (row, type) => {
          if(type === 'row'){
            let package_info = row && row.package_info && JSON.parse(row.package_info)
            return (package_info && package_info.no_of_movers) ? package_info.no_of_movers : '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "Price", 
        field: "price",
        cellStyle: {
          // minWidth: 100,
          maxWidth: 100
        },
        sorting: false,
        customSort: (a,b) => Number(a.amount ? a.amount : '') - Number(b.amount ? b.amount : ''),
        hidden: this.state.hiddenColumns.includes('price'),
        render: (row, type) => {
          if(type === 'row'){
            if ( row.vendor_id == this.state.user.owner_info.application_company_id) {
              return (row && row.vendor_payout && row.vendor_payout !== '') ? parseFloat(row.vendor_payout).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00';
            } 
            return (row && row.amount && row.amount !== '') ? parseFloat(row.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00';
            
          } else {
            return row;
          }
        }
      },
      { 
        title: "Vendor Payout", 
        field: "vendor_payout",
        sorting: false,
        customSort: (a,b) => Number(a.vendor_payout ? a.vendor_payout : '') - Number(b.vendor_payout ? b.vendor_payout : ''),
        hidden: this.state.hiddenColumns.includes('vendor_payout'),
        render: (row, type) => {
          if(type === 'row'){
            if ( row.vendor_id == this.state.user.owner_info.application_company_id || !row.vendor_id) {
                return '-'
            } 
            return (row && row.vendor_payout && row.vendor_payout !== '') ? parseFloat(row.vendor_payout).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00';

          } else {
            return row;
          }
        }
      },
      ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver Payout')) || (this.state.currentRole == "BD Admin") || (this.state.currentRole == "Finance Admin")  ?[{ 
        title: "Driver Payout", 
        field: "driver_payout",
        hidden: this.state.hiddenColumns.includes('driver_payout'),
        sorting: false,
        customSort: (a,b) => Number(a.driver_payout ? a.driver_payout : '') - Number(b.driver_payout ? b.driver_payout : ''),
        render: (row, type) => {
          if(type === 'row'){
            if((row.vendor_id && row.vendor_id != this.state.user.owner_info.application_company_id) || !row.drop_off_worker_id ) {
              return '-'
            }
            return (row && row.driver_payout && row.driver_payout !== '') ? parseFloat(row.driver_payout).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00';
          } else {
            return row;
          }
        }
      }] : [],
      { 
        title: "Notes to Driver-Partner", 
        field: "notes_to_driver",
        cellStyle: {
          // minWidth: 180,
          maxWidth: 180
        },
        sorting: false,
        customSort: (a,b) => (a.driver_notes? a.driver_notes: '').localeCompare(b.driver_notes ? b.driver_notes : ''),
        hidden: this.state.hiddenColumns.includes('notes_to_driver'),
        render: (row, type) => {
          if(type === 'row'){
            const entities = new Html5Entities();
            const encodedNotes = entities.encode(row.driver_notes);
            return (row && row.driver_notes && row.driver_notes !== '') ? <span dangerouslySetInnerHTML={{ __html: encodedNotes }} /> : '';
           } else {
            return row;
          }
        }
      }
    ];

    if(isSort) {
      const jobColumnOrder = JSON.parse(localStorage.getItem('job_column_order'));
      const columnOrder = this.state.sortColumn.length > 0 ? this.state.sortColumn : []
      if(columnOrder.length > 0) {
        let columnSort = [];
        columnOrder.map((val,index) => {
          if(columns[val]) {
            columnSort.push(columns[val])    
          }          
        });
        return columnSort;
      }
    }
    return columns;    
  }
  
  arrMove = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  }
  
  onColumnDragged = (sourceIndex,destinationIndex) => {
 
    const originalColumn = this.getColumns(false).filter((obj) => obj.hidden == false);
    const jobColumnOrder = JSON.parse(localStorage.getItem('job_column_order'));
    const columnOrder = this.state.sortColumn.length > 0 ? this.state.sortColumn : []
    let sortArray = columnOrder.length > 0 ? columnOrder : Array.from(Array(originalColumn.length).keys())
    const sortedArray = this.arrMove(sortArray,sourceIndex,destinationIndex);
    this.setState({
      sortColumn: sortedArray 
    });
    this.callCreateColumnOrder(sortedArray);
    
  }

  /* TABLE */
  customTable = () => {
    const { isCustomerRequired } = this.context;
    const data = this.state.data.slice();
    if(!this.state.isLoading) {
      return <Table 
      ref={this.refTable}
      className={'job-table-list' }  
      isLoading={this.state.isLoadingStatus ? true : this.state.isLoading ? true : false}
      isDraggable={false}
      grouping={false}
      limit={this.limits}
      page={this.pages}
      total={this.state.total}
      data={this.state.data ? fromJS(this.state.data).toJS() : this.state.data}
      defaultExpanded={true}
      isCollectionFailed={this.isCollectionFailed}
      selection={true}
      handleRowClick={(e, row)=> {
        if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')) {
          this.setState({
            openPreviewDialog: true,
            openPreviewDialogItem: row.id,
          });
        }
      }}
      onColumnDragged={this.onColumnDragged}
      onPageChange={(page,limit) => {
          // this.setState({
          //   limit:limit,
          //   page:page
          // }, () => {
            this.pages  = page;
            this.limits =  limit;
            this.callReadApi();
          // });
      }}
      // onMouseDown={(open, id)=> {
      //   console.log("detail click", id)
      //   if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')) {
      //     this.setState({
      //       openPreviewDialog: open,
      //       openPreviewDialogItem: id,
      //     });
      //   }
      // }}
      onSelectionChange={(rows, column)=>{
        // console.log("columns", column);
        const selectedData = rows.slice();
        this.rowData = selectedData;
      }}
      // Toolbar={this.customToolbar}
      // Row={this.customRow}
      columns={this.getColumns()}
    />

    }else {
      return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
        <CircularProgress size={35} />
      </Box>
    }

    
  }

    /* TABLE */
    previewTable = (data) => {
      const { isCustomerRequired } = this.context;
      if(!this.state.isLoading) {
        let pages = 1;
        let limits = 50;
        return <Table 
        ref={this.refTable}
        className={'job-table-list' }  
        isLoading={this.state.isLoading}
        isDraggable={false}
        grouping={false}
        // limit={limits}
        // page={pages}
        maxBodyHeight={250}
        total={data.length}
        duplicatePreview={true}
        data={data}
        isPreview={true}
        defaultExpanded={true}
        isCollectionFailed={this.isCollectionFailed}
        onPageChange={(page,limit) => {
            // this.setState({
            //   limit:limit,
            //   page:page
            // }, () => {
              pages  = page;
              limits =  limit;
            // });
        }}
        onSelectionChange={(rows, column)=>{
          // console.log("columns", column);
          this.rowData = Object.assign({}, rows);
        }}
        // Toolbar={this.customToolbar}
        // Row={this.customRow}
        columns={[
          { 
            title: "Trip Date",
            field: "drop_off_date",
            cellStyle: {
              // minWidth: 120,
              maxWidth: 120            
            },
            hidden: this.state.hiddenColumns.includes('date'),
            render: (row, type) => {
              if(type === 'row'){
                return (row && row.drop_off_date && row.drop_off_date !== '') ? 
                <span style={{  overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{moment(row.drop_off_date).format(apiUtil.getImportDateFormat()) }</span> : '';
              } else {
                return row;
              }
            }
          },
          ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'DO')) ? [{ 
            title: "D.O Number", 
            field: "do_number",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            hidden: this.state.hiddenColumns.includes('do_number'),
            render: (row, type) => {
              if(type === 'row'){
                return (row && row.do_number && row.do_number !== '') ? <span style={{  overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{row.do_number.replace(/\s/g, '\u00A0')}</span> : '';
              } else {
                return row;
              }
            }
          }] : [],
          { 
            title: "Job Type", 
            field: "job_type",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            hidden: this.state.hiddenColumns.includes('delivery_type'),
            render: (row, type) => {
              if(type === 'row'){
                return (row && row.job_type && row.job_type !== '') ? <span style={{  overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{row.job_type == "Collection" ? 'Pick Up' : 'Delivery'}</span> : '';
              } else {
                return row;
              }
            }
          },
          { 
            title: "Project", 
            field: "is_project",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            hidden: this.state.hiddenColumns.includes('is_project'),
            render: (row, type) => {
              if(type === 'row'){
                return (row && row.is_project !== null) ? `${row.is_project == true ? "yes" : "No"}` : "No";
              } else {
                return row;
              }
            }
          },
          { 
            title: "Category", 
            field: "period_of_delivery",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            hidden: this.state.hiddenColumns.includes('period_of_delivery'),
            render: (row, type) => {
              if(type === 'row'){
                return (row && row.period_of_delivery !== null) ? `${row.period_of_delivery}` : "";
              } else {
                return row;
              }
            }
          },
          { 
            title: "Ordered By", 
            field: "uploader",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            customSort: (a,b) => (a.uploader && a.uploader.first_name? a.uploader.first_name : '').localeCompare(b.uploader &&  b.uploader.first_name? b.uploader.first_name : ''),
            hidden: this.state.hiddenColumns.includes('order_by'),
            render: (row, type) => {
              return this.state.user ? this.state.user.owner_info.first_name : '';
            }
          },
          { 
            title: "Order Status",
            field: "order_status",
            cellStyle: {
              // minWidth: 240,
              maxWidth: 240            
            },
            customSort: (a,b) =>(a.order_status.status == "Not Assigned" ? "Pending" : a.order_status.status == "Assigned Pending" ? "On Hold": a.order_status.status).toString().toLowerCase().trim().localeCompare((b.order_status.status == "Not Assigned" ? "Pending" : b.order_status.status == "Assigned Pending" ? "On Hold": b.order_status.status).toString().toLowerCase().trim()),
            hidden: this.state.hiddenColumns.includes('order_status'),
            render: (row, type) => {
              let item = [];
              if(type === 'row'){
                return <Box>
                      <Box>
                      <DropdownMenu 
                        isShouldEnable={true}
                        className={"status-btn pending"}
                        text={"Pending"}
                        // rightIcon={<ArrowDownIcon />}
                        // items={this.state.tableStatus}
                      />  
                    </Box>
                </Box>;
            } else {
                return row;
              }
            }
          },
          { 
            title: "POD", 
            field: "POD",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            hidden: this.state.hiddenColumns.includes('POD'),
            render: (row, type) => {
              if(type === 'row'){
                return '';
              } else {
                return row;
              }
            }
          },
          { 
            title: "Reason for Failure", 
            field: "reason_for_failure",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            customSort: (a,b) => (a.job_steps.length > 0 && a.job_steps[0].order_attempts.length > 0 ? a.job_steps[0].order_attempts[0].reason: '').toString().toLowerCase().trim().localeCompare((b.job_steps.length > 0 && b.job_steps[0].order_attempts.length > 0 ? b.job_steps[0].order_attempts[0].reason : '').toString().toLowerCase().trim()),
            hidden: this.state.hiddenColumns.includes('reason_for_failure'),
            render: (row, type) => {
              if(type === 'row'){
                return '';
              } else {
                return row;
              }
            }
          },
          ... (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Address')) ?[{ 
            title: "Delivery Address", 
            field: "drop_off_address",
            cellStyle: {
              // minWidth: 190,
              maxWidth: 190
            },
            hidden: this.state.hiddenColumns.includes('delivery_address'),
            render: (row, type) => {
              if(type === 'row'){
                // return (row && row.drop_off_address && row.drop_off_address !== '') ? HtmlParser(row.drop_off_address) : '';
                return (row && row.drop_off_address && row.drop_off_address !== '') ?<span style={{  overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}> {row.drop_off_address }</span>  : '';
              } else {
                return row;
              }
            }
          }] : [],
          { 
            title: "Postal Code", 
            field: "drop_off_postal_code",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            customSort: (a,b) => Number(a.drop_off_postal_code ? a.drop_off_postal_code: '') - Number(b.drop_off_postal_code ? b.drop_off_postal_code: ''),
            hidden: this.state.hiddenColumns.includes('postal_code'),
            render: (row, type) => {
              if(type === 'row'){
                return (row && row.drop_off_postal_code && row.drop_off_postal_code !== '') ? HtmlParser(row.drop_off_postal_code) : '';
              } else {
                return row;
              }
            }
          },
          ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Delivery Time')) ?[{ 
            title: "Between", 
            field: "drop_off_time_planned",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            hidden: this.state.hiddenColumns.includes('time_slot'),
            render: (row, type) => {
              if(type === 'row'){
                let drop_from_time = row && row.drop_off_time_planned && row.drop_off_time_planned !== '' ? row.drop_off_time_planned.split("-") : [];
                return drop_from_time.length > 0 && row.drop_off_time_planned != '9:00 - 18:00' ? drop_from_time[0] : '';
              } else {
                return row;
              }
            }
          }, 
          { 
            title: "And", 
            field: "drop_off_time_planned",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            hidden: this.state.hiddenColumns.includes('time_slot'),
            render: (row, type) => {
              if(type === 'row'){
                let drop_from_time = row && row.drop_off_time_planned && row.drop_off_time_planned !== '' ? row.drop_off_time_planned.split("-") : [];
                return drop_from_time.length > 0 && row.drop_off_time_planned != '9:00 - 18:00' ? drop_from_time[1] : '';
              } else {
                return row;
              }
            }
          }] : [],
          ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver') ||
              this.state.currentRole == 'Ops Admin' ||
              this.state.currentRole == 'Finance Admin' ||
              this.state.currentRole == 'BD Admin') ? [{
            title: "Driver-Partner", 
            field: "driver_partner",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            customSort: (a,b) => (a.drop_off_worker ? a.drop_off_worker.first_name : '').toString().toLowerCase().trim().localeCompare((b.drop_off_worker ? b.drop_off_worker.first_name : '').toString().toLowerCase().trim()),
            hidden: this.state.hiddenColumns.includes('driver_partner'),
            render: (row, type) => {
              if(type === 'row'){
                return '';
              } else {
                return row;
              }
            }
          }] : [],
          ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Man Power') ||
              this.state.currentRole == 'Ops Admin' ||
              this.state.currentRole == 'Finance Admin' ||
              this.state.currentRole == 'BD Admin') ? [{ 
            title: "Driver-Assistant", 
            field: "extra_workers[0].first_name",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            customSort: (a,b) => (a.extra_workers && a.extra_workers.length > 0 && a.extra_workers[0].first_name ? a.extra_workers[0].first_name : '').toString().toLowerCase().trim().localeCompare((b.extra_workers && b.extra_workers.length > 0 && b.extra_workers[0].first_name ? b.extra_workers[0].first_name  : '').toString().toLowerCase().trim()),
            hidden: this.state.hiddenColumns.includes('driver_partner'),
            render: (row, type) => {
              if(type === 'row'){
                return '';
              } else {
                return row;
              }
            }
          }] : [],
          ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Company')) ? [{ 
            title: "Client Company", 
            field: "recipent_company",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            hidden: this.state.hiddenColumns.includes('recipent_company'),
            customSort: (a,b) => (a.customer ? a.customer.company_name : '').toString().toLowerCase().trim().localeCompare((b.customer ? b.customer.company_name : '').toString().toLowerCase().trim()),
            render: (row, type) => {
              if(type === 'row'){
                return <span style={{ overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row && row.customer && row.customer.company_name !== '') ? HtmlParser(row.customer.company_name) : ''} </span>;
                // return (row && row.drop_off_description && row.drop_off_description !== '') ?<span style={{ width:350, overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}> {row.drop_off_description }</span>  : '';
              } else {
                return row;
              }
            }
          }] : [],
          { 
            title: "Department", 
            field: "department",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            hidden: this.state.hiddenColumns.includes('department'),
            render: (row, type) => {
              if(type === 'row'){
                return <span style={{ overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row && row.department && row.department.name ) ? HtmlParser(row.department.name) : ''}</span>;
              } else {
                return row;
              }
            }
          },
          { 
            title: "Recipient's Name", 
            field: "drop_off_contact_name",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            hidden: this.state.hiddenColumns.includes('recipient_name'),
            render: (row, type) => {
              if(type === 'row'){
                return <span style={{ overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row && row.drop_off_contact_name ) ? HtmlParser(row.drop_off_contact_name) : ''}</span>;
              } else {
                return row;
              }
            }
          },
          { 
            title: "Company", 
            field: "drop_off_name",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            hidden: this.state.hiddenColumns.includes('company'),
            render: (row, type) => {
              if(type === 'row'){
                return <span style={{ overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row && row.drop_off_name ) ? HtmlParser(row.drop_off_name) : ''}</span>;
              } else {
                return row;
              }
            }
          },
          { 
            title: "Contact No.", 
            field: "drop_off_contact_no",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            hidden: this.state.hiddenColumns.includes('recipient_contact'),
            render: (row, type) => {
              if(type === 'row'){
                return <span style={{  overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row && row.drop_off_contact_no) ? HtmlParser(row.drop_off_contact_no) : ''} </span>;
              } else {
                return row;
              }
            }
          },
          { 
            title: "Alternative Contact", 
            field: "drop_off_alternate_contact",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            hidden: this.state.hiddenColumns.includes('alternate_contact'),
            render: (row, type) => {
              if(type === 'row'){
                return <span style={{  overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row && row.drop_off_alternate_contact) ? HtmlParser(row.drop_off_alternate_contact) : ''} </span>;
              } else {
                return row;
              }
            }
          },
          { 
            title: "Recipient Email", 
            field: "recipient_email",
            cellStyle: {
              // minWidth: 170,
              maxWidth: 170
            },
            hidden: this.state.hiddenColumns.includes('recipient_email'),
            render: (row, type) => {
              if(type === 'row'){
                return <span style={{overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row && row.notification_email ) ? HtmlParser(row.notification_email) : ''}</span>;
              } else {
                return row;
              }
            }
          },
          ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Description')) ?[{ 
            title: "Item Description", 
            field: "drop_off_description",
            cellStyle: {
              // minWidth: 190,
              maxWidth: 190
            },
            hidden: this.state.hiddenColumns.includes('item_description'),
            render: (row, type) => {
              if(type === 'row'){
                return <span className='none-backgroud-color' style={{  overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row && row.drop_off_description && row.drop_off_description !== '') ? HtmlParser(row.drop_off_description) : ''}</span>;
                // return (row && row.drop_off_description && row.drop_off_description !== '') ?<span style={{ width:350, overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}> {row.drop_off_description }</span>  : '';
              } else {
                return row;
              }
            }
          }] : [],
          { 
            title: "XS", 
            field: "xs_packages",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 1).length > 0 ? a.order_packages.filter(dd => dd.package_id == 1)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 1).length > 0 ? b.order_packages.filter(dd => dd.package_id == 1)[0].package_quantity: ''),
            hidden: this.state.hiddenColumns.includes('xs_packages'),
            render: (row, type) => {
              if(type === 'row'){
                let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 1) : []
                return (pack.length > 0) ? pack[0].package_quantity: '';
              } else {
                return row;
              }
            }
          },
          { 
            title: "S", 
            field: "s_packages",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 2).length > 0 ? a.order_packages.filter(dd => dd.package_id == 2)[0].package_quantity: '') - (b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 2).length > 0 ? b.order_packages.filter(dd => dd.package_id == 2)[0].package_quantity: ''),
            hidden: this.state.hiddenColumns.includes('s_packages'),
            render: (row, type) => {
              if(type === 'row'){
                let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 2) : []
                return (pack.length > 0) ? pack[0].package_quantity: '';
              } else {
                return row;
              }
            }
          },
          { 
            title: "M", 
            field: "m_packages",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 3).length > 0 ? a.order_packages.filter(dd => dd.package_id == 3)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 3).length > 0 ? b.order_packages.filter(dd => dd.package_id == 3)[0].package_quantity: ''),
            hidden: this.state.hiddenColumns.includes('m_packages'),
            render: (row, type) => {
              if(type === 'row'){
                let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 3) : []
                return (pack.length > 0) ? pack[0].package_quantity: '';
              } else {
                return row;
              }
            }
          },
          { 
            title: "L", 
            field: "l_packages",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 4).length > 0 ? a.order_packages.filter(dd => dd.package_id == 4)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 4).length > 0 ? b.order_packages.filter(dd => dd.package_id == 4)[0].package_quantity: ''),
            hidden: this.state.hiddenColumns.includes('l_packages'),
            render: (row, type) => {
              if(type === 'row'){
                let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 4) : []
                return (pack.length > 0) ? pack[0].package_quantity: '';
              } else {
                return row;
              }
            }
          },
          { 
            title: "Oversized", 
            field: "oversized_packages",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 6).length > 0 ? a.order_packages.filter(dd => dd.package_id == 6)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 6).length > 0 ? b.order_packages.filter(dd => dd.package_id == 6)[0].package_quantity: ''),
            hidden: this.state.hiddenColumns.includes('oversized_packages'),
            render: (row, type) => {
              if(type === 'row'){
                let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 6) : []
                return (pack.length > 0) ? pack[0].package_quantity: '';
              } else {
                return row;
              }
            }
          },
          // { 
          //   title: "Document", 
          //   field: "document",
          //   cellStyle: {
          //     // minWidth: 150,
          //     maxWidth: 150
          //   },
          //   customSort: (a,b) => Number(a.extra_data && JSON.parse(a.extra_data).document && !isNaN(JSON.parse(a.extra_data).document) ? JSON.parse(a.extra_data).document : '') - Number(b.extra_data && JSON.parse(b.extra_data).document && !isNaN(JSON.parse(b.extra_data).document) ? JSON.parse(b.extra_data).document : ''),
          //   hidden: this.state.hiddenColumns.includes('document'),
          //   render: (row, type) => {
          //     if(type === 'row'){
          //       let extra_data = JSON.parse(row.extra_data)
          //       return (extra_data && extra_data.document) ? extra_data.document : '';
          //     } else {
          //       return row;
          //     }
          //   }
          // },
          // { 
          //   title: "Boxes", 
          //   field: "boxes",
          //   cellStyle: {
          //     // minWidth: 150,
          //     maxWidth: 150
          //   },
          //   customSort: (a,b) => Number(a.extra_data && JSON.parse(a.extra_data).boxes && !isNaN(JSON.parse(a.extra_data).boxes) ? JSON.parse(a.extra_data).boxes : '') - Number(b.extra_data && JSON.parse(b.extra_data).boxes && !isNaN(JSON.parse(b.extra_data).boxes) ? JSON.parse(b.extra_data).boxes : ''),
          //   hidden: this.state.hiddenColumns.includes('boxes'),
          //   render: (row, type) => {
          //     if(type === 'row'){
          //       let extra_data = JSON.parse(row.extra_data)
          //       return (extra_data && extra_data.boxes) ? extra_data.boxes : '';
          //     } else {
          //       return row;
          //     }
          //   }
          // },
          // { 
          //   title: "Cartons", 
          //   field: "cartons",
          //   cellStyle: {
          //     // minWidth: 150,
          //     maxWidth: 150
          //   },
          //   customSort: (a,b) => Number(a.extra_data && JSON.parse(a.extra_data).cartons && !isNaN(JSON.parse(a.extra_data).cartons) ? JSON.parse(a.extra_data).cartons : '') - Number(b.extra_data && JSON.parse(b.extra_data).cartons && !isNaN(JSON.parse(b.extra_data).cartons) ? JSON.parse(b.extra_data).cartons : ''),
          //   hidden: this.state.hiddenColumns.includes('cartons'),
          //   render: (row, type) => {
          //     if(type === 'row'){
          //       let extra_data = JSON.parse(row.extra_data)
          //       return (extra_data && extra_data.cartons) ? extra_data.cartons : '';
          //     } else {
          //       return row;
          //     }
          //   }
          // },
          { 
            title: "Pallets", 
            field: "pallets",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 5).length > 0 ? a.order_packages.filter(dd => dd.package_id == 5)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 5).length > 0 ? b.order_packages.filter(dd => dd.package_id == 5)[0].package_quantity: ''),
            hidden: this.state.hiddenColumns.includes('pallets'),
            render: (row, type) => {
              if(type === 'row'){
                let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 5) : []
                return (pack.length > 0) ? pack[0].package_quantity: '';
              } else {
                return row;
              }
            }
          },
          { 
            title: "Depalletization Request", 
            field: "depalletization_request",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            customSort: (a,b) => (a.package_info && JSON.parse(a.package_info).depalletization_request ? JSON.parse(a.package_info).depalletization_request : '').toString().toLowerCase().trim().localeCompare((b.package_info && JSON.parse(b.package_info).depalletization_request ? JSON.parse(b.package_info).depalletization_request : '').toString().toLowerCase().trim()),
            hidden: this.state.hiddenColumns.includes('depalletization_request'),
            render: (row, type) => {
              if(type === 'row'){
                let package_info = row && row.package_info && JSON.parse(row.package_info)
                return (package_info && package_info.depalletization_request) ? package_info.depalletization_request  : '';
               } else {
                return row;
              }
            }
          },
          { 
            title: "Size in cm(L + W + H)", 
            field: "size_lwh",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            customSort: (a,b) => Number(a.package_info &&  JSON.parse(a.package_info).size_l_w_h  && !isNaN(JSON.parse(a.package_info).size_l_w_h) ? JSON.parse(a.package_info).size_l_w_h : '') - Number(b.package_info && JSON.parse(b.package_info).size_l_w_h && !isNaN(JSON.parse(b.package_info).size_l_w_h)? JSON.parse(b.package_info).size_l_w_h : ''),
            hidden: this.state.hiddenColumns.includes('size_lwh'),
            render: (row, type) => {
              if(type === 'row'){
                let package_info = row && row.package_info && JSON.parse(row.package_info)
                return (package_info && package_info.size_l_w_h) ? package_info.size_l_w_h : '';
              } else {
                return row;
              }
            }
          },
          { 
            title: "Weight (KG)", 
            field: "weight",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            customSort: (a,b) => Number(a.package_info &&  JSON.parse(a.package_info).weight  && !isNaN(JSON.parse(a.package_info).weight) ? JSON.parse(a.package_info).weight : '') - Number(b.package_info && JSON.parse(b.package_info).weight && !isNaN(JSON.parse(b.package_info).weight)? JSON.parse(b.package_info).weight : ''),
            hidden: this.state.hiddenColumns.includes('weight'),
            render: (row, type) => {
              if(type === 'row'){
                let package_info = row && row.package_info &&  JSON.parse(row.package_info)
                return (package_info && package_info.weight) ? package_info.weight : '';
              } else {
                return row;
              }
            }
          },
          { 
            title: "Waiting Time", 
            field: "waiting_time",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            customSort: (a,b) => Number(a.package_info &&  JSON.parse(a.package_info).waiting_time  && !isNaN(JSON.parse(a.package_info).waiting_time) ? JSON.parse(a.package_info).waiting_time : '') - Number(b.package_info && JSON.parse(b.package_info).waiting_time && !isNaN(JSON.parse(b.package_info).waiting_time)? JSON.parse(b.package_info).waiting_time : ''),
            hidden: this.state.hiddenColumns.includes('waiting_time'),
            render: (row, type) => {
              if(type === 'row'){
                let package_info = row && row.package_info && JSON.parse(row.package_info)
                return (package_info && package_info.waiting_time) ? package_info.waiting_time : '';
               } else {
                return row;
              }
            }
          },
          { 
            title: "Additional Manpower", 
            field: "no_of_movers",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            customSort: (a,b) => Number(a.package_info &&  JSON.parse(a.package_info).no_of_movers  && !isNaN(JSON.parse(a.package_info).no_of_movers) ? JSON.parse(a.package_info).no_of_movers : '') - Number(b.package_info && JSON.parse(b.package_info).no_of_movers && !isNaN(JSON.parse(b.package_info).no_of_movers)? JSON.parse(b.package_info).no_of_movers : ''),
            hidden: this.state.hiddenColumns.includes('no_of_movers'),
            render: (row, type) => {
              if(type === 'row'){
                let package_info = row && row.package_info && JSON.parse(row.package_info)
                return (package_info && package_info.no_of_movers) ? package_info.no_of_movers : '';
              } else {
                return row;
              }
            }
          },
          { 
            title: "Price", 
            field: "amount",
            cellStyle: {
              // minWidth: 100,
              maxWidth: 100
            },
            customSort: (a,b) => Number(a.amount ? a.amount : '') - Number(b.amount ? b.amount : ''),
            hidden: this.state.hiddenColumns.includes('price'),
            render: (row, type) => {
              if(type === 'row'){
                if ( row.vendor_id == this.state.user.owner_info.application_company_id) {
                  return (row && row.vendor_payout && row.vendor_payout !== '') ? parseFloat(row.vendor_payout).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
                } 
                return (row && row.amount && row.amount !== '') ? parseFloat(row.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
                
              } else {
                return row;
              }
            }
          },
          { 
            title: "Vendor Payout", 
            field: "vendor_payout",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            customSort: (a,b) => Number(a.vendor_payout ? a.vendor_payout : '') - Number(b.vendor_payout ? b.vendor_payout : ''),
            hidden: this.state.hiddenColumns.includes('vendor_payout'),
            render: (row, type) => {
              return '-';
            }
          },
          ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver Payout')) || (this.state.currentRole == "BD Admin") || (this.state.currentRole == "Finance Admin")  ?[{ 
            title: "Driver Payout", 
            field: "driver_payout",
            cellStyle: {
              // minWidth: 150,
              maxWidth: 150
            },
            hidden: this.state.hiddenColumns.includes('driver_payout'),
            customSort: (a,b) => Number(a.driver_payout ? a.driver_payout : '') - Number(b.driver_payout ? b.driver_payout : ''),
            render: (row, type) => {
              return '-'
            }
          }] : [],
          { 
            title: "Notes to Driver-Partner", 
            field: "driver_notes",
            cellStyle: {
              // minWidth: 180,
              maxWidth: 180
            },
            hidden: this.state.hiddenColumns.includes('notes_to_driver'),
            render: (row, type) => {
              if(type === 'row'){
                return (row && row.driver_notes && row.driver_notes !== '') ? HtmlParser(row.driver_notes) : '';
               } else {
                return row;
              }
            }
          }
        ]}
      />
  
      }else {
        return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'30vh'}>
          <CircularProgress size={35} />
        </Box>
      }
  
      
    }

  handleTabChange = (event, newValue) => {
    this.setState({
        tabValue: newValue
    })
  }
  customToolbar = (e) => {
    let user = JSON.parse(window.localStorage.getItem("user"));
    let currentRole = window.localStorage.getItem("current_role")

    let isOwner = false;
    if(user != null){
       isOwner = user.owner_info.is_customer_admin !== undefined ? user.owner_info.is_customer_admin : false;
    }

    return <Box>
      <Grid container>
        
          
        {/* <Box clone order={{ xs: 3, md: 1}}>
          <Grid item xs={12} md={3}>
            <CustomInput
              ref={this.refTableSearch}
              placeholder={'Search job'}
              endAdornment={
                <IconButton
                  onClick={(e) => {
                    this.callReadApi(this.refTableSearch.current.state.value);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              }
              onPressEnter={(e) => {
                this.callReadApi(e.target.value);
              }}
            />
          </Grid>

        </Box>*/}
        <Grid container item  xs={12} md={12} justify='flex-end'>

          {(currentRole == "Super Admin Role" || currentRole == "Admin Template Roles" || currentRole == "LDS Template Role") ? (
          <Box clone pb={{ xs: 2, md: 0}} order={{ xs: 2, md: 2}} pr={{ xs: 0, md: 2}} textAlign={'right'}>
            <Grid item xs={12} md={true}>
              <GroupButton
                color={'secondary'}
                selected={this.state.jobTabTable}
                buttons={[ "Own Jobs", "Vendor Jobs", "Table Column" ]}
                onClick={(selected, buttons) => {
                  if (selected === 2) {
                    this.setState({
                      openSettingsDialog: true
                    });
                  } else if (selected === 1){
                    this.setState({
                      jobTabTable: 1,
                    }, () => {
                      this.props.history.push('/job-vendor-list');
                    });
                  }
                }}
              />
            </Grid>
          </Box>
          )
          :
          (
          <Box clone pb={{ xs: 2, md: 0}} order={{ xs: 2, md: 2}} pr={{ xs: 0, md: 2}} textAlign={'right'}>
          <Grid item xs={12} md={true}>
            <GroupButton
              color={'secondary'}
              selected={this.state.jobTabTable}
              buttons={[ "Table Column" ]}
              onClick={(selected, buttons) => {
                if (selected == 0) {
                  this.setState({
                    openSettingsDialog: true
                  });
                } 
              }}
            />
          </Grid>
        </Box>
          )}
           <Box clone textAlign={'right'} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}} style={{paddingLeft:'5px', paddingRight:'5px'}}>
              <Grid item xs={12} md={'auto'}>
                  {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Order Auto Assign')) && 
                  <CustomButton 
                    color={'primary'}
                    onClick={() => {
                      
                      let selectedRow = this.rowData;
                      let ids = '';
                      if(selectedRow != null && selectedRow != '') {
                        selectedRow.map((item, i) => { ids += item.id +','; });
                      } else {
                        apiUtil.toast('You must select order!', 'warning', 'error');
                        return
                      }
                      let params = {
                        order_ids: ids
                      }
                      this.callAutoAssignApi(params,(data)=>{
                        
                        this.props.history.push({
                          pathname: "/auto-assign-preview",
                          orders_data: data
                        });
                      })
                    }}>
                    <ExportIcon /> &nbsp;&nbsp;
                    <Box display={'inline-block'}>Auto Assign</Box>
                  </CustomButton>
                }
              </Grid>
            </Box>
          { 
            isOwner ? "" :
            
            <Box clone textAlign={'right'} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}} style={{paddingLeft:'5px', paddingRight:'5px'}}>
              <Grid item xs={12} md={'auto'}>
                  {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Batch Upate')) && <CustomButton 
                    color={'secondary'}
                    onClick={() => {
                        this.props.history.push('/jobs/bulk-update');
                    }}>
                    <EditIcon />
                    <Box display={'inline-block'}>Batch Update</Box>
                  </CustomButton> 
                }
              </Grid>
            </Box>
          }          
          <Box clone textAlign={'right'} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}} style={{paddingLeft:'5px', paddingRight:'5px'}}>
              <Grid item xs={12} md={'auto'}>
                  <DropdownMenu
                  text={'Actions'}
                  color={'primary'}
                  className={'job-list-action'}
                  leftIcon={<img src={SettingWhite} style={{width:20,marginTop:"3px"}}/>}
                  rightIcon={<ArrowDownIcon />}
                  items={this.getItemforAction()}
                  onClick={(item) => {
                    
                    switch(item.id) {
                      
                      case 1:
                        this.setState({
                          openDialogJobAssign: true
                        });
                        break;
                      case 2:
                        this.setState({
                          openDialogChangePayout: true
                        })
                        break;
                      case 3:
                        this.setState({
                          drop_off_change_date: null,
                          openDialogDateChange: true
                        });
                        
                        break;
                      case 4:
                        this.setState({
                          openDialogChangeStatus: true
                        });
                        break;
                      case 5:
                        this.setState({
                          openDialogUnassign: true
                        })
                        break;
                      case 6:
                        this.setState({
                          openDialogMassDelete: true
                        });
                        break;
                      case 7:
                        this.callExportXLSApi();
                        break;
                      case 8:
                        this.generatePDF();
                        break;
                      case 9:
                        this.generatePOD();
                        break;
                      default:
                        break;
    
                  }
                  }}
                />
                
              </Grid>
          </Box> 
        </Grid>
          {/* <Box clone textAlign={'right'} pl={1} pr={1} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}}>
            <Grid item xs={12} md={'auto'} > 
                {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Delete')) &&           
                <CustomButton 
                  deleteClass = "deleteBtn"
                  // disabled = { true }
                  color={'secondary'}
                  onClick={() => {
                    this.setState({
                      openDialogMassDelete: true
                    });
                  }}
                >
                  <DeleteIcon />
                  <Box display={'inline-block'} pl={1}>Bulk Delete</Box>
                </CustomButton> 
              }
            </Grid>
          </Box> */}
          {/* <Box clone textAlign={'right'} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}}>
            <Grid item xs={12} md={'auto'}> 
            {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Export Jobs')) && <CustomButton 
                  color={'primary'}
                  onClick={() => {
                    this.callExportXLSApi();
                  }}
                >
                  <ExportIcon />
                  <Box display={'inline-block'} pl={1}>Export To Excel</Box>
                </CustomButton>
            }
            </Grid>
          </Box> */}
          <Box style={{marginTop:"10px"}}>
          <Tabs value={this.state.tabValue} onChange={this.handleTabChange} aria-label="basic tabs example">
            <Tab label={<span><SearchIcon />Search</span>} style={{border:"1px solid #00000017", marginRight:"2px", textTransform:'none'}}  {...a11yProps(0)} />
            <Tab label="Advanced Search" style={{border:"1px solid #00000017", textTransform:'none'}} {...a11yProps(1)} />
          </Tabs>
        </Box>
        {(this.search !== '' || this.do_number !== '' || this.uploder_id !== '') && <Box mb={1}>
          <IconButton
          onClick={e => {
            this.search = '';
            this.do_number = '';
            this.uploder_id = '';
            this.setState({
              selected_uploader:''
            }, () =>{
              this.callReadApi()
            })
            
          }}
          ><span  data-tooltip="clear search" data-tooltip-conf="danger"><img src={Filterimg} width="25px" /></span></IconButton>

          </Box>
        }
        
      </Grid>
      <Box mb={2} mt={2}> 
        <TabPanel value={this.state.tabValue} index={0}><Grid container className="box-shadow-search">
          <Box clone>
            <Grid item xs={8} md={3}>
              <CustomInput
                ref={this.refTableSearch}
                placeholder={'Job Number, Company Name, Address...'}
                value={this.search}
                className='margin_right_10px'
              />
            </Grid>  
          </Box>
          <Grid Grid item xs={4} md={3}>
            <CustomButton
                color={"secondary"}
                onClick={() => {
                  let search = this.refTableSearch.current.state.value
                  this.search = search
                  this.callReadApi(search);
                }}
              >
                <SearchIcon /><Box >Search</Box>
              </CustomButton>
            </Grid>
            
          </Grid>
        </TabPanel>
        <TabPanel value={this.state.tabValue} index={1}><Grid container >

            <Box clone  >
              <Grid item xs={6} md={3}>
                <CustomInput 
                ref={this.refTableAdvSearch}
                value={this.search}
                label={'Keywords'}
                placeholder={'Job Number, Company Name, Address...'}
                className='margin_right_10px'
                />
              </Grid>
            </Box>
            <Box clone >
              <Grid item xs={6} md={3}>
                <CustomInput 
                ref={this.refTableDoSearch}
                value={this.do_number}
                label={'Do Number'}
                placeholder={'Enter Do Number here...'}
                className='margin_right_10px'
                />
              </Grid>
            </Box>
            <Box clone >
              <Grid item xs={6} md={3}>
                <Box className="custom-label-component">
                  <InputLabel >Uploader</InputLabel>
                </Box>
                <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderBottom:"0px solid white", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
                  placeholder={'Enter Uploader here...'}
                  // ref={this.refTableUploaderSearch}
                  value={this.state.search_uploader}
                  native
                  onChange={(e) => {
                    this.setState({
                      search_uploader: e.target.value
                    })
                  }}
                >
                  <option value=""> Select Uploader </option>
                  {this.state.uploaders && this.state.uploaders.length > 0 && this.state.uploaders.map(uploader => {
                  return( <option value={uploader.id}>{uploader.first_name ? uploader.first_name : ""} {uploader.last_name? uploader.last_name : ""}</option>)
                  })
                  }
                </Select>
              </Grid>
            </Box>
            <Box clone >
              <Grid item xs={6} md={3}>
              <CustomButton
              color={"secondary"}
              className={"margin_left_10px margin_top_30px"}
              onClick={() => {
                this.search = this.refTableAdvSearch.current.state.value
                this.do_number = this.refTableDoSearch.current.state.value
                this.uploder_id = this.state.selected_uploader
                this.callReadApi()
              }}
              >
                <SearchIcon /><Box ml={1}>Search</Box>
              </CustomButton>
              </Grid>
          </Box>
            
          </Grid>
          
        </TabPanel>

      </Box>
    </Box>
  }

  getItemforAction = () => {
  
    let item = [];

    if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Delete')){
      item.push({          
          id: 6,
          text: <Box display={'inline-block'} >Delete</Box>,          
      })
    }

    if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver Payout') && this.state.currentRole !== "Customer Template Role") {
      item.push({          
      id: 2,
      text: <Box display={'inline-block'} >Change Driver Payout</Box>,          
      })
    }

    if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Batch Upate') || this.state.currentRole !== "Customer Template Role"){
      item.push({          
        id: 3,
        text: <Box display={'inline-block'} >Change Date</Box>,          
      })
    }

    if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Batch Upate') && this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver & Manpower Add') && this.state.currentRole !== "Customer Template Role"){
      item.push({          
        id: 4,
        text: <Box display={'inline-block'} >Change Job Status</Box>,          
      })
    }
    if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Batch Upate') && this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver & Manpower Add') && this.state.currentRole !== "Customer Template Role") {
      item.push({          
          id: 1,
          text: <Box display={'inline-block'} >Assign Driver</Box>,          
      })
      
      item.push({          
        id: 5,
        text: <Box display={'inline-block'} >Unassign Driver</Box>,          
      })
      
    }
    item.push({          
      id: 11,
      text: <Box display={'inline-block'} >Duplicate Orders</Box>,          
    })
    
    if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Export Jobs')) {
      item.push({
        id: 7,
        text: <Box display={'inline-block'}>Export CSV</Box>,
      }) 
    }
    if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Add')){
      item.push({          
          id: 8,
          text: <Box display={'inline-block'} >Generate Label</Box>,          
      })
    }
    if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Add')){
      item.push({          
          id: 9,
          text: <Box display={'inline-block'} >Generate POD</Box>,          
      })
    }
    item.push({          
      id:10,
      text: <Box display={'inline-block'} >Table Column</Box>,          
  })

    return item;

  }
  customRow = (e, row) => {
    // let desc = (row && row.drop_off_description && row.drop_off_description !== '') ? row.drop_off_description : '';

    let date = (row && row.drop_off_date && row.order_status) ? moment(row.drop_off_date).format(apiUtil.getDefaultDateFormat()) : '';
    let time = (date && date !== '') ? (row && row.drop_off_time_planned && row.drop_off_time_planned) ? moment(row.drop_off_time_planned).format(apiUtil.getDefaultTimeFormat()) : '' : '';
    
    let dateTime = '';
    if(date && time){
      dateTime = date + ' - ' + time;
    } else if (date){
      dateTime = date;
    } else if (time){
      dateTime = time;
    }

    return <tr>
      <td 
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >
        <Box pb={1}>
          <DropdownMenu
            className={'status-btn ' + apiUtil.statusToClassName((row.order_status && row.order_status.status && row.order_status.status !== '') ? row.order_status.status : '')}
            text={(row && row.order_status && row.order_status.status) ? row.order_status.status : ''}
            rightIcon={<ArrowDownIcon />}
            items={this.state.tableStatus}
            onClick={(item) => {
              /*if(row.drop_off_worker_id && row.drop_off_worker_id > 0){*/
                let data = {
                  data: [
                    {
                      id: [ row.id ],
                      job_steps: (row.job_steps && row.job_steps.length > 0) ? row.job_steps.map(step => {
                        step.job_step_id = step.id;
                        if (item.text.toString().toLowerCase().trim() === "completed") {
                          step.job_step_status_id = 3;
                        } else {
                          step.job_step_status_id = 1;
                        }
                        return step
                      }): row.job_steps,
                      order_status_id: item.id,
                      send_notification_to_customer: true,
                    }
                  ]
                };

                if(item.text.toString().toLowerCase().trim() === 'pending'){
                  data.data[0]['drop_off_worker_id'] = null;
                  data.data[0]['extra_worker_ids'] = null;
                }

                this.callUpdateStatusApi(data, (newRow) => {
                  if(newRow){
                    let newData = apiUtil.updateRow(fromJS(this.state.data).toJS(), newRow, 'id');
                    this.setState({
                      data: newData,
                    });
                  }
                });
              /*} else {
                apiUtil.toast('You must first assign a driver!', 'warning', 'error');
              }*/
            }}
          />
        </Box>
      </td>
      <td 
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >
        <Box pl={2}>
          <Box>
            {dateTime}
          </Box>
          <Box>{row.job_steps && row.job_steps.length > 0 ? row.job_steps[0].location : ''}</Box>
          {row.amount !== null && <Box>Price: ${Number.parseFloat(row.amount).toLocaleString()}</Box>}
        </Box>
      </td>
      <td 
        colSpan={6}
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >&nbsp;</td>
      {/* <td 
        colSpan={5}
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >
        {(desc !== '') && <Box>
          <Box pl={2} pb={2}>
            <CustomReadMore className={'lightGray'} maxLine={2} text={(row && row.drop_off_description && row.drop_off_description !== '') ? row.drop_off_description : ''} />
          </Box>  
        </Box>}
      </td> */}
      {/* <td 
        colSpan={4}
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >
        {(desc !== '') && <Box>
          <Box pl={2} pb={2}>
            <CustomReadMore className={'lightGray'} maxLine={2} text={(row && row.drop_off_description && row.drop_off_description !== '') ? row.drop_off_description : ''} />
          </Box>  
        </Box>}
      </td> */}
      <td 
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >&nbsp;</td>
    </tr>
  }
  /* END TABLE */
  

  /* DIALOG */
  customDialog = () => {
    return <CustomDialog 
      open={this.state.openDialog}
      title={'Delete'}
      onClose={() => {
        this.setState({
          openDialog: false,
          openDialogItem: null,
        });
      }}
      onOk={() => {
        let row = this.state.openDialogItem;
        this.callDeleteApi(row, () => {
          this.setState({
            openDialog: false,
            openDialogItem: null,
          }, () => {
            this.loadStatusApi(()=> {
              this.callReadApi();
            });
            apiUtil.toast('Successfully Deleted', 'check_circle');
          });
        });
      }}
    >
      <Box>Are you sure you want to delete?</Box>
    </CustomDialog>
  }

  massDeleteDialog = () => {
    
    return <CustomDialog 
      open={this.state.openDialogMassDelete}
      title={'Delete'}
      onClose={() => {
        this.rowData = null;
        this.setState({
          openDialogMassDelete: false,
        });
      }}
      onOk={() => {
        this.callMassDeleteApi(() => {
          this.setState({
            openDialogMassDelete: false,
          }, () => {
            // this.loadStatusApi();
            // this.callReadApi(this.refTableSearch.current.state.value);            
            // apiUtil.toast('Successfully Deleted', 'check_circle');
            this.rowData = null;
          });
        });
      }}
    >
      <Box>Are you sure you want to delete selected items?</Box>
    </CustomDialog>
  }
  
  emptyRecycleDialog = () => {
    
    return <CustomDialog 
      open={this.state.openDialogEmptyRecycle}
      title={'Empty Recycle Bin'}
      onClose={() => {
        this.rowData = null;
        this.setState({
          openDialogEmptyRecycle: false,
        });
      }}
      onOk={() => {
        this.setState({
          empty_recycle: true   
        },() => {
          this.callMassDeleteApi(() => {
            this.setState({
              openDialogEmptyRecycle: false,
            }, () => {
              this.rowData = null;
            });
          });
        });
      }}
    >
      <Box>Are you sure you want to empty items within date range?</Box>
    </CustomDialog>
  }

  massRestoreDialog = () => {
    
    return <CustomDialog 
      open={this.state.openDialogMassRestore}
      title={'Restore'}
      onClose={() => {
        this.rowData = null;
        this.setState({
          openDialogMassRestore: false,
        });
      }}
      onOk={() => {
        this.callMassRestoreApi(() => {
          this.setState({
            openDialogMassRestore: false,
          }, () => {
            this.rowData = null;
          });
        });
      }}
    >
      <Box>Are you sure you want to restore selected items?</Box>
    </CustomDialog>
  }
 
  Updatedtoast = () => {
    apiUtil.toast('Successfully Updated', 'check_circle');
  }

  unAssignDriverDialog = () => {
    return <CustomDialog 
      open={this.state.openDialogUnassign}
      title={'Unassign Driver'}
      onClose={() => {
        this.rowData = null;
        this.setState({
          openDialogUnassign: false,
        });
      }}
      onOk={() => {
        let orderStatusItem = apiUtil.customFilter(this.state.status, 'status', "pending");
        // console.log("orderStatusItem", orderStatusItem, this.state.status)
        let selectedRow = this.rowData;
        let ids = [];
        if(selectedRow != null && selectedRow != '') selectedRow.map((item, i) => { ids.push(item.id); });
        

        let rowdata = selectedRow ? selectedRow.filter(dd => dd.order_status.status == "Completed") : []
        if (rowdata.length > 0 && this.state.currentRole != 'Super Admin Role') {
            apiUtil.toast("Compeleted Order(s) can not unassign driver-partner!", "check_circle", "error");
            this.setState({
              openDialogUnassign: false
            });
            this.rowData = null;
            return;
        }
        let data = {
          data: [
            {
              id: ids,
              order_status_id: (orderStatusItem) ? orderStatusItem.id : '',
              send_notification_to_customer: true,
              drop_off_worker_id: null,
              extra_worker_ids: null,
              batch_update_assign: true,
              unassign_driver: true,
            }
          ]
        };
        
        this.callMassUpdateStatusApi(data, (newRows) => {
          if(newRows){
            let data = fromJS(this.state.data).toJS()
            newRows.map(row => {
              data = apiUtil.updateRow(data, row, 'id');
            })
            this.setState({
              data: data,
              openDialogUnassign: false
            });
            this.rowData = null;
            this.Updatedtoast()
          }
        });
      }}
    >
      <Box>Are you sure you want to unassign selected items?</Box>
    </CustomDialog>
  }

  /* CUSTOMER API */
  

  customDialogBatchUpdate = () => {
    return <CustomFormDialog
        open={this.state.openDialogBatchUpdate}
        title={'Bulk Update'}
        maxWidth={'lg'}
        btnOkText={'Save Changes'}
        onClose={() => {
          this.setState({
              openDialogBatchUpdate: false,
          });
        }}
    >
      <BulkUpdateForm 
        onClose={()=> {
            this.setState({
              openDialogBatchUpdate: false,
          });
        }}
        onSave={(data)=>{
          if (data) {
            // apiUtil.toast( 'Order(s) Successfully Updated', 'check_circle', 'success');
            this.callReadApi()
            this.loadStatusApi()
            this.setState({
              openDialogBatchUpdate: false,
          });
          }
        }}
      />
    </CustomFormDialog>
  }

  customDialogDateChange = () => {
    return <CustomDialog 
        open={this.state.openDialogDateChange}
        title={'Change Date'}
        onClose={() => {
          this.rowData = null;
          this.setState({
            openDialogDateChange: false,
            drop_off_change_date: null,
          });
        }}
        onOk={() =>{
            
          let data ={}
          data.drop_off_date = this.state.drop_off_change_date
          let orderStatusItem = apiUtil.customFilter(this.state.status, 'status', "completed");
          let orderStatusItemFailed = apiUtil.customFilter(this.state.status, 'status', "failed");
          let check_completed = this.rowData ? this.rowData.filter(dd => dd.order_status_id && dd.order_status_id == orderStatusItem.id) : []
          let check_fail = this.rowData ? this.rowData.filter(dd => dd.order_status_id && dd.order_status_id == orderStatusItemFailed.id) : []
          
          if ((check_completed.length > 0 || check_fail.length > 0) && this.state.currentRole != 'Super Admin Role') {
            apiUtil.toast(`Can't change date to complete or fail order(s)`, 'warning', 'error');
            this.setState({
              drop_off_change_date: null,
              openDialogDateChange: false,
            })
            return
          }
          this.batchUpdateChangeDateApi(data, () => {
            this.setState({
              drop_off_change_date: null,
              openDialogDateChange: false
            })
            this.rowData = null;
            // this.Updatedtoast()
          });
        }}
    >
        
        <CustomInput
          label={'Select Date'}
          placeholder={'DD/MM/YYYY'}
          color={'secondary'}
          type={"date"}
          value={this.state.drop_off_change_date}
          endAdornment={(obj) => {
            return <Box
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <CalendarIcon />
            </Box>
          }}
          onChange={(e) => {
              this.setState({
                drop_off_change_date: e.target.value
              })
          }}
      />


    </CustomDialog>
  }

  customDialogPayoutChange = () => {
    return <CustomDialog 
        open={this.state.openDialogChangePayout}
        title={'Driver Payout'}
        onClose={() => {
          this.rowData = null;
          this.setState({
            openDialogChangePayout: false,
            change_driver_payout: null,
          });
        }}
        onOk={() =>{
            
          let selectedRow = this.rowData;
          let ids = [];

          if(selectedRow != null && selectedRow != '') selectedRow.map((item, i) => { ids.push(item.id); });
          let data = {
            data: [
              {
                id: ids,
                driver_payout: this.state.change_driver_payout,
              }
            ]
          };
          this.callMassUpdateStatusApi(data, (newRows) => {
            this.setState({
              change_driver_payout: null,
              openDialogChangePayout: false
            });
            if(newRows){
              this.Updatedtoast()
              this.rowData = null;
            }
          });
        }}
    >
        
        <CustomInput
          label={'Payout'}
          placeholder={'Payout'}
          color={'secondary'}
          value={this.state.change_driver_payout}
          type={"number"}
          min={0}
          startAdornment={<p>$</p>}
          endAdornment={<span>SGD</span>}
          onKeyDown={e => exceptThisSymbolsPayout.includes(e.key) && e.preventDefault()}
          onChange={(e) => {
              this.setState({
                change_driver_payout:  e.target.value > 0 ? e.target.value : 0
              })
          }}
      />


    </CustomDialog>
  }
  customDialogAssign = () => {
    return <CustomFormDialog 
        open={this.state.openDialogJobAssign}
        title={'Assign Driver'}
        onClose={() => {
          this.rowData = null;
          this.setState({
            openDialogJobAssign: false,
          });
        }}
    >
        
    <BatchUpdateAssignForm
      isLoading={this.state.openBatchUpdateisLoading}
      workerItems={this.state.workerItems}
      manPowerItems={this.state.manPowerItems}
      rowData={this.rowData}
      status={this.state.status}
      onSave={(data) => {
        let check_vendor = this.rowData ? this.rowData.filter(dd => dd.vendor_id && dd.vendor_id == this.state.user.owner_info.application_company_id ) : []
        let vendor_order = ''
        check_vendor.map((item, i) => { vendor_order += item.order_number +','; });
        vendor_order = vendor_order.slice(0, -1);
        let orderStatusItem = apiUtil.customFilter(this.state.status, 'status', "completed");
        let orderStatusItemFail = apiUtil.customFilter(this.state.status, 'status', "Failed");
        let check_completed = this.rowData ? this.rowData.filter(dd => dd.order_status_id && dd.order_status_id == orderStatusItem.id) : []
        let check_fail = this.rowData ? this.rowData.filter(dd => dd.order_status_id && dd.order_status_id == orderStatusItemFail.id) : []
        
        if ((check_completed.length > 0 || check_fail.length >0) && this.state.currentRole != 'Super Admin Role') {
          apiUtil.toast(`Can't assign to complete or fail order(s)`, 'warning', 'error');
          this.setState({
            openDialogJobAssign: false,
            openBatchUpdateisLoading: false,
          })
          return
        }

        if(check_vendor.length > 0) {
          if (data.vendor_id != null) {
            apiUtil.toast(`Can't assign Vendor to Vendor order(s) ${vendor_order}`, 'warning', 'error');
            this.setState({
              openDialogJobAssign: false,
              openBatchUpdateisLoading: false,
            })
            return
          }
        } 
        // else {
          if(data.drop_off_worker_id != null || data.vendor_id != null) {
            this.setState({
              openBatchUpdateisLoading:true
            })
            let orderStatusItem = "";
            if(data.drop_off_worker_id) {
              orderStatusItem = apiUtil.customFilter(this.state.status, 'status', "assigned");
              data.vendor_id = null
            } else if (data.vendor_id) {
              orderStatusItem = apiUtil.customFilter(this.state.status, 'status', "assigned to vendor");

            } else {
              orderStatusItem = apiUtil.customFilter(this.state.status, 'status', "pending");
            }
            
            data.order_status_id = (orderStatusItem) ? orderStatusItem.id : '';
            this.batchUpdateApi(data, () => {
              this.setState({
                openDialogJobAssign: false,
              })
              this.rowData = null;
              // this.Updatedtoast()
            });
          } else {
            this.setState({
              openBatchUpdateisLoading:false
            })
          }
        // }
      }}
      onClose={() => {
          this.rowData = null;
          this.setState({
            openDialogJobAssign: false
          });
      }}
    />


    </CustomFormDialog>
  }


  customDialogChangeStatus = () => {
    return <CustomFormDialog 
        open={this.state.openDialogChangeStatus}
        title={'Change Status'}
        onClose={() => {
          this.rowData = null;
          this.setState({
            openDialogChangeStatus: false,
          });
        }}
    >
        
    <BatchUpdateJobStatusForm
      isLoading={this.state.openBatchUpdateisLoading}
      onSave={(data, status) => {
        let check_vendor = this.rowData ? this.rowData.filter(dd => dd.vendor_id && dd.vendor_id != this.state.user.owner_info.application_company_id ) : []
        let check_driver = this.rowData ? this.rowData.filter(dd => dd.drop_off_worker_id) : []
        let vendor_order = ''
        let driver_order = ''
        check_vendor.map((item, i) => { vendor_order += item.order_number +','; });
        check_driver.map((item, i) => { driver_order += item.order_number +','; });
        vendor_order = vendor_order.slice(0, -1);
        driver_order = driver_order.slice(0, -1);
        
        let orderStatusItem = apiUtil.customFilter(this.state.status, 'status', "completed");
        let orderStatusItemFailed = apiUtil.customFilter(this.state.status, 'status', "failed");
        let orderStatusItemAck = apiUtil.customFilter(this.state.status, 'status', "acknowledged");
        let orderStatusItemProgress = apiUtil.customFilter(this.state.status, 'status', "in progress");
        let check_completed = this.rowData ? this.rowData.filter(dd => dd.order_status_id && dd.order_status_id == orderStatusItem.id) : []
        let check_not_fail = this.rowData ? this.rowData.filter(dd => dd.order_status_id && dd.order_status_id != orderStatusItemFailed.id) : []
        let check_not_complete = this.rowData ? this.rowData.filter(dd => dd.order_status_id && dd.order_status_id != orderStatusItem.id) : []
        let check_Ack_Progress = this.rowData ? this.rowData.filter(dd => dd.order_status_id == orderStatusItemAck.id || dd.order_status_id == orderStatusItemProgress.id) : []
        /*if (check_completed.length > 0 && status != "Failed") {
          apiUtil.toast(`Can't change status to complete order(s)`, 'warning', 'error');
          this.setState({
            openDialogChangeStatus: false,
            openBatchUpdateisLoading: false,
          })
          return
        }*/

        /*if (check_Ack_Progress.length > 0 && status == "On Hold") {
          apiUtil.toast(`Can't change on hold status to Acknowledged/In Progress order(s)`, 'warning', 'error');
          this.setState({
            openDialogChangeStatus: false,
            openBatchUpdateisLoading: false,
          })
          return
        }*/
        
        if(driver_order.length > 0 && (status.toString().toLowerCase().trim() === 'on hold' || status.toString().toLowerCase().trim() === 'self collect')) {
          apiUtil.toast("Only Pending order(s) can be edited", "warning","error");
          this.setState({
            openDialogChangeStatus: false,
            openBatchUpdateisLoading: false,
          })
          this.rowData = null;
          return;
        }
        

        if (driver_order.length > 0 && status.toString().toLowerCase().trim() == "assigned to vendor") {
          apiUtil.toast(`Invalid Assign(Assigned To Vendor) to Driver Order(s) ${driver_order}`, 'warning', 'error');
          this.setState({
            openDialogChangeStatus: false,
            openBatchUpdateisLoading: false,
          })
          this.rowData = null;
          return
        }

        /*if (!(check_not_fail.length > 0)) {
          if (driver_order.length > 0 && status.toString().toLowerCase().trim() != "assigned to vendor" && status.toString().toLowerCase().trim() != "not assigned" && status.toString().toLowerCase().trim() != "pending" && status.toString().toLowerCase().trim() != "cancelled" && status.toString().toLowerCase().trim() != "self collect" && status.toString().toLowerCase().trim() != "on hold" && status.toString().toLowerCase().trim() != "completed") {
            apiUtil.toast(`Admin can\'t change ${status} to ${driver_order}`, 'warning', 'error');
            this.setState({
              openDialogChangeStatus: false,
              openBatchUpdateisLoading: false,
            })
            return
          }
        } else if (!(check_not_complete.length > 0)) {
          if (driver_order.length > 0 && status.toString().toLowerCase().trim() != "assigned to vendor" && status.toString().toLowerCase().trim() != "not assigned" && status.toString().toLowerCase().trim() != "pending" && status.toString().toLowerCase().trim() != "cancelled" && status.toString().toLowerCase().trim() != "self collect" && status.toString().toLowerCase().trim() != "on hold" && status.toString().toLowerCase().trim() != "failed") {
            apiUtil.toast(`Admin can\'t change ${status} to ${driver_order}`, 'warning', 'error');
            this.setState({
              openDialogChangeStatus: false,
              openBatchUpdateisLoading: false,
            })
            return
          }
        } else {

          if (driver_order.length > 0 && status.toString().toLowerCase().trim() != "assigned to vendor" && status.toString().toLowerCase().trim() != "not assigned" && status.toString().toLowerCase().trim() != "pending" && status.toString().toLowerCase().trim() != "cancelled" && status.toString().toLowerCase().trim() != "self collect" && status.toString().toLowerCase().trim() != "on hold") {
            apiUtil.toast(`Admin can\'t change ${status} to ${driver_order}`, 'warning', 'error');
            this.setState({
              openDialogChangeStatus: false,
              openBatchUpdateisLoading: false,
            })
            return
          }
        }*/
        
        if(check_vendor.length > 0  && status.toString().toLowerCase().trim() != "assigned to vendor") {
          // if (status != "Pending") {
            apiUtil.toast(`Admin should unassign the vendor order(s) ${vendor_order} first!`, 'warning', 'error');
            this.setState({
              openDialogChangeStatus: false,
              openBatchUpdateisLoading: false,
            })
            this.rowData = null;
            return
          // }
        } else {
          if(status != "Pending" && status != "Cancelled" && status != "On Hold" && status != "Self Collect") {
            // let rowdata = this.rowData ? this.rowData.filter(dd => (dd.drop_off_worker_id == null || dd.drop_off_worker_id == '') && (dd.vendor_id == null || dd.vendor_id == '')) : []
            let rowdata = this.rowData ? this.rowData.filter(dd => (dd.drop_off_worker_id == null || dd.drop_off_worker_id == '')) : []
            if (rowdata.length > 0 ) {
              let tracker_number = ''
              rowdata.map((item, i) => { tracker_number += item.order_number +','; });
              tracker_number = tracker_number.slice(0, -1);
              apiUtil.toast(`You must first assign a driver!`, 'warning', 'error');
              this.setState({
                openDialogChangeStatus: false,
                openBatchUpdateisLoading: false,
              })
              this.rowData = null;
              return
            }
          }
          
          this.setState({
            openBatchUpdateisLoading:true
          })

          this.batchUpdateApi(data, status, () => {
              this.setState({
                openDialogChangeStatus: false,
                openBatchUpdateisLoading: false,
              })
              this.rowData = null;
              // this.Updatedtoast()
          });
        }
      }}
      onClose={() => {
          this.setState({
            openDialogChangeStatus: false
          });
          this.rowData = null;
      }}
    />


    </CustomFormDialog>
  }
  customDialogAutoAssign = () => {
    return <CustomFormDialog 
        maxWidth={'md'}
        open={this.state.openDialogAutoAssign}
        title={'Auto Assign - Invalid Address'}
        onClose={() => {
          this.setState({
              openDialogAutoAssign: false,
          });
        }}
    >

    <AutoAssignForm
      data={this.state.invalidAddress}
      onSave={(data) => {
          this.checkLatLong(data);
          this.setState({
              openDialogAutoAssign: false
          });
      }}
      onClose={() => {
          this.setState({
              openDialogAutoAssign: false
          });
      }}
    />

    </CustomFormDialog>
  }

  toggleColumn = (checked, columns) => {
    let hiddenColumns = this.state.hiddenColumns;

    if (columns.length > 0) {
      if (checked === true) { // hide column
        hiddenColumns = hiddenColumns.concat(columns);
      } else { // show column
        columns.forEach(column => {
          let index = hiddenColumns.indexOf(column);
          if (index !== -1) {
            hiddenColumns.splice(index, 1);
          }
        });
      }
    }
    this.reSaveColumnOrder(hiddenColumns,false);
    this.setState({
      hiddenColumns: hiddenColumns
    });
  }
  
  reSaveColumnOrder = (hiddenColumns,isSave=true) => {
    let originalColumn = this.getColumns(false);
    let hiddenColumn = hiddenColumns.filter((obj) => obj != '');
    const jobColumnOrder = JSON.parse(localStorage.getItem('job_column_order'));
    const columnOrder = this.state.sortColumn.length > 0 ? this.state.sortColumn : []
    let sortArray = columnOrder.length > 0 ? columnOrder : Array.from(Array(originalColumn.length).keys()) 
    if(hiddenColumn.length > 0) {
      let indexList = [];
      let removeIndex = '';
      let newArray = [];
      originalColumn.map((obj,index) => {
        if(obj.hidden) {
          removeIndex = sortArray.findIndex(val => val == index);
          if(removeIndex > -1) {
            sortArray.splice(removeIndex,1);
          }
        } else if (!sortArray.includes(index)) {
          sortArray.splice(index, 0, index);            
        }
      });         
    } else {
      originalColumn.map((obj,index) => {
        if(!sortArray.includes(index)) {
          sortArray.splice(index, 0, index);   
        }
      });             
    }

    this.setState({
      sortColumn: sortArray
    });
    if(isSave) {
      this.callCreateColumnOrder(sortArray);   
    }
  }

  columnSettingsDialog = () => {
    const { accessToken } = this.context;
    let hiddenColumns = this.state.hiddenColumns;
    return <CustomDialog2
      title={'Column Settings'}
      btnOkText={'Save Settings'}
      open={this.state.openSettingsDialog}
      hideCancelButtons={true}
      onOk={() => {
        this.setState({
          isLoading: true,
          hiddenColumns: hiddenColumns
        });

        if (this.state.hiddenColumnSettingId > 0) {
          api.update('application_company_settings/' + this.state.hiddenColumnSettingId, {
            rule: 'job_column_settings',
            json_data: JSON.stringify(hiddenColumns)
          }, accessToken)
            .then(result => {
              let hiddenColumnSettingId = 0;
              if (result.data.data.result.id) {
                hiddenColumnSettingId = result.data.data.result.id;
                localStorage.setItem("job_column_settings", JSON.stringify(result.data.data.result));
                this.reSaveColumnOrder(hiddenColumns);
              }

              this.setState({
                openSettingsDialog: false,
                hiddenColumnSettingId: hiddenColumnSettingId,
                isLoading: false
              })
          });
        } else {
          api.create('application_company_settings', {
            enabled: true,
            rule: "job_column_settings",
            json_data: JSON.stringify(hiddenColumns)
          }, accessToken)
            .then(result => {
              let hiddenColumnSettingId = 0;
              if (result.data.data.result.id) {
                hiddenColumnSettingId = result.data.data.result.id;
                localStorage.setItem("job_column_settings", JSON.stringify(result.data.data.result));
                this.reSaveColumnOrder(hiddenColumns);
              }

              this.setState({
                openSettingsDialog: false,
                hiddenColumnSettingId: hiddenColumnSettingId,
                isLoading: false
              })
          });
        }
      }}
      onClose={() => {
        this.setState({
          openSettingsDialog: false
        })
      }}
    >
     <CustomCheckbox checked={this.state.hiddenColumns.includes('date')} label={'Hide Trip Date'} onChange={(checked) => this.toggleColumn(checked, ['date'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('delivery_number')} label={'Hide Tracker Number'} onChange={(checked) => this.toggleColumn(checked, ['delivery_number'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('do_number')} label={'Hide D.O Number'} onChange={(checked) => this.toggleColumn(checked, ['do_number'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('delivery_type')} label={'Hide Job Type'} onChange={(checked) => this.toggleColumn(checked, ['delivery_type'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('order_by')} label={'Hide Ordered By'} onChange={(checked) => this.toggleColumn(checked, ['order_by'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('order_status')} label={'Hide Order Status'} onChange={(checked) => this.toggleColumn(checked, ['order_status'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('POD')} label={'Hide POD'} onChange={(checked) => this.toggleColumn(checked, ['POD'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('reason_for_failure')} label={'Hide Reason For Failure'} onChange={(checked) => this.toggleColumn(checked, ['reason_for_failure'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('delivery_address')} label={'Hide Delivery Address'} onChange={(checked) => this.toggleColumn(checked, ['delivery_address'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('postal_code')} label={'Hide Postal Code'} onChange={(checked) => this.toggleColumn(checked, ['postal_code'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('time_slot')} label={'Hide Time Window'} onChange={(checked) => this.toggleColumn(checked, ['time_slot'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('is_project')} label={'Hide Project'} onChange={(checked) => this.toggleColumn(checked, ['is_project'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('period_of_delivery')} label={'Hide Category'} onChange={(checked) => this.toggleColumn(checked, ['period_of_delivery'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('collection_reference')} label={'Hide Pick Up Ref (D.O)'} onChange={(checked) => this.toggleColumn(checked, ['collection_reference'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('company')} label={'Hide Company'} onChange={(checked) => this.toggleColumn(checked, ['company'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('department')} label={'Hide Department'} onChange={(checked) => this.toggleColumn(checked, ['department'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('driver_partner')} label={'Hide Driver Partner'} onChange={(checked) => this.toggleColumn(checked, ['driver_partner'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('recipent_company')} label={'Hide Client Company'} onChange={(checked) => this.toggleColumn(checked, ['recipent_company'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('recipient_name')} label={"Hide Recipient's Name"} onChange={(checked) => this.toggleColumn(checked, ['recipient_name'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('recipient_contact')} label={'Hide Contact No.'} onChange={(checked) => this.toggleColumn(checked, ['recipient_contact'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('alternate_contact')} label={'Hide Alternative Contact'} onChange={(checked) => this.toggleColumn(checked, ['alternate_contact'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('recipient_email')} label={'Hide Recipient Email'} onChange={(checked) => this.toggleColumn(checked, ['recipient_email'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('item_description')} label={'Hide Item Description'} onChange={(checked) => this.toggleColumn(checked, ['item_description'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('xs_packages')} label={'Hide XS Packages'} onChange={(checked) => this.toggleColumn(checked, ['xs_packages'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('s_packages')} label={'Hide S Packages'} onChange={(checked) => this.toggleColumn(checked, ['s_packages'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('m_packages')} label={'Hide M Packages'} onChange={(checked) => this.toggleColumn(checked, ['m_packages'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('l_packages')} label={'Hide L Packages'} onChange={(checked) => this.toggleColumn(checked, ['l_packages'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('oversized_packages')} label={'Hide Oversized Packages'} onChange={(checked) => this.toggleColumn(checked, ['oversized_packages'])} />
     {/* <CustomCheckbox checked={this.state.hiddenColumns.includes('document')} label={'Hide Document'} onChange={(checked) => this.toggleColumn(checked, ['document'])} /> */}
     {/* <CustomCheckbox checked={this.state.hiddenColumns.includes('boxes')} label={'Hide Boxes'} onChange={(checked) => this.toggleColumn(checked, ['boxes'])} /> */}
     <CustomCheckbox checked={this.state.hiddenColumns.includes('pallets')} label={'Hide Pallets'} onChange={(checked) => this.toggleColumn(checked, ['pallets'])} />
     {/* <CustomCheckbox checked={this.state.hiddenColumns.includes('cartons')} label={'Hide Cartons'} onChange={(checked) => this.toggleColumn(checked, ['cartons'])} /> */}
     <CustomCheckbox checked={this.state.hiddenColumns.includes('size_lwh')} label={'Hide Size in cm(L + W + H)'} onChange={(checked) => this.toggleColumn(checked, ['size_lwh'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('weight')} label={'Hide Weight'} onChange={(checked) => this.toggleColumn(checked, ['weight'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('waiting_time')} label={'Hide Waiting Time'} onChange={(checked) => this.toggleColumn(checked, ['waiting_time'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('no_of_movers')} label={'Hide Additional Manpower'} onChange={(checked) => this.toggleColumn(checked, ['no_of_movers'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('price')} label={'Hide Price'} onChange={(checked) => this.toggleColumn(checked, ['price'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('vendor_payout')} label={'Hide Vendor Payout'} onChange={(checked) => this.toggleColumn(checked, ['vendor_payout'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('driver_payout')} label={'Hide Driver Payout'} onChange={(checked) => this.toggleColumn(checked, ['driver_payout'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('notes_to_driver')} label={'Hide Notes to Driver-Partner'} onChange={(checked) => this.toggleColumn(checked, ['notes_to_driver'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('depalletization_request')} label={'Hide Depalletization Request'} onChange={(checked) => this.toggleColumn(checked, ['depalletization_request'])} />
     

    </CustomDialog2>
  }

  callDetailsApi = (id = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.read('orders/' + id, null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback((data && data.result) ? data.result : null, (data && data.sorted_order_details) ? data.sorted_order_details : []);
              }
            });
          }, (error, type) => {
            apiUtil.toast(error, 'warning', 'error'); 
            this.setState({
              isLoading: false,
            });
          });
      });
    });
  }

  previewDialog = () => {
    if(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')){
    return <CustomDialog2 
      open={this.state.openPreviewDialog}
      title={'Order Details'}
      maxWidth={'lg'}
      padding={'0'}
      // hideButtons={true}
      onClose={() => {
        this.setState({
          openPreviewDialog: false,
          openPreviewDialogItem: null,
        });
      }}
      btnOkText={"Save Changes"}
      hideCancelButtons={true}
      hideOkButtons={true}
    >
      <JobPreviewDialog
        id={this.state.openPreviewDialogItem}
        image={this.state.company_logo}
      />
      <OrderDetailForm 
        orderId={this.state.openPreviewDialogItem}
        image={this.state.company_logo}
        onSave={() => {
          this.callInitApis();
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
        }}
        onClose={()=>{
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });       
        }}
    /> 
    </CustomDialog2>
    }
  }

  autoAssignDialog = () => {
    return  this.setState({
      autoAssign: true
    })
  }

  importDialog = () => {
    return <CustomDialog 
      open={this.state.openImportDialog}
      title={'Import Jobs'}
      maxWidth={'xl'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openImportDialog: false,
          openImportDialogItem: null,
        });
      }}
    >
      <JobImportDialog
        file={this.state.openImportDialogItem}
        isLoading={this.state.openImportDialogIsLoading}
        onClose={() => {
          this.setState({
            openImportDialog: false,
            openImportDialogItem: null,
          });
        }}
        onImport={(form) => {
          this.setState({
            openImportDialogIsLoading: true
          }, () => {
            this.callImportApi(form, (data) => {
              let all_jobs = (form && form.length > 0) ? form.length : 0;
              let failed_orders = (data && data.failed_orders && data.failed_orders.length > 0) ? data.failed_orders.length : 0;
              let invalid_company_code_orders = (data && data.invalid_company_code_orders && data.invalid_company_code_orders.length > 0) ? data.invalid_company_code_orders.length : 0;
              let saved_orders = (data && data.saved_orders && data.saved_orders.length > 0) ? data.saved_orders.length : 0;
              
              if(failed_orders > 0){
                apiUtil.toast(failed_orders + ' of ' + all_jobs + ' jobs have not successfully imported', 'check_circle', 'error');
              }
              if(invalid_company_code_orders > 0){
                apiUtil.toast(invalid_company_code_orders + ' of ' + all_jobs + ' company code does not match, jobs have not successfully imported', 'check_circle', 'error');
              }
  
              if(saved_orders > 0){
                apiUtil.toast(saved_orders + ' of ' + all_jobs + ' Jobs Successfully Imported', 'check_circle', 'success');
              }
  
              this.callReadApi();
              this.loadStatusApi();
              this.setState({
                openImportDialog: false,
                openImportDialogItem: null,
                openImportDialogIsLoading: false,
              });
            });
          });
        }}
      />
    </CustomDialog>
  }

  toggleCopyData = (checked, column) => {
    let copyData = this.state.copyData;
    copyData[column] = checked;
    this.setState({
      copyData: copyData
    });
  }

  copyDialog = () => {
    return <CustomDialog
      title={'Duplicate Job'}
      btnOkText={'Duplicate Job'}
      open={this.state.copyDialogOpen}
      onClose={() => {
        this.setState({
          copyDialogOpen: false,
        });
      }}
      onOk={() => {
        this.props.history.push({
          pathname: "/jobs-form",
          copyData: this.state.copyData
        });
      }}
    >
      <p>Which items you want to duplicate as a new job?</p>
      <p className="modal-subtitle">Order Details</p>
      <CustomCheckbox label={'Job Date'} checked={this.state.copyData.drop_off_date} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_date');
      })} />
      <CustomCheckbox label={'Job Description'} checked={this.state.copyData.drop_off_description} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_description');
      })} />
      <CustomCheckbox label={'Job Time'} checked={this.state.copyData.drop_off_time_planned} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_time_planned');
      })} />
       <CustomCheckbox label={'Order Number'} checked={this.state.copyData.order_number} onChange={((checked) => {
        this.toggleCopyData(checked, 'order_number');
      })} />
       <CustomCheckbox label={'Do Number'} checked={this.state.copyData.do_number} onChange={((checked) => {
        this.toggleCopyData(checked, 'do_number');
      })} />
       <CustomCheckbox label={'Driver Payout'} checked={this.state.copyData.driver_payout} onChange={((checked) => {
        this.toggleCopyData(checked, 'driver_payout');
      })} />

      <p className="modal-subtitle">Recipient Details</p>
      <CustomCheckbox label={'Name'} checked={this.state.copyData.drop_off_name} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_name');
      })} />
      <CustomCheckbox label={'Email'} checked={this.state.copyData.drop_off_contact_email} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_contact_email');
      })} />
      <CustomCheckbox label={'Phone No'} checked={this.state.copyData.drop_off_contact_no} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_contact_no');
      })} />
      <CustomCheckbox label={'Tracking Number'} checked={this.state.copyData.item_tracking_number} onChange={((checked) => {
        this.toggleCopyData(checked, 'item_tracking_number');
      })} />
      <CustomCheckbox label={'Company Name'} checked={this.state.copyData.drop_off_contact_name} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_contact_name');
      })} />
      <CustomCheckbox label={'Postal Code'} checked={this.state.copyData.drop_off_postal_code} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_postal_code');
      })} />
       <CustomCheckbox label={'Address'} checked={this.state.copyData.drop_off_address} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_address');
      })} />

      <p className="modal-subtitle">Additional Details</p>
      <CustomCheckbox label={'Items in this job'} checked={this.state.copyData.order_details} onChange={((checked) => {
        this.toggleCopyData(checked, 'order_details');
      })} />
      <CustomCheckbox label={'Assign Workers'} checked={this.state.copyData.drop_off_worker} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_worker');
      })} />
      <CustomCheckbox label={'Job Steps'} checked={this.state.copyData.job_steps} onChange={((checked) => {
        this.toggleCopyData(checked, 'job_steps');
      })} />
      <CustomCheckbox label={'Advanced Options'} checked={this.state.copyData.amount} onChange={((checked) => {
        this.toggleCopyData(checked, 'amount');
      })} />
      <CustomCheckbox label={'Documents'} checked={this.state.copyData.document} onChange={((checked) => {
        this.toggleCopyData(checked, 'document');
      })} />
      <CustomCheckbox label={'Boxes'} checked={this.state.copyData.boxes} onChange={((checked) => {
        this.toggleCopyData(checked, 'boxes');
      })} />
      <CustomCheckbox label={'Pallets'} checked={this.state.copyData.pallets} onChange={((checked) => {
        this.toggleCopyData(checked, 'pallets');
      })} />
      <CustomCheckbox label={'Cartons'} checked={this.state.copyData.cartons} onChange={((checked) => {
        this.toggleCopyData(checked, 'cartons');
      })} />
      <CustomCheckbox label={'Driver Notes'} checked={this.state.copyData.driver_notes} onChange={((checked) => {
        this.toggleCopyData(checked, 'driver_notes');
      })} />
       <CustomCheckbox label={'Package Type'} checked={this.state.copyData.package_type} onChange={((checked) => {
        this.toggleCopyData(checked, 'package_type');
      })} />
        <CustomCheckbox label={'Package Quantity'} checked={this.state.copyData.package_quantity} onChange={((checked) => {
        this.toggleCopyData(checked, 'package_quantity');
      })} />
    </CustomDialog>
  }
  /* END DIALOG */


  /* STEPPER */
  setStepper = () => {
    return <>
      <CustomGuideStepper
        activeStep={3}
        steps={[
          {
            label: 'Create Customer',
          },
          {
            label: 'Create Vehicle',
          },
          {
            label: 'Create Driver',
          },
          {
            label: 'Create Job',
          },
        ]}
        onClick={(index) => {
          if(index === 0){
            this.props.history.push('/customers');
          } else if(index === 1){
            this.props.history.push('/resources/vehicle');
          } else if(index === 2){
            this.props.history.push('/resources/transport-operator');
          } else if(index === 3){
            // this.props.history.push('/job-list');
          }
        }}
      />
      <Box pb={2} textAlign={'center'}>This is the Job list page.Let's learn some basics about this page.</Box>
      <Box textAlign={'center'}>
        <CustomButton 
          color={'primary'}
          href={'/'}
          onClick={(e) => {
              e.preventDefault();

              this.setState({
                openSkipDialog: true,
              });
          }}
        >
          Skip All
        </CustomButton>
      </Box>
    </>
  }
  /* END STEPPER */

  /* TOUR */
  setTour = () => {
    return <>
      <Tour
        steps={[
          {
            selector: '.select-dates-step',
            content: <Box>
              <h2>Select dates</h2>
              <p>Here is where you choose a range of dates to display in the job list</p>
            </Box>,
          },
          {
            selector: '.job-statuses-step',
            content: <Box>
              <h2>Job Statuses</h2>
              <p>This area is where you monitor all of your job progress in general. You can filter all job statuses by clicking on any of the statuses here.</p>
            </Box>,
          },
          {
            selector: '.job-listing-step',
            content: <Box>
              <h2>Job Listing</h2>
              <p>Like vehicles and drivers, here is where all your available jobs are are populated.You can edit the displayed columns and sort by a column.</p>
            </Box>,
          },
          {
            selector: '.new-job-step',
            stepInteraction: true,
            content: <Box>
              <h2>Create job</h2>
              <p>Now let's create a new job.</p>
            </Box>,
          },
        ]}
        isOpen={this.state.isTourOpen}
        rounded={10}
        // accentColor={'#64CCC9'}
        accentColor={'#007CDE'}
        showNumber={false}
        showNavigation={false}
        disableInteraction={true}
        // prevButton={<></>}
        // nextButton={<></>}
        lastStepNextButton={<></>}
        onRequestClose={() => {
          this.setState({
            isTourOpen: false,
          });
        }} />
    </>
  }
  /* END TOUR */

  /* SKIP DIALOG */
  skipDialog = () => {
    const { accessToken, setOnBoardingCustomer, setOnBoardingVehicle, setOnBoardingDriver, setOnBoardingJob } = this.context;

    return <CustomDialog 
      open={this.state.openSkipDialog}
      title={'End Tutorial'}
      btnCloseText={'No'}
      onClose={() => {
        this.setState({
          openSkipDialog: false,
        });
      }}
      onOk={() => {
        apiUtil.callUpdateAdminProfileApi(accessToken, (data) => {
          this.setState({
            openSkipDialog: false,
          }, () => {
            setOnBoardingCustomer(null);
            setOnBoardingVehicle(null);
            setOnBoardingDriver(null);
            setOnBoardingJob(null);

            apiUtil.toastOnBoarding('Done', 'check_circle');
            this.props.history.push('/');
          });
        });
      }}
    >
      <Box>Are you sure you want to end tutorial?</Box>
    </CustomDialog>
  }
  /* END SKIP DIALOG */

  /*START AUTO ASSIGN*/
  autoAssignApi = (callback = null) =>{
    const { accessToken } = this.context;

    let row = {};
    row.start_date = moment(this.state.startDate).format("YYYY-MM-DD");
    row.end_date = moment(this.state.endDate).format("YYYY-MM-DD");

    this.setState({
      isLoading: true
    }, () => {

      ldsApi.create('auto_assign/jobs', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.job_assigned && data.job_assigned.length > 0) ? data.job_assigned : null;

            if(result){
              let currentData = fromJS(this.state.data).toJS();
              currentData.map((item, i)=>{
                 result.map((res, ii)=>{
                    if(item.id == res.id){
                      item.order_status_id = res.order_status_id;
                      item.order_status = res.order_status;

                      item.drop_off_worker_id = res.drop_off_worker_id;
                      item.drop_off_worker = res.drop_off_worker;
                      item.drop_off_worker_name = res.drop_off_worker_name;
                    }
                 }); 
              });

              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(result);
                }
              });
            } else {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });    
  }

  checkLatLong = (currentData=null, callback = null)=>{
    if(!currentData){ currentData = fromJS(this.state.data).toJS(); };

    let invalidLatLong = [];
    let finishCallback = [];
  
    this.setState({
      isLoading: true
    }, () => {

        currentData.filter(x => x.order_status_id == 679).map((v,k)=>{
             
            let row = {};
            row.address = v.drop_off_address; 
            this.generateLatLong(row, (data) => {
                if(data.success){
                  let isDiffLatLong = false;
                  if(v.drop_off_latitude != data.lat && v.drop_off_longitude != data.lng){
                    v.drop_off_latitude = data.lat.toString();
                    v.drop_off_longitude = data.lng.toString();
                    isDiffLatLong = true;
                  }
                  
                  let format = {};
                  format.data = [];

                  let template = {};
                  template.id = [v.id];
                  template.drop_off_latitude = v.drop_off_latitude;
                  template.drop_off_longitude = v.drop_off_longitude;
                  template.drop_off_address = v.drop_off_address;
                  format.data.push(template);

                  if(isDiffLatLong){
                    this.updateLatLong(format, (result) => {
                      if(!result){ invalidLatLong.push(v); }
                      finishCallback.push("true");

                      if(finishCallback.length == currentData.filter(x => x.order_status_id == 679).length){
                        this.showInvalidAddress(invalidLatLong);
                      }
                    });
                  }else{
                    finishCallback.push("true");

                    if(finishCallback.length == currentData.filter(x => x.order_status_id == 679).length){
                      this.showInvalidAddress(invalidLatLong);
                    }
                  }
                  
                }else{
                  invalidLatLong.push(v);
                  finishCallback.push("true");

                  if(finishCallback.length == currentData.filter(x => x.order_status_id == 679).length){
                    this.showInvalidAddress(invalidLatLong);
                  }
                }
            });
          
        });
    });  
  }

  showInvalidAddress = (invalidLatLong, callback = null)=>{
    if(invalidLatLong.length == 0){ 
      this.autoAssignApi();
    }else{
      this.setState({
                      isLoading: false, 
                      openDialogAutoAssign:true,
                      invalidAddress: invalidLatLong
                  });
    }
  }

  generateLatLong = (row, callback = null)=>{
    const { accessToken } = this.context;
    ldsApi.create('geo_functions/geocoder_address', row, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result) ? data.result : null;
          if(result){            
            callback(result);
          }else{ 
            callback(null);   
          }
        }, (error, type) => {
          callback(null)
        });
    });
  }

  updateLatLong = (row, callback = null)=>{
    const { accessToken } = this.context;
    ldsApi.create('orders/assign/order', row, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result) ? data.result : null;
          if(result){            
            callback(result);
          }else{ 
            callback(null);   
          }
        }, (error, type) => {
          callback(null)
        });
    });
  }

  /*END AUTO ASSIGN*/

  /* PDF GENERATE */ 

  generatePDF = () => {
    const { accessToken } = this.context;
    let selectedRow = this.rowData;
    let ids = '';
    if(selectedRow != null && selectedRow != '') {
      selectedRow.map((item, i) => { ids += item.id +','; });
    } 
    ids = ids.slice(0, -1);
    
    if(ids != "") {
      window.open(REACT_APP_LDS_API_URL + 'multiple_generate_label.pdf?ids=' + ids,'_blank');    
    } else {
      apiUtil.toast("Please select data for generation", "check_circle")
    }

  } 

  generatePOD = () => {

    let selectedRow = this.rowData;
    let ids = '';
    if(selectedRow != null && selectedRow != '') {
      selectedRow.map((item, i) => { ids += item.id +','; });
    } 
    ids = ids.slice(0, -1);
    
    if(ids != "") {
      window.open(REACT_APP_LDS_API_URL + '/multiple_generate_pod.pdf?ids=' + ids + '&application_company_id=' + this.state.user.owner_info.application_company_id,'_blank');
    } else {
      apiUtil.toast("Please select data for generation", "check_circle")
      // window.open(REACT_APP_LDS_API_URL + '/multiple_generate_pod.pdf?start_date=' + moment(this.state.startDate).format("YYYY-MM-DD") + "&end_date=" + moment(this.state.endDate).format("YYYY-MM-DD") + '&application_company_id=' + this.state.user.owner_info.application_company_id,'_blank');
    }
    this.rowData = '';
    this.setState({
      data: fromJS(this.state.data).toJS()
    })
    // window.open(REACT_APP_LDS_API_URL + '/multiple_generate_pod.pdf?ids=' + ids + '&application_company_id=' + this.state.user.owner_info.application_company_id,'_blank');  
  }
  
    /*END PDF GENERATE */
  
  /*START BATCH UPDATE*/
  batchUpdateApi = (data, status = null, callback = null) =>{
    // console.log("batchUpdateApi");

    const { accessToken } = this.context;
    // console.log("select row render");
    let row = {};
    row.data = [];
    let template = {};
    let selectedRow = this.rowData;
    let ids = [];
    if(selectedRow != null && selectedRow != '') selectedRow.map((item, i) => { ids.push(item.id); });

    if(!ids.length > 0) {
      apiUtil.toast('You must select order!', 'warning', 'error');
      this.setState({
        openDialogBatchUpdate:false,
        openDialogChangeStatus:false,
        openDialogJobAssign:false,
        openDialogDateChange: false,
        openBatchUpdateisLoading:false,
        drop_off_change_date: null,
      });
      return
    }
    
    if(!data.order_status_id && !data.drop_off_worker_id && !data.vendor_id && !data.drop_off_date){
      this.setState({
        openDialogBatchUpdate:false,
        openDialogChangeStatus:false,
        openDialogJobAssign:false,
        openDialogDateChange: false,
        openBatchUpdateisLoading:false
      });
      return;
    }


    template.id = ids;
    if(data.order_status_id){ template.order_status_id = data.order_status_id; }
    if(data.drop_off_worker_id){ 
      template.drop_off_worker_id = data.drop_off_worker_id; 
      template.extra_worker_ids = null
      template.batch_update_assign = true
      }
    if(data.vendor_id) {
      template.assign_type = "vendor";
      template.vendor_id = data.vendor_id; 
    } else {
      template.vendor_id = null
    }
    if(data.extra_worker_ids){ template.extra_worker_ids = data.extra_worker_ids.toString(); }
    if(data.drop_off_date){ template.drop_off_date = data.drop_off_date}
    if (status == "Pending") {
      let statusItem = apiUtil.customFilter(this.state.status, 'status', 'assigned to vendor') 
      let rowData = '';
      selectedRow.map((value,i) => {
        rowData = {
        id: [value.id],
        job_steps: (value.job_steps && value.job_steps.length > 0) ? value.job_steps.map(step => {
            step.job_step_id = step.id;
            if (status === "Completed") {
              step.job_step_status_id = 3;
            } else {
              step.job_step_status_id = 1;
            }
            return step
          }): value.job_steps,
        order_status_id: value.vendor_id && value.vendor_id == this.state.user.owner_info.application_company_id ? statusItem.id : data.order_status_id,
        drop_off_worker_id: null,
        extra_worker_ids: null,
        vendor_id: value.vendor_id && value.vendor_id == this.state.user.owner_info.application_company_id ? value.vendor_id : null,
        }
        row.data.push(rowData);
      });
    } else {    
      row.data.push(template);       
    }
    if (status == "Completed" || status == "Failed") {
      row.data[0].drop_off_time_end = moment().format("h:mm a");
    }
    row.data = row.data.sort((a, b) => {
      if (a.job_type > b.job_type) {
        return -1;
      }
      if (a.job_type > b.job_type) {
        return 1;
      }
      return 0;
    });
    this.rowData = null
    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('orders/assign/order', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result[0] : null;

            if(result){
              this.rowData = '';
              let currentData = fromJS(this.state.data).toJS();
              currentData.map((item, i)=>{
                 data.result.map((res, ii)=>{
                    if(item.id == res.id){
                      item.order_status_id = res.order_status_id;
                      item.order_status = res.order_status;

                      item.drop_off_worker_id = res.drop_off_worker_id;
                      item.drop_off_worker = res.drop_off_worker;
                      item.drop_off_worker_name = res.drop_off_worker_name;

                      item.extra_workers_ids = res.extra_workers_ids;
                      item.extra_workers = res.extra_workers;
                      item.drop_off_date = res.drop_off_date;
                    }
                 }); 
              });
              this.loadStatusApi(()=> {
                this.callReadApi(); 
              });
             
              this.setState({
                isLoading: false,
                openDialogBatchUpdate:false,
                openDialogChangeStatus:false,
                openDialogJobAssign:false,
                openDialogDateChange: false,
                openBatchUpdateisLoading:false,
                data:currentData
              }, () => {
                if(callback){
                  callback(result);
                }
              });
              apiUtil.toast(data.message != null ? data.message : "Successfully Updated", 'warning', 'error');
            } else {
              this.rowData = '';
              this.setState({
                isLoading: false,
                openDialogBatchUpdate:false,
                openDialogChangeStatus:false,
                openDialogJobAssign:false,
                openDialogDateChange: false,
                openBatchUpdateisLoading:false
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }

          }, (error, type) => {
            if(error && error.email && error.email.length > 0){
              apiUtil.toast(error.email[0], 'warning', 'error');
            } else if(error && error !== ''){
              apiUtil.toast(error, 'warning', 'error');
            }

            this.setState({
              data:this.state.data,
              openDialogBatchUpdate:false,
              openDialogChangeStatus:false,
              openDialogJobAssign:false,
              openDialogDateChange: false,
              openBatchUpdateisLoading:false
            }, () => {
              this.setState({
                isLoading: false,
              })
            });
            
          });
      });
    });
    
  }
  /*END Batch Update*/

   /*START BATCH UPDATE*/
   batchUpdateChangeDateApi = (data, status = null, callback = null) =>{
    // console.log("batchUpdateApi");

    const { accessToken } = this.context;
    // console.log("select row render");
    let row = {};
    row.data = [];
    let template = {};
    let selectedRow = this.rowData;
    let ids = [];
    if(selectedRow != null && selectedRow != '') selectedRow.map((item, i) => { ids.push(item.id); });

    if(!ids.length > 0) {
      apiUtil.toast('You must select order!', 'warning', 'error');
      this.setState({
        openDialogBatchUpdate:false,
        openDialogChangeStatus:false,
        openDialogJobAssign:false,
        openDialogDateChange: false,
        openBatchUpdateisLoading:false,
        drop_off_change_date: null,
      });
      return
    }
    
    if(!data.order_status_id && !data.drop_off_worker_id && !data.vendor_id && !data.drop_off_date){
      this.setState({
        openDialogBatchUpdate:false,
        openDialogChangeStatus:false,
        openDialogJobAssign:false,
        openDialogDateChange: false,
        openBatchUpdateisLoading:false
      });
      return;
    }


    template.id = ids;

    if(data.drop_off_date){ template.drop_off_date = data.drop_off_date}
    row.data.push(template);       
    
    this.rowData = null
    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('orders/assign/order', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result[0] : null;

            if(result){
              this.rowData = '';
              /*let currentData = fromJS(this.state.data).toJS();
              currentData.map((item, i)=>{
                 data.result.map((res, ii)=>{
                    if(item.id == res.id){
                      item.order_status_id = res.order_status_id;
                      item.order_status = res.order_status;

                      item.drop_off_worker_id = res.drop_off_worker_id;
                      item.drop_off_worker = res.drop_off_worker;
                      item.drop_off_worker_name = res.drop_off_worker_name;

                      item.extra_workers_ids = res.extra_workers_ids;
                      item.extra_workers = res.extra_workers;
                      item.drop_off_date = res.drop_off_date;
                    }
                 }); 
              });*/
              this.loadStatusApi();
              this.callReadApi(); 
              this.setState({
                openDialogBatchUpdate:false,
                openDialogChangeStatus:false,
                openDialogJobAssign:false,
                openDialogDateChange: false,
                openBatchUpdateisLoading:false,
              }, () => {
                if(callback){
                  callback(result);
                }
              });
              apiUtil.toast(data.message != null ? data.message : "Successfully Updated", 'warning', 'error');
            } else {
              this.rowData = '';
              this.setState({
                isLoading: false,
                openDialogBatchUpdate:false,
                openDialogChangeStatus:false,
                openDialogJobAssign:false,
                openDialogDateChange: false,
                openBatchUpdateisLoading:false
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            if(error && error.email && error.email.length > 0){
                apiUtil.toast(error.email[0], 'warning', 'error');
            } else if(error && error !== ''){
                apiUtil.toast(error, 'warning', 'error');
            }
            this.setState({
              isLoading: false,
              openDialogBatchUpdate:false,
              openDialogChangeStatus:false,
              openDialogJobAssign:false,
              openDialogDateChange: false,
              openBatchUpdateisLoading:false
            }, () => {
            });
          });
      });
    });
    
  }
  /*END Batch Update*/
  
  unSelectAll = () => {
    if(this.rowData) {
      this.rowData = this.rowData.filter((obj) => {
        obj.tableData.checked = false;
      });
    }    
  }

  changeTableLimit = (e) => {
    this.limits = e.target.value;
    this.pages = 1;
    this.callReadApi();
  }
  handleChange = (val) => this.setState({value: val});

  customSearch = () => {
    const time_slot_default_value = [{label: '10am - 2pm',value: '10am-2pm'},{label: '2pm - 6pm',value: '2pm-6pm'},{label: '6pm - 9pm',value: '6pm-9pm'}];
    return <div>
    <Row className="pt-4 px-4 mar-1">
      <Col xs={8} md={6} className="m-0 p-0">
          <ToggleButtonGroup type="radio" id="toggle-border-radius" name="search-btn" value={this.state.value} onChange={this.handleChange}>
              <ToggleButton id="tbg-radio-1" value={1} className={this.state.value == 1 ? "text-dark border-0 px-3 py-3 border-end active-toggle-btn" : "text-dark border-0 px-3 py-3 border-end"}>
                  Search
              </ToggleButton>
              <ToggleButton id="tbg-radio-2" value={2} className={this.state.value == 2 ? "text-dark border-0 px-3 py-3 active-toggle-btn" : "text-dark border-0 px-3 py-3"}>
                  Advanced Search
              </ToggleButton>
          </ToggleButtonGroup>
          {(this.state.search_text || this.state.search_delivery_number || this.state.search_do_number || this.state.search_worker_id.length > 0 || this.state.customerId || this.state.search_vendor_id || this.state.search_time_slot || this.state.search_delivery_type || this.state.search_recipient_name || this.state.search_recipient_email || this.state.search_recipient_contact || this.state.search_item_desc || this.state.search_delivery_address || this.state.search_postal_code || this.state.search_uploader.length > 0 || this.state.search_status_id.length > 0 || this.state.search_group_id || this.state.search_from_time || this.state.search_to_time) && <IconButton
          onClick={e => {
            this.rowData =null;
            this.setState({
              search_text: '',
              search_do_number: '',
              search_worker_id: [],
              search_delivery_number: '',
              search_time_slot: '',
              search_delivery_type: '',
              search_recipient_name: '',
              search_recipient_contact: '',
              search_recipient_email: '',
              search_item_desc: '',
              search_vendor_id: '',
              search_delivery_address: '',
              search_postal_code: '',
              search_uploader: [],
              search_status_id: [],
              order_status_list: this.state.old_order_status_list,
              search_group_id: '',
              search_from_time: '',
              search_to_time: '',
              customerId: '',
              customerName: '',
              is_searched:false,
            }, () =>{
              this.pages  = 1;
              this.callReadApi()
            })
            
          }}
          ><span  data-tooltip="clear search" data-tooltip-conf="danger"><img src={Filterimg} width="25px" /></span></IconButton> }
      </Col>
  </Row>
  <Row className="px-4">
  <Col>
      {this.state.value == 1 ?
          <Card className="overflow-hidden rounded-0 border-0 box-shadow-search">
              <div className="row border-0 d-flex justify-content-start align-items-center">
                  <div className="col-xs-12 col-md-8">
                  <input placeholder="Enter Job Number, Company Name, etc" className="border-0 border-bottom p-2 m-4 max-height-input"
                  value={this.state.search_text}
                  onChange={e => {
                    this.setState({
                      search_text:e.target.value,
                    })
                  }}
                   />
                  <Button className="col-6 col-xs-12 col-md-3 upload-style max-height-btn my-4"
                  onClick={e =>{
                    this.rowData =null;
                    this.pages  = 1; 
                    this.setState({
                      is_searched:true,                       
                    }, () => {
                      this.callReadApi()    
                    })
                    }}
                  ><FontAwesomeIcon icon={faMagnifyingGlass} /> Search</Button>
                  </div>
                
              </div>
          </Card> 
          : 
          <Card className="overflow-hidden rounded-0 border-0 box-shadow-search">
              <Row className="px-5">
                  <Col xs={12} md={6} className="py-4">
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Tracker Number
                      </Col>
                      <Col xs={12} md={8}> 
                          <input type="text" className="form-control search-input-style" 
                          value={this.state.search_delivery_number}
                          onChange={e=>{
                            this.setState({
                              search_delivery_number: e.target.value,
                              is_search: false,
                            })
                          }}
                          />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          D.O Number
                      </Col>
                      <Col xs={12} md={8}> 
                          <input type="text" className="form-control search-input-style" 
                          value={this.state.search_do_number}
                          onChange={e=>{
                            this.setState({
                              is_search: false,
                              search_do_number: e.target.value
                            })
                          }}
                          />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Driver Partner
                      </Col>
                      <Col xs={12} md={8}> 
                      <ReactSelect 
                        isMulti
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            height: '150px', // Set the desired height for the dropdown box
                            backgroundColor: 'white', // Set a solid background color
                            zIndex: 1000, // Ensure it appears above other elements
                          }),
                          menuList: (provided) => ({
                            ...provided,
                            maxHeight: '150px', // Set the maximum height for the list of options
                            overflowY: 'auto',  // Enable scrolling if the content exceeds the maximum height
                            backgroundColor: 'white', // Set a solid background color
                            zIndex: 1000, // Ensure it appears above other elements
                          }),
                        }}
                        options={this.state.workerItems}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        value={this.state.search_worker_id}
                        onChange={(e,opt) => {
                          let check_driver = []
                          if(opt.action == 'select-option') {
                            check_driver = this.state.workerItems.filter((obj) => !e.filter((sub) => sub.value == obj.value).length > 0);
                            this.setState({
                              is_search: false,
                              search_worker_id: e
                            })
                          } else if(opt.action == 'remove-value') {
                            check_driver = this.state.oldWorkerItems.filter((obj) => !e.filter((sub) => sub.value == obj.value).length > 0);

                            this.setState({
                              search_worker_id: e,
                              workerItems: check_driver,   
                              is_search: true                              
                            },() => {
                              this.callReadApi();   
                            });
                          } else if(opt.action == 'clear') {
                            this.setState({
                              search_worker_id: [],
                              workerItems: this.state.oldWorkerItems,
                              is_search: true
                            },() => {
                              this.callReadApi();   
                            });                          
                          }
                          
                        }}
                      />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Client Company
                      </Col>
                      <Col xs={12} md={8}>
                        <CustomAutosuggest
                          placeholder={"Enter any keyword to search for customers"}
                          items={this.state.customerItems}
                          value={this.state.customerName}
                          onChange={(value) => {
                            if (value) {
                              this.setState({
                                customerName: value.label,
                                customerId: value.value,
                                customerItem: value.item,
                              });
                            } else {
                              this.setState({
                                customerName: "",
                                customerId: "",
                                customerItem: null,
                              });
                            }
                          }}
                          onSearch={(value, e) => {
                            this.setState(
                              {
                                customerName: value,
                                customerId: value != "" ? this.state.customerId : "",
                                customerItem: value != '' ? this.state.customerItem : null,
                              },
                              () => {
                                e.showNewSuggestions(this.state.customerItems.filter(dd => dd.label && (dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase()))));
                              }
                            );
                          }}
                          onClick={(value, e) => {
                            e.showNewSuggestions(this.state.customerItems.filter(dd => dd.label && (dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase()))));
                          }}
                        />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Order Status
                      </Col>
                      <Col xs={12} md={8}> 
                        <ReactSelect 
                          isMulti
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              height: '150px', // Set the desired height for the dropdown box
                            }),
                            menuList: (provided) => ({
                              ...provided,
                              maxHeight: '150px', // Set the maximum height for the list of options
                              overflowY: 'auto',  // Enable scrolling if the content exceeds the maximum height
                            }),
                          }}
                          options={this.state.order_status_list}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={this.state.search_status_id}
                          onChange={(e,opt) => {
                            let order_status = []
                            if(opt.action == 'select-option') {
                              order_status = this.state.order_status_list.filter((obj) => !e.filter((sub) => sub.value == obj.value).length > 0);
                              this.setState({
                                search_status_id: e,
                                order_status_list: order_status
                              });                          
                            } else if(opt.action == 'remove-value') {
                              order_status = this.state.old_order_status_list.filter((obj) => !e.filter((sub) => sub.value == obj.value).length > 0);
                              this.pages = 1;
                              this.setState({
                                search_status_id: e,
                                order_status_list: order_status,
                                is_search: true                                
                              },() => {
                                this.callReadApi();   
                              });
                            } else if(opt.action == 'clear') {
                              this.setState({
                                search_status_id: [],
                                order_status_list: this.state.old_order_status_list,
                                is_search: true
                              });                          
                            }
                          }}
                        />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                      Job Type
                      </Col>
                      <Col xs={12} md={8}> 
                      <select className="form-select search-input-style" aria-label="Default select example"
                      value={this.state.search_delivery_type}
                      onChange={e=>{
                        this.setState({
                          is_search: false,
                          search_delivery_type: e.target.value
                        })
                      }}
                      >
                          <option value="">Select Job Type</option>
                          <option value={'Collection'}>Pick Up </option>
                          <option value={'Delivery'}>Delivery</option>
                      </select>
                      </Col>
                  </Row>
                  </Col>
                  <Col xs={12} md={6} className="py-4">
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Recipient's Name
                      </Col>
                      <Col xs={12} md={8}> 
                          <input type="text" className="form-control search-input-style" 
                          value={this.state.search_recipient_name}
                          onChange={e=>{
                            this.setState({
                              is_search: false,
                              search_recipient_name: e.target.value
                            })
                          }}
                          />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Contact No
                      </Col>
                      <Col xs={12} md={8}> 
                          <input type="text" className="form-control search-input-style" 
                          value={this.state.search_recipient_contact}
                          onChange={e=>{
                            this.setState({
                              is_search: false,
                              search_recipient_contact: e.target.value
                            })
                          }}
                          />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Uploader
                      </Col>
                      <Col xs={12} md={8}> 
                        <ReactSelect 
                          isMulti
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              height: '150px', // Set the desired height for the dropdown box
                              backgroundColor: 'white', // Set a solid background color
                              zIndex: 1000, // Ensure it appears above other elements
                            }),
                            menuList: (provided) => ({
                              ...provided,
                              maxHeight: '150px', // Set the maximum height for the list of options
                              overflowY: 'auto',  // Enable scrolling if the content exceeds the maximum height
                              backgroundColor: 'white', // Set a solid background color
                              zIndex: 1000, // Ensure it appears above other elements
                            }),
                          }}
                          options={this.state.uploaders}
                          getOptionLabel={(option) => option.first_name+' '+(option.last_name != null ? option.last_name : '')} // Use `name` as the label
                          getOptionValue={(option) => option.id}  // Use `id` as the value
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={this.state.search_uploader}
                          onChange={(e,opt) => {
                            let check_uploader = []
                            if(opt.action == 'select-option') {
                              check_uploader = this.state.uploaders.filter((obj) => !e.filter((sub) => sub.value == obj.value).length > 0);
                              this.setState({
                                is_search: false,
                                search_uploader: e
                              })

                            } else if(opt.action == 'remove-value') {
                        
                              this.setState({
                                search_uploader: e,
                                is_search: true                        
                              },() => {
                                this.callReadApi();   
                              });
                            } else if(opt.action == 'clear') {
                              this.setState({
                                search_uploader: [],
                                is_search: true,
                                uploaders: this.state.oldUploaders,
                              },() => {
                                this.callReadApi();   
                              });                          
                            }
                            
                          }}
                        />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Delivery Address
                      </Col>
                      <Col xs={12} md={8}> 
                          <input type="text" className="form-control search-input-style" 
                          value={this.state.search_delivery_address}
                          onChange={e=>{
                            this.setState({
                              is_search: false,
                              search_delivery_address: e.target.value
                            })
                          }}
                          />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Postal Code
                      </Col>
                      <Col xs={12} md={8}> 
                          <input type="text" className="form-control search-input-style" 
                          value={this.state.search_postal_code}
                            onChange={e=>{
                              this.setState({
                                is_search: false,
                                search_postal_code: e.target.value
                              })
                            }}
                          />
                      </Col>
                  </Row>
                  </Col>
              </Row>
              <Row className="border-0 d-flex justify-content-center align-items-center pb-4 position-relative">
                  <Button className="upload-style max-height-btn" 
                  onClick={e=>{
                    this.rowData =null;
                    this.pages  = 1; 
                    this.setState({
                      is_search: true,
                    }, ()=>{
                      this.callReadApi()
                    })
                    }}><FontAwesomeIcon icon={faMagnifyingGlass} 
                  /> Search</Button>
             
              </Row>
             
          </Card>
          }
  </Col>
  </Row>
</div>
  }

  render() {
    return <Container fluid>
    {  this.state.autoAssign? 
    <Box className="loadingScreen">
      <CircularProgress size={35} className="circular_progress"/>
    </Box> 
   : '' }
      {/* <StickyPageHeader isSmall={false}>
        {this.customStickyPageHeader()}
      </StickyPageHeader> */}
      <Row className="p-4 overflow-hidden">
        <Col xs={12} md={6} className="d-flex justify-content-xs-center justify-content-start mb-2">
          <div className="fs-4 font-medium min-width-font-size">Overview for {this.state.startDate? moment(this.state.startDate).format(apiUtil.getImportDateFormat()): ''} {this.state.endDate && this.state.endDate != this.state.startDate? ' - ' + moment(this.state.endDate).format(apiUtil.getImportDateFormat()) : ''}</div>
        </Col>
        <Col xs={12} md={6} className="d-flex align-items-center justify-content-end">
          <Row className="p-0 m-0 d-flex">
            <Col xs={12} md={'auto'} className="p-0 m-0 pb-2 mb-2">
              <CustomDateRangePicker
                id={'select-dates-step-cus w-50'}
                range={this.state.rangeDate}
                disabled={this.state.isLoading}
                onChange={(range) => {
                  localStorage.setItem(this.pageName + '_rangeDate', JSON.stringify(range));
                  this.pages = 1;
                  this.setState({
                    rangeDate: range,
                    startDate: range.startDate,
                    endDate: range.endDate,
                    is_date_change: true
                  }, () => {
                    this.callInitApis();
                  });
                }}
              />
            </Col>
        </Row>
        </Col>
      </Row>

      <Row className="p-4 overflow-hidden">
        <Col xs={12}>
      { (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Dashboard Stats')) &&  <Card className="cd-shadow">
        {this.state.isLoadingStatus ?  
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} className="p-3">
          <CircularProgress size={35} />
        </Box>
        : this.customStatus()}
      </Card> }
      </Col>
      </Row>

      {this.customSearch()}

      <Row className="p-4 overflow-hidden">
        <Col xs={12}>
      <Card className="cd-shadow p-4">
      {this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Delete') && <div className="two-btn-pos">
      <div>
        <Button className="upload-style my-4 mx-2"
        onClick={e =>{
          this.setState({
            openDialogEmptyRecycle: true
          });
        }}>Empty Recycle Bin</Button>
      </div>
      <div className={'border-delete-btn'} 
      onClick={()=> { 
        this.setState({
          openDialogMassDelete: true
        });
      }}><FontAwesomeIcon icon={faTrashAlt} /></div>
        <div className={'border-restore-btn'} 
        onClick={()=> { 
          this.setState({
            openDialogMassRestore: true
          });
        }}><FontAwesomeIcon icon={faTrashRestoreAlt} /></div>
        </div>
      }
        {this.customTable()}
      </Card>
      </Col>
      </Row>
      
      {this.customDialog()}
      {this.massDeleteDialog()}
      {this.emptyRecycleDialog()}
      {this.massRestoreDialog()}
      {this.customDialogBatchUpdate()}
      {this.customDialogAssign()}
      {this.unAssignDriverDialog()}
      {this.customDialogChangeStatus()}
      {this.customDialogDateChange()}
      {this.customDialogPayoutChange()}
      {this.customDialogAutoAssign()}
      {this.previewDialog()}
      {this.importDialog()}
      {this.duplicateDialog()}
      {/* {this.autoAssignDialog()} */}
      {this.setTour()}
      {this.skipDialog()}
      {this.columnSettingsDialog()}
      {this.copyDialog()}
	    <div id="barcode" style={{display:'none'}}></div>
      <div id="qr" style={{display:'none'}}></div>
      <div id="bottom-text" style={{display:'none', fontStyle: "italic", fontWeight: "lighter"}}>Sustainable deliveries · Green warehousing · EV Leasing</div>
      <img id="imageid" src={this.state.user.result.company_logo} style={{display:'none'}}/>
      <canvas id="imgCanvas" />
    </Container>;
  }
}

export default TrashBin;