import React, { Component } from 'react';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import moment from 'moment';
import jsPDF from "jspdf";
import QRCode  from "qrcode.react";
import EVFYLogo from '../../assets/img/evfy-logo2.png';
import location from '../../assets/img/Vector.png';
import building from '../../assets/img/building.png';
import box from '../../assets/img/box.png';
import comment from '../../assets/img/comment.png';
import person from '../../assets/img/person.png';
import time from '../../assets/img/access_time.png';
import phone from '../../assets/img/local_phone.png';
import bottomTxt from '../../assets/img/bottom-txt.png';
import EVFYLogoForLabel from '../../assets/img/EVFY_Logo.png';
import ReactDOM from 'react-dom';
import Barcode from 'react-barcode';
import { Container, Row, Col, Button, Card, ToggleButton, ToggleButtonGroup, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'react-circular-progressbar/dist/styles.css';
import Filterimg from '../../assets/img/icons/filter.png';
import { faDownload, faMagnifyingGlass, faCircle, faEye, faBarcode, faFile, faCopy, faPen, faTrashCan, faFilter, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { 
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Select
} from '@material-ui/core';

import Table from '../../components/Table/Table.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import CustomDialog2 from '../../components/Dialog/CustomDialog2.jsx';
import DropdownMenu from '../../components/Dropdown/DropdownMenu.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import {Verdana} from '../../components/Fonts/Verdana'
import { Anmollipi } from '../../components/Fonts/Anmollipi.jsx';
import { InterLight } from '../../components/Fonts/InterLight.jsx';
import { InterBold } from '../../components/Fonts/InterBold.jsx';
import JobPreviewDialog from '../Jobs/Partial/JobPreviewDialog.jsx';
import socketIOClient from "socket.io-client";
import { Observable } from 'rxjs/Observable';
import {RoleAccessService} from '../../data/role-access'; 
import HtmlParser from 'react-html-parser';
import CustomAutosuggest from '../../components/CustomFields/CustomAutosuggest.jsx';
import OrderDetailForm from '../../views/Dashboard/OrderDetailForm';
import ReactSelect  from 'react-select';
const { 
  REACT_APP_JOB_UPDATE_SOCKET_URL,
  REACT_APP_LDS_API_URL,
} = process.env;
 

let search_param_global = {};
class ClientInvoiceDetail extends Component {
  static contextType = AuthContext;
  rowData =null;
  pages = 1;
  limits = 50;
  search = '';
  do_number = '';
  uploder_id = '';
  constructor(props){
    super(props); 
    this.pageName = 'jobList';
    let id = (this.props.location && this.props.location.state && this.props.location.state.id && this.props.location.state.id > 0) ? this.props.location.state.id : null;
    // let orders = (this.props.location && this.props.location.state && this.props.location.state.orders && this.props.location.state.orders.length > 0) ? this.props.location.state.orders : [];
    let client_name = (this.props.location && this.props.location.state && this.props.location.state.client_name ) ? this.props.location.state.client_name : '';
    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();

    // var selectRow = [];
    let hiddenColumns = [];
    this.state = {
      id ,
      grantedAccess : new RoleAccessService(),
      currentRole: window.localStorage.getItem('current_role') || null,
      order_ids: '',
      isLoadingStatus: false,
      value: 1,
      isLoading: true,
      grouping: true,
      data: [],
      total: 0,
      client_name: client_name,
      company_logo: '',
      orders_logo: [],
      allocation_groups: [],
      user: JSON.parse(localStorage.getItem('user')),

      hiddenColumns: hiddenColumns,
      uploaders: [],
      oldUploaders: [],
      selected_uploader: '',
      status: [],
      search_text: '',
      vendors: [],
      workers: [],
      oldWorkers: [],
      is_search: false,
      order_status: null,
      search_do_number: '',
      search_worker_id: [],
      search_delivery_number: null,
      search_time_slot: null,
      search_delivery_type: '',
      search_recipient_name: '',
      search_recipient_contact: '',
      search_recipient_email: '',
      search_item_desc: '',
      search_uploader: [],
      search_delivery_address: '',
      search_postal_code: '',
      search_group_id: '',
      search_vendor_id: '',
      downloadMenu: false,

      search_to_time: "",
      search_from_time: "",
      sortColumn: [],
      order_status_list: [],
      old_order_status_list: [],
      search_status_id: []
    }
  }
  

  componentDidMount() {
    this.callWorkerDriverReadApi()
    this.callVendorInitApi()
    // this.callReadApi()
    this.loadStatusApi(()=>{
      this.callDetailApi(this.state.id, (orders) => {
        if (orders.length > 0) {
          let order_ids = ''
          if (orders.length > 0) {
            orders.map((item, i) => { order_ids += item.id +','; });
          }
          order_ids = order_ids.slice(0, -1);
          this.setState({
            data: orders,
            order_ids,
          })
        }
      })
    })
    this.callAllocationGroupApi();
  }

  setColumnSetting = () => {
    const { accessToken } = this.context;
    let hiddenColumns = [];
    let columnHiddenSettingsId = 0;
    let columnHiddenSettings = JSON.parse(localStorage.getItem('job_column_settings'));

    if (columnHiddenSettings !== null) {
      hiddenColumns = columnHiddenSettings.json_data ? columnHiddenSettings.json_data.replace(/[^0-9a-zA-Z-_.,]/g, "").split(",") : [];
      columnHiddenSettingsId = columnHiddenSettings && columnHiddenSettings.id ? columnHiddenSettings.id : 0;
    }
    
    this.setState({
      hiddenColumns: hiddenColumns,
      columnHiddenSettingsId: columnHiddenSettingsId,
    },() => {
      const jobColumnOrder = JSON.parse(localStorage.getItem('job_column_order'));
      let originalColumn = this.getColumns(false).filter((obj) => obj.hidden == false);
      let columnOrder = jobColumnOrder ? JSON.parse(jobColumnOrder.json_data) : []

      let foundHideColumn = false
      let searchColumns = this.getColumns(false);
      this.state.hiddenColumns.map((val) => {
        if(val != '' && !searchColumns.filter((obj) => obj.field == val).length > 0) {
          foundHideColumn = true;   
        }
      });

      if(columnOrder.length !== originalColumn.length || foundHideColumn) {
        originalColumn = this.getColumns(false);
        columnOrder = Array.from(Array(originalColumn.length).keys()); 
        let findIndex = originalColumn.map((obj,index) => obj.hidden == true ? index : '' ).filter(String);
        if(findIndex.length > 0) {
          let removeIndex;
          findIndex.map((num) => {
            removeIndex = columnOrder.findIndex((id) => id == num);
            columnOrder.splice(removeIndex,1); 
          });              
        }
        api.create('application_company_settings', {
          enabled: true,
          rule: "job_column_settings",
          json_data: JSON.stringify([''])
        }, accessToken)
          .then(result => {
            let hiddenColumnSettingId = 0;
            if (result.data.data.result.id) {
              hiddenColumnSettingId = result.data.data.result.id;
              localStorage.setItem("job_column_settings", JSON.stringify(result.data.data.result));
            }
        });
        this.callCreateColumnOrder(columnOrder);
      }
      

      this.setState({
        sortColumn: columnOrder
      });          
         
    });   
    
  }
  
  callDetailApi = (id, callback = null) => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoading: true
    }, () => {
      
      
      // if(order)
      ldsApi.read('client_driver_billings/' + id, null, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          this.setColumnSetting();
          let result = data.result  ? data.result : null;
          let orders = result && result.orders ? result.orders : []
          this.setState({
            uploaders: (data.uploaders && data.uploaders.length > 0) ? data.uploaders : [],
            company_logo : data.company_logo_base_64? data.company_logo_base_64 : '',
            orders_logo : data.orders_image_base64.length > 0? data.orders_image_base64 : [],
            total: orders.length
          }, () => {
            this.setState({
              oldUploaders: this.state.uploaders
            });                
          })
          if(callback){
            callback(orders);
            this.setState({
              isLoading: false,
            })
          }
        }, (error, type) => {
          if(callback){
            callback([]);
            this.setState({
              isLoading: false,
            })
          }
        });
      });
    });
  }

  /* VENDOR API */
  callVendorInitApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

      let param = {
        page: 1,
        take: apiUtil.getDefaultPageSize(),
        is_active: true,
      };

      api.read('vendors/active_vendor', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result : [];

            let arr = [];
            if(result && result.length > 0){
              arr = result.map((item, i) => {
                let value = item.company_id;
                let label = item.company_name;

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              this.setState({
                vendors: arr.sort((a, b) => {
                  const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                  const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                
                  // names must be equal
                  return 0;
                }),
              }, () => {
                if(callback){
                  callback(arr);
                }
              });
            } else {
              // this.setState({
              //   vendorItems: [],
              // }, () => {
                if(callback){
                  callback([]);
                }
              // });
            }
          }, (error, type) => {
            // this.setState({
            //   vendorItems: [],
            // }, () => {
              if(callback){
                callback([]);
              }
            // });
          });
      });
  }
  /* END VENDOR API */

  callAllocationGroupApi = (callback = null) => {
    const { accessToken } = this.context;
    
    this.setState({
      // isLoading: true
    }, () => {
      let param = {
        sort: "latest",
        light_data: true,
      };
      
      // if(order)
      ldsApi.read('allocation_groups', param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result && data.result.length > 0) ? data.result : [];
  
          this.setState({
            allocation_groups: result,
            // isLoading: false,
          })
        }, (error, type) => {
          if(callback){
            callback([]);
            this.setState({
              // isLoading: false,
            })
          }
        });
      });
    });
  }

  loadStatusApi = (callback = null) => {
    this.callStatusApi((data, total) => {
      if(data && data.length > 0){
        let status = [];
        data.map((item, i) => {
          if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "assign-pending" || item.status_details.status.replace(' ', '-').trim().toLowerCase() == "on-hold") {
            return status.splice(1, 0, {
              order: 8,
              id: item.status_details.id,
              status: "On Hold",
              text: "On Hold",
              total: item.total_job,
            });
            // status[0].push(objectVal)
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "not-assigned") {
            return status.splice(2, 0, {
              order: 1,
              id: item.status_details.id,
              status: "Pending",
              text: "Pending",
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "assigned") {
            return status.splice(3, 0, {
              order: 3,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "acknowledged") {
            return status.splice(4, 0, {
              order: 4,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "in-progress") {
            return status.splice(5, 0, {
              order: 5,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "completed") {
            return status.splice(6, 0, {
              order: 6,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "failed") {
            return status.splice(7, 0, {
              order: 7,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "cancelled") {
            return status.splice(8, 0, {
              order: 9,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "self-collect") {
            return status.splice(9, 0, {
              order: 10,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replaceAll(' ', '-').trim().toLowerCase() == "assigned-to-vendor") {
            return status.splice(10, 0, {
              order: 2,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          }
        });

        status = status.sort(function(a, b) {
          return (a.order - b.order);
        });
        let tableStatus = status;
        
        let search_order_status = [];
        status.map((obj) => {
          search_order_status.push({
            value: obj.id,
            label: obj.text
          });              
        });
        
        status.unshift({
          id: 0,
          status: 'Total Orders',
          total: total,
        });
  
        this.setState({
          status: status,
          order_status_list: search_order_status,
          old_order_status_list: search_order_status,
          status_total: total,
          tableStatus: tableStatus,
        }, () => {
          if(callback) {
            callback(data);
          }
        });
      } else {
        if(callback) {
          callback();
        }
      }
    });
  }

  /* WORKER API */
  callWorkerDriverReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    let param = {
      // page: 1,
      disabled: false,
      // take: apiUtil.getDefaultPageSize(),
      is_active: true,
      search: search ? search.trim() : search,
      is_truck_driver: true,
      // require_worker_only: true,
      light_data: true
    };

    api.read('workers', param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result && data.result.length > 0) ? data.result : [];

          let arr = [];
          if(result && result.length > 0){
            arr = result.map((item, i) => {
              let value = item.id;
              let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

              if(arr.findIndex(x => x.value === value) === -1){
                return {
                  value: value,
                  label: label,
                  item: item
                }
              } else {
                return null;
              }
            });
            this.setState({
              workers: arr.sort((a, b) => {
                const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
              
                // names must be equal
                return 0;
              })
            }, () => {
              this.setState({
                oldWorkers: this.state.workers
              });                  
            })
            if(callback){
              callback(arr);
            }
            
          } else {
            if(callback){
              callback([]);
            }
          }
        }, (error, type) => {
          if(callback){
            callback([]);
          }
        });
    });
  }
  /* SOCKET */
  getMessages() { 
    const { user } = this.context;

    let application_company_id = apiUtil.getAppCompanyId(user);

    let observable = new Observable(observer => {
      this.socket = socketIOClient(REACT_APP_JOB_UPDATE_SOCKET_URL);

      this.socket.on('connect', () => {
        // console.log("SOCKET", 'connect', this.socket.connected);
      });

      this.socket.emit("join", "job_updated");

      this.socket.on(application_company_id.toString(), (data) => {
        observer.next(data);    
      });

      return () => {
        this.socket.disconnect();
      };  
    }) 
    return observable;
  }
  
  socketData(){
    this.connection = this.getMessages().subscribe(message => {
      
      this.dataMessage = message;
      this.dataMessage = this.dataMessage.order_history;
      if(this.dataMessage && !this.isArray(this.dataMessage)){
        let data = []; data.push(this.dataMessage);
        this.dataMessage= data;
      }
      if(this.dataMessage.length > 0){
        this.dataMessage.forEach(dataMessage=>{
          let actionText = ''
          let userName = ''
          if(dataMessage.admin_id){
            if(dataMessage.action === 'Created'){
              actionText = ' was created by ';
            }else if(dataMessage.action === 'Deleted Order'){
              actionText = ' was deleted by ';
            }else if(dataMessage.action === 'Updated'){
              if(dataMessage.attributes_updated && dataMessage.attributes_updated.includes('order_status_id') !== -1){
                actionText = ' status has been updated to ';
              }else{
                actionText = ' has been updated  by ';
              }
            }
            userName = dataMessage.admin.first_name

          }else if(dataMessage.worker_id){

            userName = dataMessage.worker.first_name
          }

          const icon =  dataMessage.admin_id ? "account_box" : "local_shipping";

          dataMessage.toShow = <div>
          <i className={'material-icons'}>{icon}</i>
          <b style={{marginRight:'5px'}}>{dataMessage.order_reference_no} </b>
          <span style={{marginRight:'5px'}}>{actionText}</span>
          {dataMessage.attributes_updated && dataMessage.attributes_updated.includes('order_status_id') !== -1 && 
          <b style={{marginRight:'5px'}}>{" " + dataMessage.current_status.status + " by "}</b>}
          <b>{userName}</b>
          </div>;
          if(this.state.isShouldDisableSocketToast === true){
            apiUtil.toast('', icon, '', 5000, dataMessage.toShow);
          }
          dataMessage.read_by_me = this.findRead(dataMessage.history_read_shown_markers);
          dataMessage.show_by_me = false;
          if(this.findNew(dataMessage.history_read_shown_markers)){ 
            dataMessage.show_by_me = true;
          }else{this.hadNew = true; }


          localStorage.setItem("doReload", JSON.stringify(dataMessage));
        });
      }
    });
  }
  

  isArray = (value) => {
    return value && typeof value === 'object' && value.constructor === Array;
  }

  findNew = (arrayList) => {
    let isNewItem = false;
    if(arrayList && arrayList.length > 0){
      arrayList.forEach(objItem=>{
        if(objItem.admin_id === localStorage.getItem("admin") && objItem.is_shown){
          isNewItem = true;
        }
      });
    }
    return isNewItem;
  }

  findRead = (arrayList) => {
    let isReadItem = false;
    if(arrayList && arrayList.length > 0){
      arrayList.forEach(objItem=>{
        if(objItem.admin_id === localStorage.getItem("admin") && objItem.is_read){
          isReadItem = true;
        }
      });
    }


    return isReadItem;
  }
  /* END SOCKET */


  /* API */
  callStatusApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoadingStatus: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
      };

      ldsApi.read('orders/job/stats', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              let total = data.total_delivery;
              let result = Object.keys(data.result).map((k) => data.result[k]);
              
              if(result && result.length > 0){
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(result, total);
                  }
                });
              } else {
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(null, 0);
                  }
                });
              }
            } else {
              this.setState({
                isLoadingStatus: false,
              }, () => {
                if(callback){
                  callback(null, 0);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoadingStatus: false,
            }, () => {
              if(callback){
                callback(null, 0);
              }
            });
          });
      });
    });
  }
  
  callReadApi = (search = '', do_num = '', uploader = '') => {
    const { accessToken } = this.context;
    this.rowData = null
    this.setState({
      isLoading: true
    }, () => {
      let searchAdv = false;
      if( this.state.search_do_number || 
          this.state.search_delivery_number || 
          this.state.search_time_slot || 
          this.state.search_recipient_name || 
          this.state.search_worker_id.length > 0 ||
          this.state.search_recipient_contact || 
          this.state.search_recipient_email || 
          this.state.search_item_desc || 
          this.state.search_delivery_address || 
          this.state.search_postal_code || 
          this.state.search_uploader.length > 0 )
      {
        searchAdv = true;
      }
      
      let param = null
      
      if(this.state.is_search) {
        param = {
          page: this.pages,
          take: this.limits,
          order_ids: this.state.order_ids,
          search: this.state.search_text,
          adv_search: searchAdv,
          order_status: this.state.order_status || this.state.search_status_id.map(obj => obj.value).join(','),
          do_number: !this.state.search_do_number ? this.state.search_do_number : this.state.search_do_number.trim(),
          worker_ids: this.state.search_worker_id.map(obj => typeof(obj.item.company_id) == 'undefined' ? obj.value : null).filter((item) => item !== null).join(this.state.search_worker_id.length > 1 ? ',' : ''),
          vendor_ids: this.state.search_worker_id.map(obj => typeof(obj.item.company_id) != 'undefined' ? obj.value : null).filter((item) => item !== null).join(this.state.search_worker_id.length > 1 ? ',' : ''),
          order_number: !this.state.search_delivery_number ? this.state.search_delivery_number : this.state.search_delivery_number.trim(),
          time_slot: (this.state.search_from_time && this.state.search_to_time) ? (this.state.search_from_time + " - " + this.state.search_to_time) : '',
          delivery_type: !this.state.search_delivery_type ? this.state.search_delivery_type : this.state.search_delivery_type.trim(),
          recipient_name: !this.state.search_recipient_name ? this.state.search_recipient_name : this.state.search_recipient_name.trim(),
          recipient_contact: !this.state.search_recipient_contact ? this.state.search_recipient_contact : this.state.search_recipient_contact.trim(),
          recipient_email: !this.state.search_recipient_email ? this.state.search_recipient_email : this.state.search_recipient_email.trim(),
          item_description: !this.state.search_item_desc ? this.state.search_item_desc : this.state.search_item_desc.trim(),
          delivery_address: !this.state.search_delivery_address ? this.state.search_delivery_address : this.state.search_delivery_address.trim(),
          postal_code: !this.state.search_postal_code ? this.state.search_postal_code : this.state.search_postal_code.trim(),
          uploader: this.state.search_uploader.map(obj => obj.id).join(','),
          allocation_group: !this.state.search_group_id ? this.state.search_group_id : this.state.search_group_id.trim(),
          sort: "latest"
        };         
      } else {
        param = {
          page: this.pages,
          take: this.limits,
          order_ids: this.state.order_ids,
          order_status: this.state.order_status,
          sort: "latest"
        };          
      }

      search_param_global = param ;
      // if(order)
      ldsApi.read('orders', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              data: (data.result && data.result.length > 0) ? data.result : [],
              total: data.total,
              isLoading: false,
            }, ()=> {
              this.setState({
                uploaders: data.uploaders,
                oldUploaders: this.state.uploaders,
                is_search: false
              });               
            });

            // this.loadStatusApi();
          }, (error, type) => {
            this.setState({
              data: [],
              total: 0,
              uploaders: [],
              oldUploaders: [],
              isLoading: false,
            });
          });
      });
    });
  }


  callUpdateIsActiveApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.update('orders/' + row.id, row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callDeleteApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      let param = {
        destroy_all: true,
      };

      ldsApi.delete('delete/orders/multiple?id=' + row.id, param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  /* Start Mass Delete Api*/

  callMassDeleteApi = ( callback = null) => {
    const { accessToken } = this.context;

   
      let param = {
        destroy_all: true,
      };
      let selectedRow = this.rowData;
      let ids = '';
      if(selectedRow != null && selectedRow != '') {
        selectedRow.map((item, i) => { ids += item.id +','; });
      } else {
        apiUtil.toast('You must select order!', 'warning', 'error');
        this.setState({
          openDialogMassDelete: false,
        })
        return
      }
      ids = ids.slice(0, -1);
      this.setState({
        isLoading: true
        }, () => {
      ldsApi.delete('delete/orders/multiple?id=' + ids, param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {

            this.loadStatusApi();
            this.callReadApi();            
            apiUtil.toast('Successfully Deleted', 'check_circle');
            this.rowData = null
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  /*End Mass Delete Api*/

  callUpdateStatusApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('orders/assign/order', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result[0] : null;
            this.loadStatusApi()
            if(result){
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(result);
                }
              });
            } else {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
 

   /* EXPORT API */
   callExportXLSApi = () => {
    const { accessToken } = this.context;

    this.setState({
        downloadIsLoading: true
    }, () => {
      
      let selectedRow = this.rowData;
      let ids = '';
      if(selectedRow != null && selectedRow != '') {
        selectedRow.map((item, i) => { ids += item.id +','; });
      } else {
        ids = this.state.order_ids
      }

      if(!ids) {
        apiUtil.toast("No data to download", 'error')
        return
      }

      let searchAdv = false;
      if( this.state.search_do_number || 
          this.state.search_delivery_number || 
          this.state.search_time_slot || 
          this.state.search_recipient_name || 
          this.state.search_recipient_contact || 
          this.state.search_recipient_email || 
          this.state.search_item_desc || 
          this.state.search_delivery_address || 
          this.state.search_postal_code || 
          this.state.search_uploader.length > 0 ||
          (this.state.search_from_time && this.state.search_to_time)
          )
      {
        searchAdv = true;
      }
      let search_param = {
        order_ids: ids,
        search: this.state.search_text,
        adv_search: searchAdv,
      };
      if (this.state.is_search == true) {
      let param = {
        order_ids: ids,
        search: this.state.search_text,
        adv_search: searchAdv,
        order_status: this.state.order_status || this.state.search_status_id.map(obj => obj.value).join(','),
        do_number: !this.state.search_do_number ? this.state.search_do_number : this.state.search_do_number.trim(),
        worker_ids: this.state.search_worker_id.map(obj => typeof(obj.item.company_id) == 'undefined' ? obj.value : null).filter((item) => item !== null).join(this.state.search_worker_id.length > 1 ? ',' : ''),
        vendor_ids: this.state.search_worker_id.map(obj => typeof(obj.item.company_id) != 'undefined' ? obj.value : null).filter((item) => item !== null).join(this.state.search_worker_id.length > 1 ? ',' : ''),
        order_number: !this.state.search_delivery_number ? this.state.search_delivery_number : this.state.search_delivery_number.trim(),
        time_slot: (this.state.search_from_time && this.state.search_to_time) ? (this.state.search_from_time + " - " + this.state.search_to_time) : '',
        delivery_type: !this.state.search_delivery_type ? this.state.search_delivery_type : this.state.search_delivery_type.trim(),
        recipient_name: !this.state.search_recipient_name ? this.state.search_recipient_name : this.state.search_recipient_name.trim(),
        recipient_contact: !this.state.search_recipient_contact ? this.state.search_recipient_contact : this.state.search_recipient_contact.trim(),
        recipient_email: !this.state.search_recipient_email ? this.state.search_recipient_email : this.state.search_recipient_email.trim(),
        item_description: !this.state.search_item_desc ? this.state.search_item_desc : this.state.search_item_desc.trim(),
        delivery_address: !this.state.search_delivery_address ? this.state.search_delivery_address : this.state.search_delivery_address.trim(),
        postal_code: !this.state.search_postal_code ? this.state.search_postal_code : this.state.search_postal_code.trim(),
        uploader: this.state.search_uploader.map(obj => obj.id).join(','),
        allocation_group: !this.state.search_group_id ? this.state.search_group_id : this.state.search_group_id.trim(),
      };
      search_param = param;
      search_param_global = param;
      } else {
        if (Object.keys(search_param_global).length !== 0){
          search_param = search_param_global;
          if(ids) {
            search_param.order_ids = ids;
          }
        }
      }

      

      ldsApi.exportXLS('admin/api/orders/delivery/export', search_param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (response) => {
            apiUtil.saveToFile(response, `${this.state.client_name}.xlsx`);
    
            this.setState({
              downloadIsLoading: false,
            });
          }, (error, type) => {
            if(result.status == 400) {
              apiUtil.toast('You can export maximum 10000 orders once.',  'warning', 'error');
            }
            this.setState({
              downloadIsLoading: false,
            });
          });
      });
    });
  }
  /* END EXPORT API */
  isCollectionFailed = (deliOrder) => {
    if (deliOrder.job_type == "Delivery") {
      // let orderStatusItemFailed = apiUtil.customFilter(this.state.status, 'status', "failed");
      let orderStatusItemFailed = apiUtil.customFilter(this.state.status, 'status', "failed");
      let collection_order = this.state.data.filter(dd => dd.order_number == deliOrder.collection_reference && dd.job_type == 'Collection');

      if (collection_order.length > 0 && collection_order['0'].order_status.status == orderStatusItemFailed.status) {
        return true;
      } else {
        if (!(collection_order.length > 0)) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }
  
  callCreateColumnOrder = (sort) => {
    const { accessToken } = this.context;
    api.create('application_company_settings', {
      enabled: true,
      rule: "job_column_order",
      json_data: JSON.stringify(sort)
    }, accessToken)
      .then(result => {
        if (result.data.data.result.id) {
          localStorage.setItem("job_column_order", JSON.stringify(result.data.data.result));
        }
    });   
  }
  
  arrMove = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  }
  
  onColumnDragged = (sourceIndex,destinationIndex) => {
    const originalColumn = this.getColumns(false).filter((obj) => obj.hidden == false);
    const jobColumnOrder = JSON.parse(localStorage.getItem('job_column_order'));
    const columnOrder = jobColumnOrder ? JSON.parse(jobColumnOrder.json_data) : []
    const sortArray = columnOrder.length > 0  ? columnOrder : Array.from(Array(originalColumn.length).keys())
    const sortedArray = this.arrMove(sortArray,sourceIndex,destinationIndex);
    this.callCreateColumnOrder(sortedArray);
  }
  
  /*
  *  Column field name and hidden name must same
  */
  
  getColumns = (isSort=true) => {
    let columns = [
      { 
        title: "Tracker Number",
        field: "delivery_number",
        customSort: (a,b) => (a.order_number? a.order_number: '').localeCompare(b.order_number? b.order_number : ''),
        hidden: this.state.hiddenColumns.includes('delivery_number'),
        render: (row, type) => {
          if(type === 'row'){
              return  (row && row.order_number && row.order_number !== '') ?  
              <span
              style={{display:'inline-block'}}  
              > {row.order_number} </span>: '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "Trip Date",
        field: "date",
       customSort: (a,b) => (a.drop_off_date? a.drop_off_date: '').localeCompare(b.drop_off_date? b.drop_off_date : ''),
        hidden: this.state.hiddenColumns.includes('date'),
        render: (row, type) => {
          if(type === 'row'){
            return (row && row.drop_off_date && row.drop_off_date !== '') ? 
            <span style={{overflowWrap: 'break-word', wordBreak: 'break-all',  display:'inline-block'}}>{moment(row.drop_off_date).format(apiUtil.getImportDateFormat()) }</span> : '';
          } else {
            return row;
          }
        }
      },
      ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'DO')) ? [{ 
        title: "D.O Number", 
        field: "do_number",
        cellStyle: {
          maxWidth: 160,
       },
        sorting: false,
        customSort: (a,b) => (a.do_number? a.do_number: '').localeCompare(b.do_number? b.do_number : ''),
        hidden: this.state.hiddenColumns.includes('do_number'),
        render: (row, type) => {
          if(type === 'row'){
            return (row && row.do_number && row.do_number !== '') ?<span style={{overflowWrap: 'break-word', wordBreak: 'break-word', display:'inline-block'}}> {row.do_number.replace(/\s/g, '\u00A0')}</span> : '';
          } else {
            return row;
          }
        }
      }] : [],
      { 
        title: "Job Type", 
        field: "delivery_type",
        sorting: false,
        customSort: (a,b) => (a.job_type? a.job_type: '').localeCompare(b.job_type? b.job_type : ''),
        hidden: this.state.hiddenColumns.includes('delivery_type'),
        render: (row, type) => {
          if(type === 'row'){
            return (row && row.job_type && row.job_type !== '') ? <span style={{ overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{row.job_type == "Collection" ? 'Pick Up' : 'Delivery'}</span> : '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "Project", 
        field: "is_project",
        sorting: false,
        customSort: (a,b) => (a.is_project !== null? a.is_project.toString(): '').localeCompare(b.is_project !== null? b.is_project.toString() : ''),
        hidden: this.state.hiddenColumns.includes('is_project'),
        render: (row, type) => {
          if(type === 'row'){
            return (row && row.is_project !== null) ? `${row.is_project == true ? "yes" : "No"}` : "No";
          } else {
            return row;
          }
        }
      },
      { 
        title: "Category", 
        field: "period_of_delivery",
        sorting: false,
        customSort: (a,b) => (a.period_of_delivery? a.period_of_delivery: '').localeCompare(b.period_of_delivery? b.period_of_delivery : ''),
        hidden: this.state.hiddenColumns.includes('period_of_delivery'),
        render: (row, type) => {
          if(type === 'row'){
            return (row && row.period_of_delivery !== null) ? `${row.period_of_delivery}` : "";
          } else {
            return row;
          }
        }
      },
      { 
        title: "Pick Up Ref (D.O)",
        field: "collection_reference",
        sorting: false,
        customSort: (a,b) => (a.collection_reference? a.collection_reference: '').localeCompare(b.collection_reference? b.collection_reference : ''),
        hidden: this.state.hiddenColumns.includes('collection_reference'),
        render: (row, type) => {
          if(type === 'row'){
            return (row && row.collection_reference && row.collection_reference !== '') ? <span style={{display:'inline-block'}}>{row.collection_reference}</span> : '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "Ordered By", 
        field: "order_by",
        cellStyle: {
          // minWidth: 150,
          maxWidth: 150
        },
        sorting: false,
        customSort: (a,b) => (a.uploader && a.uploader.first_name? a.uploader.first_name : '').localeCompare(b.uploader &&  b.uploader.first_name? b.uploader.first_name : ''),
        hidden: this.state.hiddenColumns.includes('order_by'),
        render: (row, type) => {
          if(type === 'row'){
            if(row.vendor_id && row.vendor_id == this.state.user.owner_info.application_company_id) {
              return (row && row.uploader && (row.uploader.application_company && row.uploader.application_company.company_name !== '')) ? <span style={{ width:'100%', overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{row.uploader.application_company.company_name}</span> : '';
            } else {
              return (row && row.uploader && (row.uploader.first_name !== '' || row.uploader.last_name !== '')) ? <span style={{ width:'100%', overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row.uploader.first_name? row.uploader.first_name: '') + ' ' + (row.uploader.last_name? row.uploader.last_name: '')}</span> : '';
            }
          } else {
            return row;
          }
        }
      },
      { 
        title: "Order Status",
        field: "order_status",
        cellStyle: {
          minWidth: 240,
          maxWidth: 240            
        },
        sorting: false,
        customSort: (a,b) =>(a.order_status.status == "Not Assigned" ? "Pending" : a.order_status.status == "Assigned Pending" ? "On Hold": a.order_status.status).toString().toLowerCase().trim().localeCompare((b.order_status.status == "Not Assigned" ? "Pending" : b.order_status.status == "Assigned Pending" ? "On Hold": b.order_status.status).toString().toLowerCase().trim()),
        hidden: this.state.hiddenColumns.includes('order_status'),
        render: (row, type) => {
          let item = [];
          if(type === 'row'){
            item = this.state.tableStatus;
            return <Box style={{zIndex:2000}}>
                  <Box>
                  {<DropdownMenu 
                    isShouldEnable={true}
                    className={'status-btn ' + apiUtil.statusToClassName((row.order_status && row.order_status.status && row.order_status.status !== '') ?  row.order_status.status == "Not Assigned" ? "Pending" :  row.order_status.status == "Assign Pending" ? "On Hold" :  row.order_status.status : '')}
                    text={(row && row.order_status && row.order_status.status) ? row.order_status.status == "Not Assigned" ? "Pending" : row.order_status.status == "Assign Pending" ? "On Hold" : row.order_status.status : ''}
                    // rightIcon={<ArrowDownIcon />}
                    // items={this.state.tableStatus}
                  />
                   }        
                </Box>
            </Box>;
        } else {
            return row;
          }
        }
      },
      { 
        title: "POD", 
        field: "POD",
        cellStyle: {
          // minWidth: 150,
          maxWidth: 150
        },
        sorting: false,
        hidden: this.state.hiddenColumns.includes('POD'),
        render: (row, type) => {
          if(type === 'row'){
            let jobStep = row.job_steps && row.job_steps[row.job_steps.length - 1]
            if(jobStep) {
            let signature_img = row.job_steps.length > 0 && jobStep.order_attempts && jobStep.order_attempts.length > 0 && jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.length > 0 ? jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.filter(dd => dd.is_signature == true) : []
            let proof_img =  row.job_steps.length > 0 && jobStep.order_attempts && jobStep.order_attempts.length > 0 && jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.length > 0 ? jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.filter(dd => dd.is_signature == false) : []
           
            return <div className='d-flex align-items-center justify-content-center'>
                    {proof_img.length ? <div className="image-preview d-flex align-items-center justify-content-between w-100">
                    <img src={proof_img[0]['image_url']} className="w-50" style={{margin: "auto"}}/>
                    <span>
                      <img src={proof_img[0]['image_url']} className="hovered-image" style={{margin: "auto"}}/>
                    </span> 
                    {jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.length > 1 ? 
                      <FontAwesomeIcon icon={faPlusCircle} className=" fs-5 svg-primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        // e.preventDefault();
                        if((this.isCollectionFailed(row) == false || (this.isCollectionFailed(row) == true && row.job_type == "Collection"))) {
                          if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')) {
                            this.setState({
                              openPreviewDialog: true,
                              openPreviewDialogItem: row.id,
                            });
                          }
                        }
                      }}/> : ''} </div> : signature_img.length ? <div className="image-preview d-flex align-items-center justify-content-between w-100">
                        <img src={signature_img[0]['image_url']} className="w-50" style={{margin: "auto"}}/>
                      <span>
                        <img src={signature_img[0]['image_url']} className="hovered-image" style={{margin: "auto"}}/>
                      </span>
                      {jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.length > 1 ? 
                        <FontAwesomeIcon icon={faPlusCircle} className=" fs-5 svg-primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          // e.preventDefault();
                          if((this.isCollectionFailed(row) == false || (this.isCollectionFailed(row) == true && row.job_type == "Collection"))) {
                            if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')) {
                              this.setState({
                                openPreviewDialog: true,
                                openPreviewDialogItem: row.id,
                              });
                            }
                          }
                        }}/> : ""}
                </div> : '' }
            </div>
            }
          } else {
            return row;
          }
        }
      },
      { 
        title: "Reason for Failure", 
        field: "reason_for_failure",
        cellStyle: {
          // minWidth: 150,
          maxWidth: 150
        },
        sorting: false,
        customSort: (a,b) => (a.job_steps.length > 0 && a.job_steps[0].order_attempts.length > 0 ? a.job_steps[0].order_attempts[0].reason: '').toString().toLowerCase().trim().localeCompare((b.job_steps.length > 0 && b.job_steps[0].order_attempts.length > 0 ? b.job_steps[0].order_attempts[0].reason : '').toString().toLowerCase().trim()),
        hidden: this.state.hiddenColumns.includes('reason_for_failure'),
        render: (row, type) => {
          if(type === 'row'){
            let jobStep = row.job_steps && row.job_steps[row.job_steps.length - 1]
            if(jobStep) {
            let failed_reason = row.job_steps.length > 0 && jobStep.order_attempts.length > 0  ? jobStep.order_attempts[jobStep.order_attempts.length - 1].reason : ''
            return row && row.order_status && row.order_status.status == "Failed" ? failed_reason : '';
            } 
          } else {
            return row;
          }
        }
      },
      ... (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Address')) ?[{ 
        title: "Delivery Address", 
        field: "delivery_address",
        cellStyle: {
          // minWidth: 190,
          maxWidth: 190
        },
        sorting: false,
        customSort: (a,b) => (a.drop_off_address? a.drop_off_address: '').localeCompare(b.drop_off_address? b.drop_off_address : ''),
        hidden: this.state.hiddenColumns.includes('delivery_address'),
        render: (row, type) => {
          if(type === 'row'){
            // return (row && row.drop_off_address && row.drop_off_address !== '') ? HtmlParser(row.drop_off_address) : '';
            return (row && row.drop_off_address && row.drop_off_address !== '') ?<span style={{ overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}> {row.drop_off_address }</span>  : '';
          } else {
            return row;
          }
        }
      }] : [],
      { 
        title: "ETA", 
        field: "arrival_time",
        cellStyle: {
          // minWidth: 100,
          maxWidth: 100
        },
        sorting: false,
        customSort: (a,b) => (a.route_entity && a.drop_off_latitude && a.drop_off_longitude ? a.route_entity.arrival: '').localeCompare(b.route_entity && b.drop_off_latitude && b.drop_off_longitude? b.route_entity.arrival : ''),
        hidden: this.state.hiddenColumns.includes('arrival_time'),
        render: (row, type) => {
          let today = moment().format('YYYY-MM-DD');
          let startDate = moment(row.drop_off_date).format('YYYY-MM-DD');
          if(type === 'row'){
            return (today == startDate && row && row.route_entity && row.route_entity.active && (row.order_status.status == "In Progress" || row.order_status.status == "Acknowledged")) && row.drop_off_latitude && row.drop_off_longitude ? moment.parseZone(row.route_entity.arrival).format('H:mm')  : '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "Postal Code", 
        field: "postal_code",
        cellStyle: {
          // minWidth: 100,
          maxWidth: 100
        },
        sorting: false,
        customSort: (a,b) => Number(a.drop_off_postal_code ? a.drop_off_postal_code: '') - Number(b.drop_off_postal_code ? b.drop_off_postal_code: ''),
        hidden: this.state.hiddenColumns.includes('postal_code'),
        render: (row, type) => {
          if(type === 'row'){
            return (row && row.drop_off_postal_code && row.drop_off_postal_code !== '') ? HtmlParser(row.drop_off_postal_code) : '';
          } else {
            return row;
          }
        }
      },
      ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Delivery Time')) ?[{ 
        title: "Between", 
        field: "time_slot",
        cellStyle: {
          // minWidth: 100,
          maxWidth: 100
        },
        sorting: false,
        customSort: (a,b) => (a.drop_off_time_planned && a.drop_off_time_planned !== '' ? a.drop_off_time_planned.split("-")[0]: '').localeCompare(b.drop_off_time_planned && b.drop_off_time_planned !== '' ? b.drop_off_time_planned.split("-")[0]: ''),
        hidden: this.state.hiddenColumns.includes('time_slot'),
        render: (row, type) => {
          if(type === 'row'){
            let drop_from_time = row && row.drop_off_time_planned && row.drop_off_time_planned !== '' ? row.drop_off_time_planned.split("-") : [];
            return drop_from_time.length > 0 && row.drop_off_time_planned != '9:00 - 18:00' ? drop_from_time[0] : '';
          } else {
            return row;
          }
        }
      }, 
      { 
        title: "And", 
        field: "time_slot",
        cellStyle: {
          // minWidth: 100,
          maxWidth: 100
        },
        sorting: false,
        customSort: (a,b) => (a.drop_off_time_planned && a.drop_off_time_planned !== '' ? a.drop_off_time_planned.split("-")[1]: '').localeCompare(b.drop_off_time_planned && b.drop_off_time_planned !== '' ? b.drop_off_time_planned.split("-")[1]: ''),
        hidden: this.state.hiddenColumns.includes('time_slot'),
        render: (row, type) => {
          if(type === 'row'){
            let drop_from_time = row && row.drop_off_time_planned && row.drop_off_time_planned !== '' ? row.drop_off_time_planned.split("-") : [];
            return drop_from_time.length > 0 && row.drop_off_time_planned != '9:00 - 18:00' ? drop_from_time[1] : '';
          } else {
            return row;
          }
        }
      }] : [],
      ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver') ||
          this.state.currentRole == 'Ops Admin' ||
          this.state.currentRole == 'Finance Admin' ||
          this.state.currentRole == 'BD Admin') ? [{
        title: "Driver-Partner", 
        field: "driver_partner",
        cellStyle: {
          // minWidth: 150,
          maxWidth: 150
        },
        sorting: false,
        customSort: (a,b) => (a.drop_off_worker ? a.drop_off_worker.first_name : '').toString().toLowerCase().trim().localeCompare((b.drop_off_worker ? b.drop_off_worker.first_name : '').toString().toLowerCase().trim()),
        hidden: this.state.hiddenColumns.includes('driver_partner'),
        render: (row, type) => {
          if(type === 'row'){
            if(row.drop_off_worker_id && row.drop_off_worker_id > 0 && row.drop_off_worker){
              
              row.workerName = row.drop_off_worker.first_name +" "+ row.drop_off_worker.last_name;
            }
            if(row.vendor_id && row.vendor_id != null){
              row.vendorName = ( row.vendor && row.vendor.company_name != null ? row.vendor.company_name : "") ;
            }
            return row.vendor_id ? row.vendor_id != this.state.user.owner_info.application_company_id ? row.vendorName : row.workerName : row.workerName  
          
          } else {
            return row;
          }
        }
      }] : [],
      /*...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Man Power') ||
          this.state.currentRole == 'Ops Admin' ||
          this.state.currentRole == 'Finance Admin' ||
          this.state.currentRole == 'BD Admin') ? [{ 
        title: "Driver-Assistant", 
        field: "extra_workers[0].first_name",
        cellStyle: {
          // minWidth: 150,
          maxWidth: 150
        },
        customSort: (a,b) => (a.extra_workers && a.extra_workers.length > 0 && a.extra_workers[0].first_name ? a.extra_workers[0].first_name : '').toString().toLowerCase().trim().localeCompare((b.extra_workers && b.extra_workers.length > 0 && b.extra_workers[0].first_name ? b.extra_workers[0].first_name  : '').toString().toLowerCase().trim()),
        hidden: this.state.hiddenColumns.includes('driver_partner'),
        render: (row, type) => {
          if(type === 'row'){
            let label = '';
            let manpowerName = (row.extra_workers && row.extra_workers.length > 0) ? row.extra_workers[0] : null;

            if(manpowerName){
              label = ((manpowerName.first_name && manpowerName.first_name !== '') ? manpowerName.first_name : '') + ' ' + ((manpowerName.last_name && manpowerName.last_name !== '') ? manpowerName.last_name : '');
            }

            row.manpowerName = label;
            if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Man Power') ||
                this.state.currentRole == 'Ops Admin' ) {
            return <Box>
              <CustomAutosuggest 
                disabled={(row.order_status && row.order_status.status == "Completed"  || row.order_status && row.order_status.status == "Failed") ? true : ((this.isCollectionFailed(row) == false || (this.isCollectionFailed(row) == true && row.job_type == "Collection")) && (row.drop_off_worker_id || row.vendor_id)? ((row.vendor_id != null && row.vendor_id != this.state.user.owner_info.application_company_id)? true : ((row.vendor_id != null && row.vendor_id == this.state.user.owner_info.application_company_id) && !row.drop_off_worker_id? true : false)) : true)}
                className={'no-margin'}
                placeholder={'Assign Manpower'}
                value={row.manpowerName}
                items={this.state.manPowerItems}
                onChange={(value) => {
                  if(value){
                    row.manpowerName = value.label;
                    row.manpowerId = value.value;
                    row.manpowerItem = value.item;
                  } else {
                    row.manpowerName = '';
                    row.manpowerId = null;
                    row.manpowerItem = null;
                  }

                  if (row.job_steps === undefined) {
                    row.job_steps = [];
                  }

                  let data = {
                    data: [
                      {
                        id: [ row.id ],
                        job_steps: row.job_steps.length > 0 ? row.job_steps.map(item=> {
                          item.job_step_id = item.id
                          return item
                        }): row.job_steps,
                        order_status_id: row.order_status_id,
                        send_notification_to_customer: true,
                        extra_worker_ids: (row.manpowerId) ? row.manpowerId.toString() : null,
                      }
                    ]
                  };
                  this.callUpdateStatusApi(data, (newRow) => {
                    if(newRow){
                      let newData = apiUtil.updateRow(fromJS(this.state.data).toJS(), newRow, 'id');
                      this.setState({
                        data: newData,
                      });
                      this.callReadApi();
                    }
                  });
                }}
                onSearch={(value, e) => {
                  row.manpowerName = value;
                  let searchItems = [];
                  e.state.items.find( obj => {
                    let name = obj.label != null ? obj.label : '';
                    if(name.includes(value)) {
                      searchItems.push(obj)
                    }
                  });
                  searchItems = searchItems.length > 0 && value != '' ? searchItems : this.state.manPowerItems;
                  e.showNewSuggestions(searchItems);
                }}
                onClick={(value, e) => {
                  e.showAllSuggestions();
                }}
              />
            </Box>;
            } else {
              return row.manpowerName;
            }
          } else {
            return row;
          }
        }
      }] : [],*/
      ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Company')) ? [{ 
        title: "Client Company", 
        field: "recipent_company",
        cellStyle: {
          // minWidth: 130,
          maxWidth: 130,
       },
        hidden: this.state.hiddenColumns.includes('recipent_company'),
        sorting: false,
        customSort: (a,b) => (a.customer ? a.customer.company_name : '').toString().toLowerCase().trim().localeCompare((b.customer ? b.customer.company_name : '').toString().toLowerCase().trim()),
        render: (row, type) => {
          if(type === 'row'){
            return <span style={{ overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row && row.customer && row.customer.company_name !== '') ? HtmlParser(row.customer.company_name) : ''} </span>;
            // return (row && row.drop_off_description && row.drop_off_description !== '') ?<span style={{ width:350, overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}> {row.drop_off_description }</span>  : '';
          } else {
            return row;
          }
        }
      }] : [],
      { 
        title: "Department", 
        field: "department",
        cellStyle: {
          // minWidth: 130,
          maxWidth: 130,
       },
       sorting: false,
       customSort: (a,b) => (a.department && a.department.name? a.department.name: '').localeCompare(b.department && b.department.name? b.department.name : ''),
        hidden: this.state.hiddenColumns.includes('department'),
        render: (row, type) => {
          if(type === 'row'){
            return <span style={{ overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row && row.department && row.department.name ) ? HtmlParser(row.department.name) : ''}</span>;
          } else {
            return row;
          }
        }
      },
      { 
        title: "Recipient's Name", 
        field: "recipient_name",
        cellStyle: {
          // minWidth: 130,
          maxWidth: 130,
       },
       sorting: false,
       customSort: (a,b) => (a.drop_off_contact_name? a.drop_off_contact_name: '').localeCompare(b.drop_off_contact_name ? b.drop_off_contact_name : ''),
        hidden: this.state.hiddenColumns.includes('recipient_name'),
        render: (row, type) => {
          if(type === 'row'){
            return <span style={{ overflowWrap: 'break-word', wordBreak: 'break-all',  display:'inline-block'}}>{(row && row.drop_off_contact_name ) ? HtmlParser(row.drop_off_contact_name) : ''}</span>;
          } else {
            return row;
          }
        }
      },
      { 
        title: "Company", 
        field: "company",
        cellStyle: {
          // minWidth: 130,
          maxWidth: 130,
       },
       sorting: false,
       customSort: (a,b) => (a.drop_off_name? a.drop_off_name: '').localeCompare(b.drop_off_name ? b.drop_off_name : ''),
        hidden: this.state.hiddenColumns.includes('company'),
        render: (row, type) => {
          if(type === 'row'){
            return <span style={{ overflowWrap: 'break-word', wordBreak: 'break-all',  display:'inline-block'}}>{(row && row.drop_off_name ) ? HtmlParser(row.drop_off_name) : ''}</span>;
          } else {
            return row;
          }
        }
      },
      { 
        title: "Contact No.", 
        field: "recipient_contact",
        cellStyle: {
          // minWidth: 130,
          maxWidth: 130,
       },
       sorting: false,
       customSort: (a,b) => (a.drop_off_contact_no? a.drop_off_contact_no: '').localeCompare(b.drop_off_contact_no ? b.drop_off_contact_no : ''),
        hidden: this.state.hiddenColumns.includes('recipient_contact'),
        render: (row, type) => {
          if(type === 'row'){
            return <span style={{ overflowWrap: 'break-word', wordBreak: 'break-all',  display:'inline-block'}}>{(row && row.drop_off_contact_no) ? HtmlParser(row.drop_off_contact_no) : ''} </span>;
          } else {
            return row;
          }
        }
      },
      { 
        title: "Alternative Contact", 
        field: "alternate_contact",
        cellStyle: {
          // minWidth: 130,
          maxWidth: 130,
       },
       sorting: false,
       customSort: (a,b) => (a.drop_off_alternate_contact? a.drop_off_alternate_contact: '').localeCompare(b.drop_off_alternate_contact ? b.drop_off_alternate_contact : ''),
        hidden: this.state.hiddenColumns.includes('alternate_contact'),
        render: (row, type) => {
          if(type === 'row'){
            return <span style={{ overflowWrap: 'break-word', wordBreak: 'break-all',  display:'inline-block'}}>{(row && row.drop_off_alternate_contact) ? HtmlParser(row.drop_off_alternate_contact) : ''} </span>;
          } else {
            return row;
          }
        }
      },
      { 
        title: "Recipient Email", 
        field: "recipient_email",
        sorting: false,
        customSort: (a,b) => (a.notification_email? a.notification_email: '').localeCompare(b.notification_email ? b.notification_email : ''),
        hidden: this.state.hiddenColumns.includes('recipient_email'),
        cellStyle: {
          // minWidth: 120,
          maxWidth: 120,
       },
        render: (row, type) => {
          if(type === 'row'){
            return <span style={{ overflowWrap: 'break-word', wordBreak: 'break-all',  display:'inline-block'}}>{(row && row.notification_email ) ? HtmlParser(row.notification_email) : ''}</span>;
          } else {
            return row;
          }
        }
      },
      ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Description')) ?[{ 
        title: "Item Description", 
        field: "item_description",
        sorting: false,
        customSort: (a,b) => (a.drop_off_description? a.drop_off_description: '').localeCompare(b.drop_off_description ? b.drop_off_description : ''),
        cellStyle: {
          // minWidth: 190,
          maxWidth: 190
        },
        hidden: this.state.hiddenColumns.includes('item_description'),
        render: (row, type) => {
          if(type === 'row'){
            return <span className='none-backgroud-color' style={{ overflowWrap: 'break-word', wordBreak: 'break-all',  display:'inline-block'}}>{(row && row.drop_off_description && row.drop_off_description !== '') ? HtmlParser(row.drop_off_description) : ''}</span>;
            // return (row && row.drop_off_description && row.drop_off_description !== '') ?<span style={{ width:350, overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}> {row.drop_off_description }</span>  : '';
          } else {
            return row;
          }
        }
      }] : [],
      { 
        title: "XS", 
        field: "xs_packages",
        sorting: false,
        customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 1).length > 0 ? a.order_packages.filter(dd => dd.package_id == 1)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 1).length > 0 ? b.order_packages.filter(dd => dd.package_id == 1)[0].package_quantity: ''),
        hidden: this.state.hiddenColumns.includes('xs_packages'),
        render: (row, type) => {
          if(type === 'row'){
            let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 1) : []
            return (pack.length > 0) ? pack[0].package_quantity: '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "S", 
        field: "s_packages",
        sorting: false,
        customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 2).length > 0 ? a.order_packages.filter(dd => dd.package_id == 2)[0].package_quantity: '') - (b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 2).length > 0 ? b.order_packages.filter(dd => dd.package_id == 2)[0].package_quantity: ''),
        hidden: this.state.hiddenColumns.includes('s_packages'),
        render: (row, type) => {
          if(type === 'row'){
            let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 2) : []
            return (pack.length > 0) ? pack[0].package_quantity: '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "M", 
        field: "m_packages",
        sorting: false,
        customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 3).length > 0 ? a.order_packages.filter(dd => dd.package_id == 3)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 3).length > 0 ? b.order_packages.filter(dd => dd.package_id == 3)[0].package_quantity: ''),
        hidden: this.state.hiddenColumns.includes('m_packages'),
        render: (row, type) => {
          if(type === 'row'){
            let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 3) : []
            return (pack.length > 0) ? pack[0].package_quantity: '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "L", 
        field: "l_packages",
        sorting: false,
        customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 4).length > 0 ? a.order_packages.filter(dd => dd.package_id == 4)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 4).length > 0 ? b.order_packages.filter(dd => dd.package_id == 4)[0].package_quantity: ''),
        hidden: this.state.hiddenColumns.includes('l_packages'),
        render: (row, type) => {
          if(type === 'row'){
            let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 4) : []
            return (pack.length > 0) ? pack[0].package_quantity: '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "Oversized", 
        field: "oversized_packages",
        sorting: false,
        customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 6).length > 0 ? a.order_packages.filter(dd => dd.package_id == 6)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 6).length > 0 ? b.order_packages.filter(dd => dd.package_id == 6)[0].package_quantity: ''),
        hidden: this.state.hiddenColumns.includes('oversized_packages'),
        render: (row, type) => {
          if(type === 'row'){
            let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 6) : []
            return (pack.length > 0) ? pack[0].package_quantity: '';
          } else {
            return row;
          }
        }
      },
      // { 
      //   title: "Document", 
      //   field: "document",
      //   cellStyle: {
      //     // minWidth: 150,
      //     maxWidth: 150
      //   },
      //   customSort: (a,b) => Number(a.extra_data && JSON.parse(a.extra_data).document && !isNaN(JSON.parse(a.extra_data).document) ? JSON.parse(a.extra_data).document : '') - Number(b.extra_data && JSON.parse(b.extra_data).document && !isNaN(JSON.parse(b.extra_data).document) ? JSON.parse(b.extra_data).document : ''),
      //   hidden: this.state.hiddenColumns.includes('document'),
      //   render: (row, type) => {
      //     if(type === 'row'){
      //       let extra_data = JSON.parse(row.extra_data)
      //       return (extra_data && extra_data.document) ? extra_data.document : '';
      //     } else {
      //       return row;
      //     }
      //   }
      // },
      // { 
      //   title: "Boxes", 
      //   field: "boxes",
      //   cellStyle: {
      //     // minWidth: 150,
      //     maxWidth: 150
      //   },
      //   customSort: (a,b) => Number(a.extra_data && JSON.parse(a.extra_data).boxes && !isNaN(JSON.parse(a.extra_data).boxes) ? JSON.parse(a.extra_data).boxes : '') - Number(b.extra_data && JSON.parse(b.extra_data).boxes && !isNaN(JSON.parse(b.extra_data).boxes) ? JSON.parse(b.extra_data).boxes : ''),
      //   hidden: this.state.hiddenColumns.includes('boxes'),
      //   render: (row, type) => {
      //     if(type === 'row'){
      //       let extra_data = JSON.parse(row.extra_data)
      //       return (extra_data && extra_data.boxes) ? extra_data.boxes : '';
      //     } else {
      //       return row;
      //     }
      //   }
      // },
      // { 
      //   title: "Cartons", 
      //   field: "cartons",
      //   cellStyle: {
      //     // minWidth: 150,
      //     maxWidth: 150
      //   },
      //   customSort: (a,b) => Number(a.extra_data && JSON.parse(a.extra_data).cartons && !isNaN(JSON.parse(a.extra_data).cartons) ? JSON.parse(a.extra_data).cartons : '') - Number(b.extra_data && JSON.parse(b.extra_data).cartons && !isNaN(JSON.parse(b.extra_data).cartons) ? JSON.parse(b.extra_data).cartons : ''),
      //   hidden: this.state.hiddenColumns.includes('cartons'),
      //   render: (row, type) => {
      //     if(type === 'row'){
      //       let extra_data = JSON.parse(row.extra_data)
      //       return (extra_data && extra_data.cartons) ? extra_data.cartons : '';
      //     } else {
      //       return row;
      //     }
      //   }
      // },
      { 
        title: "Pallets", 
        field: "pallets",
        sorting: false,
        customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 5).length > 0 ? a.order_packages.filter(dd => dd.package_id == 5)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 5).length > 0 ? b.order_packages.filter(dd => dd.package_id == 5)[0].package_quantity: ''),
        hidden: this.state.hiddenColumns.includes('pallets'),
        render: (row, type) => {
          if(type === 'row'){
            let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 5) : []
            return (pack.length > 0) ? pack[0].package_quantity: '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "Depalletization Request", 
        field: "depalletization_request",
        sorting: false,
        customSort: (a,b) => (a.package_info && JSON.parse(a.package_info).depalletization_request ? JSON.parse(a.package_info).depalletization_request : '').toString().toLowerCase().trim().localeCompare((b.package_info && JSON.parse(b.package_info).depalletization_request ? JSON.parse(b.package_info).depalletization_request : '').toString().toLowerCase().trim()),
        hidden: this.state.hiddenColumns.includes('depalletization_request'),
        render: (row, type) => {
          if(type === 'row'){
            let package_info = row && row.package_info && JSON.parse(row.package_info)
            return (package_info && package_info.depalletization_request) ? package_info.depalletization_request  : '';
           } else {
            return row;
          }
        }
      },
      { 
        title: "Size in cm(L + W + H)", 
        field: "size_lwh",
        sorting: false,
        customSort: (a,b) => Number(a.package_info &&  JSON.parse(a.package_info).size_l_w_h  && !isNaN(JSON.parse(a.package_info).size_l_w_h) ? JSON.parse(a.package_info).size_l_w_h : '') - Number(b.package_info && JSON.parse(b.package_info).size_l_w_h && !isNaN(JSON.parse(b.package_info).size_l_w_h)? JSON.parse(b.package_info).size_l_w_h : ''),
        hidden: this.state.hiddenColumns.includes('size_lwh'),
        render: (row, type) => {
          if(type === 'row'){
            let package_info = row && row.package_info && JSON.parse(row.package_info)
            return (package_info && package_info.size_l_w_h) ? package_info.size_l_w_h : '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "Weight (KG)", 
        field: "weight",
        sorting: false,
        customSort: (a,b) => Number(a.package_info &&  JSON.parse(a.package_info).weight  && !isNaN(JSON.parse(a.package_info).weight) ? JSON.parse(a.package_info).weight : '') - Number(b.package_info && JSON.parse(b.package_info).weight && !isNaN(JSON.parse(b.package_info).weight)? JSON.parse(b.package_info).weight : ''),
        hidden: this.state.hiddenColumns.includes('weight'),
        render: (row, type) => {
          if(type === 'row'){
            let package_info = row && row.package_info &&  JSON.parse(row.package_info)
            return (package_info && package_info.weight) ? package_info.weight : '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "Waiting Time", 
        field: "waiting_time",
        sorting: false,
        customSort: (a,b) => (a.package_info && JSON.parse(a.package_info).waiting_time ? JSON.parse(a.package_info).waiting_time : '').toString().toLowerCase().trim().localeCompare((b.package_info && JSON.parse(b.package_info).waiting_time ? JSON.parse(b.package_info).waiting_time : '').toString().toLowerCase().trim()),
        hidden: this.state.hiddenColumns.includes('waiting_time'),
        render: (row, type) => {
          if(type === 'row'){
            let package_info = row && row.package_info && JSON.parse(row.package_info)
            return (package_info && package_info.waiting_time) ? package_info.waiting_time : '';
           } else {
            return row;
          }
        }
      },
      { 
        title: "Additional Manpower", 
        field: "no_of_movers",
        sorting: false,
        customSort: (a,b) => Number(a.package_info &&  JSON.parse(a.package_info).no_of_movers  && !isNaN(JSON.parse(a.package_info).no_of_movers) ? JSON.parse(a.package_info).no_of_movers : '') - Number(b.package_info && JSON.parse(b.package_info).no_of_movers && !isNaN(JSON.parse(b.package_info).no_of_movers)? JSON.parse(b.package_info).no_of_movers : ''),
        hidden: this.state.hiddenColumns.includes('no_of_movers'),
        render: (row, type) => {
          if(type === 'row'){
            let package_info = row && row.package_info && JSON.parse(row.package_info)
            return (package_info && package_info.no_of_movers) ? package_info.no_of_movers : '';
          } else {
            return row;
          }
        }
      },
      { 
        title: "Price", 
        field: "price",
        cellStyle: {
          // minWidth: 100,
          maxWidth: 100
        },
        sorting: false,
        customSort: (a,b) => Number(a.amount ? a.amount : '') - Number(b.amount ? b.amount : ''),
        hidden: this.state.hiddenColumns.includes('price'),
        render: (row, type) => {
          if(type === 'row'){
            if ( row.vendor_id == this.state.user.owner_info.application_company_id) {
              return (row && row.vendor_payout && row.vendor_payout !== '') ? parseFloat(row.vendor_payout).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
            } 
            return (row && row.amount && row.amount !== '') ? parseFloat(row.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
            
          } else {
            return row;
          }
        }
      },
      { 
        title: "Vendor Payout", 
        field: "vendor_payout",
        sorting: false,
        customSort: (a,b) => Number(a.vendor_payout ? a.vendor_payout : '') - Number(b.vendor_payout ? b.vendor_payout : ''),
        hidden: this.state.hiddenColumns.includes('vendor_payout'),
        render: (row, type) => {
          if(type === 'row'){
            if ( row.vendor_id == this.state.user.owner_info.application_company_id || !row.vendor_id) {
                return '-'
            } 
            return (row && row.vendor_payout && row.vendor_payout !== '') ? parseFloat(row.vendor_payout).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';

          } else {
            return row;
          }
        }
      },
      ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver Payout')) || (this.state.currentRole == "BD Admin") || (this.state.currentRole == "Finance Admin")  ?[{ 
        title: "Driver Payout", 
        field: "driver_payout",
        hidden: this.state.hiddenColumns.includes('driver_payout'),
        sorting: false,
        customSort: (a,b) => Number(a.driver_payout ? a.driver_payout : '') - Number(b.driver_payout ? b.driver_payout : ''),
        render: (row, type) => {
          if(type === 'row'){
            if((row.vendor_id && row.vendor_id != this.state.user.owner_info.application_company_id) || !row.drop_off_worker_id ) {
              return '-'
            }
            return (row && row.driver_payout && row.driver_payout !== '') ? parseFloat(row.driver_payout).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
          } else {
            return row;
          }
        }
      }] : [],
      { 
        title: "Notes to Driver-Partner", 
        field: "notes_to_driver",
         cellStyle: {
                minWidth: 180,
                maxWidth: 180
          },
        sorting: false,
        hidden: this.state.hiddenColumns.includes('notes_to_driver'),
        render: (row, type) => {
          if(type === 'row'){
            return (row && row.driver_notes && row.driver_notes !== '') ? HtmlParser(row.driver_notes) : '';
           } else {
            return row;
          }
        }
      }
    ];

    if(isSort) {
      const jobColumnOrder = JSON.parse(localStorage.getItem('job_column_order'));
      const columnOrder = jobColumnOrder ? JSON.parse(jobColumnOrder.json_data) : []
      if(columnOrder.length > 0) {
        let columnSort = [];
        columnOrder.map((val,index) => {
          if(columns[val]){
            columnSort.push(columns[val])  
          }          
        });
        return columnSort;
      }
    }
    return columns; 
    
  }
  
  /* TABLE */
  customTable = () => {
    const { isCustomerRequired } = this.context;
    if(!this.state.isLoading) {

      return <Table 
      ref={this.refTable}
      className={'job-table-list' }  
      isDraggable={false}
      isLoading={this.state.isLoading}
      grouping={false}
      limit={this.limits}
      page={this.pages}
      isCollectionFailed={this.isCollectionFailed}
      total={this.state.total}
      data={this.state.data}
      defaultExpanded={true}
      selection={true}
      onColumnDragged={this.onColumnDragged}
      handleRowClick={(e, row)=> {
        if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')) {
          this.setState({
            openPreviewDialog: true,
            openPreviewDialogItem: row.id,
          });
        }
      }}
      onPageChange={(page,limit) => {
          
            this.pages  = page;
            this.limits =  limit;
            this.callReadApi();
          // });
      }}
      onSelectionChange={(rows, column)=>{
        this.rowData = rows;
      }}
      columns={this.getColumns()}
    />

    }else {
      return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
        <CircularProgress size={35} />
      </Box>
    }

    
  }

  handleTabChange = (event, newValue) => {
    this.setState({
        tabValue: newValue
    })
  }
  

  /* DIALOG */
  customDialog = () => {
    return <CustomDialog 
      open={this.state.openDialog}
      title={'Delete'}
      onClose={() => {
        this.setState({
          openDialog: false,
          openDialogItem: null,
        });
      }}
      onOk={() => {
        let row = this.state.openDialogItem;
        this.callDeleteApi(row, () => {
          this.setState({
            openDialog: false,
            openDialogItem: null,
          }, () => {
            this.loadStatusApi();
            this.callReadApi(this.refTableSearch.current.state.value);
            apiUtil.toast('Successfully Deleted', 'check_circle');
          });
        });
      }}
    >
      <Box>Are you sure you want to delete?</Box>
    </CustomDialog>
  }

 
  Updatedtoast = () => {
    apiUtil.toast('Successfully Updated', 'check_circle');
  }

 

  previewDialog = () => {
    if(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')){
    return <CustomDialog2 
      open={this.state.openPreviewDialog}
      title={'Order Details'}
      maxWidth={'lg'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openPreviewDialog: false,
          openPreviewDialogItem: null,
        });
      }}
      btnOkText={"Save Changes"}
    >
      <JobPreviewDialog
        id={this.state.openPreviewDialogItem}
        image={this.state.company_logo}
        onClose={() => {
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
        }}
        onSave={(data) => {
          if(data) {
            apiUtil.toast('Successfully Updated', 'check_circle');
            this.callDetailApi(this.state.id, (orders) => {
              if (orders.length > 0) {
                let order_ids = ''
                if (orders.length > 0) {
                  orders.map((item, i) => { order_ids += item.id +','; });
                }
                order_ids = order_ids.slice(0, -1);
                this.setState({
                  data: orders,
                  order_ids,
                })
              }
            })
          }
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
        }}
      />
      <OrderDetailForm 
        orderId={this.state.openPreviewDialogItem}
        image={this.state.company_logo}
        onSave={() => {
          this.callReadApi();
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
        }}
        onClose={()=>{
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });        
        }}
    /> 
    </CustomDialog2>
    }
  }



  /*END AUTO ASSIGN*/

  /* PDF GENERATE */ 

  generatePDF = () => {
    const { accessToken } = this.context;
    let selectedRow = this.rowData;
    let ids = '';
    if(selectedRow != null && selectedRow != '') {
      selectedRow.map((item, i) => { ids += item.id +','; });
    } 
    ids = ids.slice(0, -1);
    
    if(ids != "") {
      window.open(REACT_APP_LDS_API_URL + 'multiple_generate_label.pdf?ids=' + ids,'_blank');    
    } else {
      apiUtil.toast("Please select data for generation", "check_circle")
    }

  } 

  generatePOD = ( ) => {

    let selectedRow = this.rowData;
    let ids = '';
    if(selectedRow != null && selectedRow != '') {
      selectedRow.map((item, i) => { ids += item.id +','; });
    } else {
      // this.state.data.map((item, i) => { ids += item.id +','; });
    }
    ids = ids.slice(0, -1);

    if(!ids) {
      apiUtil.toast("please select data ", "check_circle", "error")
      return
    }
    
    window.open(REACT_APP_LDS_API_URL + '/multiple_generate_pod.pdf?ids=' + ids + '&application_company_id=' + this.state.user.owner_info.application_company_id,'_blank');
  }
  
    /*END PDF GENERATE */


  handleChange = (val) => this.setState({value: val});
  changeTableLimit = (e) => {
    this.limits = e.target.value;
    e.key === 'Enter' && this.callReadApi();
  }
  customSearch = () => {
    return <div>
    <Row className="pt-4 px-4 mar-1">
      <Col xs={8} md={6} className="m-0 p-0">
          <ToggleButtonGroup type="radio" id="toggle-border-radius" name="search-btn" value={this.state.value} onChange={this.handleChange}>
              <ToggleButton id="tbg-radio-1" value={1} className={this.state.value == 1 ? "text-dark border-0 px-3 py-3 border-end active-toggle-btn" : "text-dark border-0 px-3 py-3 border-end"}>
                  Search
              </ToggleButton>
              <ToggleButton id="tbg-radio-2" value={2} className={this.state.value == 2 ? "text-dark border-0 px-3 py-3 active-toggle-btn" : "text-dark border-0 px-3 py-3"}>
                  Advanced Search
              </ToggleButton>
          </ToggleButtonGroup>
          {(this.state.search_text || this.state.search_delivery_number || this.state.search_do_number || this.state.search_worker_id.length > 0 || this.state.search_vendor_id || this.state.search_time_slot || this.state.search_status_id.length > 0 || this.state.search_delivery_type || this.state.search_recipient_name || this.state.search_recipient_email || this.state.search_recipient_contact || this.state.search_item_desc || this.state.search_delivery_address || this.state.search_postal_code || this.state.search_group_id || this.state.search_uploader.length > 0 || this.state.search_from_time || this.state.search_to_time) && <IconButton
          onClick={e => {
            this.setState({
              search_text: '',
              search_delivery_number: '',
              search_do_number: '',
              search_worker_id: [],
              search_time_slot: '',
              search_delivery_type: '',
              search_recipient_name: '',
              search_recipient_contact: '',
              search_recipient_email: '',
              search_item_desc: '',
              search_delivery_address: '',
              search_vendor_id: '',
              search_postal_code: '',
              search_group_id: '',
              search_status_id: [],
              order_status_list: this.state.old_order_status_list,
              search_uploader: [],
              search_from_time: '',
              search_to_time: ''
            }, () =>{
              this.callReadApi()
            })
            
          }}
          ><span  data-tooltip="clear search" data-tooltip-conf="danger"><img src={Filterimg} width="25px" /></span></IconButton> }
      </Col>
  </Row>
  <Row className="px-4">
  <Col>
  {this.state.value == 1 ?
          <Card className="overflow-hidden rounded-0 border-0  box-shadow-search">
          <div className="row border-0 d-flex justify-content-start align-items-center">
              <div className="col-xs-12 col-md-8">
              <input placeholder="Enter Job Number, Company Name, etc" className="border-0 border-bottom p-2 m-4 max-height-input"
              value={this.state.search_text}
              onChange={e => {
                this.setState({
                  search_text:e.target.value
                })
              }}
               />
              <Button className="col-6 col-xs-12 col-md-3 upload-style max-height-btn my-4"
              onClick={e =>{
                 this.pages  = 1;
                 this.setState({
                   is_search: true    
                 },() => {
                   this.callReadApi()                         
                 });
                 
              }}
              ><FontAwesomeIcon icon={faMagnifyingGlass} /> Search</Button>
              </div>
              <div className="col-xs-6 col-md-4 d-flex justify-content-end align-items-center mb-4 mt-2">
                <span className="px-2">Show</span>
                <Form.Select aria-label="Default select example" defaultValue={this.limits} className="form-control max-width-select" onChange={(e)=> {  this.changeTableLimit(e);}}>
                    <option value="50">50</option>
                    <option value="100">100</option>
              </Form.Select>
                 <span className="px-2">entries</span>
              
              </div>
          </div>
      </Card> 
          : 
          <Card className="overflow-hidden rounded-0 border-0 box-shadow-search">
              <Row className="px-5">
                  <Col xs={12} md={6} className="py-4">
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Tracker Number
                      </Col>
                      <Col xs={12} md={8}> 
                          <input type="text" className="form-control search-input-style" 
                          value={this.state.search_delivery_number}
                          onChange={e=>{
                            this.setState({
                              search_delivery_number: e.target.value,
                              is_search: false,
                            })
                          }}
                          />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          D.O Number
                      </Col>
                      <Col xs={12} md={8}> 
                          <input type="text" className="form-control search-input-style" 
                          value={this.state.search_do_number}
                          onChange={e=>{
                            this.setState({
                              is_search: false,
                              search_do_number: e.target.value
                            })
                          }}
                          />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Driver Partner
                      </Col>
                      <Col xs={12} md={8}> 
                      <ReactSelect 
                        isMulti
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            height: '150px', // Set the desired height for the dropdown box
                            backgroundColor: 'white', // Set a solid background color
                            zIndex: 1000, // Ensure it appears above other elements
                          }),
                          menuList: (provided) => ({
                            ...provided,
                            maxHeight: '150px', // Set the maximum height for the list of options
                            overflowY: 'auto',  // Enable scrolling if the content exceeds the maximum height
                            backgroundColor: 'white', // Set a solid background color
                            zIndex: 1000, // Ensure it appears above other elements
                          }),
                        }}
                        options={this.state.workers}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        value={this.state.search_worker_id}
                        onChange={(e,opt) => {
                          let check_driver = []
                          if(opt.action == 'select-option') {
                            check_driver = this.state.workers.filter((obj) => !e.filter((sub) => sub.value == obj.value).length > 0);
                            this.setState({
                              is_search: false,
                              search_worker_id: e
                            })
                          } else if(opt.action == 'remove-value') {
                            check_driver = this.state.oldWorkers.filter((obj) => !e.filter((sub) => sub.value == obj.value).length > 0);

                            this.setState({
                              search_worker_id: e,
                              workers: check_driver,    
                              is_search: true                              
                            },() => {
                              this.callReadApi();   
                            });
                          } else if(opt.action == 'clear') {
                            this.setState({
                              search_worker_id: [],
                              workerItems: this.state.oldWorkers,
                              is_search: true
                            },() => {
                              this.callReadApi();   
                            });                          
                          }
                          
                        }}
                      />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Order Status
                      </Col>
                      <Col xs={12} md={8}> 
                        <ReactSelect 
                          isMulti
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              height: '150px', // Set the desired height for the dropdown box
                            }),
                            menuList: (provided) => ({
                              ...provided,
                              maxHeight: '150px', // Set the maximum height for the list of options
                              overflowY: 'auto',  // Enable scrolling if the content exceeds the maximum height
                            }),
                          }}
                          options={this.state.order_status_list}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={this.state.search_status_id}
                          onChange={(e,opt) => {
                            let order_status = []
                            if(opt.action == 'select-option') {
                              order_status = this.state.order_status_list.filter((obj) => !e.filter((sub) => sub.value == obj.value).length > 0);
                              this.setState({
                                search_status_id: e,
                                order_status_list: order_status
                              });                          
                            } else if(opt.action == 'remove-value') {
                              order_status = this.state.old_order_status_list.filter((obj) => !e.filter((sub) => sub.value == obj.value).length > 0);
                              this.pages = 1;
                              this.setState({
                                search_status_id: e,
                                order_status_list: order_status,
                                is_search: true                                
                              },() => {
                                this.callReadApi();   
                              });
                            } else if(opt.action == 'clear') {
                              this.setState({
                                search_status_id: [],
                                order_status_list: this.state.old_order_status_list,
                                is_search: true
                              });                          
                            }
                          }}
                        />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                      Job Type
                      </Col>
                      <Col xs={12} md={8}> 
                      <select class="form-select search-input-style" aria-label="Default select example"
                      value={this.state.search_delivery_type}
                      onChange={e=>{
                        this.setState({
                          is_search: false,
                          search_delivery_type: e.target.value
                        })
                      }}
                      >
                          <option value="">Select Job Type</option>
                          <option value={'Collection'}>Pick Up </option>
                          <option value={'Delivery'}>Delivery</option>
                      </select>
                      </Col>
                  </Row>
                  </Col>
                  <Col xs={12} md={6} className="py-4">
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Recipient's Name
                      </Col>
                      <Col xs={12} md={8}> 
                          <input type="text" className="form-control search-input-style" 
                          value={this.state.search_recipient_name}
                          onChange={e=>{
                            this.setState({
                              is_search: false,
                              search_recipient_name: e.target.value
                            })
                          }}
                          />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Contact No
                      </Col>
                      <Col xs={12} md={8}> 
                          <input type="text" className="form-control search-input-style" 
                          value={this.state.search_recipient_contact}
                          onChange={e=>{
                            this.setState({
                              is_search: false,
                              search_recipient_contact: e.target.value
                            })
                          }}
                          />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Uploader
                      </Col>
                      <Col xs={12} md={8}> 
                        <ReactSelect 
                          isMulti
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              height: '150px', // Set the desired height for the dropdown box
                              backgroundColor: 'white', // Set a solid background color
                              zIndex: 1000, // Ensure it appears above other elements
                            }),
                            menuList: (provided) => ({
                              ...provided,
                              maxHeight: '150px', // Set the maximum height for the list of options
                              overflowY: 'auto',  // Enable scrolling if the content exceeds the maximum height
                              backgroundColor: 'white', // Set a solid background color
                              zIndex: 1000, // Ensure it appears above other elements
                            }),
                          }}
                          options={this.state.uploaders}
                          getOptionLabel={(option) => option.first_name+' '+(option.last_name != null ? option.last_name : '')} // Use `name` as the label
                          getOptionValue={(option) => option.id}  // Use `id` as the value
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={this.state.search_uploader}
                          onChange={(e,opt) => {
                            let check_uploader = []
                            if(opt.action == 'select-option') {
                              check_uploader = this.state.uploaders.filter((obj) => !e.filter((sub) => sub.value == obj.value).length > 0);
                              this.setState({
                                is_search: false,
                                search_uploader: e
                              })

                            } else if(opt.action == 'remove-value') {                      
                              this.setState({
                                search_uploader: e,
                                is_search: true                          
                              },() => {
                                this.callReadApi();   
                              });
                            } else if(opt.action == 'clear') {
                              this.setState({
                                search_uploader: [],
                                is_search: true,
                                uploaders: this.state.oldUploaders,
                              },() => {
                                this.callReadApi();   
                              });                          
                            }
                            
                          }}
                        />
                      </Col>
                  </Row>
                  {/* <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Order Group
                      </Col>
                      <Col xs={12} md={8}> 
                      <select className="form-select search-input-style" aria-label="Default select example"
                          placeholder={'Enter Group here...'}
                          // ref={this.refTableUploaderSearch}
                          value={this.state.search_group_id}
                          native
                          onChange={(e) => {
                            this.setState({
                              is_search: false,
                              search_group_id: e.target.value
                            })
                          }}
                        >
                          <option value=""> Select Group </option>
                          {this.state.allocation_groups.length > 0 && this.state.allocation_groups.map(group => {
                          return( <option value={group.id}>{group.group_name ? group.group_name : ""}  </option>)
                          })
                          }
                        </select>
                      </Col>
                  </Row> */}
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Delivery Address
                      </Col>
                      <Col xs={12} md={8}> 
                          <input type="text" className="form-control search-input-style" 
                          value={this.state.search_delivery_address}
                          onChange={e=>{
                            this.setState({
                              is_search: false,
                              search_delivery_address: e.target.value
                            })
                          }}
                          />
                      </Col>
                  </Row>
                  <Row className="py-3">
                      <Col xs={12} md={4} className="text-end"> 
                          Postal Code
                      </Col>
                      <Col xs={12} md={8}> 
                          <input type="text" className="form-control search-input-style" 
                          value={this.state.search_postal_code}
                            onChange={e=>{
                              this.setState({
                                is_search: false,
                                search_postal_code: e.target.value
                              })
                            }}
                          />
                      </Col>
                  </Row>
                  </Col>
              </Row>
              <Row className="border-0 d-flex justify-content-center align-items-center pb-4">
                  <Button className="upload-style max-height-btn" 
                  onClick={e=>{this.pages  = 1; 
                    this.setState({
                      is_search: true,
                    })
                    this.callReadApi()}}><FontAwesomeIcon icon={faMagnifyingGlass} 
                  /> Search</Button>
              </Row>
          </Card>
          }
  </Col>
  </Row>
</div>
  }

  render() {
    return <Container fluid>
    {  this.state.autoAssign? 
    <Box className="loadingScreen">
      <CircularProgress size={35} className="circular_progress"/>
    </Box> 
   : '' }
      <Row className="p-4 overflow-hidden">
        <Col xs={12} md={6}>
          <div className="fs-4 font-medium">Summary of {this.state.client_name}</div>
        </Col>
        <Col xs={12} md={6} className="d-flex justify-content-end align-items-center">
            <Button className="upload-style"
                onClick={() => {
                  this.setState({
                    downloadMenu:true
                });}}
        ><FontAwesomeIcon icon={faDownload} /></Button>
        <Menu
            id="download-menu-job"
            anchorEl={this.state.downloadMenu}
            keepMounted
            getContentAnchorEl={null}
            open={Boolean(this.state.downloadMenu)}
            onClose={() => {
                this.setState({
                  downloadMenu: null
                });
            }}
          >
            <MenuItem onClick={() => {this.callExportXLSApi(); }} > Export CSV </MenuItem>
            <MenuItem onClick={() => { this.generatePDF()}} > Generate Label </MenuItem>
            <MenuItem  onClick={() => { this.generatePOD() }} > Generate POD </MenuItem>
          </Menu>
        </Col>
      </Row>

      {this.customSearch()}

      <Row className="p-4 overflow-hidden">
        <Col xs={12}>
      <Card className="cd-shadow p-4">
        {this.customTable()}
      </Card>
      </Col>
      </Row>
      
      {this.customDialog()}
      {this.previewDialog()}
	  <div id="barcode" style={{display:'none'}}></div>
      <div id="qr" style={{display:'none'}}></div>


    </Container>;
  }
}

export default ClientInvoiceDetail;