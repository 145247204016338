import React from 'react';

import {
    Box,
    Grow,
    ClickAwayListener,
    Popper,
    Paper,
    IconButton,
    Backdrop
} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";

const CustomDropdown = props => {
    // const { children, element, className } = props;
    const { children, element } = props;

    const [open, setOpen] = React.useState((props.open === true || props.open === false) ? props.open : false);
    // const [disablePortal, setDisablePortal] = React.useState((props.disablePortal === true || props.disablePortal === false) ? props.disablePortal : true);
    
    const anchorRef = React.useRef();


    const handleToggle = (e) => {
      e.preventDefault();
      e.stopPropagation();

      setOpen(open => !open);
      console.log('handle toggle');
      if(props.onChange){
        props.onChange(open);
      }
    };
  
    const handleClose = event => {
      setOpen(false);
      console.log('handle close');
      if(props.onChange){
        props.onChange(open);
      }
    };


    React.useEffect(() => {
      console.log('hit use effect',props.open);
      if (typeof props.open === "boolean") {
        setOpen(((props.open === true || props.open === false) ? props.open : false));
      }
      //setDisablePortal(((props.disablePortal === true || props.disablePortal === false) ? props.disablePortal : true));
    }, [props.open]);


    if(children) {
        return (
          <div>
          <Box
          ref={anchorRef}
          aria-haspopup="true"
          onClick={handleToggle}
          >
            {element}
          </Box>
          {open && (
            <Backdrop open={open} style={{ zIndex: 1200, color: "#fff",display: "flex",alignItems: "center",justifyContent: "center" }}>
              <div style={{ position: "relative"}}>
                <IconButton
                    size="small"
                    onClick={() => setOpen(handleClose)}
                    style={{ position: "absolute", top: -10, right: -5, backgroundColor: "white", zIndex: 1300 }}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
                <ClickAwayListener onClickAway={handleClose}>
                  <Paper
                    elevation={3}
                    style={{
                      backgroundColor: "white",
                      borderRadius: 8,
                      position: "relative",
                      margin: "auto",
                      overflowY: 'auto',
                      maxWidth: '100vw'
                    }}
                  >
                    {children}
                  </Paper>
                </ClickAwayListener>
              </div>
            </Backdrop>
          )}
          </div>
        );
    } else {
        return null;
    }
  }

  export default CustomDropdown;