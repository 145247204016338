import React, {useState, useCallback, useEffect, useContext} from 'react'
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import Api from "../../api/api.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import CustomSelectOption from '../../components/CustomFields/CustomSelectOption';
import CustomSwitch from '../../components/CustomFields/CustomSwitch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';
import axios from 'axios';
import {RoleAccessService} from '../../data/role-access'; 
import SmsTermsAndConditions from '../TermsAndPolicy/smsTermsAndConditions.jsx';
import Table from '../../components/Table/Table.jsx';
import SettingTable from '../../components/Table/SettingTable.jsx';
import HtmlParser from 'react-html-parser';
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';
import Icon from '@material-ui/core/Icon';

import OrderDiscountList from './Partial/OrderDiscountList.jsx';

import { ReactComponent as CopyIcon } from '../../assets/img/icons/copy.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import { 
    Box,
    Grid,
    Container,
    Divider,
    IconButton,
    Tabs,
    Tab,
    Button,
    Dialog
  } from '@material-ui/core';
import {TabContext, TabList} from "@material-ui/lab";
import CloseIcon from '@material-ui/icons/Close';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import countryList from '../../assets/countryList.json';
import RTextEditor from "../../components/RichTextEditor/RTextEditor.jsx";
import RSunEditor from '../../components/RichTextEditor/RSunEditor.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import { alignProperty } from '@mui/material/styles/cssUtils.js';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={2}>
            {children}
          </Box>
        )}
      </div>
    );
}
const a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`
    };
}
export const Settings = () => {
    let date = '';
    let useDate = null;
    let grantedAccess = new RoleAccessService();
    let role = window.localStorage.getItem('uniqueRolePermissions');
    let currentRole = window.localStorage.getItem('current_role');

    const history = useHistory();
    const location = useLocation();
    const { accessToken, setIsCustomerRequired, isOnBoarding } = useContext(AuthContext)
    const pathName = (location && location.state && location.state.pathname && location.state.pathname.length > 0) ? location.state.pathname : "/";
    const [value, setValue] = useState(0);
    const [valueInner, setValueInner] = useState(0);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoadingSMS, setIsLoadingSMS ] = useState(false);
    const [ grouping, setGrouping ] = useState();
    const [ limits, setLimits ] = useState(20);
    const [ total, setTotal ] = useState();
    const [ testMail, setTestMail ] = useState();
    const [ totalRecord, setTotalRecord ] = useState();
    const [ dataRecord, setDataRecord ] = useState();
    const [ data, setData ] = useState();
    const [ pages, setPages ] = useState(1);
    const [ checked, setChecked ] = useState(false);
    const [ smsSettingDropdown, setSmsSettingDropdown ] = useState(false);
    const sortCountry = countryList.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
    const allCountry  = sortCountry.filter((v,i,a)=>a.findIndex(t=>(t.label === v.label))===i)

    
    //pre section
    const preScalePrefWeight = window.localStorage.getItem('Weight') || null;
    const preScalePrefVol = window.localStorage.getItem('Volume') || null;
    const preScalePrefLength = window.localStorage.getItem('Length') || null;
    const preScalePrefDistance = window.localStorage.getItem('Distance') || null;
    const preScalePrefSpeed = window.localStorage.getItem('Speed') || null;
    const preScalePrefDateFormat = window.localStorage.getItem('Date Format') || null;
    const preScalePrefTimeFormat = window.localStorage.getItem('Time Format') || null;
    const preCountry = window.localStorage.getItem('Country') || null;
    const preTimeZone = window.localStorage.getItem('TimeZone') || null;
    const preTimeZoneList = window.localStorage.getItem('TimeZoneList')? JSON.parse(window.localStorage.getItem('TimeZoneList')) : [];
    // general section
    const [country, setCountry] = useState(preCountry)
    const [countryCode, setCountryCode] = useState("")
    const [countryIdentifier, setCountryIdentifier] = useState("")
    const [timeZone, setTimeZone] = useState(preTimeZone)
    const [timeZoneList, setTimeZoneList] = useState(preTimeZoneList)
    // const [timeZone, setTimeZone] = useState("Singapore")
    const [driverLabel, setDriverLabel] = useState("")
    const [manPowerLabel, setManPowerLabel] = useState("")

    const [twilioSmsEnabled, setTwilioSmsEnabled] = useState(false)
    const [twilioSmsKey, setTwilioSmsKey] = useState("")
    const [fromNumber, setFromNumber] = useState("")
    const [twilioSmsSecret, setTwilioSmsSecret] = useState("")

    const [smsData, setSmsData] = useState()
    const [smsStatus, setSmsStatus] = useState()
    const [smsDate, setSmsDate] = useState()
    const [terminateDialog, setTerminateDialog] = useState(false)
    const [rerequestDialog, setRequestDialog] = useState(false)

    const [openTermCondition, setOpenTermCondition] = useState(false)
    // formatting preference
    const [deliveryFormattingInitial, setDeliveryFormattingInitial] = useState("")
    const [deliveryFormattingYear, setDeliveryFormattingYear] = useState("")
    const [deliveryFormattingMonth, setDeliveryFormattingMonth] = useState("")
    const [deliveryFormattingDay, setDeliveryFormattingDay] = useState("")
    const [deliveryNoSeparator, setDeliveryNoSeparator] = useState("")
    const [deliveryNoSeparatorDash, setDeliveryNoSeparatorDash] = useState("")
    const [deliveryNoSeparatorSlash, setDeliveryNoSeparatorSlash] = useState("")
    const [deliveryNoSeparatorDot, setDeliveryNoSeparatorDot] = useState("")
    const [scalePrefWeight, setScalePrefWeight] = useState(preScalePrefWeight)
    const [scalePrefSpeed, setScalePrefSpeed] = useState(preScalePrefSpeed)
    const [scalePrefDistance, setScalePrefDistance] = useState(preScalePrefDistance)
    const [scalePrefLength, setScalePrefLength] = useState(preScalePrefLength)
    const [scalePrefVol, setScalePrefVol] = useState(preScalePrefVol)
    const [scalePrefDateFormat, setScalePrefDateFormat] = useState(preScalePrefDateFormat)
    const [scalePrefDateExampleFormat, setScalePrefDateExampleFormat] = useState("")
    const [scalePrefTimeFormat, setScalePrefTimeFormat] = useState(preScalePrefTimeFormat)
    const [enableCustomer, setEnableCustomer] = useState(false);
    const [enableManPower, setEnableManPower] = useState(false);
    const [enableManPowerAcknowledged, setEnableManPowerAcknowledged] = useState(false);
    const [enableManPowerInProgress, setEnableManPowerInProgress] = useState(false);
    const [enableManPowerCompleted, setEnableManPowerCompleted] = useState(false);
    const [enableManPowerFailed, setEnableManPowerFailed] = useState(false);
    const [enableSmsAssigned, setEnableSmsAssigned] = useState(false);
    const [enableSmsInProgress, setEnableSmsInProgress] = useState(false);
    const [enableSmsCompleted, setEnableSmsCompleted] = useState(false);
    const [enableSmsFailed, setEnableSmsFailed] = useState(false);
    const [enableEmailAssigned, setEnableEmailAssigned] = useState(false);
    const [enableEmailInProgress, setEnableEmailInProgress] = useState(false);
    const [enableEmailCompleted, setEnableEmailCompleted] = useState(false);
    const [enableEmailFailed, setEnableEmailFailed] = useState(false);
    const [currentApplicationData, setCurrentApplicationData] = useState([]);
    const [currentMeasurementData, setCurrentMeasurementData] = useState([]);

    //Templates
    const [ emailTemplates, setEmailTemplates ] = useState([]);
    const [ smsTemplates, setSmsTemplates ] = useState([]);

    //
    // end general section
    // http://auth-api.taskk.me/admin/api/application_company_settings
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        setOpenTermCondition(false);
    };

    const handleChangeInner = (event, newValue) => {
        setValueInner(newValue);
    };
    
    const callReadSettingsApi = useCallback(() => {
        if (grantedAccess.isShouldShowMenu(currentRole, 'Application Settings') || currentRole == "Finance Admin" || currentRole == "BD Admin" || currentRole == "Ops Admin") {
        Api.read('application_company_settings', {}, accessToken)
        .then(result => {
            apiUtil.parseResult(result, data => {
                
                
                const res = (data.result && data.result.length > 0) ? data.result : []
                res.forEach( (obj, index) => {
                    switch (obj.rule) {
                        case "manpower_label":
                            setManPowerLabel(obj.view_name)
                            break;
                        case "driver_label":
                            setDriverLabel(obj.view_name)
                            break;
                        case "sms_credentials":
                        case "twilio_sms_credentials":
                            let twilio = JSON.parse(obj.json_data);
                            setTwilioSmsEnabled(obj.enable ? obj.enable : false)
                            setTwilioSmsKey(twilio.account_key ? twilio.account_key : "")
                            setFromNumber(twilio.from_number ? twilio.from_number : "")
                            setTwilioSmsSecret(twilio.account_secret ? twilio.account_secret : "")
                            break;
                        case "email_sms_notification_status":
                            let notification = JSON.parse(obj.json_data);
                            setEnableSmsAssigned(notification.assigned_sms ? notification.assigned_sms : false)
                            setEnableSmsInProgress(notification.in_progress_sms ? notification.in_progress_sms : false)
                            setEnableSmsCompleted(notification.completed_sms ? notification.completed_sms : false)
                            setEnableSmsFailed(notification.failed_sms ? notification.failed_sms : false)
                            setEnableEmailAssigned(notification.assigned_email ? notification.assigned_email : false)
                            setEnableEmailInProgress(notification.in_progress_email ? notification.in_progress_email : false)
                            setEnableEmailCompleted(notification.completed_email ? notification.completed_email : false)
                            setEnableEmailFailed(notification.failed_email ? notification.failed_email : false)
                            break;
                        default:
                            break;
                    }
                    if(obj.group_name === "Customer") {
                        setEnableCustomer(obj.enable);
                    }
                    if(obj.view_name === "In Progress" && obj.group_name === "mobile") {
                        setEnableManPowerInProgress(obj.enable);
                    }
                    if(obj.view_name === "Completed" && obj.group_name === "mobile") {
                        setEnableManPowerCompleted(obj.enable);
                    }
                    if(obj.view_name === "Acknowledged" && obj.group_name === "mobile") {
                        setEnableManPowerAcknowledged(obj.enable);
                    }  
                    if(obj.view_name === "Failed" && obj.group_name === "mobile") {
                        setEnableManPowerFailed(obj.enable);
                    }
                    if(obj.description === "Manpower can update this status" && obj.group_name === "mobile") {
                        setEnableManPower(obj.enable);
                    }
                    if(obj.group_name === "Delivery Order") {
                        setDeliveryNoSeparatorDash(false)
                        setDeliveryNoSeparatorSlash(false)
                        setDeliveryNoSeparatorDot(false)
                        let ruleFormat = obj.rule_format;
                        let numberSeperator =  ruleFormat.includes("-") ? "-": ruleFormat.includes("/")? "/": ".";
                        setDeliveryNoSeparator(numberSeperator);
                        (numberSeperator === "-" ? setDeliveryNoSeparatorDash(true): (numberSeperator === "/")? setDeliveryNoSeparatorSlash(true): setDeliveryNoSeparatorDot(true));
                        let transformArray = ruleFormat.split(numberSeperator);
                        let FormattingInitial = transformArray[0];
                        let formattingData = transformArray[1].replace("[[", "").replace("]]", "");
                        let formattingYear = formattingData.includes("Y") ? true : false;
                        let formattingMonth = formattingData.includes("b") ? true : false; 
                        let formattingDay = formattingData.includes("d") ? true : false;
                        setDeliveryFormattingYear(formattingYear);
                        setDeliveryFormattingMonth(formattingMonth);
                        setDeliveryFormattingDay(formattingDay);
                        setDeliveryFormattingInitial(FormattingInitial);
                    }

                    // if(obj.id === 1042 ){
                        
                    // }
                    // if(obj.id)
                })
                setCurrentApplicationData(res);
            })
        })  
    }
    if (grantedAccess.isShouldShowMenu(currentRole, 'Unit Measurements') || currentRole == "Finance Admin" || currentRole == "BD Admin" || currentRole == "Ops Admin") {
        Api.read('application_unit_measurements', {}, accessToken)
        .then(result => {
            apiUtil.parseResult(result, data => {
                const res = (data.result && data.result.length > 0) ? data.result : []
                setCurrentMeasurementData(res);
            })
        }) 
    }
    },[])
    const callUpdateApi = ( row, callback = null) => {
        setIsLoading(true);
        Api.create('batch_update/application_company_settings', row, accessToken)
        .then((result) => {
  
            apiUtil.parseResult(result, (data) => {
                setIsLoading(false);
                if(data.successfully_update.length > 0) {
                    data.successfully_update.filter(resultData => {
                        if(resultData.group_name === "Country") {
                            localStorage.setItem('Country',(country !== null && Array.isArray(country))? country.sort(): country);
                            localStorage.setItem('TimeZoneList', JSON.stringify(timeZoneList));
                            localStorage.setItem('TimeZone', timeZone);
                        }

                        if(resultData.rule === "is_customer_required"){
                            localStorage.setItem("isCustomerRequired", resultData.enable);
                            setIsCustomerRequired(resultData.enable);
                        }
                    });

                    // setIsCustomerRequired(enableCustomer);
                }
                if(callback) {
                    callback();
                }
            }, (error, type) => {
                // console.log("error", error)
                setIsLoading(false);
                if(callback) {
                    callback();
                }
            });
        }).catch(
            error =>  console.log(error)
        )
    }
    
    const callTestMailApi = ( content, available_parameters, mail, type) => {
        let data = {
            content: content,
            available_parameters: available_parameters,
            mail: mail,
            type: type,
        }
        // console.log('content', content)
        setIsLoading(true);
        ldsApi.create('test/mail', data, accessToken)
        .then((result) => {
            apiUtil.parseResult(result, (data) => {
                setIsLoading(false);
               apiUtil.toast("Successfully Sent")
            }, (error, type) => {
                apiUtil.toast("Fail to send testing mail")
                setIsLoading(false);
            });
        }).catch(
            error => console.log(error)
        )
    }
    

    const callUpdateMeasurements = (row, callback = null) => {
        setIsLoading(true);
        if(row.measurements_data.length > 0) {
            Api.create('batch_update/application_unit_measurements', row, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, data => {
                    setIsLoading(false);
                    const res = (data.successfully_update && data.successfully_update.length > 0) ? data.successfully_update : []
                    if(res.length > 0) {
                        res.forEach( item => {
                            if(item.module && item.module !== "" && item.is_active){
                              localStorage.setItem(item.measurement_type, item.measurement);
                            } else {
                                // console.log("not active");
                            }
                          })
                    }
                  
                })
            }).catch(
                error => console.log(error)
            )
        } else {
            setIsLoading(false);
        }
        
    } 
    const callUpdatewithPutApplicationCompanySettings = (row, callback = null) => {
        setIsLoading(true);

        if(Object.keys(row).length > 0) {
            Api.update('application_company_settings/' + row.id, row, accessToken)
            .then((result) => {
                setIsLoading(false);
            }).catch(
                error => console.log(error)
            )
        } else {
            setIsLoading(false);
        }
       
    }    
 
    const callEmailTemplateApi = useCallback(() => {
        setIsLoading(true);

        let emptyItems = [
            {
                isNew: true,
                name: 'In Progress',
                template: {
                    template_name: "Job-In-Progress",
                    template: "",
                    template_type: "",
                    available_parameters: "",
                    email_type: "",
                    email_subject: "",
                    dynamic_content: ""
                }
            },
            {
                isNew: true,
                name: 'Completed',
                template: {
                    template_name: "Job-Completed",
                    template: "",
                    template_type: "",
                    available_parameters: "",
                    email_type: "",
                    email_subject: "",
                    dynamic_content: ""
                }
            }
        ];

        Api.read('email_templates', null, accessToken)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                if(data && data.result){
                    if(data.result && data.result.length > 0){
                        let items = [];
                        let assignedItem = data.result.filter(x => x.template_name.trim().toLowerCase() == 'job-assigned-en');
                        if(assignedItem && assignedItem.length > 0){
                            items.push({
                                name: 'Assigned',
                                template: assignedItem[0]
                            });
                        }

                        let inProgressItem = data.result.filter(x => x.template_name.trim().toLowerCase() == 'job-in-progress-tracking-en');
                        if(inProgressItem && inProgressItem.length > 0){
                            items.push({
                                name: 'In Progress',
                                template: inProgressItem[0]
                            });
                        }

                        let inProgressNoTrackingItem = data.result.filter(x =>  x.template_name.trim().toLowerCase() == 'job-in-progress-no-tracking-en');
                        if(inProgressNoTrackingItem && inProgressNoTrackingItem.length > 0){
                            items.push({
                                name: 'In Progress - no tracking',
                                template: inProgressNoTrackingItem[0]
                            });
                        }


                        let completedItem = data.result.filter(x => x.template_name.trim().toLowerCase() == 'job-failed-en');
                        if(completedItem && completedItem.length > 0){
                            items.push({
                                name: 'Failed',
                                template: completedItem[0]
                            });
                        }
                        
                        let failedItem = data.result.filter(x => x.template_name.trim().toLowerCase() == 'job-completed-en');
                        if(failedItem && failedItem.length > 0){
                            items.push({
                                name: 'Completed',
                                template: failedItem[0]
                            });
                        }

                        setEmailTemplates(items);
                        setIsLoading(false);
                    } else {
                        setEmailTemplates(emptyItems);
                        setIsLoading(false);
                    }
                } else {
                    setEmailTemplates(emptyItems);
                    setIsLoading(false);
                }
            }, (error, type) => {
                setIsLoading(false);
            });
        });
    },[])

    const callUpdateEmailTemplateApi = (state, params, callback = null) => {
        return new Promise(resolve => { 
            setIsLoading(true);
            
            if(params){
                Api.update('email_templates/' + params.id, params, accessToken)
                    .then((result) => {
                    apiUtil.parseResult(result, (data) => {
                        setIsLoading(false);
                        resolve({
                            status: true,
                            type: state,
                        });
                    }, (error, type) => {
                        setIsLoading(false);
                        resolve({
                            status: false,
                            type: state,
                        });
                    });
                });
            } else {
                setIsLoading(false);
                resolve({
                    status: false,
                    type: state,
                });
            }
        });
    }
    const callCreateEmailTemplateApi = (state, params, callback = null) => {
        return new Promise(resolve => { 
            setIsLoading(true);
            
            if(params){
                Api.create('email_templates', params, accessToken)
                    .then((result) => {
                    apiUtil.parseResult(result, (data) => {
                        setIsLoading(false);
                        resolve({
                            status: true,
                            type: state,
                        });
                    }, (error, type) => {
                        setIsLoading(false);
                        resolve({
                            status: false,
                            type: state,
                        });
                    });
                });
            } else {
                setIsLoading(false);
                resolve({
                    status: false,
                    type: state,
                });
            }
        });
    }

    const callSMSTemplateApi = useCallback(() => {
        setIsLoading(true);

        let emptyItems = [
            {
                isNew: true,
                name: 'In Progress',
                template: {
                    template_name: "Job-In-Progress",
                    template: "",
                    template_type: "",
                    available_parameters: "",
                    display_name: ""
                }
            },
            {
                isNew: true,
                name: 'Completed',
                template: {
                    template_name: "Job-Completed",
                    template: "",
                    template_type: "",
                    available_parameters: "",
                    display_name: ""
                }
            }
        ];

        ldsApi.read('sms_templates', null, accessToken)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                if(data && data.length > 0){
                    // let items = [];

                    // let inProgressItem = data.result.filter(x => (x.template_name.trim().toLowerCase().includes("progress") && (x.email_subject.trim().toLowerCase().includes("progress") || x.email_type.trim().toLowerCase().includes("progress"))));
                    // if(inProgressItem && inProgressItem.length > 0){
                    //     items.push({
                    //         name: 'In Progress',
                    //         template: inProgressItem[0]
                    //     });
                    // }


                    // let completedItem = data.result.filter(x => (x.template_name.trim().toLowerCase().includes("completed") && (x.email_subject.trim().toLowerCase().includes("completed") || x.email_type.trim().toLowerCase().includes("completed"))));
                    // if(completedItem && completedItem.length > 0){
                    //     items.push({
                    //         name: 'Completed',
                    //         template: completedItem[0]
                    //     });
                    // }

                    let items = data.map((item, i) => {
                        let name = item.template_name.replaceAll('_', ' ').trim().toLowerCase();

                        return {
                            name: name.charAt(0).toUpperCase() + name.slice(1),
                            template: item
                        }
                    });

                    setSmsTemplates(items);
                    setIsLoading(false);
                } else {
                    setSmsTemplates(emptyItems);
                    setIsLoading(false);
                }
            }, (error, type) => {
                setIsLoading(false);
            });
        });
    },[])

    const callUpdateSMSTemplateApi = (state, params, callback = null) => {
        return new Promise(resolve => { 
            setIsLoading(true);
            
            if(params){
                ldsApi.update('sms_templates/' + params.id, params, accessToken)
                    .then((result) => {
                    apiUtil.parseResult(result, (data) => {
                        setIsLoading(false);
                        resolve({
                            status: true,
                            type: state,
                        });
                    }, (error, type) => {
                        setIsLoading(false);
                        resolve({
                            status: false,
                            type: state,
                        });
                    });
                });
            } else {
                setIsLoading(false);
                resolve({
                    status: false,
                    type: state,
                });
            }
        });
    }

    const callCreateSMSTemplateApi = (state, params, callback = null) => {
        return new Promise(resolve => { 
            setIsLoading(true);
            
            if(params){
                ldsApi.create('sms_templates', params, accessToken)
                    .then((result) => {
                    apiUtil.parseResult(result, (data) => {
                        setIsLoading(false);
                        resolve({
                            status: true,
                            type: state,
                        });
                    }, (error, type) => {
                        setIsLoading(false);
                        resolve({
                            status: false,
                            type: state,
                        });
                    });
                });
            } else {
                setIsLoading(false);
                resolve({
                    status: false,
                    type: state,
                });
            }
        });
    }

    const callSmsApi = () => {
        let errorMessage = "";
        let status = 0;
        const { 
          REACT_APP_AUTH_API_URL
        } = process.env;
        const PATH = 'admin/api/';
        const api = "sms_requests";
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');
        setIsLoading(true);
          axios.get(
                URL,
                { 
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                .catch( error => {
                    try {
                        status = error.response.status;
                          if (error.response) {
                            // Request made and server responded
                            errorMessage = error.response.data;
                          } else if (error.request) {
                            // The request was made but no response was received
                            errorMessage = error.request;
                          } else {
                            // Something happened in setting up the request that triggered an Error
                            errorMessage = error.message;
                          }
                    } catch(err) {
                        errorMessage = 'Service Unavailable!'
                    }
                   setIsLoading(false);
                }).then((response) => {
                  // console.log(response);
                  if(response != null){
                      setSmsData(response.data.result);
                      if(response.data.result != null ) {
                        setSmsStatus(response.data.result.status_name);
                        setSmsDate(response.data.result.updated_at);
                      }
                      setIsLoading(false);
                  }
                });
      }

      const callSmsCreateApi = () => {
        let errorMessage = "";
        let status = 0;
        const { 
          REACT_APP_AUTH_API_URL
        } = process.env;
        const PATH = 'admin/api/';
        const api = "sms_requests";
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');
        let data = {

        }
        setIsLoading(true);
          axios.post(
                URL,
                data,
                { 
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                .catch( error => {
                    try {
                        status = error.response.status;
                          if (error.response) {
                            // Request made and server responded
                            errorMessage = error.response.data.error;
                            apiUtil.toast(errorMessage, 'warning', "error");
                          } else if (error.request) {
                            // The request was made but no response was received
                            errorMessage = error.request;
                          } else {
                            // Something happened in setting up the request that triggered an Error
                            errorMessage = error.message;
                          }
                    } catch(err) {
                        errorMessage = 'Service Unavailable!'
                    }
                   setIsLoading(false);
                }).then((response) => {
                  if(response != null){
                     callSmsApi();
                  }
                });
      }

      const callSmsTerminateApi = () => {
        let errorMessage = "";
        let status = 0;
        const { 
          REACT_APP_AUTH_API_URL
        } = process.env;
        const PATH = 'admin/api/';
        const api = "sms_terminate_request";
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');
        setIsLoading(true);
        let data = {
            status: "terminated",
          };
          axios.put(
                URL,
                data,
                { 
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                .catch( error => {
                    try {
                        status = error.response.status;
                          if (error.response) {
                            // Request made and server responded
                            errorMessage = error.response.data.error;
                            apiUtil.toast(errorMessage, 'warning', "error");
                          } else if (error.request) {
                            // The request was made but no response was received
                            errorMessage = error.request;
                          } else {
                            // Something happened in setting up the request that triggered an Error
                            errorMessage = error.message;
                          }
                    } catch(err) {
                        errorMessage = 'Service Unavailable!'
                    }
                   setIsLoading(false);
                }).then((response) => {
                  if(response != null){
                        callSmsApi();
                        setIsLoading(false);
                  }
                });
      }

      const callSmsRerequestApi = () => {
        let errorMessage = "";
        let status = 0;
        const { 
          REACT_APP_AUTH_API_URL
        } = process.env;
        const PATH = 'admin/api/';
        const api = "sms_terminate_request";
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');
        setIsLoading(true);
        let data = {
            status: "pending",
          };
          axios.put(
                URL,
                data,
                { 
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                .catch( error => {
                    try {
                        status = error.response.status;
                          if (error.response) {
                            // Request made and server responded
                            errorMessage = error.response.data.error;
                            apiUtil.toast(errorMessage, 'warning', "error");
                          } else if (error.request) {
                            // The request was made but no response was received
                            errorMessage = error.request;
                          } else {
                            // Something happened in setting up the request that triggered an Error
                            errorMessage = error.message;
                          }
                    } catch(err) {
                        errorMessage = 'Service Unavailable!'
                    }
                   setIsLoading(false);
                }).then((response) => {
                  if(response != null){
                        callSmsApi();
                        setIsLoading(false);
                  }
                });
      }

      const callSmsRecordApi = () => {
        let errorMessage = "";
        let status = "";
        // let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
        // let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
        setIsLoadingSMS(true);
        const { 
          REACT_APP_LDS_API_URL
        } = process.env;
        const PATH = 'admin/api/';
        const api = "sms_usages";
        const URL = REACT_APP_LDS_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');

        let param = {
          page: pages,
          take: limits,
          sort: 'latest',
        //   start_date: startDate,
        //   end_date: endDate,
        //   application_company_id: company_id,
        //   search: "",
        };
        
          axios.get(
                URL,
                { 
                    params: param,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                .catch( error => {
                    try {
                        status = error.response.status;
                          if (error.response) {
                            // Request made and server responded
                            errorMessage = error.response.data;
                          } else if (error.request) {
                            // The request was made but no response was received
                            errorMessage = error.request;
                          } else {
                            // Something happened in setting up the request that triggered an Error
                            errorMessage = error.message;
                          }
                    } catch(err) {
                        errorMessage = 'Service Unavailable!'
                    }
                   setIsLoadingSMS(false);
                }).then((response) => {
                  if(response != null){
                      setDataRecord(response.data.result);
                      setTotalRecord(response.data.total);
                      setIsLoadingSMS(false);
                  }
                });
        
      }

    useEffect(() => {
        callReadSettingsApi();
        callEmailTemplateApi();
        callSMSTemplateApi();
        callSmsApi();
        callSmsRecordApi();
        return () => {
          
        }
    }, [callReadSettingsApi, callEmailTemplateApi, callSMSTemplateApi,pages])

    const handleCountryChange = (value, item) => {

        let cloneCountryList =   countryList.map(object => ({ ...object }));
        let filterCountry = cloneCountryList.filter((oldCountry) => {
            if(value === oldCountry.value) {
                oldCountry.value = oldCountry.offset;
                oldCountry.label = "GMT "+oldCountry.offset + "("+ oldCountry.identifier+")";;
                return oldCountry;
            }
        });

        setTimeZoneList(filterCountry);
        setTimeZone(filterCountry[0]);
        setCountry(value);
        setCountryCode(item.code);
        setCountryIdentifier(item.identifier);

    }
    const CustomStickyPageHeader = () => {
     return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        {(!isOnBoarding) && <Box clone>
          <Grid item xs={'auto'}>
                <IconButton 
                    onClick={() => {
                        history.push(pathName);
                    }}
                >
                    <CloseIcon />
                </IconButton>
          </Grid>
        </Box>}
        {(!isOnBoarding) && <Box clone>
          <Grid item xs={'auto'}>
                <Divider orientation={'vertical'} />
          </Grid>
        </Box>}
        <Box clone>
          <Grid item xs={true}>
            <CustomLabel
                className={'no-margin'}
                label={(!isOnBoarding) ? 'General Settings' : 'Welcome to FMS. Let\'s set up your preferences before we start'}
                weight={'bold'}
                size={'md'}
            />
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={'auto'}>
            <CustomButton 
              type={'submit'}
              color={'secondary'}
              disabled={(grantedAccess.isPermissionActionAllowed(currentRole, 'Update Application Setting')  || currentRole == "Finance Admin" || currentRole == "BD Admin" || currentRole == "Ops Admin")? false : true}
              isLoading={isLoading}
            >
              <Box pl={2} pr={2}>{isOnBoarding ? 'Begin FMS' : 'Save Changes'}</Box>
            </CustomButton>
          </Grid>
        </Box>
      </Grid>
    </Box>
    }
    
    const smsGeneralSetting = () => {
            date = new Date(smsDate);
            useDate = date.toDateString();
        return <Box>

            <Card className='job-listing-step custom-card-margin-x' style={{pointerEvents: (grantedAccess.isPermissionActionAllowed(currentRole, 'Update Application Setting') || currentRole == "Finance Admin" || currentRole == "BD Admin" || currentRole == "Ops Admin") ? "auto" : "none"}}>
                <h3>Tiwilo SMS</h3>
                <Grid Container>
                <CustomLabel
                    label={''}
                    weight={'bold'}
                    size={'md'}
                />
                <Box clone>
                <Grid container >

                    <Grid item xs={10}>
                        <span>Enable Tiwilo</span>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomSwitch 
                        label={'Enable Tiwilo'} 
                        disabled= { 
                            (smsData == '' || 
                            smsData == null || 
                            smsStatus == "terminated" || 
                            smsStatus == "rejected") ? false : true}
                        checked={twilioSmsEnabled}
                        value={twilioSmsEnabled}
                        onChange={ () => {
                        // if(smsStatus == "pending" || smsStatus == "approved") {
                        //         setTwilioSmsEnabled(false);
                        //  } else {
                            setTwilioSmsEnabled(!twilioSmsEnabled);
                        //  }   
                        }
                        }/>
                    </Grid>
{/*                     
                        <CustomInput
                            label={'Enable Tiwilo'}
                            placeholder={'Enter Twilio Accont Key'}
                            className="input-label--horizontal"
                            value={twilioSmsEnabled}
                            onChange={ e => {
                                setTwilioSmsEnabled(e.target.value)
                                // setFirstNameValidated((e.target.value !== '') ? false : true) 
                            }}
                        /> */}
                 
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        <CustomInput
                            label={'From Number'}
                            disabled= { 
                                (smsData == '' || 
                                smsData == null || 
                                smsStatus == "terminated" || 
                                smsStatus == "rejected") ? false : true}
                            placeholder={'Enter From Number'}
                            className="input-label--horizontal"
                            value={fromNumber}
                            onChange={ e => {
                                setFromNumber(e.target.value)
                                // setFirstNameValidated((e.target.value !== '') ? false : true) 
                            }}
                        />
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        <CustomInput
                            label={'Account SSID'}
                            disabled= { 
                                (smsData == '' || 
                                smsData == null || 
                                smsStatus == "terminated" || 
                                smsStatus == "rejected") ? false : true}
                            placeholder={'Enter Twilio Accont SSID'}
                            className="input-label--horizontal"
                            value={twilioSmsKey}
                            onChange={ e => {
                                setTwilioSmsKey(e.target.value)
                                // setFirstNameValidated((e.target.value !== '') ? false : true) 
                            }}
                        />
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        <CustomInput
                            label={'Auth Token'}
                            disabled= { 
                                (smsData == '' || 
                                smsData == null || 
                                smsStatus == "terminated" || 
                                smsStatus == "rejected") ? false : true}
                            placeholder={'Enter Twilio Auth Token'}
                            className="input-label--horizontal"
                            value={twilioSmsSecret}
                            // error={lastNameValidated}
                            required={true}
                            onChange={e => {
                                setTwilioSmsSecret(e.target.value)
                                // setLastNameValidated((e.target.value !== '') ? false : true) 
                            }}
                        />
                    </Grid>
                </Box>
                </Grid>

                {/* <Grid container style={  valueInner == 1 ? {display: 'block'} : {display: 'none'}}>
                <CustomLabel
                    label={''}
                    weight={'bold'}
                    size={'md'}
                />
                <Box clone>
                <Grid container >

                    <Grid item xs={10}>
                        <span>Enable Company SMS</span>
                    </Grid>
                        <Grid item xs={2}>
                            <CustomSwitch  
                            checked={companySmsEnabled}  
                            onChange={ () =>
                                setOpenTermCondition(true)
                            }
                            disabled= { 
                                (smsData == '' || 
                                smsData == null || 
                                smsStatus == "terminated" || 
                                smsStatus == "rejected") ? false : true}
                            label="Enable Company SMS"/>
                        </Grid>
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        <CustomInput
                            label={'From Number'}
                            placeholder={'Enter From Number'}
                            className="input-label--horizontal"
                            disabled= { 
                                (smsData == '' || 
                                smsData == null || 
                                smsStatus == "terminated" || 
                                smsStatus == "rejected") ? false : true}
                            value={companyFromNumber}
                            onChange={ e => {
                                setCompanyFromNumber(e.target.value)
                                // setFirstNameValidated((e.target.value !== '') ? false : true) 
                            }}
                        />
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        <CustomInput
                            label={'Account SSID'}
                            placeholder={'Enter Company Accont SSID'}
                            disabled= { 
                                (smsData == '' || 
                                smsData == null || 
                                smsStatus == "terminated" || 
                                smsStatus == "rejected") ? false : true}
                            className="input-label--horizontal"
                            value={companySmsKey}
                            onChange={ e => {
                                setCompanySmsKey(e.target.value)
                                // setFirstNameValidated((e.target.value !== '') ? false : true) 
                            }}
                        />
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        <CustomInput
                            label={'Auth Token'}
                            disabled= { 
                                (smsData == '' || 
                                smsData == null || 
                                smsStatus == "terminated" || 
                                smsStatus == "rejected") ? false : true}
                            placeholder={'Enter Company Auth Token'}
                            className="input-label--horizontal"
                            value={companySmsSecret}
                            // error={lastNameValidated}
                            required={true}
                            onChange={e => {
                                setCompanySmsSecret(e.target.value)
                                // setLastNameValidated((e.target.value !== '') ? false : true) 
                            }}
                        />
                    </Grid>
                </Box>
                </Grid> */}
            </Card>

            <Card className='job-listing-step custom-card-margin-x' >
            <Box>
            {smsStatus == null ? 
            <div m={3} mt={3} style={{padding: "20px", backgroundColor: "#ffd3ab"}}>
                if you don't have your own Twilio credentials, you can use ours. 
                <a 
                style={{
                    color: '#29b6f6',
                    textDecoration: 'underline',
                    cursor: 'pointer'
                }}
                onClick={ () =>
                    setOpenTermCondition(true)
                }>Click to use</a>!
                </div>  
                : 
                <>
                 {smsStatus == "pending"? 
                 <Box style={{display: 'flex'}} >
                    <Grid item xs={12} md={6} lg={6}>
                    <span style={{color: '#FF6600', cursor:'pointer'}} 
                       onClick={() =>{
                           setTerminateDialog(true);
                           terminateDialogPop();
                       }}><strong>In Review</strong></span>
                       <hr />
                       <p style={{textAlign:'center', padding:'15px'}}>Your request is being processed. Evfy admin team will review your request and send you an email confirming your acceptance or rejection
</p>
                       </Grid>
                       <Grid item xs={12} md={6} lg={6} style={{textAlign:'center'}}>
                           <p style={{padding:'0px 15px'}}>you can terminate your request at anytime!</p>
                           <div style={{padding:'0px 15px'}}>
                           <p 
                           style={{
                               backgroundColor: '#EA5455',
                               padding: '10px 15px',
                               cursor: 'pointer',
                               borderRadius: '5px',
                               color: '#FFFFFF',
                           }}
                           onClick={() =>{
                               setTerminateDialog(true);
                               terminateDialogPop();
                           }}>Terminate</p></div>
                       </Grid>
                       </Box>  :
                    <>{smsStatus == "approved" ? <>
                    <Box style={{display: 'flex'}}>
                    <Grid item xs={12} md={6} lg={6}>
                    <span style={{color: '#28a745', cursor:'pointer'}}><strong>Approved</strong></span>
                       <hr />
                       <p style={{textAlign:'center', padding:'15px'}}>your SMS service subscription is active starting from <strong>{useDate? useDate: ''}</strong></p>
                       </Grid>
                       <Grid item xs={12} md={6} lg={6} style={{textAlign:'center' , pointerEvents: (grantedAccess.isPermissionActionAllowed(currentRole, 'Update Application Setting') || currentRole == "Finance Admin" || currentRole == "BD Admin" || currentRole == "Ops Admin") ? "auto" : "none"}}>
                           <p style={{padding:'0px 15px', marginBottom: '15px'}}>you can terminate your request at anytime</p>
                           <div style={{padding:'0px 15px'}}>
                           <p 
                           style={{
                               backgroundColor: '#EA5455',
                               padding: '10px 15px',
                               cursor: 'pointer',
                               borderRadius: '5px',
                               color: '#FFFFFF',
                               margin:'15px !important'
                           }}
                           onClick={() =>{
                               setTerminateDialog(true);
                               terminateDialogPop();
                           }}>Terminate</p></div>
                       </Grid>
                       </Box>
                       </>:
                    <>{smsStatus == "terminated"? 
                          <Box style={{display: 'flex'}}>
                    <Grid item xs={12} md={6} lg={6}>
                    <span style={{color: '#EA5455', cursor:'pointer'}}><strong>Terminated</strong></span>
                       <hr />
                       <p style={{textAlign:'center', padding:'15px'}}>Your SMS service is terminated !</p>
                       </Grid>
                       <Grid item xs={12} md={6} lg={6} style={{textAlign:'center'}}>
                           <p style={{padding:'0px 15px'}}>You can re-request SMS service again</p>
                           <div style={{padding:'0px 15px'}}>
                           <p 
                           style={{
                               backgroundColor: '#ABC2E8',
                               padding: '10px 15px',
                               cursor: 'pointer',
                               borderRadius: '5px',
                               color: '#FFFFFF',
                               margin:'15px !important'
                           }}
                           onClick={() =>{
                            setRequestDialog(true);
                            rerequestDialogPop();
                        }}>Re-Request</p></div>
                       </Grid>
                       </Box>:
                    <>{smsStatus == "rejected" ?
                    <Box style={{display: 'flex'}}>
                    <Grid item xs={12} md={6} lg={6}>
                    <span style={{color: '#EA5455', cursor:'pointer'}}><strong>Rejected</strong></span>
                       <hr />
                       <p style={{textAlign:'center', padding:'15px'}}>Sorry! We will not proceed with your request, Please try again in next time </p>
                       </Grid>
                       <Grid item xs={12} md={6} lg={6} style={{textAlign:'center'}}>
                           <p style={{padding:'0px 15px'}}>You can re-request SMS service again</p>
                           <div style={{padding:'0px 15px'}}>
                           <p 
                           style={{
                               backgroundColor: '#ABC2E8',
                               padding: '10px 15px',
                               cursor: 'pointer',
                               borderRadius: '5px',
                               color: '#FFFFFF',
                               margin:'15px !important'
                           }}
                           onClick={() =>{
                            setRequestDialog(true);
                            rerequestDialogPop();
                        }}>Re-Request</p></div>
                       </Grid>
                       </Box>: ''}
                    </>}</>}</>}
                </> }
            </Box>  
            </Card>   
        </Box>
    }

    const settingsGeneral = () => {
        return <Box>
            <Box style={{ backgroundColor: "#FAFAFA" }} p={3} mb={2}><Grid container p={1} >
                <CustomLabel
                    label={'General'}
                    weight={'bold'}
                    size={'md'}
                />
                <Box clone>
                    <Grid item xs={12}>
                        <CustomSelectOption
                            label={'Country'}
                            value={country}
                            items={allCountry}
                            onChange={handleCountryChange}

                        />
                        <CustomSelectOption
                            label={'Timezone'}
                            value={timeZone}
                            items={timeZoneList}
                            onChange={(value) => {
                                setTimeZone(value)
                            }}
                        />
                    </Grid>
                </Box>
            </Grid>
            </Box>
            <Divider light />
        </Box>;
    }

    const orderDiscountForm = () => {
        return <Box style={{pointerEvents: (grantedAccess.isPermissionActionAllowed(currentRole, 'Update Application Setting')  || currentRole == "Finance Admin" || currentRole == "BD Admin" || currentRole == "Ops Admin") ? "auto" : "none"}}>
            <OrderDiscountList />
        </Box>
    }
    const termAndConditionPopUp = () => {
        return <Dialog 
        open={openTermCondition}
        onClose={handleClose} 
        // center
      >
        <Box><Grid><SmsTermsAndConditions /></Grid></Box>
        <Box style={{display: 'flex'}} m={2}>
        <input type="checkbox"
               onChange={(e) => {handleChangeChecked(e)}}
               defaultChecked={checked} />
        <label for="vehicle2"> I agree with the term & Condition</label>
        </Box>
        <Box style={{display: 'flex', justifyContent: 'flex-end'}} mr={5} mb={2}>
        <Button 
        className="smsCancelBtn"
        onClick={() => {
            setOpenTermCondition(false);
        }}>Close</Button>
        
        <Button 
        className={checked? 'smsSubmitBtn': 'smsDisableBtn'}
        disabled = {!checked}
        onClick={() => {
            callSmsCreateApi();
            setOpenTermCondition(false);
            if(twilioSmsEnabled == true) {
                setTwilioSmsEnabled(false)  
            }
        }}>Submit</Button>
        </Box>
      </Dialog>
    }

    const terminateDialogPop = () => {
       return <CustomDialog 
      open={terminateDialog}
      title={'are you sure want to terminate ?'}
      onClose={() => {
        setTerminateDialog(false);
      }}
      onOk={() => {
        callSmsTerminateApi();
        setTerminateDialog(false);
      }}
    >
      <Box>
          <strong>Notice!</strong>
          <p>If you terminate this SMS service, you will not be able to send any SMS for your job updates subsequently</p></Box>
    </CustomDialog>
    }
    const rerequestDialogPop = () => {
    
        return <CustomDialog 
       open={rerequestDialog}
       title={'Are you sure to request again?'}
       onClose={() => {
         setRequestDialog(false);
       }}
       onOk={() => {
         callSmsRerequestApi();
         setRequestDialog(false);
       }}
     >
       <Box></Box>
     </CustomDialog>
     }

    const handleChangeChecked = (e) => {
        const { checked } = e.target
        setChecked(checked);
      }

    const formattingPreference = () => {
        return <> <Box style={{pointerEvents: (grantedAccess.isPermissionActionAllowed(currentRole, 'Update Application Setting') || currentRole == "Finance Admin" || currentRole == "BD Admin" || currentRole == "Ops Admin") ? "auto" : "none"}}>
            <Grid container p={1} >
        <CustomLabel
            label={'Formatting Preference'}
            weight={'bold'}
            size={'md'}
        />
        {(grantedAccess.isShouldShowMenu(currentRole, 'Application Settings') || currentRole == "Finance Admin" || currentRole == "BD Admin" || currentRole == "Ops Admin")? 
            <><Box clone>
                <Grid container>
                    <Grid item xs={12} lg={4} className="max-xs-width-control-1010">
                        <Box mt={1}>Delivery Order Formatting</Box>
                    </Grid>
                    <Grid item xs={12} lg={3} className="max-xs-width-control-1010">
                        <Box mt={1}>{deliveryFormattingInitial} {deliveryNoSeparator} {(deliveryFormattingYear) ? "2017" : ""} {(deliveryFormattingMonth) ? "JAN" : ""}{(deliveryFormattingDay) ? "01" : ""}
                        {/* {deliveryNoSeparator}0001 */}
                         </Box>
                    </Grid>
                    <Grid item xs={12} lg={1} className="max-xs-width-control-1010">
                        <CustomInput
                            className="custom-inline"
                            placeholder={'DO'}
                            value={deliveryFormattingInitial}
                            style={{ width: "40px", padding: "2px" }}
                            width={"50px"}
                            // error={lastNameValidated}
                            // required={true}
                            // autoFocus={true}
                            onChange={e => {
                                setDeliveryFormattingInitial(e.target.value)
                                // setLastNameValidated((e.target.value !== '') ? false : true) 
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4} className="max-xs-width-control-1010">
                        <div className="custom-input-parent">

                            <div className="custominputgroup custom-inline"
                                style={{
                                    border: "1px solid #777777a6",
                                    borderRight: "0", borderRadius: "10px 0 0 10px"
                                }}>
                                <input id="option1" name="year"
                                    checked={deliveryFormattingYear}
                                    onChange={() => setDeliveryFormattingYear(!deliveryFormattingYear)}
                                    type="checkbox" />
                                <label htmlFor="option1"
                                    style={{ borderRadius: "10px 0 0 10px", color: "#000" }}>Year</label>
                            </div>
                            <div className="custominputgroup custom-inline"
                                style={{ border: "1px solid #777777a6" }}>
                                <input id="option3" name="month"
                                    checked={deliveryFormattingMonth}
                                    onChange={() =>
                                        setDeliveryFormattingMonth(!deliveryFormattingMonth)
                                    }
                                    type="checkbox" />
                                <label htmlFor="option3" style={{ color: "#000" }}>Month</label>
                            </div>

                            <div className="custominputgroup custom-inline"
                                style={{
                                    border: "1px solid #777777a6",
                                    borderLeft: "0",
                                    borderRadius: "0 10px 10px 0"
                                }}>
                                <input id="option2" name="day"
                                    checked={deliveryFormattingDay}
                                    onChange={() =>
                                        setDeliveryFormattingDay(!deliveryFormattingDay)
                                    }
                                    type="checkbox" />
                                <label htmlFor="option2"
                                    style={{ borderRadius: "0 10px 10px 0", color: "#000" }}>Day</label>
                            </div>
                        </div>

                    </Grid>
                </Grid>
            </Box>
                <Box clone>
                    <Grid container>
                        <Grid item xs={12} lg={4} className="max-xs-width-control-1010">
                            <Box mt={1}>Delivery Order Number seperator</Box>
                        </Grid>
                        <Grid item xs={12} lg={4} className="max-xs-width-control-1010">
                            <Box mt={1}>{deliveryFormattingInitial} {deliveryNoSeparator} {(deliveryFormattingYear) ? "2017" : ""} {(deliveryFormattingMonth) ? "JAN" : ""}{(deliveryFormattingDay) ? "01" : ""}
                            {/* {deliveryNoSeparator}0001  */}
                            </Box>
                        </Grid>

                        <Grid item xs={12} lg={4}>

                            <div className="custom-input-parent">

                                <div className="custominputgroup custom-inline"
                                    style={{
                                        border: "1px solid #777777a6",
                                        borderRight: "0", borderRadius: "10px 0 0 10px"
                                    }}>
                                    <input id="option5" name="radio" type="radio"
                                        checked={deliveryNoSeparatorDash}
                                        onChange={() => {
                                            setDeliveryNoSeparator("-");
                                            setDeliveryNoSeparatorDash(true);
                                            setDeliveryNoSeparatorSlash(false);
                                            setDeliveryNoSeparatorDot(false);
                                        }
                                        }
                                    />
                                    <label htmlFor="option5"
                                        style={{ borderRadius: "10px 0 0 10px", color: "#000" }}>-</label>
                                </div>
                                <div className="custominputgroup custom-inline"
                                    style={{ border: "1px solid #777777a6" }}>
                                    <input id="option6" name="radio" type="radio"
                                        checked={deliveryNoSeparatorSlash}
                                        onChange={() => {
                                            setDeliveryNoSeparator("/")
                                            setDeliveryNoSeparatorDash(false);
                                            setDeliveryNoSeparatorSlash(true);
                                            setDeliveryNoSeparatorDot(false);
                                        }
                                        }
                                    />
                                    <label htmlFor="option6" style={{ color: "#000" }}>/</label>
                                </div>
                                <div className="custominputgroup custom-inline"
                                    style={{
                                        border: "1px solid #777777a6",
                                        borderLeft: "0",
                                        borderRadius: "0 10px 10px 0"
                                    }}>
                                    <input id="option7" name="radio" type="radio"
                                        checked={deliveryNoSeparatorDot}
                                        onChange={() => {
                                            setDeliveryNoSeparator(".")
                                            setDeliveryNoSeparatorDash(false);
                                            setDeliveryNoSeparatorSlash(false);
                                            setDeliveryNoSeparatorDot(true);
                                        }
                                        }
                                    />
                                    <label htmlFor="option7"
                                        style={{ borderRadius: "0 10px 10px 0", color: "#000" }}>.</label>
                                </div>
                            </div>

                        </Grid>
                    </Grid>
                </Box>
            </> 
             : 
            <Box>You don't have permission for this function(s) </Box> }
            </Grid>
            <Divider light/>
            <Grid container >
                <CustomLabel
                    label={'Scale Preference'}
                    weight={'bold'}
                    size={'md'}
                />
                {(grantedAccess.isShouldShowMenu(currentRole, 'Unit Measurements') || currentRole == "Finance Admin" || currentRole == "BD Admin" || currentRole == "Ops Admin") ?
                <>
                <Box clone>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <span>Length</span>
                    </Grid>
                    <Grid item xs={4}>
                        <span>100 {scalePrefLength}</span>
                    </Grid>
                    <Grid item xs={4}>
                        <CustomSelectOption
                            value={scalePrefLength}
                            items={[{ id : 1877, label: "in", value: "Inches (in)"}, { id : 1898, label: "m", value: "Metre (m)"}, { id : 1887, label: "cm", value: "centimetre (cm)"}]}
                            onChange={ (value, item) => {
                                setScalePrefLength(value)
                            }}
                        />
                        </Grid>
                        </Grid>
                </Box>
                <Box clone>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <span>Volume</span>
                    </Grid>
                    <Grid item xs={4}>
                        <span>100 {scalePrefVol}</span>
                    </Grid>
                    <Grid item xs={4}>
                        <CustomSelectOption
                            // label={'Length'}
                            value={scalePrefVol}
                            items={[{ id : 1877, label: "m3", value: "cubic metre (m3)"}, { id : 1898, label: "cm³", value: "cubic centimeter (cm³)"}, { id : 1887, label: "in³", value: "cubic inch (in³)"}]}
                            onChange={ (value, item) => {
                                setScalePrefVol(value)
                            }}
                        />
                        </Grid>
                        </Grid>
                
                </Box>
                <Box clone>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <span>Distance</span>
                        </Grid>
                        <Grid item xs={4}>
                            <span>100 {scalePrefDistance}</span>
                        </Grid>
                        <Grid item xs={4}>
                        
                            <RadioGroup aria-label="distance" name="distance" style={{display: 'block'}} value={scalePrefDistance} 
                            onChange={ (event) => {
                                setScalePrefDistance(event.target.value)
                            }}
                            >
                                <FormControlLabel style={{ display: 'inline-block', width: "99px" }}  value="kilometre (km)" control={<Radio />} label="km" />
                                <FormControlLabel style={{ display: 'inline-block', width: "99px" }}  value="Mile (mi)" control={<Radio />} label="mi" />
                            
                            </RadioGroup>
                            
                        </Grid>
                    </Grid>
                </Box>
                <Box clone>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <span>Speed</span>
                        </Grid>
                        <Grid item xs={4}>
                            <span>100 {scalePrefSpeed}</span>
                        </Grid>
                        <Grid item xs={4}>
                        
                            <RadioGroup aria-label="speed" name="speed" style={{display: 'block'}} value={scalePrefSpeed} 
                            onChange={ (event) => {
                                setScalePrefSpeed(event.target.value)
                            }}
                            >
                                <FormControlLabel style={{ display: 'inline-block',  width: "99px" }}  value="kilometre per hour (kph)" control={<Radio />} label="kph" />
                                <FormControlLabel style={{ display: 'inline-block',  width: "99px"}}  value="Miles per hour (mph)" control={<Radio />} label="mph" />
                            
                            </RadioGroup>
                            
                        </Grid>
                    </Grid>
                </Box>
                <Box clone>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <span>Weight</span>
                        </Grid>
                        <Grid item xs={4}>
                            <span>100 {scalePrefWeight}</span>
                        </Grid>
                        <Grid item xs={4}>
                        
                            <RadioGroup aria-label="weight" name="weight" style={{display: 'block'}} value={scalePrefWeight} 
                            onChange={ (event) => {
                                setScalePrefWeight(event.target.value)
                            }}
                            >
                                <FormControlLabel style={{ display: 'inline-block', width: "99px" }}  value="kilogram (kg)" control={<Radio />} label="kg" />
                                <FormControlLabel style={{ display: 'inline-block', width: "99px" }}  value="pound (lb)" control={<Radio />} label="lb" />
                            
                            </RadioGroup>
                            
                        </Grid>
                    </Grid>
                </Box>
                <Box clone>
                <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <span>DateFormat</span>
                        </Grid>
                        <Grid item xs={4}>
                            <span>{moment().format(scalePrefDateFormat?scalePrefDateFormat.replace("dd", "DD"): "")}</span>
                        </Grid>
                        <Grid item xs={4}>
                        

                        <CustomSelectOption
                            className=""
                            style={{opacity: "unset"}}
                            value={scalePrefDateFormat}
                            items={[{ id: 1866, label:"yyyy-MM-DD", exampleValue: "yyyy-MM-DD", value: "yyyy-MM-dd" }, { id: 1867, label:"DD/MM/yyyy", exampleValue: "DD/MM/yyyy", value: "dd/MM/yyyy" }, { id: 1867, label:"MM/DD/yyyy",  exampleValue: "MM/DD/yyyy",value: "MM/dd/yyyy" }, { id: 1867, label:"DD MMMM yyyy", exampleValue: "DD MMMM yyyy", value: "dd MMMM yyyy" }]}
                            // error={lastNameValidated}
                            // required={true}
                            // autoFocus={true}
                            onChange={ (value, item) => {
                                setScalePrefDateFormat(value)
                                setScalePrefDateExampleFormat(value)
                            }}
                        />
                    </Grid>
                </Grid>
                </Box>
                <Box clone>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <span>Time Format</span>
                        </Grid>
                        <Grid item xs={4}>
                            <span>11:19 {(scalePrefTimeFormat === "12-Hour" ? "AM" : "") }</span>
                        </Grid>
                        <Grid item xs={4}>
                        
                            <RadioGroup aria-label="weight" name="weight" style={{display: 'block'}} value={scalePrefTimeFormat} 
                            onChange={ (event) => {
                                setScalePrefTimeFormat(event.target.value)
                            }}
                            >
                                <FormControlLabel style={{ display: 'inline-block', width: "99px" }}  value="12-Hour" control={<Radio />} label="12-Hour" />
                                <FormControlLabel style={{ display: 'inline-block', width: "99px" }}  value="24-Hour" control={<Radio />} label="24-Hour" />
                            
                            </RadioGroup>
                            
                        </Grid>
                    </Grid>
                </Box>
                </>
            : <Box>You don't have permission for this function(s) </Box> }
            </Grid>
    </Box></>
    }
    const optionPreference = () => {
        return <Box style={{pointerEvents: (grantedAccess.isPermissionActionAllowed(currentRole, 'Update Application Setting') || currentRole == "Finance Admin" || currentRole == "BD Admin" || currentRole == "Ops Admin") ? "auto" : "none"}}><Grid container p={1}>
            <Box style={{backgroundColor:"#FAFAFA", overflow: 'auto'}} p={3} my={2}>
        <CustomLabel
            label={'Option'}
            weight={'bold'}
            size={'md'}
        />
        <Box clone style={{marginBottom:"20px"}} > 
            <Grid item xs={12}>
                <Grid component="label" container alignItems="center" spacing={3}>
                    <Grid item xs={10}><span style={{fontSize: "1rem", fontWeight: 400,lineHeight: 1.5,letterSpacing: "0.00938em",color: "#101820"}}>Enable Customers</span>
                    <Grid item><span style={{color: "#b3b3b3",fontSize: "13px"}}>This allows you to keep a list of companies you associated with and use their details when you add a job</span></Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomSwitch  
                        checked={enableCustomer}  
                        onChange={ () => 
                            setEnableCustomer(!enableCustomer)
                        }
                        label="Enable Customer"/>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
        <Divider light/>

        <Box clone style={{marginBottom:"40px"}}>
            <Grid item xs={12}>
                <Grid component="label" container alignItems="center" spacing={3}>
                    <Grid item xs={10}><span style={{fontSize: "1rem", fontWeight: 400,lineHeight: 1.5,letterSpacing: "0.00938em",color: "#101820"}}>Enable manpower</span>
                    <Grid item><span style={{color: "#b3b3b3",fontSize: "13px"}}>Enable this option if you have assistance following your drivers. </span></Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomSwitch
                        checked={enableManPower}  
                        onChange={ () => 
                            setEnableManPower(!enableManPower)
                        }
                        label="Enable Manpower"/>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
        </Box>
        </Grid>
        <Divider light/>
        <Box style={{backgroundColor:"#FAFAFA", overflow: 'auto'}} p={3} my={2}>
        <Box clone style={{marginTop:"40px"}}>
            <Grid item xs={12}>
                <span>Manpower job step status change (from mobile app) </span>
                <span>We noticed that you required manpower to assist the driver. Would you want to grant them the privilege to update the job status like the driver does ? </span>
            </Grid>
        </Box>
        <Box clone style={{marginTop:"40px"}}>
            <Grid component="label" container alignItems="center" spacing={3}>
                    <Grid item xs={10}><span style={{fontSize: "1rem", fontWeight: 400,lineHeight: 1.5,letterSpacing: "0.00938em",color: "#101820"}}>Acknowledged</span>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomSwitch 
                         checked={enableManPowerAcknowledged}  
                         onChange={ (event) => {
                             setEnableManPowerAcknowledged(!enableManPowerAcknowledged)
                         }}
                         />
                    </Grid>
            </Grid>
        </Box>
        <Divider light/>
        <Box clone>
            <Grid component="label" container alignItems="center" spacing={3}>
                    <Grid item xs={10}><span style={{fontSize: "1rem", fontWeight: 400,lineHeight: 1.5,letterSpacing: "0.00938em",color: "#101820"}}>In Progress</span>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomSwitch 
                         checked={enableManPowerInProgress}  
                         onChange={ () => 
                             setEnableManPowerInProgress(!enableManPowerInProgress)
                         }
                         />
                    </Grid>
            </Grid>
        </Box>
        <Divider light/>
        <Box clone>
            <Grid component="label" container alignItems="center" spacing={3}>
                    <Grid item xs={10}><span style={{fontSize: "1rem", fontWeight: 400,lineHeight: 1.5,letterSpacing: "0.00938em",color: "#101820"}}>Completed</span>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomSwitch 
                         checked={enableManPowerCompleted}  
                         onChange={ () => 
                             setEnableManPowerCompleted(!enableManPowerCompleted)
                         }
                         />
                    </Grid>
            </Grid>
        </Box>
        <Divider light/>
        <Box clone>
            <Grid component="label" container alignItems="center" spacing={3}>
                    <Grid item xs={10}><span style={{fontSize: "1rem", fontWeight: 400,lineHeight: 1.5,letterSpacing: "0.00938em",color: "#101820"}}>Failed</span>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomSwitch 
                         checked={enableManPowerFailed}  
                         onChange={ () => 
                             setEnableManPowerFailed(!enableManPowerFailed)
                         }
                         />
                    </Grid>
            </Grid>
        </Box>
        </Box>
        <Divider light/>
        <Box style={{backgroundColor:"#FAFAFA", overflow: 'auto'}} p={3} my={2}>
        <CustomLabel
            label={'SMS Notification'}
            weight={'bold'}
            size={'md'}
        />
        <Box clone >
            <Grid component="label" container alignItems="center" spacing={3}>
                    <Grid item xs={10}><span style={{fontSize: "1rem", fontWeight: 400,lineHeight: 1.5,letterSpacing: "0.00938em",color: "#101820"}}>Assigned</span>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomSwitch 
                         checked={enableSmsAssigned}  
                         onChange={ (event) => {
                             setEnableSmsAssigned(!enableSmsAssigned)
                         }}
                         />
                    </Grid>
            </Grid>
        </Box>
        <Divider light/>
        <Box clone>
            <Grid component="label" container alignItems="center" spacing={3}>
                    <Grid item xs={10}><span style={{fontSize: "1rem", fontWeight: 400,lineHeight: 1.5,letterSpacing: "0.00938em",color: "#101820"}}>In Progress</span>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomSwitch 
                         checked={enableSmsInProgress}  
                         onChange={ () => 
                             setEnableSmsInProgress(!enableSmsInProgress)
                         }
                         />
                    </Grid>
            </Grid>
        </Box>
        <Divider light/>
        <Box clone>
            <Grid component="label" container alignItems="center" spacing={3}>
                    <Grid item xs={10}><span style={{fontSize: "1rem", fontWeight: 400,lineHeight: 1.5,letterSpacing: "0.00938em",color: "#101820"}}>Completed</span>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomSwitch 
                         checked={enableSmsCompleted}  
                         onChange={ () => 
                             setEnableSmsCompleted(!enableSmsCompleted)
                         }
                         />
                    </Grid>
            </Grid>
        </Box>
        <Divider light/>
        <Box clone>
            <Grid component="label" container alignItems="center" spacing={3}>
                    <Grid item xs={10}><span style={{fontSize: "1rem", fontWeight: 400,lineHeight: 1.5,letterSpacing: "0.00938em",color: "#101820"}}>Failed</span>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomSwitch 
                         checked={enableSmsFailed}  
                         onChange={ () => 
                             setEnableSmsFailed(!enableSmsFailed)
                         }
                         />
                    </Grid>
            </Grid>
        </Box>
        </Box>
        <Divider light/>
        <Box style={{backgroundColor:"#FAFAFA", overflow: 'auto'}} p={3} my={2}>
        <CustomLabel
            label={'Email Notification'}
            weight={'bold'}
            size={'md'}
        />
        <Box clone >
            <Grid component="label" container alignItems="center" spacing={3}>
                    <Grid item xs={10}><span style={{fontSize: "1rem", fontWeight: 400,lineHeight: 1.5,letterSpacing: "0.00938em",color: "#101820"}}>Assigned</span>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomSwitch 
                         checked={enableEmailAssigned}  
                         onChange={ (event) => {
                             setEnableEmailAssigned(!enableEmailAssigned)
                         }}
                         />
                    </Grid>
            </Grid>
        </Box>
        <Divider light/>
        <Box clone>
            <Grid component="label" container alignItems="center" spacing={3}>
                    <Grid item xs={10}><span style={{fontSize: "1rem", fontWeight: 400,lineHeight: 1.5,letterSpacing: "0.00938em",color: "#101820"}}>In Progress</span>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomSwitch 
                         checked={enableEmailInProgress}  
                         onChange={ () => 
                             setEnableEmailInProgress(!enableEmailInProgress)
                         }
                         />
                    </Grid>
            </Grid>
        </Box>
        <Divider light/>
        <Box clone>
            <Grid component="label" container alignItems="center" spacing={3}>
                    <Grid item xs={10}><span style={{fontSize: "1rem", fontWeight: 400,lineHeight: 1.5,letterSpacing: "0.00938em",color: "#101820"}}>Completed</span>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomSwitch 
                         checked={enableEmailCompleted}  
                         onChange={ () => 
                             setEnableEmailCompleted(!enableEmailCompleted)
                         }
                         />
                    </Grid>
            </Grid>
        </Box>
        <Divider light/>
        <Box clone>
            <Grid component="label" container alignItems="center" spacing={3}>
                    <Grid item xs={10}><span style={{fontSize: "1rem", fontWeight: 400,lineHeight: 1.5,letterSpacing: "0.00938em",color: "#101820"}}>Failed</span>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomSwitch 
                         checked={enableEmailFailed}  
                         onChange={ () => 
                             setEnableEmailFailed(!enableEmailFailed)
                         }
                         />
                    </Grid>
            </Grid>
        </Box>
        </Box>
        <Divider light/>
    </Box>
    }
    const emailTemplate = () => {
        return <Box style={{pointerEvents: (grantedAccess.isPermissionActionAllowed(currentRole, 'Update Application Setting') || currentRole == "Finance Admin" || currentRole == "BD Admin" || currentRole == "Ops Admin") ? "auto" : "none"}}>
            <Grid container>
                <Box clone>
                    <Grid item xs={12}>
                        {
                        (emailTemplates && emailTemplates.length > 0)
                        ?
                        emailTemplates.map((item, i) => {
                            item.template.dynamic_content = item.template.dynamic_content.replace('ORDERNUMBER','DONUMBER');
                            item.template.available_parameters = item.template.available_parameters.replace('ORDERNUMBER','DONUMBER');
                            return <Box key={i} pb={4}>
                                <Grid container>
                                    <Box clone pb={1}>
                                        <Grid item xs={12}>
                                            <CustomLabel
                                                label={item.name}
                                                weight={'bold'}
                                                size={'md'}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone pb={1}>
                                        <Grid item xs={12}>
                                        <CustomInput
                                                placeholder={'Testing Mail'}
                                                value={testMail}
                                                onChange={ e => {
                                                   setTestMail(e.target.value);
                                                }}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone pb={1}>
                                        <Grid item xs={12} className="d-flex justify-content-end">
                                            <button style={{color: '#ffffff', backgroundColor: '#007cde', padding: '4px 10px', border: '0px solid black'}} onClick={(e)=>{
                                                e.preventDefault();
                                                e.stopPropagation();
                                                if (testMail) {
                                                    callTestMailApi(item.template.dynamic_content, item.template.available_parameters, testMail, item.template.template_name)
                                                } else {
                                                    apiUtil.toast("Please add testing email")
                                                }
                                            }}>Test Mail</button>
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            <CustomInput
                                                label={'Subject'}
                                                placeholder={'Subject'}
                                                value={item.template.email_subject}
                                                onChange={ e => {
                                                    item.template.email_subject = e.target.value;
                                                }}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            {/* <CustomInput
                                                label={'Content'}
                                                placeholder={'Content'}
                                                multiline={true}
                                                rows={10}
                                                value={item.template.dynamic_content}
                                                onChange={ e => {
                                                    item.template.dynamic_content = e.target.value;
                                                }}
                                            /> */}
                                             {/* <CodeMirror
                                            value={item.template.dynamic_content}
                                            options={{
                                                mode: 'javascript',
                                                theme: 'material',
                                                lineNumbers: true,
                                            }}
                                            onBeforeChange={item.template.dynamic_content}
                                            onChange={(value) => {
                                                item.template.dynamic_content = value;
                                            }}
                                            /> */}
                                            {/* <RSunEditor
                                                defaultValue={item.template.dynamic_content}
                                                value={item.template.dynamic_content}
                                                placeholder={"Content"}
                                                onChange={(value) => {
                                                    item.template.dynamic_content = value;
                                                }}
                                            /> */}

                                            <RTextEditor
                                                editorState={item.template.dynamic_content}
                                                placeholder={"Content"}
                                                onChange={(value) => {
                                                    item.template.dynamic_content = value;
                                                }}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            <CustomLabel
                                                label={'Available Parameters'}
                                                size={'md'}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            <CustomLabel
                                                label={item.template.available_parameters}
                                                size={'md'}
                                            />
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>
                        })
                        :
                        <>No Email Templates!</>
                        }
                    </Grid>
                </Box>
            </Grid>
        </Box>
    }
    const smsTemplate = () => {
        return <Box style={{pointerEvents: (grantedAccess.isPermissionActionAllowed(currentRole, 'Update Application Setting') || currentRole == "Finance Admin" || currentRole == "BD Admin" || currentRole == "Ops Admin") ? "auto" : "none"}}>
            <Grid container>
                <Box clone>
                    <Grid item xs={12}>
                        {(grantedAccess.admin_roles.includes(currentRole) || currentRole == "Finance Admin" || currentRole == "BD Admin" || currentRole == "Ops Admin")?
                        (smsTemplates && smsTemplates.length > 0)
                        ?<>
                        {smsTemplates.map((item, i) => {
                            return <Box key={i} pb={4}>
                                <Grid container>
                                    <Box clone pb={1}>
                                        <Grid item xs={12}>
                                            <CustomLabel
                                                label={item.name}
                                                weight={'bold'}
                                                size={'md'}
                                            />
                                        </Grid>
                                    </Box>
                                    {/* <Box clone>
                                        <Grid item xs={12}>
                                            <CustomInput
                                                label={'Subject'}
                                                placeholder={'Subject'}
                                                value={item.template.display_name}
                                                onChange={ e => {
                                                    item.template.display_name = e.target.value;
                                                }}
                                            />
                                        </Grid>
                                    </Box> */}
                                    <Box clone>
                                        <Grid item xs={12}>
                                            <CustomInput
                                                label={'Content'}
                                                placeholder={'Content'}
                                                multiline={true}
                                                rows={10}
                                                value={item.template.template}
                                                onChange={ e => {
                                                    item.template.template = e.target.value;
                                                }}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            <CustomLabel
                                                label={'Available Parameters'}
                                                size={'md'}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            <CustomLabel
                                                label={item.template.available_parameters}
                                                size={'md'}
                                            />
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>
                        })}
                         <Box pb={4}>
                                <Grid container>
                                    <Box clone pb={1}>
                                        <Grid item xs={12}>
                                            <CustomLabel
                                                label='Onboarding Driver'
                                                weight={'bold'}
                                                size={'md'}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            <CustomInput
                                                disabled = {true}
                                                label={'Content'}
                                                placeholder={'Content'}
                                                multiline={true}
                                                rows={10}
                                                value={'Welcome to EVFY Tracker! Your account has been created. \n  \nusername:[[username]]  \npassword:[[password]] \n  \nDownload iOS app:\nhttps://goo.gl/rd7r23 \nDownload Android app:\nhttps://goo.gl/hLN3UR '}
                                                // onChange={ e => {
                                                //     item.template.template = e.target.value;
                                                // }}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            <CustomLabel
                                                label={'Available Parameters'}
                                                size={'md'}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            <CustomLabel
                                                label={'password, username'}
                                                size={'md'}
                                            />
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>
                            <Box pb={4}>
                                <Grid container>
                                    <Box clone pb={1}>
                                        <Grid item xs={12}>
                                            <CustomLabel
                                                label='Customer Job Summary'
                                                weight={'bold'}
                                                size={'md'}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            <CustomInput
                                                disabled = {true}
                                                label={'Content'}
                                                placeholder={'Content'}
                                                multiline={true}
                                                rows={10}
                                                value={'Good Day [[Customer]] A parcel is schduled for delivery tomorrow. Please ensure someone is at the delivery location to pick up your order.'}
                                                // onChange={ e => {
                                                //     item.template.template = e.target.value;
                                                // }}
                                            />
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>
                        </>
                        :
                        <>No SMS Templates!</>
                        :
                        <>No SMS Templates!</>
                        }
                    </Grid>
                </Box>
            </Grid>
        </Box>
    }

    const smsRecord = () => {
        return <Box style={{pointerEvents: grantedAccess.isPermissionActionAllowed(currentRole, 'Update Application Setting') ? "auto" : "none"}}>
            <Grid container>
            <SettingTable 
                // ref={this.refTable}
                className={'job-table-list sms-table'}  
                isLoading={isLoadingSMS}
                grouping={grouping}
                limit={limits}
                page={pages}
                total={totalRecord}
                data={dataRecord}
                defaultExpanded={true}
                // selection={true}
                onPageChange={(page,limit) => {
                    setLimits(limit);
                    setPages(page);
                    callSmsRecordApi();
                }}
                onSelectionChange={(rows, column)=>{
                    // this.rowData = rows;
                }}
                // Toolbar={this.customToolbar}
                columns={[
                    { 
                      title: "Sender Company", 
                      field: "sender company",
                      cellStyle: {
                        minWidth: 220,
                        maxWidth: 220
                      },
                      render: (row, type) => {
                        if(type === 'row'){
                        return row.application_company.company_name? row.application_company.company_name : ''
                        } else {
                          return row;
                        }
                      }
                    },
                    { 
                      title: "To Customer", 
                      field: "to customer",
                      cellStyle: {
                        minWidth: 220,
                        maxWidth: 220
                      },
                      render: (row, type) => {
                        if(type === 'row'){
                        return row.to_customer? row.to_customer : '';
                        } else {
                          return row;
                        }
                      }
                    },
                    { 
                        title: "Tracker Number", 
                        field: "tracker_number",
                        cellStyle: {
                          minWidth: 220,
                          maxWidth: 220
                        },
                        render: (row, type) => {
                          if(type === 'row'){
                          return row.order_number? row.order_number : '';
                          } else {
                            return row;
                          }
                        }
                      },
                      { 
                        title: "Job Status", 
                        field: "job_status",
                        cellStyle: {
                          minWidth: 150,
                          maxWidth: 150
                        },
                        render: (row, type) => {
                          if(type === 'row'){
                          return row.order_status? row.order_status : '';
                          } else {
                            return row;
                          }
                        }
                      },
                    { 
                      title: "Status", 
                      field: "status",
                      cellStyle: {
                        minWidth: 150,
                        maxWidth: 150            
                      },
                      render: (row, type) => {
                        if(type === 'row'){
                          return <Box>
                           {row.is_success == true? 
                           <span style={{color:"green"}}>Delivered</span>:
                           <span style={{color:"orange"}}>Pending</span>
                          }
                          </Box>;
                        } else {
                          return row;
                        }
                      }
                    },
                    { 
                        title: "Date", 
                        field: "date",
                        cellStyle: {
                          minWidth: 150,
                          maxWidth: 150            
                        },
                        render: (row, type) => {
                          if(type === 'row'){
                            return row.usage_date ? row.usage_date: '';
        
                          } else {
                            return row;
                          }
                        }
                      },
                  ]}>

         </SettingTable>
            </Grid>
        </Box>
    }


    const redirectIsOnboarding = () => {
        if(isOnBoarding){
            apiUtil.toastOnBoarding('Successfully Updated', 'check_circle');
            
            setTimeout(() => {
                history.push('/onboarding');
            }, 500);
        } else {
            apiUtil.toast('Successfully Updated', 'check_circle');
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if(value === 6){ // Save Email Template - emailTemplates
            const promises = [];

            if(emailTemplates && emailTemplates.length > 0){
                emailTemplates.forEach((item, i) => {
                    if(item && item.template){
                        if(item.isNew === true){
                            promises.push(callCreateEmailTemplateApi(i, item.template));
                        } else {
                            promises.push(callUpdateEmailTemplateApi(i, item.template));
                        }
                    }
                });
            }

            if(promises && promises.length > 0){
                Promise.all(promises).then((results) => {
                    if(results && results.length > 0){
                        let typeInProgress = results.filter(x => x.type === 0);
                        if(typeInProgress && typeInProgress.length > 0){
                            let status = typeInProgress[0].status;

                            if(status){
                                apiUtil.toast('Successfully updated In Progress Email template', 'check_circle');
                            } else {
                                apiUtil.toast('An error occurred while updating the In Progress Email template', 'check_circle', 'error');
                            }
                        }

                        let typeCompleted = results.filter(x => x.type === 1);
                        if(typeCompleted && typeCompleted.length > 0){
                            let status = typeCompleted[0].status;

                            if(status){
                                apiUtil.toast('Successfully updated Completed Email template', 'check_circle');
                            } else {
                                apiUtil.toast('An error occurred while updating the Completed Email template', 'check_circle', 'error');
                            }
                        }

                        redirectIsOnboarding();
                    } else {
                        apiUtil.toast('An error has occurred', 'check_circle', 'error');
                    }
                }).catch((e) => {
                    apiUtil.toast('An error has occurred', 'check_circle', 'error');
                });
            }
        } else if(value === 4){ // Save SMS Template
            const promises = [];

            if(smsTemplates && smsTemplates.length > 0){
                smsTemplates.forEach((item, i) => {
                    if(item && item.template){
                        if(item.isNew === true){
                            promises.push(callCreateSMSTemplateApi(i, item.template));
                        } else {
                            promises.push(callUpdateSMSTemplateApi(i, item.template));
                        }
                    }
                });
            }

            if(promises && promises.length > 0){
                Promise.all(promises).then((results) => {
                    if(results && results.length > 0){
                        // let typeInProgress = results.filter(x => x.type === 0);
                        // if(typeInProgress && typeInProgress.length > 0){
                        //     let status = typeInProgress[0].status;

                        //     if(status){
                        //         apiUtil.toast('Successfully updated In Progress SMS template', 'check_circle');
                        //     } else {
                        //         apiUtil.toast('An error occurred while updating the In Progress SMS template', 'check_circle', 'error');
                        //     }
                        // }

                        // let typeCompleted = results.filter(x => x.type === 1);
                        // if(typeCompleted && typeCompleted.length > 0){
                        //     let status = typeCompleted[0].status;

                        //     if(status){
                        //         apiUtil.toast('Successfully updated Completed SMS template', 'check_circle');
                        //     } else {
                        //         apiUtil.toast('An error occurred while updating the Completed SMS template', 'check_circle', 'error');
                        //     }
                        // }

                        results.forEach((item, i) => {
                            if(item && item.status){
                                apiUtil.toast('Successfully updated SMS template', 'check_circle');
                            } else {
                                apiUtil.toast('An error occurred while updating the SMS template', 'check_circle', 'error');
                            }
                        });
                    } else {
                        apiUtil.toast('An error has occurred', 'check_circle', 'error');
                    }
                }).catch((e) => {
                    apiUtil.toast('An error has occurred', 'check_circle', 'error');
                });
            }
        } else {
            var data = [];
            currentApplicationData.map((res)=>{                                                                     
                switch(res.rule)
                {
                    case "manpower_label":
                            data.push({
                                id: res.id?res.id:1042,
                                view_name: manPowerLabel
                            });
                        break;
                    case "driver_label":
                            data.push({
                                id: res.id?res.id:1041,
                                view_name: driverLabel
                            });
                        break;
                    case "sms_credentials":
                    case "twilio_sms_credentials":
                        data.push({
                            id: res.id,
                            enable: twilioSmsEnabled,
                            json_data: JSON.stringify({ sms_provider: 'twilio', account_key : twilioSmsKey, from_number : fromNumber, account_secret: twilioSmsSecret })
                        });
                        break;
                    case "email_sms_notification_status":
                        data.push({
                            id: res.id,
                            json_data: JSON.stringify({ assigned_sms: enableSmsAssigned, in_progress_sms : enableSmsInProgress, completed_sms : enableSmsCompleted, failed_sms: enableSmsFailed, assigned_email: enableEmailAssigned, in_progress_email : enableEmailInProgress, completed_email : enableEmailCompleted, failed_email: enableEmailFailed })
                        });
                        break;
                }

            });

            if (data.length == 0){
                data = [{
                            id: 1042,
                            view_name: manPowerLabel
                        }, {
                            id: 1041,
                            view_name: driverLabel
                        }, {
                            id: 3800,
                            enable: twilioSmsEnabled,
                            json_data: JSON.stringify({ sms_provider: 'twilio', account_key : twilioSmsKey, from_number : fromNumber, account_secret: twilioSmsSecret })
                        }];
            }
            
            let increment_by = deliveryFormattingDay? "increment_by_day": deliveryFormattingMonth ? "increment_by_month": "increment_by_year";
            let format = "";
            format = format.concat(deliveryFormattingYear? "%Y": "", deliveryFormattingMonth? "%b": "", deliveryFormattingDay? "%d": "")  ;//"OOo-[[%Y%b%d]]-[[increment_by_day]]"
            let rule_format = deliveryFormattingInitial + deliveryNoSeparator + "[["+format+"]]" + deliveryNoSeparator + "[["+ increment_by +"]]";
            let available_parameters = format +","+ increment_by; 
            // const deliveryOrderFormattingData = {"id":1048,"rule":"do_number_format","enable":true,"description":"%Y - eg: 2017, %b - eg: Jan, %B - eg: January, %d - eg: 31, 01","application_company_id":117,"group_name":"Delivery Order","current_status_general_id":null,"to_status_general_id":null,"is_man_power_worker":null,"is_scan_required":null,"view_name":"Reference/DO Number Format","available_parameters":available_parameters,"rule_format": rule_format,"current_step_status_id":null,"to_step_status_id":null,"job_step_type_id":null,"json_data":null};
            let fieldNames = ["Delivery Order"];
            let deliveryOrderFormattingData= {};
            let getRequireParameters = currentApplicationData.map((data) => {
                fieldNames.forEach((fieldName) => {
                    if(data.group_name === fieldName && rule_format !== data.rule_format) {
                        Object.assign(deliveryOrderFormattingData,{
                            "application_company_id": data.application_company_id,
                            "rule_format": rule_format,
                            "available_parameters": available_parameters,
                            "group_name": fieldName,
                            "id": data.id   
                        });  

                    }
                })
        
            })
            callUpdatewithPutApplicationCompanySettings(deliveryOrderFormattingData, () => {
                
                apiUtil.toast('Successfully Updated Formatting Preference', 'check_circle');
            })
        

            let scaleFieldNames = ["Volume", "Length", "Weight", "Distance", "Speed", "Date Format", "Time Format"];
            let measurementData= [];

            const getMeasurementFormat = (data) => {
                measurementData.push({
                    "application_company_id": data.application_company_id,
                    "id": data.id,
                    "is_active": true, 
                    "module": "all"
                });
            }

            let getRequireParametersForMeasurement = currentMeasurementData.map((data) => {
                scaleFieldNames.forEach((option) => {
                    if(data.measurement_type === option) {
                        switch (option) {
                            case "Volume":
                                if(data.measurement === scalePrefVol && preScalePrefVol !== scalePrefVol) {
                                    getMeasurementFormat(data);
                                }
                                break;
                            case "Length":
                                if(data.measurement === scalePrefLength && preScalePrefLength !== scalePrefLength) {
                                    getMeasurementFormat(data);
                                }
                                break;
                            case "Weight":
                                if(data.measurement === scalePrefWeight && preScalePrefWeight !== scalePrefWeight) {
                                    getMeasurementFormat(data);
                                }
                                break;
                            case "Distance":
                                if(data.measurement === scalePrefDistance && preScalePrefDistance !== scalePrefDistance) {
                                    getMeasurementFormat(data);
                                }
                                break;
                            case "Speed":
                                if(data.measurement === scalePrefSpeed && preScalePrefSpeed !== scalePrefSpeed) {
                                    getMeasurementFormat(data);
                                }
                                break;
                            case "Date Format":
                                if(data.measurement === scalePrefDateFormat && preScalePrefDateFormat !==scalePrefDateFormat) {
                                    getMeasurementFormat(data);
                                }
                                break;
                            case "Time Format":
                                if(data.measurement === scalePrefTimeFormat && preScalePrefTimeFormat !==scalePrefTimeFormat) {
                                    getMeasurementFormat(data);
                                }
                                break;
                            default:
                                break;
                        }
                    }
                })
            })

            let measurementObj = {measurements_data:measurementData};
            callUpdateMeasurements( measurementObj, () => {
                // history.push('/resources/transport-operator');
                apiUtil.toast('Successfully Updated', 'check_circle');
            });
            let options = ["Customer", "mobile", "Country"];
            let isEnable;
            let optionsData= [];
            const getOptionDataFormat = (data, isEnable) => {
                optionsData.push({
                "application_company_id": data.application_company_id,
                "enable": isEnable,
                "group_name": data.group_name,
                "id": data.id  
                })
            }

            let getRequireParametersForOption = currentApplicationData.map((data) => {
                options.forEach((option) => {
                    if(data.group_name === option) {
                        switch (option) {
                            case "Customer":
                                getOptionDataFormat(data, enableCustomer);
                                break; 
                            case "Country":
                                let jsonParse = data && data.json_data ? JSON.parse(data.json_data) : null;
                                let oldCountry = jsonParse !== null ? jsonParse.name : '';
                                if(country !== oldCountry) {
                                    let newJson = {name: country, code: countryCode, identifier: countryIdentifier, offset: timeZone }
                                    optionsData.push({
                                        "application_company_id": data.application_company_id,
                                        "enable": isEnable,
                                        "group_name": data.group_name,
                                        "id": data.id ,
                                        "json_data": JSON.stringify(newJson),  
                                        })
                                }
                                break;
                            case "mobile":
                                if(data.description === "Manpower can update this status") {
                                    if(enableManPower !== data.enable) {
                                        getOptionDataFormat(data, enableManPower);
                                    }
                                } else if(data.view_name === "Acknowledged" && data.enable !== enableManPowerAcknowledged) {
                                    getOptionDataFormat(data, enableManPowerAcknowledged);
                                } else if(data.view_name === "Completed" && data.enable !== enableManPowerCompleted) {
                                    getOptionDataFormat(data, enableManPowerCompleted);
                                } else if (data.view_name === "In Progress" && data.enable !== enableManPowerInProgress) {
                                    getOptionDataFormat(data, enableManPowerInProgress);
                                } else if (data.view_name === "Failed" && data.enable !== enableManPowerFailed) {
                                    getOptionDataFormat(data, enableManPowerFailed);
                                }
                                break;
                            default:
                                break;
                        }
                        
                    }
                })
        
            })
            let mergeData = optionsData.concat(data);
            let optionDataFormat = {application_settings_data : mergeData};
            callUpdateApi( optionDataFormat, () => {
                // history.push('/resources/transport-operator');
                redirectIsOnboarding();
            })
        }
    }

    return (
    <Box className="settings--form" >
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <StickyPageHeader isSmall={true}>
                <CustomStickyPageHeader />
            </StickyPageHeader>
            <Container maxWidth="lg">
                <Grid container>

                    <Grid item xs={3}>
                        <Box clone marginTop={3}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Settinsg Menus"
                            className="tab_align"
                        >
                            <Tab label="General Preference" {...a11yProps(0)} style={{left:'0'}}/>
                            <Tab label="Formatting Preference" {...a11yProps(1)} />
                            {/* <Tab label="Options" {...a11yProps(2)} /> */}
                                <div 
                                style={{
                                    cursor:'pointer',
                                    textAlign: 'left',
                                    padding: '10px'
                                }}
                                onClick={()=>{setSmsSettingDropdown(!smsSettingDropdown)}}>SMS SETTINGS {smsSettingDropdown== true ? <strong>-</strong> : <strong>+</strong> }</div>
                                <Tab label="Enable SMS" {...a11yProps(2)} className="tab_label" style={smsSettingDropdown== true ?{display: 'block'} : {display: 'none'}}/>
                                <Tab label="SMS Template" {...a11yProps(3)} className="tab_label" style={smsSettingDropdown== true ?{display: 'block'} : {display: 'none'}}/>
                                <Tab label="User SMS Record" {...a11yProps(4)} className="tab_label" style={smsSettingDropdown== true ?{display: 'block'} : {display: 'none'}}/>
                                <Tab label="Email Template" {...a11yProps(5)} />
                                <Tab label="Order Discounts" {...a11yProps(7)} />
                        </Tabs>
                        </Box>
                    </Grid>

                    <Grid item xs={9}>
                        <Card className="custom-card-margin-x">
                        
                        <TabPanel value={value} index={0}>
                           {/* {settingsGeneral()}     */}
                           {optionPreference()}    

                        </TabPanel>
                        <TabPanel value={value} index={1}>
                           {formattingPreference()}
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            {smsGeneralSetting()}
                            {termAndConditionPopUp()}
                            {terminateDialogPop()}
                            {rerequestDialogPop()}
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            {smsTemplate()}
                        </TabPanel>
                        <TabPanel value={value} index={5}>
                            {smsRecord()}
                        </TabPanel>
                        <TabPanel value={value} index={6}>
                            {emailTemplate()}
                        </TabPanel>
                        <TabPanel value={value} index={7}>
                            {orderDiscountForm()}
                        </TabPanel>
                        </Card>
                    </Grid>

                </Grid>

            </Container>
        </form>
    </Box>
    )
}
