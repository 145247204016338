import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import BreadCrumb from '../../components/Navs/Breadcrumb';
import moment from 'moment';
import jsPDF from "jspdf";
import QRCode  from "qrcode.react";
import EVFYLogo from '../../assets/img/evfy-logo.png';
import ReactDOM from 'react-dom';
import Barcode from 'react-barcode';
import CSVfile from "../../assets/files/DeliveryTemplate.xlsx";
import ClientCSVfile from "../../assets/files/DeliveryTemplateClient.xlsx"
import AdminCSVfile from "../../assets/files/DeliveryTemplateAdmin.xlsx"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faEye, faBarcode, faFile, faCopy, faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { 
  Box,
  Grid,
  IconButton,
  Tab,
  Tabs,
  InputLabel,
  Select,
  CircularProgress,
  Typography,
} from '@material-ui/core';

import Icon from '@material-ui/core/Icon';

import { ReactComponent as JobsIcon } from '../../assets/img/left_menu/jobs.svg';
import { ReactComponent as SearchIcon } from '../../assets/img/icons/search.svg';
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';
import { ReactComponent as ExportIcon } from '../../assets/img/icons/export.svg';
import { ReactComponent as ImportIcon } from '../../assets/img/icons/import.svg';
import { ReactComponent as CopyIcon } from '../../assets/img/icons/copy.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import { ReactComponent as CalendarIcon } from '../../assets/img/icons/calendar.svg';
import { ReactComponent as AutoIcon } from '../../assets/img/icons/flash.svg';
import { ReactComponent as LabelIcon } from '../../assets/img/icons/view_week.svg';
import Settingimg from '../../assets/img/icons/setting-balck.svg';
import SettingWhite from '../../assets/img/icons/setting.svg';
import Filterimg from '../../assets/img/icons/filter.png';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import Table from '../../components/Table/Table.jsx';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker.jsx';

import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomCheckbox from '../../components/CustomFields/CustomCheckbox.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import CustomFormDialog from '../../components/Dialog/CustomFormDialog.jsx';
import GroupButton from '../../components/GroupButton/GroupButton.jsx';
import CircleIcon from '../../components/CircleIcon/CircleIcon.jsx';
import StatusList from '../../components/Status/StatusList.jsx';
import StatusItem from '../../components/Status/StatusItem.jsx';
import DropdownMenu from '../../components/Dropdown/DropdownMenu.jsx';
import CustomAutosuggest from '../../components/CustomFields/CustomAutosuggest.jsx';
import CustomUploadButton from '../../components/CustomFields/CustomUploadButton.jsx';
import CustomGuideStepper from '../../components/Stepper/CustomGuideStepper.jsx';

import JobPreviewDialog from './Partial/JobPreviewDialog.jsx';
import JobImportDialog from './Partial/JobImportDialog.jsx';
import BatchUpdateForm from './Partial/BatchUpdateForm.jsx';
import BatchUpdateAssignForm from './Partial/BatchUpdateAssignForm.jsx';
import BatchUpdateJobStatusForm from './Partial/BatchUpdateJobStatusForm.jsx';
import AutoAssignForm from './Partial/AutoAssignForm.jsx';

import socketIOClient from "socket.io-client";
import { Observable } from 'rxjs/Observable';
import {RoleAccessService} from '../../data/role-access'; 
import Tour from 'reactour';
import HtmlParser from 'react-html-parser';

import MaterialTable, { MTableGroupbar, MTableBodyRow, MTableHeader } from "material-table";
import Pagination from '@material-ui/lab/Pagination';



const { 
  REACT_APP_JOB_UPDATE_SOCKET_URL,
  REACT_APP_LDS_API_URL,
} = process.env;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  };
}
 
export class JobList extends Component {
  static contextType = AuthContext;
  rowData =null;
  pages = 1;
  limits = 50;
  search = '';
  do_number = '';
  uploder_id = '';
  constructor(props){
    super(props); 
    this.pageName = 'jobList';
    let initStatusSelected = (this.props.location && this.props.location.state && this.props.location.state.index && this.props.location.state.index > 0) ? this.props.location.state.index : null;
    let initOrderStatus = (this.props.location && this.props.location.state && this.props.location.state.order_status && this.props.location.state.order_status > 0) ? this.props.location.state.order_status : null;
    let isShouldDisableSocketToast = (this.props.location && this.props.location.state && this.props.location.state.isShouldDisableSocketToast && this.props.location.state.isShouldDisableSocketToast === true) ? this.props.location.state.isShouldDisableSocketToast: false;
    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();
    this.refTableDoSearch = React.createRef();
    this.refTableUploaderSearch = React.createRef();
    this.refTableAdvSearch = React.createRef();

    let rangeDate = {
      startDate: moment(),
      endDate: moment().add(2,'days')
    };
    // let rowData = null;
    let rangeDateItem = localStorage.getItem(this.pageName + '_rangeDate');
    if(rangeDateItem && rangeDateItem !== ''){
      try {
        rangeDate = JSON.parse(rangeDateItem);
      }catch(e){}
    }
    // var selectRow = [];
    let hiddenColumns = [];
    let columnHiddenSettingsId = 0;
    let columnHiddenSettings = JSON.parse(localStorage.getItem('job_column_settings'));
    if (columnHiddenSettings !== null) {
      hiddenColumns = columnHiddenSettings.json_data.replace(/[^0-9a-zA-Z-_.,]/g, "").split(",");
      columnHiddenSettingsId = columnHiddenSettings.id ? columnHiddenSettings.id : 0;
    }
    // const grantedAccess = new RoleAccessService();
    // const currentRole = window.localStorage.getItem('current_role') || null;
    this.state = {
      grantedAccess : new RoleAccessService(),
      currentRole: window.localStorage.getItem('current_role') || null,
      jobTab: 0,
      jobTabTable: 0,
      current_status_name: "",
      total_current_status_job: 0,
      dateRangePickerIsOpen: false,
      isShouldDisableSocketToast: isShouldDisableSocketToast,
      rangeDate: rangeDate,
      startDate: rangeDate.startDate,
      endDate: rangeDate.endDate,

      isLoadingStatus: false,
      status: [],
      tableStatus: [],
      statusSelected: initStatusSelected,
      order_status: initOrderStatus,

      isLoading: false,
      grouping: true,
      data: [],
      total: 0,
      selectedRow:[],
      openDialogBatchUpdate:false,
      openDialog: false,
      openDialogMassDelete: false,
      openDialogItem: null,
      openDialogAutoAssign:false,
      invalidAddress:[],

      openPreviewDialog: false,
      openPreviewDialogItem: null,

      openImportDialog: false,
      openImportDialogItem: null,
      openImportDialogIsLoading: false,
      openBatchUpdateisLoading: false,

      openSettingsDialog: false,
      autoAssign: false,
      defaultGroupOrder: -1,

      isTourOpen: false,
      openSkipDialog: false,

      hiddenColumnSettingId: columnHiddenSettingsId,
      hiddenColumns: hiddenColumns,

      tabValue: 0,
      uploaders: [],
      selected_uploader: '',
      copyDialogOpen: false,
      copyData: {
        id: 0,

        drop_off_date: true,
        drop_off_time_planned: true,
        drop_off_description: true,
        
        drop_off_name: true,
        drop_off_contact_email: true,
        drop_off_contact_no: true,
        item_tracking_number: true,

        order_details: true,
        job_steps: true,
        drop_off_worker: true,
        amount: true,
        drop_off_contact_name:true,
        order_number: true,
        do_number: true,
        drop_off_postal_code: true,
        drop_off_address: true,
        driver_notes: true,
        driver_payout: true,
        document: true,
        boxes: true,
        pallets: true,
        cartons:true,
        package_type:true,
        package_quantity:true,
      }
    }
  }
  

  componentDidMount() {
    const { isOnBoarding } = this.context;
    // console.log("render");
    if(isOnBoarding){
      // console.log("render in if function");
      this.setState({
        isTourOpen: true,
      });
    }

      // this.socketData();
      this.loadStatusApi(() => {
        // console.log("render in status api");
      this.callReadApi();
    }); 

  }

  


  callInitApis = () => {

    this.loadStatusApi();
    this.callReadApi();
  }

  loadStatusApi = (callback = null) => {
    this.callStatusApi((data, total) => {
      if(data && data.length > 0){
        let status = data.map((item, i) => {
          return {
            id: item.status_details.id,
            status: item.status_details.status,
            text: item.status_details.status,
            total: item.total_job,
          };
        });
  
        let tableStatus = status;
        status.unshift({
          id: 0,
          status: 'Total Orders',
          total: total,
        });
  
        this.setState({
          status: status,
          tableStatus: tableStatus,
        }, () => {
          if(callback) {
            callback();
          }
        });
      } else {
        if(callback) {
          callback();
        }
      }
    });
  }

  /* SOCKET */
  getMessages() { 
    const { user } = this.context;

    let application_company_id = apiUtil.getAppCompanyId(user);

    let observable = new Observable(observer => {
      this.socket = socketIOClient(REACT_APP_JOB_UPDATE_SOCKET_URL);

      this.socket.on('connect', () => {
        // console.log("SOCKET", 'connect', this.socket.connected);
      });

      this.socket.emit("join", "job_updated");

      this.socket.on(application_company_id.toString(), (data) => {
        observer.next(data);    
      });

      return () => {
        this.socket.disconnect();
      };  
    }) 
    return observable;
  }
  
  socketData(){
    this.connection = this.getMessages().subscribe(message => {
      
      this.dataMessage = message;
      this.dataMessage = this.dataMessage.order_history;
      if(this.dataMessage && !this.isArray(this.dataMessage)){
        let data = []; data.push(this.dataMessage);
        this.dataMessage= data;
      }
      if(this.dataMessage.length > 0){
        this.dataMessage.forEach(dataMessage=>{
          let actionText = ''
          let userName = ''
          if(dataMessage.admin_id){
            if(dataMessage.action === 'Created'){
              actionText = ' was created by ';
            }else if(dataMessage.action === 'Deleted Order'){
              actionText = ' was deleted by ';
            }else if(dataMessage.action === 'Updated'){
              if(dataMessage.attributes_updated && dataMessage.attributes_updated.includes('order_status_id') !== -1){
                actionText = ' status has been updated to ';
              }else{
                actionText = ' has been updated  by ';
              }
            }
            userName = dataMessage.admin.first_name

          }else if(dataMessage.worker_id){

            userName = dataMessage.worker.first_name
          }

          const icon =  dataMessage.admin_id ? "account_box" : "local_shipping";

          dataMessage.toShow = <div>
          <i className={'material-icons'}>{icon}</i>
          <b style={{marginRight:'5px'}}>{dataMessage.order_reference_no} </b>
          <span style={{marginRight:'5px'}}>{actionText}</span>
          {dataMessage.attributes_updated && dataMessage.attributes_updated.includes('order_status_id') !== -1 && 
          <b style={{marginRight:'5px'}}>{" " + dataMessage.current_status.status + " by "}</b>}
          <b>{userName}</b>
          </div>;
          if(this.state.isShouldDisableSocketToast === true){
            apiUtil.toast('', icon, '', 5000, dataMessage.toShow);
          }
          dataMessage.read_by_me = this.findRead(dataMessage.history_read_shown_markers);
          dataMessage.show_by_me = false;
          if(this.findNew(dataMessage.history_read_shown_markers)){ 
            dataMessage.show_by_me = true;
          }else{this.hadNew = true; }


          localStorage.setItem("doReload", JSON.stringify(dataMessage));
        });
      }
    });
  }
  

  isArray = (value) => {
    return value && typeof value === 'object' && value.constructor === Array;
  }

  findNew = (arrayList) => {
    let isNewItem = false;
    if(arrayList && arrayList.length > 0){
      arrayList.forEach(objItem=>{
        if(objItem.admin_id === localStorage.getItem("admin") && objItem.is_shown){
          isNewItem = true;
        }
      });
    }
    return isNewItem;
  }

  findRead = (arrayList) => {
    let isReadItem = false;
    if(arrayList && arrayList.length > 0){
      arrayList.forEach(objItem=>{
        if(objItem.admin_id === localStorage.getItem("admin") && objItem.is_read){
          isReadItem = true;
        }
      });
    }


    return isReadItem;
  }
  /* END SOCKET */


  /* API */
  callStatusApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoadingStatus: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
      };

      ldsApi.read('orders/job/stats', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              let total = data.total_delivery;
              let result = Object.keys(data.result).map((k) => data.result[k]);
              
              if(result && result.length > 0){
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(result, total);
                  }
                });
              } else {
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(null, 0);
                  }
                });
              }
            } else {
              this.setState({
                isLoadingStatus: false,
              }, () => {
                if(callback){
                  callback(null, 0);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoadingStatus: false,
            }, () => {
              if(callback){
                callback(null, 0);
              }
            });
          });
      });
    });
  }
  
  callReadApi = (search = '', do_num = '', uploader = '') => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
      let param = {
        page: this.pages,
        take: this.limits,
        search: this.search,
        start_date: startDate,
        end_date: endDate,
        order_status: this.state.order_status,
        do_number: this.do_number,
        uploader: this.uploder_id,
        sort: "latest"
      };
      
      // if(order)
      ldsApi.read('orders', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              data: (data.result && data.result.length > 0) ? data.result : [],
              uploaders: (data.uploaders && data.uploaders.length > 0) ? data.uploaders : [],
              total: data.total,
              isLoading: false,
            });

            // this.loadStatusApi();
          }, (error, type) => {
            this.setState({
              data: [],
              total: 0,
              uploaders:[],
              isLoading: false,
            });
          });
      });
    });
  }

  callUpdateIsActiveApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.update('orders/' + row.id, row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callDeleteApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      let param = {
        destroy_all: true,
      };

      ldsApi.delete('delete/orders/multiple?id=' + row.id, param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  /* Start Mass Delete Api*/

  callMassDeleteApi = ( callback = null) => {
    const { accessToken } = this.context;

   
      let param = {
        destroy_all: true,
      };
      let selectedRow = this.rowData;
      let ids = '';
      if(selectedRow) {
        selectedRow.map((item, i) => { ids += item.id +','; });
      } else {
        apiUtil.toast('You must select order!', 'warning', 'error');
        this.setState({
          openDialogMassDelete: false,
        })
        return
      }
      ids = ids.slice(0, -1);
      this.setState({
        isLoading: true
        }, () => {
      ldsApi.delete('delete/orders/multiple?id=' + ids, param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {

            this.loadStatusApi();
            this.callReadApi();            
            apiUtil.toast('Successfully Deleted', 'check_circle');
            this.rowData = null
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  /*End Mass Delete Api*/

  callUpdateStatusApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('orders/assign/order', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result[0] : null;
            this.loadStatusApi()
            if(result){
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(result);
                }
              });
            } else {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END API */

  callMassUpdateStatusApi = (row, callback = null) => {
    const { accessToken } = this.context;
    //console.log(row.data[0])
    if (!row.data[0].id.length > 0) {
      apiUtil.toast('You must select order!', 'warning', 'error');
      return
    }
    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('orders/assign/order', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result : null;
            this.loadStatusApi()
            if(result){
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(result);
                  this.callReadApi();
                }
              });
            } else {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoading: false,
              openDialogChangePayout: false,
              openDialogUnassign: false
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }

  callAutoAssignApi = (row, callback = null) => {
    const { accessToken } = this.context;
    this.setState({
      autoAssign: true
    }, () => {
      ldsApi.create('auto_assign/orders', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data.status){
              this.setState({
                autoAssign: false,
              }, () => {
                if(callback){
                  callback(data);
                }
              });
            } else {
              this.setState({
                autoAssign: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              autoAssign: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }

  /* WORKER API */
  callWorkerDriverReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    let param = {
      // page: 1,
      disabled: false,
      // take: apiUtil.getDefaultPageSize(),
      is_active: true,
      search: search,
      is_truck_driver: true,
      require_worker_only: true,
    };

    api.read('workers', param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result && data.result.length > 0) ? data.result : [];

          let arr = [];
          if(result && result.length > 0){
            arr = result.map((item, i) => {
              let value = item.id;
              let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

              if(arr.findIndex(x => x.value === value) === -1){
                return {
                  value: value,
                  label: label,
                  item: item
                }
              } else {
                return null;
              }
            });
    
            if(callback){
              callback(arr);
            }
          } else {
            if(callback){
              callback([]);
            }
          }
        }, (error, type) => {
          if(callback){
            callback([]);
          }
        });
    });
  }

  callManpowerDriverReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    let param = {
      // page: 1,
      // take: apiUtil.getDefaultPageSize(),
      disabled: false,
      is_active: true,
      search: search,
      is_man_power: true,
      require_worker_only: true,
    };

    api.read('workers', param, accessToken)
    .then((result) => {
      apiUtil.parseResult(result, (data) => {
        let result = (data.result && data.result.length > 0) ? data.result : [];

        let arr = [];
        if(result && result.length > 0){
          arr = result.map((item, i) => {
            let value = item.id;
            let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

            if(arr.findIndex(x => x.value === value) === -1){
              return {
                value: value,
                label: label,
                item: item
              }
            } else {
              return null;
            }
          });
  
          if(callback){
            callback(arr);
          }
        } else {
          if(callback){
            callback([]);
          }
        }
      }, (error, type) => {
        if(callback){
          callback([]);
        }
      });
    });
  }
  /* END WORKER API */


  /* EXPORT API */
  callExportXLSApi = () => {
    const { accessToken } = this.context;

    this.setState({
        downloadIsLoading: true
    }, () => {
      let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
        do_number: this.do_number,
        uploader: this.uploder_id,
        search: this.search,
        order_status_ids: this.state.order_status,
      };

      ldsApi.exportXLS('admin/api/orders/delivery/export', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (response) => {
            apiUtil.saveToFile(response, `Jobs ${moment(startDate).format('DD-MM-YYYY')} - ${moment(endDate).format('DD-MM-YYYY')}.xls`);
    
            this.setState({
              downloadIsLoading: false,
            });
          }, (error, type) => {
            this.setState({
              downloadIsLoading: false,
            });
          });
      });
    });
  }
  /* END EXPORT API */


  /* IMPORT API */
  callImportApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('create/orders/multiple', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END IMPORT API */
  

  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    const {  isOnBoarding } = this.context;
    const currentRole = this.state.currentRole
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb xs={isOnBoarding ? 'auto' : true} />
        {isOnBoarding && <Box clone pr={1}>
          <Grid item xs={12} sm={true}>
            {this.setStepper()}
          </Grid>
        </Box>}
        <Box clone pr={{ xs: 0, md: 1 }} pb={{ xs: 2, md: 0 }}>
          <Grid item xs={12} md={'auto'}>
            <CustomDateRangePicker
              className={'select-dates-step'}
              range={this.state.rangeDate}
              onChange={(range) => {
                localStorage.setItem(this.pageName + '_rangeDate', JSON.stringify(range));

                this.setState({
                  rangeDate: range,
                  startDate: range.startDate,
                  endDate: range.endDate
                }, () => {
                  this.callInitApis();
                });
              }}
            />
          </Grid>
        </Box>
        <Box clone pr={1}>
          <Grid item xs={'auto'}>          
          <Link to={(currentRole == "Super Admin Role" || currentRole == "Admin Template Roles" || currentRole == "LDS Template Role") ? AdminCSVfile : ClientCSVfile} target="_blank" download style={{ textDecoration: 'none' }}>
            <CustomButton color={'Primary'} >
              <ImportIcon />
                  <Box display={'inline-block'} pl={1}>CSV Template</Box>
            </CustomButton>
            </Link>
          </Grid>
        </Box>
        <Box clone pr={1}>
          <Grid item xs={'auto'}>
          {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Add')) && <CustomUploadButton 
              color={'primary'}
              accept={'.xls, .xlsx'}
              onChange={(files) => {
                if (files && files.length > 0){
                  this.setState({
                    openImportDialog: true,
                    openImportDialogItem: files[0],
                  });
                }
              }}
            >
              {/* <ImportIcon /> */}
              Import Jobs
            </CustomUploadButton>
           }
          </Grid>
        </Box>
        <Box clone pr={1}>
          <Grid item xs={'auto'}>
          {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Add')) && <CustomButton 
              className={'new-job-step'}
              color={'secondary'}
              onClick={() => {
                this.props.history.push('/jobs-form');
              }}
            >
              + New Job
            </CustomButton>
          }
          </Grid>
        </Box> 
        { this.state.current_status_name === "Not Assigned" && moment(this.state.rangeDate.startDate).format("YYYY-MM-DD") === moment(this.state.rangeDate.endDate).format("YYYY-MM-DD") ?
          <Box clone>
            <Grid item xs={'auto'}>
              <CustomButton 
                color={'secondary'}
                onClick={() => {
                  this.props.history.push({
                    pathname: '/jobs/route-optimize',
                    state: {
                      total_current_status_job: this.state.total_current_status_job,
                      rangeDate: {
                        startDate: moment(this.state.rangeDate.startDate).format("YYYY-MM-DD"),
                        endDate: moment(this.state.rangeDate.endDate).format("YYYY-MM-DD")
                      }
                    }
                  });
                }}
              >
              Route Optimize
              </CustomButton>
            </Grid>
          </Box>: ""
        }
        
        <Box clone pt={{ xs: 4, md: 0 }}>
          <Grid item xs={12}>
            <GroupButton
              className={'head-tabs'}
              color={'secondary'}
              selected={this.state.jobTab}
              buttons={[ "Job List", "Job Schedule" ]}
              onClick={(selected, btn) => {
                if(selected === 1){
                  this.props.history.push('/job-schedule');
                }
              }}
            />
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */


  /* STATUS */
  customStatus = () => {
    return <Grid container alignItems={'center'}>
      <Box clone mb={1}>
        <Grid item xs={'auto'}>
          <CircleIcon>
            <JobsIcon />
          </CircleIcon>
        </Grid>
      </Box>
      <Box clone pl={3} pr={3}>
        <Grid item xs={'auto'}>
          <CustomLabel
            className={'no-margin'}
            label={'Jobs List'}
            weight={'bold'}
            size={'md'}
          />
          <CustomLabel
            label={'Based on recent activities'}
            size={'xs'}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12} lg={true}>
          <Box className={'custom-status-list-wrapper'}>
            <StatusList
              selected={(this.state.statusSelected > 0) ? this.state.statusSelected : 0}
              items={this.state.status.map((item, i) => {
                return <StatusItem
                  text={<Box>
                    <FontAwesomeIcon icon={faCircle} component={'i'} className={'custom-dot status ' + (item.status.replace(' ', '-').trim().toLowerCase())}/>
                    {/* <Icon component={'i'} className={'custom-dot status ' + (item.status.replace(' ', '-').trim().toLowerCase())}>lens</Icon> */}
                    <span>{item.status}</span>
                  </Box>}
                  count={item.total}
                />
              })}
              onClick={(index) => {
                let defaultGroupOrder = -1;
                if(index > 1){
                  defaultGroupOrder = 0;
                }

                this.setState({
                  statusSelected: index,
                  order_status: (index && index > 0) ? this.state.status[index].id : null,
                  current_status_name: (index && index > 0) ? this.state.status[index].status : null,
                  defaultGroupOrder: defaultGroupOrder,
                  total_current_status_job: (index && index > 0) ? this.state.status[index].total : 0,
                }, () => {
                  this.callInitApis();
                });
              }}
            />
          </Box>
        </Grid>
      </Box>
    </Grid>
  }
  /* END STATUS */


  /* TABLE */
  customTable = () => {
    const { isCustomerRequired } = this.context;
    if(!this.state.isLoading) {

      return <Table 
      ref={this.refTable}
      className={'job-table-list table-last-child own-job' }  
      isLoading={this.state.isLoading}
      isDraggable={false}
      grouping={false}
      limit={this.limits}
      page={this.pages}
      total={this.state.total}
      data={this.state.data}
      defaultExpanded={true}
      selection={true}
      onPageChange={(page,limit) => {
          // this.setState({
          //   limit:limit,
          //   page:page
          // }, () => {
            this.pages  = page;
            this.limits =  limit;
            this.callReadApi();
          // });
      }}
      onSelectionChange={(rows, column)=>{
        // console.log("columns", column);
        this.rowData = rows;
      }}
      Toolbar={this.customToolbar}
      // Row={this.customRow}
      columns={[
        { 
          title: "Job Number",
          field: "order_number",
          cellStyle: {
            minWidth: 240,
            maxWidth: 240            
          },
          hidden: this.state.hiddenColumns.includes('order_number'),
          render: (row, type) => {
            if(type === 'row'){
              if(this.state.grantedAccess.admin_roles.includes(this.state.currentRole)) {
              return <Box>
                {(row.order_sequence && row.order_sequence !== null) ?
                <span className="sequence-circle">{row.order_sequence}</span>: ""}
                <div className={row.order_sequence !== null ? "float-right" : ""}>
                  <Box pb={2}>
                    <CustomButton
                      className={'underline'}
                      color={'primary'}
                      href={'/'}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        this.setState({
                          openPreviewDialog: true,
                          openPreviewDialogItem: row.id,
                          // openPreviewDialogItem: row.drop_off_worker_id,
                          // openPreviewDialogItem: row.drop_off_worker.first_name
                        });
                      }}
                    >
                      {(row && row.order_number) ? row.order_number : ''}
                    </CustomButton>
                  </Box>
                    <Box>
                    <DropdownMenu 
                      isShouldEnable={true}
                      className={'status-btn ' + apiUtil.statusToClassName((row.order_status && row.order_status.status && row.order_status.status !== '') ? row.order_status.status : '')}
                      text={(row && row.order_status && row.order_status.status) ? row.order_status.status+'   ' : ''}
                      rightIcon={<ArrowDownIcon />}
                      items={this.state.tableStatus}
                      onClick={(item) => {
                        if(row.drop_off_worker_id && row.drop_off_worker_id > 0 || item.text.toString().toLowerCase().trim() === 'cancelled' || item.text.toString().toLowerCase().trim() === 'not assigned'){
                          let data = {
                            data: [
                              {
                                id: [ row.id ],
                                job_steps: (row.job_steps && row.job_steps.length > 0) ? row.job_steps.map(step => {
                                  step.job_step_id = step.id;
                                  if (item.text.toString().toLowerCase().trim() === "completed") {
                                    step.job_step_status_id = 3;
                                  } else {
                                    step.job_step_status_id = 1;
                                  }
                                  return step
                                }): row.job_steps,
                                order_status_id: item.id,
                                send_notification_to_customer: true,
                              }
                            ]
                          };

                          if(item.text.toString().toLowerCase().trim() === 'not assigned'){
                            data.data[0]['drop_off_worker_id'] = null;
                            data.data[0]['extra_worker_ids'] = null;
                          }

                          this.callUpdateStatusApi(data, (newRow) => {
                            if(newRow){
                              let newData = apiUtil.updateRow(this.state.data, newRow, 'id');
                              this.setState({
                                data: newData,
                              });
                            }
                          });
                        } else {
                          apiUtil.toast('You must first assign a driver!', 'warning', 'error'); 
                        }
                      }}
                    />          
                  </Box>
                 
                </div>
              </Box>;
              } else {
                return <Box>
                {(row.order_sequence && row.order_sequence !== null) ?
                <span className="sequence-circle">{row.order_sequence}</span>: ""}
                <div className={row.order_sequence !== null ? "float-right" : ""}>
                  <Box pb={2}>
                    <CustomButton
                      className={'underline'}
                      color={'primary'}
                      href={'/'}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        this.setState({
                          openPreviewDialog: true,
                          openPreviewDialogItem: row.id,
                          // openPreviewDialogItem: row.drop_off_worker_id,
                          // openPreviewDialogItem: row.drop_off_worker.first_name
                        });
                      }}
                    >
                      {(row && row.order_number) ? row.order_number : ''}
                    </CustomButton>
                  </Box>
                    <Box>
                    <CustomButton
                      isShouldEnable={true}
                      className={'custom-status status-btn ' + apiUtil.statusToClassName((row.order_status && row.order_status.status && row.order_status.status !== '') ? row.order_status.status : '')}
                      text={(row && row.order_status && row.order_status.status !== '') ? row.order_status.status+'   ' : ''}
                      items={this.state.tableStatus}
                      
                    >  {row.order_status.status} </CustomButton>       
                  </Box>
                </div>
              </Box>;
              }  
          } else {
              return row;
            }
          }
        },
        { 
          title: "Description", 
          field: "drop_off_description",
          cellStyle: {
            minWidth: 350,
            maxWidth: 350
          },
          hidden: this.state.hiddenColumns.includes('drop_off_description'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.drop_off_description && row.drop_off_description !== '') ? HtmlParser(row.drop_off_description) : '';
              // return (row && row.drop_off_description && row.drop_off_description !== '') ?<span style={{ width:350, overflowWrap: 'break-word', display:'inline-block'}}> {row.drop_off_description }</span>  : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "DO Number", 
          field: "do_number",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          hidden: this.state.hiddenColumns.includes('do_number'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.do_number && row.do_number !== '') ? <span style={{ width:120, overflowWrap: 'break-word', display:'inline-block'}}>{row.do_number.replace(/\s/g, '\u00A0')}</span> : '';
            } else {
              return row;
            }
          }
        },
        ... this.state.currentRole !== "Customer Template Role"  && this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver Payout')? [{ 
          title: "Driver Payout", 
          field: "driver_payout",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          hidden: this.state.hiddenColumns.includes('driver_payout'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.driver_payout && row.driver_payout !== '') ? HtmlParser(row.driver_payout) : '';
            } else {
              return row;
            }
          }
        }] : [],
        { 
          title: "Driver", 
          field: "drop_off_worker_name",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150            
          },
          hidden: this.state.hiddenColumns.includes('driver'),
          defaultGroupOrder: this.state.defaultGroupOrder,
          render: (row, type) => {
            if(type === 'row'){
              if(row.drop_off_worker_id && row.drop_off_worker_id > 0 && row.drop_off_worker){
                // if(!row.drop_off_worker.hasOwnProperty("last_name")){
                //   row.workerName = row.drop_off_worker.first_name;
                // }else{
                //   row.workerName = row.drop_off_worker.first_name +" "+ row.drop_off_worker.last_name;
                // }
                row.workerName = row.drop_off_worker.first_name +" "+ row.drop_off_worker.last_name;
              }else{

                row.workerName = row.drop_off_by;
              }

              return <Box>
                <CustomAutosuggest 
                  disabled = {this.state.grantedAccess.isShouldShowJobAccess(this.state.currentRole, 'job_status') == false || (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver & Manpower Listing')) == false ? true: false}
                  className={'no-margin'}
                  placeholder={'Assign Driver'}
                  value={row.workerName}
                  onChange={(value) => {
                    let status = '';
  
                    if(value){
                      row.workerName = value.label;
                      row.workerId = value.value;
                      row.workerItem = value.item;
  
                      status = 'assigned';
                    } else {
                      row.workerName = '';
                      row.workerId = null;
                      row.workerItem = null;
  
                      status = 'not assigned';
                    }

                    // let orderStatusItem = apiUtil.customFilter(this.state.status, 'job_assign', status);}
                    let orderStatusItem = apiUtil.customFilter(this.state.status, 'status', status);
                    let data = {
                      data: [
                        {
                          id: [ row.id ],
                          job_steps: row.job_steps ? row.job_steps.map(item=> {
                            item.job_step_id = item.id
                            return item
                          }): row.job_steps,
                          order_status_id: (orderStatusItem) ? orderStatusItem.id : '',
                          send_notification_to_customer: true,
                          drop_off_worker_id: row.workerId,
                        }
                      ]
                    };
                    this.callUpdateStatusApi(data, (newRow) => {
                      if(newRow){
                        let newData = apiUtil.updateRow(this.state.data, newRow, 'id');
                        this.setState({
                          data: newData,
                        });
                      }
                    });
                  }}
                  onSearch={(value, e) => {
                    row.workerName = value;
  
                    this.callWorkerDriverReadApi(value, (arr) => {
                      e.showNewSuggestions(arr);
                    });
                  }}
                  onClick={(value, e) => {
                    this.callWorkerDriverReadApi(value, (arr) => {
                      e.showNewSuggestions(arr);
                    });
                  }}
                />
              </Box>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Manpower", 
          field: "extra_workers[0].first_name",
          hidden: this.state.hiddenColumns.includes('manpower'),
          render: (row, type) => {
            if(type === 'row'){
              let label = '';
              let manpowerName = (row.extra_workers && row.extra_workers.length > 0) ? row.extra_workers[0] : null;

              if(manpowerName){
                label = ((manpowerName.first_name && manpowerName.first_name !== '') ? manpowerName.first_name : '') + ' ' + ((manpowerName.last_name && manpowerName.last_name !== '') ? manpowerName.last_name : '');
              }

              row.manpowerName = label;

              return <Box>
                <CustomAutosuggest 
                  disabled = {this.state.grantedAccess.isShouldShowJobAccess(this.state.currentRole, 'job_status') == false || (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver & Manpower Listing')) == false ? true: false}
                  className={'no-margin'}
                  placeholder={'Assign Manpower'}
                  value={row.manpowerName}
                  onChange={(value) => {
                    if(value){
                      row.manpowerName = value.label;
                      row.manpowerId = value.value;
                      row.manpowerItem = value.item;
                    } else {
                      row.manpowerName = '';
                      row.manpowerId = null;
                      row.manpowerItem = null;
                    }

                    if (row.job_steps === undefined) {
                      row.job_steps = [];
                    }

                    let data = {
                      data: [
                        {
                          id: [ row.id ],
                          job_steps: row.job_steps.length > 0 ? row.job_steps.map(item=> {
                            item.job_step_id = item.id
                            return item
                          }): row.job_steps,
                          order_status_id: row.order_status_id,
                          send_notification_to_customer: true,
                          extra_worker_ids: (row.manpowerId) ? row.manpowerId.toString() : null,
                        }
                      ]
                    };
                    this.callUpdateStatusApi(data, (newRow) => {
                      if(newRow){
                        let newData = apiUtil.updateRow(this.state.data, newRow, 'id');
                        this.setState({
                          data: newData,
                        });
                      }
                    });
                  }}
                  onSearch={(value, e) => {
                    row.manpowerName = value;

                    this.callManpowerDriverReadApi(value, (arr) => {
                      e.showNewSuggestions(arr);
                    });
                  }}
                  onClick={(value, e) => {
                    this.callManpowerDriverReadApi(value, (arr) => {
                      e.showNewSuggestions(arr);
                    });
                  }}
                />
              </Box>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "POD Time", 
          field: "drop_off_time",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150            
          },
          hidden: this.state.hiddenColumns.includes('drop_off_time'),
          render: (row, type) => {
            if(type === 'row'){
              return (row.order_status && row.order_status.status && row.order_status.status.toString().toLowerCase().trim().replace(' ', '-') === 'completed') ? HtmlParser(row.drop_off_time) : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Date", 
          field: "drop_off_date",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150            
          },
          hidden: this.state.hiddenColumns.includes('drop_off_date'),
          render: (row, type) => {
            if(type === 'row'){
              if(row && row.drop_off_date && row.drop_off_date !== '') {
                var splitdate = row.drop_off_date.split(" ")
                return splitdate[0];
              } else {
                return '';
              }
            } else {
              return row;
            }
          }
        },
        { 
          title: "Time Slot", 
          field: "drop_off_time_planned",
          hidden: this.state.hiddenColumns.includes('drop_off_time_planned'),
          cellStyle: {
            minWidth: 180,
            maxWidth: 180
          },
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.drop_off_time_planned && row.drop_off_time_planned !== '') ? HtmlParser(row.drop_off_time_planned) : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Company Name",
          field: "drop_off_contact_name",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150            
          },
          hidden: this.state.hiddenColumns.includes('drop_off_contact_name'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.drop_off_contact_name && row.drop_off_contact_name !== '') ? <span style={{ width:150, overflowWrap: 'break-word', display:'inline-block'}}> {row.drop_off_contact_name }</span>: '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Receipient Name", 
          field: "drop_off_name",
          hidden: this.state.hiddenColumns.includes('drop_off_name'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.drop_off_name && row.drop_off_name !== '') ? <span style={{ width:150, overflowWrap: 'break-word', display:'inline-block'}}> {row.drop_off_name}</span> : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Phone Number", 
          field: "drop_off_contact_no",
          hidden: this.state.hiddenColumns.includes('drop_off_contact_no'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.drop_off_contact_no && row.drop_off_contact_no !== '') ? <span style={{ width:150, overflowWrap: 'break-word', display:'inline-block'}}> {row.drop_off_contact_no}</span> : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Notify Email", 
          field: "notification_email",
          hidden: this.state.hiddenColumns.includes('notification_email'),
          render: (row, type) => {
            if(type === 'row'){
              // return (row && row.notification_email && row.notification_email !== '') ? row.notification_email.substring(0, 50)+'...' : '';
              return (row && row.notification_email && row.notification_email !== '') ? <span style={{ width:250, overflowWrap: 'break-word', display:'inline-block'}}> {row.notification_email }</span>: '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Postal Code", 
          field: "drop_off_postal_code",
          hidden: this.state.hiddenColumns.includes('drop_off_postal_code'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.drop_off_postal_code && row.drop_off_postal_code !== '') ? row.drop_off_postal_code : '';
            } else {
              return row;
            }
          }
        },

        { 
          title: "Delivery Address", 
          field: "drop_off_address",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          hidden: this.state.hiddenColumns.includes('drop_off_address'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.drop_off_address && row.drop_off_address !== '') ? <span style={{ width:250, overflowWrap: 'break-word', display:'inline-block'}}> {row.drop_off_address}</span> : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Document", 
          field: "document",
          cellStyle: {
            minWidth: 50,
            maxWidth: 50
          },
          hidden: this.state.hiddenColumns.includes('document'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.extra_data && row.extra_data !== '') ? JSON.parse(row.extra_data).document : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Boxes", 
          field: "boxes",
          cellStyle: {
            minWidth: 50,
            maxWidth: 50
          },
          hidden: this.state.hiddenColumns.includes('boxes'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.extra_data && row.extra_data !== '') ? JSON.parse(row.extra_data).boxes : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Pallets", 
          field: "pallets",
          cellStyle: {
            minWidth: 50,
            maxWidth: 50
          },
          hidden: this.state.hiddenColumns.includes('pallets'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.extra_data && row.extra_data !== '') ? JSON.parse(row.extra_data).pallets : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Cartons",
          field: "cartons",
          cellStyle: {
            minWidth: 50,
            maxWidth: 50
          },
          hidden: this.state.hiddenColumns.includes('cartons'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.extra_data && row.extra_data !== '') ? JSON.parse(row.extra_data).cartons : '';
            } else {
              return row;
            }
          }
        },
        
        // { 
        //   title: (isCustomerRequired) ? "Company" : 'Recipient', 
        //   field: (isCustomerRequired) ? "company_name" : 'drop_off_name',
        //   hidden: (this.state.hiddenColumns.includes('drop_off_contact_name') || this.state.hiddenColumns.includes('drop_off_name')),
        //   render: (row, type) => {
        //     let date = (row && row.drop_off_date && row.order_status) ? moment(row.drop_off_date).format(apiUtil.getDefaultDateFormat()) : '';
        //     let time = (date && date !== '') ? (row && row.drop_off_time_planned && row.drop_off_time_planned) ? moment(row.drop_off_time_planned).format(apiUtil.getDefaultTimeFormat()) : '' : '';
            
        //     let dateTime = '';
        //     if(date && time){
        //       dateTime = date + ' - ' + time;
        //     } else if (date){
        //       dateTime = date;
        //     } else if (time){
        //       dateTime = time;
        //     }

        //     if(type === 'row'){
        //       return <Box>
        //         <Box pb={2}>
        //           {isCustomerRequired ? row.company_name : row.drop_off_name}
        //         </Box>
        //         <Box>
        //           <Box>
        //             {dateTime}
        //           </Box>
        //           <Box>{row.job_steps && row.job_steps.length > 0 ? row.job_steps[0].location : ''}</Box>
        //           {row.amount !== null && <Box>Price: ${Number.parseFloat(row.amount).toLocaleString()}</Box>}
        //         </Box>
        //       </Box>;
        //     } else {
        //       return row;
        //     }
        //   }
        // },
        
 
        /*{ 
          title: "Delivery Info", 
          field: "drop_off_time_planned",
          hidden: this.state.hiddenColumns.includes('delivery_info'),
          render: (row, type) => {
            if(type === 'row'){
              let date = (row && row.drop_off_date && row.order_status) ? moment(row.drop_off_date).format(apiUtil.getDefaultDateFormat()) : '';
              let time = (date && date !== '') ? (row && row.drop_off_time_planned && row.drop_off_time_planned) ? moment(row.drop_off_time_planned).format(apiUtil.getDefaultTimeFormat()) : '' : '';
              
              let dateTime = '';
              if(date && time){
                dateTime = date + ' - ' + time;
              } else if (date){
                dateTime = date;
              } else if (time){
                dateTime = time;
              }
              
              return <div>
              <Box>
              {dateTime}
              </Box>
              <p>{row.job_steps && row.job_steps.length > 0 ? row.job_steps[0].location : ''}</p>
              {row.amount !== null && <p>Price: ${Number.parseFloat(row.amount).toLocaleString()}</p>}
              </div>;
            } else {
              return row;
            }
          }
        },*/
        { 
          title: "Job Type",
          field: "job_type",
          cellStyle: {
            minWidth: 180,
            maxWidth: 180
          },
          hidden: this.state.hiddenColumns.includes('job_type'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.job_type && row.job_type !== '') ? row.job_type : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Notes to Driver-Partner",
          field: "driver_notes",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          hidden: this.state.hiddenColumns.includes('driver_notes'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.driver_notes && row.driver_notes !== '') ? row.driver_notes : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Uploader",
          field: "uploader",
          cellStyle: {
            minWidth: 50,
            maxWidth: 50
          },
          hidden: this.state.hiddenColumns.includes('uploader'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.uploader && row.uploader !== '') ? `${row.uploader.first_name ? row.uploader.first_name : ''}  ${row.uploader.last_name ? row.uploader.last_name: ''}` : '';
            } else {
              return row;
            }
          }
        },
        ... this.state.grantedAccess.admin_roles.includes(this.state.currentRole)  ? [{ 
          title: "Price", 
          field: "price",
          cellStyle: {
            minWidth: 100,
            maxWidth: 100
          },
          hidden: this.state.hiddenColumns.includes('price'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.amount && row.amount !== '') ? HtmlParser(row.amount) : '';
            } else {
              return row;
            }
          }
        }] : [],
        { 
          title: (<img src={Settingimg} width='30px' />),
          cellStyle: {
            minWidth: 180,
            maxWidth: 180,
            position: 'sticky',
            right:0,
            zIndex:11,
            overflow: 'visible',
            backgroundColor: '#FFFFFF',
            
          },
          // align: 'center',
          sorting: false,
          render: (row) => {
            return <Box>
              <Grid container justify={'center'}>
              <Box clone>
                  <Grid item xs={'auto'}>
                  {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')) &&<IconButton                 
                       onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
  
                          this.setState({
                            openPreviewDialog: true,
                            openPreviewDialogItem: row.id,
                          });
                        }}
                    >
                      <span  data-tooltip="view job" data-tooltip-conf="danger">
                      <FontAwesomeIcon  icon={faEye} component={'i'} style={{ fontSize: '18px', color: '#4e4e4e' }} className="tooltip" />
                        {/* <Icon component={'i'} style={{ fontSize: '20px', color: '#4e4e4e' }} className="tooltip">remove_red_eye_icon</Icon> */}
                      </span>
                    </IconButton>
                  }
                  </Grid>

                </Box><Box clone>
                  <Grid item xs={'auto'}>
                    <IconButton
                      onClick={() => {
                        this.generatePDF(row);
                      }}
                    >
                      <span  data-tooltip="generate label" data-tooltip-conf="danger">
                      <FontAwesomeIcon  icon={faBarcode} component={'i'} style={{ fontSize: '18px', color: '#4e4e4e' }} className="tooltip" />
                       {/* <Icon component={'i'} style={{ fontSize: '20px', color: '#4e4e4e' }} className="tooltip">view_week</Icon> */}
                      </span>
                    </IconButton>
                  </Grid>
                </Box>
                <Box clone>
                  <Grid item xs={'auto'}>
                    <IconButton
                      onClick={() => {
                        window.open(REACT_APP_LDS_API_URL + '/dynamic_delivery_orders_steps/' + row.id + '.pdf','_blank');
                      }}
                    >
                      <span className="tooltip--left" data-tooltip="download pod" data-tooltip-conf="danger">
                      <FontAwesomeIcon  icon={faFile} component={'i'} style={{ fontSize: '18px', color: '#4e4e4e' }} className="tooltip" />
                        {/* <Icon component={'i'} style={{ fontSize: '20px', color: '#4e4e4e' }} className="tooltip">insert_drive_file</Icon> */}
                      </span>
                    </IconButton>
                  </Grid>
                </Box>

                <Box clone>
                  <Grid item xs={'auto'}>
                    <IconButton
                      onClick={() => {
                        let copyData = this.state.copyData;
                        copyData.id = row.id;
                        this.setState({
                          copyDialogOpen: true,
                          copyDataId: copyData
                        });
                      }}
                    >
                      <span  data-tooltip="duplicate job" data-tooltip-conf="danger">
                      <FontAwesomeIcon  icon={faCopy} component={'i'} style={{ fontSize: '18px', color: '#4e4e4e' }} className="tooltip" />
                        {/* <CopyIcon /> */}
                     </span>
                    </IconButton>
                  </Grid>
                </Box>
                
                <Box clone>
                  <Grid item xs={'auto'}>
                  {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Edit')) &&<IconButton
                      onClick={() => {
                        this.props.history.push({
                          pathname: '/jobs-form',
                          state: {
                            id: row.id
                          }
                        });
                      }}
                    >
                       <span  data-tooltip="edit job" data-tooltip-conf="danger">
                       <FontAwesomeIcon  icon={faPen} component={'i'} style={{ fontSize: '18px', color: '#4e4e4e' }} className="tooltip" />
                         {/* <EditIcon className="tooltip"/> */}
                        </span>
                    </IconButton>
                  }
                  </Grid>
                </Box>
                <Box clone>
                  <Grid item xs={'auto'}>
                  {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Delete')) &&<IconButton
                      onClick={() => {
                        this.setState({
                          openDialog: true,
                          openDialogItem: row,
                        });
                      }}
                    >
                      <span className="tooltip--left" data-tooltip="delete job" data-tooltip-conf="danger">
                      <FontAwesomeIcon  icon={faTrashCan} component={'i'} style={{ fontSize: '18px', color: '#CF3E3E' }} className="tooltip" />
                        {/* <DeleteIcon className="tooltip"/> */}
                      </span>
                    </IconButton>
              }
                  </Grid>
                </Box>
              </Grid>
            </Box>
          }
        },
      ]}
    />

    }else {
      return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
        <CircularProgress size={35} />
      </Box>
    }

    
  }

  handleTabChange = (event, newValue) => {
    this.setState({
        tabValue: newValue
    })
  }
  customToolbar = (e) => {
    let user = JSON.parse(window.localStorage.getItem("user"));
    let currentRole = window.localStorage.getItem("current_role")

    let isOwner = false;
    if(user != null){
       isOwner = user.owner_info.is_customer_admin !== undefined ? user.owner_info.is_customer_admin : false;
    }

    return <Box>
      <Grid container>
        
          
        {/* <Box clone order={{ xs: 3, md: 1}}>
          <Grid item xs={12} md={3}>
            <CustomInput
              ref={this.refTableSearch}
              placeholder={'Search job'}
              endAdornment={
                <IconButton
                  onClick={(e) => {
                    this.callReadApi(this.refTableSearch.current.state.value);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              }
              onPressEnter={(e) => {
                this.callReadApi(e.target.value);
              }}
            />
          </Grid>

        </Box>*/}
        <Grid container item  xs={12} md={12} justify='flex-end'>

          {(currentRole == "Super Admin Role" || currentRole == "Admin Template Roles" || currentRole == "LDS Template Role") ? (
          <Box clone pb={{ xs: 2, md: 0}} order={{ xs: 2, md: 2}} pr={{ xs: 0, md: 2}} textAlign={'right'}>
            <Grid item xs={12} md={true}>
              <GroupButton
                color={'secondary'}
                selected={this.state.jobTabTable}
                buttons={[ "Own Jobs", "Vendor Jobs", "Table Column" ]}
                onClick={(selected, buttons) => {
                  if (selected === 2) {
                    this.setState({
                      openSettingsDialog: true
                    });
                  } else if (selected === 1){
                    this.setState({
                      jobTabTable: 1,
                    }, () => {
                      this.props.history.push('/job-vendor-list');
                    });
                  }
                }}
              />
            </Grid>
          </Box>
          )
          :
          (
          <Box clone pb={{ xs: 2, md: 0}} order={{ xs: 2, md: 2}} pr={{ xs: 0, md: 2}} textAlign={'right'}>
          <Grid item xs={12} md={true}>
            <GroupButton
              color={'secondary'}
              selected={this.state.jobTabTable}
              buttons={[ "Table Column" ]}
              onClick={(selected, buttons) => {
                if (selected == 0) {
                  this.setState({
                    openSettingsDialog: true
                  });
                } 
              }}
            />
          </Grid>
        </Box>
          )}
           <Box clone textAlign={'right'} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}} style={{paddingLeft:'5px', paddingRight:'5px'}}>
              <Grid item xs={12} md={'auto'}>
                  {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Order Auto Assign')) && 
                  <CustomButton 
                    color={'primary'}
                    onClick={() => {
                      
                      let selectedRow = this.rowData;
                      let ids = '';
                      if(selectedRow) {
                        selectedRow.map((item, i) => { ids += item.id +','; });
                      } else {
                        apiUtil.toast('You must select order!', 'warning', 'error');
                        return
                      }
                      let params = {
                        order_ids: ids
                      }
                      this.callAutoAssignApi(params,(data)=>{
                        
                        this.props.history.push({
                          pathname: "/auto-assign-preview",
                          orders_data: data
                        });
                      })
                    }}>
                    <ExportIcon /> &nbsp;&nbsp;
                    <Box display={'inline-block'}>Auto Assign</Box>
                  </CustomButton>
                }
              </Grid>
            </Box>
          { 
            isOwner ? "" :
            
            <Box clone textAlign={'right'} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}} style={{paddingLeft:'5px', paddingRight:'5px'}}>
              <Grid item xs={12} md={'auto'}>
                  {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Batch Upate')) && <CustomButton 
                    color={'secondary'}
                    onClick={() => {
                        this.props.history.push('/jobs/bulk-update');
                    }}>
                    <EditIcon />
                    <Box display={'inline-block'}>Batch Update</Box>
                  </CustomButton> 
                }
              </Grid>
            </Box>

          }          
          <Box clone textAlign={'right'} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}} style={{paddingLeft:'5px', paddingRight:'5px'}}>
              <Grid item xs={12} md={'auto'}>
                  <DropdownMenu
                  text={'Actions'}
                  color={'primary'}
                  className={'job-list-action'}
                  leftIcon={<img src={SettingWhite} style={{width:20,marginTop:"3px"}}/>}
                  rightIcon={<ArrowDownIcon />}
                  items={this.getItemforAction()}
                  onClick={(item) => {
                    switch(item.id) {
                      case 1:
                        this.setState({
                          openDialogJobAssign: true
                        });
                        break;
                      case 2:
                        this.setState({
                          openDialogChangePayout: true
                        })
                        break;
                      case 3:
                        this.setState({
                          openDialogDateChange: true
                        });
                        
                        break;
                      case 4:
                        this.setState({
                          openDialogChangeStatus: true
                        });
                        break;
                      case 5:
                        this.setState({
                          openDialogUnassign: true
                        })
                        break;
                      case 6:
                        this.setState({
                          openDialogMassDelete: true
                        });
                        break;
                      case 7:
                        this.callExportXLSApi();
                        break;
                      case 8:
                        this.generatePDF();
                        break;
                        case 9:
                          this.generatePOD();
                          break;
                      default:
                        break;
    
                  }
                  }}
                />
                
              </Grid>
          </Box> 
        </Grid>
          {/* <Box clone textAlign={'right'} pl={1} pr={1} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}}>
            <Grid item xs={12} md={'auto'} > 
                {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Delete')) &&           
                <CustomButton 
                  deleteClass = "deleteBtn"
                  // disabled = { true }
                  color={'secondary'}
                  onClick={() => {
                    this.setState({
                      openDialogMassDelete: true
                    });
                  }}
                >
                  <DeleteIcon />
                  <Box display={'inline-block'} pl={1}>Bulk Delete</Box>
                </CustomButton> 
              }
            </Grid>
          </Box> */}
          {/* <Box clone textAlign={'right'} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}}>
            <Grid item xs={12} md={'auto'}> 
            {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Export Jobs')) && <CustomButton 
                  color={'primary'}
                  onClick={() => {
                    this.callExportXLSApi();
                  }}
                >
                  <ExportIcon />
                  <Box display={'inline-block'} pl={1}>Export To Excel</Box>
                </CustomButton>
            }
            </Grid>
          </Box> */}
          <Box style={{marginTop:"10px"}}>
          <Tabs value={this.state.tabValue} onChange={this.handleTabChange} aria-label="basic tabs example">
            <Tab label={<span><SearchIcon />Search</span>} style={{border:"1px solid #00000017", marginRight:"2px", textTransform:'none'}}  {...a11yProps(0)} />
            <Tab label="Advanced Search" style={{border:"1px solid #00000017", textTransform:'none'}} {...a11yProps(1)} />
          </Tabs>
        </Box>
        {(this.search !== '' || this.do_number !== '' || this.uploder_id !== '') && <Box mb={1}>
          <IconButton
          onClick={e => {
            this.search = '';
            this.do_number = '';
            this.uploder_id = '';
            this.setState({
              selected_uploader:''
            }, () =>{
              this.callReadApi()
            })
            
          }}
          ><span  data-tooltip="clear search" data-tooltip-conf="danger"><img src={Filterimg} width="25px" /></span></IconButton>

          </Box>
        }
        
      </Grid>
      <Box mb={2} mt={2}> 
        <TabPanel value={this.state.tabValue} index={0}><Grid container>
          
          <Box clone >
            <Grid item xs={8} md={3}>
              <CustomInput
                ref={this.refTableSearch}
                placeholder={'Job Number, Company Name, Address...'}
                value={this.search}
                className='margin_right_10px'
              />
              
            </Grid>
            
          </Box>
          <Grid Grid item xs={4} md={3}>
            <CustomButton
                color={"secondary"}
                onClick={() => {
                  let search = this.refTableSearch.current.state.value
                  this.search = search
                  this.callReadApi(search);
                }}
              >
                <SearchIcon /><Box >Search</Box>
              </CustomButton>
            </Grid>
            
          </Grid>
        </TabPanel>
        <TabPanel value={this.state.tabValue} index={1}><Grid container>
        
            <Box clone  >
              <Grid item xs={6} md={3}>
                <CustomInput 
                ref={this.refTableAdvSearch}
                value={this.search}
                label={'Keywords'}
                placeholder={'Job Number, Company Name, Address...'}
                className='margin_right_10px'
                />
              </Grid>
            </Box>
            <Box clone >
              <Grid item xs={6} md={3}>
                <CustomInput 
                ref={this.refTableDoSearch}
                value={this.do_number}
                label={'Do Number'}
                placeholder={'Enter Do Number here...'}
                className='margin_right_10px'
                />
              </Grid>
            </Box>
            <Box clone >
              <Grid item xs={6} md={3}>
                <Box className="custom-label-component">
                  <InputLabel >Uploader</InputLabel>
                </Box>
                <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
                  placeholder={'Enter Uploader here...'}
                  // ref={this.refTableUploaderSearch}
                  value={this.state.selected_uploader}
                  native
                  onChange={(e) => {
                    this.setState({
                      selected_uploader: e.target.value
                    })
                  }}
                >
                  <option value=""> Select Uploader </option>
                  {this.state.uploaders.length > 0 && this.state.uploaders.map(uploader => {
                  return( <option value={uploader.id}>{uploader.first_name ? uploader.first_name : ""} {uploader.last_name? uploader.last_name : ""} </option>)
                  })
                  }
                </Select>
              </Grid>
            </Box>
            <Box clone >
              <Grid item xs={6} md={3}>
              <CustomButton
              color={"secondary"}
              className={"margin_left_10px margin_top_30px"}
              onClick={() => {
                this.search = this.refTableAdvSearch.current.state.value
                this.do_number = this.refTableDoSearch.current.state.value
                this.uploder_id = this.state.selected_uploader
                this.callReadApi()
              }}
              >
                <SearchIcon /><Box ml={1}>Search</Box>
              </CustomButton>
              </Grid>
          </Box>
            
          </Grid>
          
        </TabPanel>

      </Box>
    </Box>
  }

  getItemforAction = () => {
  
    let item = [];

    if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Delete')){
      item.push({          
          id: 6,
          text: <Box display={'inline-block'} >Delete</Box>,          
      })
    }

    if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver Payout') && this.state.currentRole !== "Customer Template Role") {
      item.push({          
      id: 2,
      text: <Box display={'inline-block'} >Change Driver Payout</Box>,          
      })
    }

    if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Batch Upate') || this.state.currentRole !== "Customer Template Role"){
      item.push({          
        id: 3,
        text: <Box display={'inline-block'} >Change Date</Box>,          
      })
    }

    if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Batch Upate') && this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver & Manpower Add') && this.state.currentRole !== "Customer Template Role"){
      item.push({          
        id: 4,
        text: <Box display={'inline-block'} >Change Job Status</Box>,          
      })
    }
    if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Batch Upate') && this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver & Manpower Add') && this.state.currentRole !== "Customer Template Role") {
      item.push({          
          id: 1,
          text: <Box display={'inline-block'} >Assign Driver</Box>,          
      })
      
      item.push({          
        id: 5,
        text: <Box display={'inline-block'} >Unassign Driver</Box>,          
      })
      
    }

    if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Export Jobs')) {
      item.push({
        id: 7,
        text: <Box display={'inline-block'}>Export CSV</Box>,
      }) 
    }
    if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Add')){
      item.push({          
          id: 8,
          text: <Box display={'inline-block'} >Generate Label</Box>,          
      })
    }
    if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Add')){
      item.push({          
          id: 9,
          text: <Box display={'inline-block'} >Generate POD</Box>,          
      })
    }

    return item;

  }
  customRow = (e, row) => {
    // let desc = (row && row.drop_off_description && row.drop_off_description !== '') ? row.drop_off_description : '';

    let date = (row && row.drop_off_date && row.order_status) ? moment(row.drop_off_date).format(apiUtil.getDefaultDateFormat()) : '';
    let time = (date && date !== '') ? (row && row.drop_off_time_planned && row.drop_off_time_planned) ? moment(row.drop_off_time_planned).format(apiUtil.getDefaultTimeFormat()) : '' : '';
    
    let dateTime = '';
    if(date && time){
      dateTime = date + ' - ' + time;
    } else if (date){
      dateTime = date;
    } else if (time){
      dateTime = time;
    }

    return <tr>
      <td 
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >
        <Box pb={1}>
          <DropdownMenu
            className={'status-btn ' + apiUtil.statusToClassName((row.order_status && row.order_status.status && row.order_status.status !== '') ? row.order_status.status : '')}
            text={(row && row.order_status && row.order_status.status) ? row.order_status.status : ''}
            rightIcon={<ArrowDownIcon />}
            items={this.state.tableStatus}
            onClick={(item) => {
              /*if(row.drop_off_worker_id && row.drop_off_worker_id > 0){*/
                let data = {
                  data: [
                    {
                      id: [ row.id ],
                      job_steps: (row.job_steps && row.job_steps.length > 0) ? row.job_steps.map(step => {
                        step.job_step_id = step.id;
                        if (item.text.toString().toLowerCase().trim() === "completed") {
                          step.job_step_status_id = 3;
                        } else {
                          step.job_step_status_id = 1;
                        }
                        return step
                      }): row.job_steps,
                      order_status_id: item.id,
                      send_notification_to_customer: true,
                    }
                  ]
                };

                if(item.text.toString().toLowerCase().trim() === 'not assigned'){
                  data.data[0]['drop_off_worker_id'] = null;
                  data.data[0]['extra_worker_ids'] = null;
                }

                this.callUpdateStatusApi(data, (newRow) => {
                  if(newRow){
                    let newData = apiUtil.updateRow(this.state.data, newRow, 'id');
                    this.setState({
                      data: newData,
                    });
                  }
                });
              /*} else {
                apiUtil.toast('You must first assign a driver!', 'warning', 'error');
              }*/
            }}
          />
        </Box>
      </td>
      <td 
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >
        <Box pl={2}>
          <Box>
            {dateTime}
          </Box>
          <Box>{row.job_steps && row.job_steps.length > 0 ? row.job_steps[0].location : ''}</Box>
          {row.amount !== null && <Box>Price: ${Number.parseFloat(row.amount).toLocaleString()}</Box>}
        </Box>
      </td>
      <td 
        colSpan={6}
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >&nbsp;</td>
      {/* <td 
        colSpan={5}
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >
        {(desc !== '') && <Box>
          <Box pl={2} pb={2}>
            <CustomReadMore className={'lightGray'} maxLine={2} text={(row && row.drop_off_description && row.drop_off_description !== '') ? row.drop_off_description : ''} />
          </Box>  
        </Box>}
      </td> */}
      {/* <td 
        colSpan={4}
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >
        {(desc !== '') && <Box>
          <Box pl={2} pb={2}>
            <CustomReadMore className={'lightGray'} maxLine={2} text={(row && row.drop_off_description && row.drop_off_description !== '') ? row.drop_off_description : ''} />
          </Box>  
        </Box>}
      </td> */}
      <td 
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >&nbsp;</td>
    </tr>
  }
  /* END TABLE */
  

  /* DIALOG */
  customDialog = () => {
    return <CustomDialog 
      open={this.state.openDialog}
      title={'Delete'}
      onClose={() => {
        this.setState({
          openDialog: false,
          openDialogItem: null,
        });
      }}
      onOk={() => {
        let row = this.state.openDialogItem;
        this.callDeleteApi(row, () => {
          this.setState({
            openDialog: false,
            openDialogItem: null,
          }, () => {
            this.loadStatusApi();
            this.callReadApi(this.refTableSearch.current.state.value);
            apiUtil.toast('Successfully Deleted', 'check_circle');
          });
        });
      }}
    >
      <Box>Are you sure you want to delete?</Box>
    </CustomDialog>
  }

  massDeleteDialog = () => {
    return <CustomDialog 
      open={this.state.openDialogMassDelete}
      title={'Delete'}
      onClose={() => {
        this.setState({
          openDialogMassDelete: false,
        });
      }}
      onOk={() => {
        this.callMassDeleteApi(() => {
          this.setState({
            openDialogMassDelete: false,
          }, () => {
            // this.loadStatusApi();
            // this.callReadApi(this.refTableSearch.current.state.value);            
            // apiUtil.toast('Successfully Deleted', 'check_circle');
            this.setState({
              // selectedRow: [],
              })
          });
        });
      }}
    >
      <Box>Are you sure you want to delete selected items?</Box>
    </CustomDialog>
  }
 
  Updatedtoast = () => {
    apiUtil.toast('Successfully Updated', 'check_circle');
  }

  unAssignDriverDialog = () => {
    return <CustomDialog 
      open={this.state.openDialogUnassign}
      title={'Unassign Driver'}
      onClose={() => {
        this.setState({
          openDialogUnassign: false,
        });
      }}
      onOk={() => {
        let orderStatusItem = apiUtil.customFilter(this.state.status, 'status', "not assigned");
        let selectedRow = this.rowData;
        let ids = [];
        if(selectedRow) selectedRow.map((item, i) => { ids.push(item.id); });
        
        let data = {
          data: [
            {
              id: ids,
              order_status_id: (orderStatusItem) ? orderStatusItem.id : '',
              send_notification_to_customer: true,
              drop_off_worker_id: null,
              extra_worker_ids: null,
            }
          ]
        };
        this.callMassUpdateStatusApi(data, (newRows) => {
          if(newRows){
            let data = this.state.data
            newRows.map(row => {
              data = apiUtil.updateRow(data, row, 'id');
            })
            this.setState({
              data: data,
              openDialogUnassign: false
            });
            this.Updatedtoast()
          }
        });
      }}
    >
      <Box>Are you sure you want to unassign selected items?</Box>
    </CustomDialog>
  }
  customDialogBatchUpdate = () => {
    return <CustomFormDialog 
        open={this.state.openDialogBatchUpdate}
        title={'Batch Update'}
        onClose={() => {
          this.setState({
              openDialogBatchUpdate: false,
          });
        }}
    >
        
    <BatchUpdateForm
      isLoading={this.state.openBatchUpdateisLoading}
      onSave={(data) => {
    // console.log("onSave");
          this.setState({
            openBatchUpdateisLoading:true
          })
          this.batchUpdateApi(data);
      }}
      onClose={() => {
          this.setState({
              openDialogBatchUpdate: false
          });
      }}
    />


    </CustomFormDialog>
  }

  customDialogDateChange = () => {
    return <CustomDialog 
        open={this.state.openDialogDateChange}
        title={'Change Date'}
        onClose={() => {
          this.setState({
            openDialogDateChange: false,
          });
        }}
        onOk={() =>{
            
          let data ={}
          data.drop_off_date = this.state.drop_off_change_date
          this.batchUpdateApi(data, () => {
            this.setState({
              drop_off_change_date: null,
              openDialogDateChange: false
            })
            this.Updatedtoast()
          });
        }}
    >
        
        <CustomInput
          label={'Select Date'}
          placeholder={'DD/MM/YYYY'}
          color={'secondary'}
          type={"date"}
          value={this.state.drop_off_change_date}
          endAdornment={(obj) => {
            return <Box
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <CalendarIcon />
            </Box>
          }}
          onChange={(e) => {
              this.setState({
                drop_off_change_date: e.target.value
              })
          }}
      />


    </CustomDialog>
  }

  customDialogPayoutChange = () => {
    return <CustomDialog 
        open={this.state.openDialogChangePayout}
        title={'Driver Payout'}
        onClose={() => {
          this.setState({
            openDialogChangePayout: false,
          });
        }}
        onOk={() =>{
            
          let selectedRow = this.rowData;
          let ids = [];

          if(selectedRow) selectedRow.map((item, i) => { ids.push(item.id); });
          let data = {
            data: [
              {
                id: ids,
                driver_payout: this.state.change_driver_payout,
              }
            ]
          };
          this.callMassUpdateStatusApi(data, (newRows) => {
            if(newRows){
              this.setState({
                change_driver_payout: null,
                openDialogChangePayout: false
              });
              this.Updatedtoast()
            }
          });
        }}
    >
        
        <CustomInput
          label={'Payout'}
          placeholder={'Payout'}
          color={'secondary'}
          value={this.state.change_driver_payout}
          type={"number"}
          startAdornment={<p>$</p>}
          endAdornment={<span>SGD</span>}
          onChange={(e) => {
              this.setState({
                change_driver_payout: e.target.value
              })
          }}
      />


    </CustomDialog>
  }
  customDialogAssign = () => {
    return <CustomFormDialog 
        open={this.state.openDialogJobAssign}
        title={'Assign Driver'}
        onClose={() => {
          this.setState({
            openDialogJobAssign: false,
          });
        }}
    >
        
    <BatchUpdateAssignForm
      isLoading={this.state.openBatchUpdateisLoading}
      onSave={(data) => {
          this.setState({
            openBatchUpdateisLoading:true
          })
          let orderStatusItem = apiUtil.customFilter(this.state.status, 'status', "assigned");
          data.order_status_id = (orderStatusItem) ? orderStatusItem.id : '';
          this.batchUpdateApi(data, () => {
            this.setState({
              openDialogJobAssign: false,
            })
            this.Updatedtoast()
        });
      }}
      onClose={() => {
          this.setState({
            openDialogJobAssign: false
          });
      }}
    />


    </CustomFormDialog>
  }


  customDialogChangeStatus = () => {
    return <CustomFormDialog 
        open={this.state.openDialogChangeStatus}
        title={'Change Status'}
        onClose={() => {
          this.setState({
            openDialogChangeStatus: false,
          });
        }}
    >
        
    <BatchUpdateJobStatusForm
      isLoading={this.state.openBatchUpdateisLoading}
      onSave={(data) => {
    // console.log("onSave");
          this.setState({
            openBatchUpdateisLoading:true
          })
          this.batchUpdateApi(data, () => {
              this.setState({
                openDialogChangeStatus: false,
              })
              this.Updatedtoast()
          });
      }}
      onClose={() => {
          this.setState({
            openDialogChangeStatus: false
          });
      }}
    />


    </CustomFormDialog>
  }
  customDialogAutoAssign = () => {
    return <CustomFormDialog 
        maxWidth={'md'}
        open={this.state.openDialogAutoAssign}
        title={'Auto Assign - Invalid Address'}
        onClose={() => {
          this.setState({
              openDialogAutoAssign: false,
          });
        }}
    >

    <AutoAssignForm
      data={this.state.invalidAddress}
      onSave={(data) => {
          this.checkLatLong(data);
          this.setState({
              openDialogAutoAssign: false
          });
      }}
      onClose={() => {
          this.setState({
              openDialogAutoAssign: false
          });
      }}
    />

    </CustomFormDialog>
  }

  toggleColumn = (checked, columns) => {
    let hiddenColumns = this.state.hiddenColumns;

    if (columns.length > 0) {
      if (checked === true) { // hide column
        hiddenColumns = hiddenColumns.concat(columns);
      } else { // show column
        columns.forEach(column => {
          let index = hiddenColumns.indexOf(column);
          if (index !== -1) {
            hiddenColumns.splice(index, 1);
          }
        });
      }
    }

    this.setState({
      hiddenColumns: hiddenColumns
    });
  }

  columnSettingsDialog = () => {
    const { accessToken } = this.context;
    let hiddenColumns = this.state.hiddenColumns;
    return <CustomDialog
      title={'Column Settings'}
      btnOkText={'Save Settings'}
      open={this.state.openSettingsDialog}
      onOk={() => {
        this.setState({
          isLoading: true,
          hiddenColumns: hiddenColumns
        });

        if (this.state.hiddenColumnSettingId > 0) {
          api.update('application_company_settings/' + this.state.hiddenColumnSettingId, {
            json_data: JSON.stringify(hiddenColumns)
          }, accessToken)
            .then(result => {
              let hiddenColumnSettingId = 0;
              if (result.data.data.result.id) {
                hiddenColumnSettingId = result.data.data.result.id;
                localStorage.setItem("job_column_settings", JSON.stringify(result.data.data.result));
              }

              this.setState({
                openSettingsDialog: false,
                hiddenColumnSettingId: hiddenColumnSettingId,
                isLoading: false
              })
          });
        } else {
          api.create('application_company_settings', {
            enabled: true,
            rule: "job_column_settings",
            json_data: JSON.stringify(hiddenColumns)
          }, accessToken)
            .then(result => {
              let hiddenColumnSettingId = 0;
              if (result.data.data.result.id) {
                hiddenColumnSettingId = result.data.data.result.id;
                localStorage.setItem("job_column_settings", JSON.stringify(result.data.data.result));
              }

              this.setState({
                openSettingsDialog: false,
                hiddenColumnSettingId: hiddenColumnSettingId,
                isLoading: false
              })
          });
        }
      }}
      onClose={() => {
        this.setState({
          openSettingsDialog: false
        })
      }}
    >
     <CustomCheckbox checked={this.state.hiddenColumns.includes('order_number')} label={'Hide Job Number'} onChange={(checked) => this.toggleColumn(checked, ['order_number'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('drop_off_time')} label={'Hide POD Time'} onChange={(checked) => this.toggleColumn(checked, ['drop_off_time'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('drop_off_date')} label={'Hide Date'} onChange={(checked) => this.toggleColumn(checked, ['drop_off_date'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('drop_off_time_planned')} label={'Hide Time Slot'} onChange={(checked) => this.toggleColumn(checked, ['drop_off_time_planned'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('drop_off_description')} label={'Hide Description'} onChange={(checked) => this.toggleColumn(checked, ['drop_off_description'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('driver')} label={'Hide Driver'} onChange={(checked) => this.toggleColumn(checked, ['driver'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('manpower')} label={'Hide Manpower'} onChange={(checked) => this.toggleColumn(checked, ['manpower'])} />
     <CustomCheckbox checked={(this.state.hiddenColumns.includes('drop_off_contact_name') || this.state.hiddenColumns.includes('drop_off_name'))} label={'Hide Company'} onChange={(checked) => this.toggleColumn(checked, ['drop_off_contact_name', 'drop_off_name'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('drop_off_name')} label={'Hide Receipient Name'} onChange={(checked) => this.toggleColumn(checked, ['drop_off_name'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('drop_off_contact_no')} label={'Hide Phone Number'} onChange={(checked) => this.toggleColumn(checked, ['drop_off_contact_no'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('notification_email')} label={'Hide Notify Email'} onChange={(checked) => this.toggleColumn(checked, ['notification_email'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('drop_off_postal_code')} label={'Hide Postal Code'} onChange={(checked) => this.toggleColumn(checked, ['drop_off_postal_code'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('drop_off_address')} label={'Hide Delivery Address'} onChange={(checked) => this.toggleColumn(checked, ['drop_off_address'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('document')} label={'Hide Document'} onChange={(checked) => this.toggleColumn(checked, ['document'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('boxes')} label={'Hide Boxes'} onChange={(checked) => this.toggleColumn(checked, ['boxes'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('pallets')} label={'Hide Pallets'} onChange={(checked) => this.toggleColumn(checked, ['pallets'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('cartons')} label={'Hide Cartons'} onChange={(checked) => this.toggleColumn(checked, ['cartons'])} />

     <CustomCheckbox checked={this.state.hiddenColumns.includes('job_type')} label={'Hide Job Type'} onChange={(checked) => this.toggleColumn(checked, ['job_type'])} />

     <CustomCheckbox checked={this.state.hiddenColumns.includes('driver_notes')} label={'Hide Notes to Driver'} onChange={(checked) => this.toggleColumn(checked, ['driver_notes'])} />
     <CustomCheckbox checked={this.state.hiddenColumns.includes('do_number')} label={'Hide Do Number'} onChange={(checked) => this.toggleColumn(checked, ['do_number'])} />
     {this.state.currentRole !== "Customer Template Role"  && this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver Payout') && <CustomCheckbox checked={this.state.hiddenColumns.includes('driver_payout')} label={'Hide Driver Payout'} onChange={(checked) => this.toggleColumn(checked, ['driver_payout'])} /> }
     <CustomCheckbox checked={this.state.hiddenColumns.includes('uploader')} label={'Hide Uploader'} onChange={(checked) => this.toggleColumn(checked, ['uploader'])} />
     {this.state.grantedAccess.admin_roles.includes(this.state.currentRole) && <CustomCheckbox checked={this.state.hiddenColumns.includes('price')} label={'Hide Price'} onChange={(checked) => this.toggleColumn(checked, ['price'])} /> }
    </CustomDialog>
  }

  previewDialog = () => {
    if(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')){
    return <CustomDialog 
      open={this.state.openPreviewDialog}
      title={'Order Details'}
      maxWidth={'lg'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openPreviewDialog: false,
          openPreviewDialogItem: null,
        });
      }}
    >
      <JobPreviewDialog
        id={this.state.openPreviewDialogItem}
        onClose={() => {
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
        }}
      />
    </CustomDialog>
    }
  }

  autoAssignDialog = () => {
    return  this.setState({
      autoAssign: true
    })
  }

  importDialog = () => {
    return <CustomDialog 
      open={this.state.openImportDialog}
      title={'Import Jobs'}
      maxWidth={'xl'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openImportDialog: false,
          openImportDialogItem: null,
        });
      }}
    >
      <JobImportDialog
        file={this.state.openImportDialogItem}
        isLoading={this.state.openImportDialogIsLoading}
        onClose={() => {
          this.setState({
            openImportDialog: false,
            openImportDialogItem: null,
          });
        }}
        onImport={(form) => {
          this.setState({
            openImportDialogIsLoading: true
          }, () => {
            this.callImportApi(form, (data) => {
              let all_jobs = (form && form.length > 0) ? form.length : 0;
              let failed_orders = (data && data.failed_orders && data.failed_orders.length > 0) ? data.failed_orders.length : 0;
              let invalid_company_code_orders = (data && data.invalid_company_code_orders && data.invalid_company_code_orders.length > 0) ? data.invalid_company_code_orders.length : 0;
              let saved_orders = (data && data.saved_orders && data.saved_orders.length > 0) ? data.saved_orders.length : 0;
              
              if(failed_orders > 0){
                apiUtil.toast(failed_orders + ' of ' + all_jobs + ' jobs have not successfully imported', 'check_circle', 'error');
              }
              if(invalid_company_code_orders > 0){
                apiUtil.toast(invalid_company_code_orders + ' of ' + all_jobs + ' company code does not match, jobs have not successfully imported', 'check_circle', 'error');
              }
  
              if(saved_orders > 0){
                apiUtil.toast(saved_orders + ' of ' + all_jobs + ' Jobs Successfully Imported', 'check_circle', 'success');
              }
  
              this.callReadApi();
              this.loadStatusApi();
              this.setState({
                openImportDialog: false,
                openImportDialogItem: null,
                openImportDialogIsLoading: false,
              });
            });
          });
        }}
      />
    </CustomDialog>
  }

  toggleCopyData = (checked, column) => {
    let copyData = this.state.copyData;
    copyData[column] = checked;
    this.setState({
      copyData: copyData
    });
  }

  copyDialog = () => {
    return <CustomDialog
      title={'Duplicate Job'}
      btnOkText={'Duplicate Job'}
      open={this.state.copyDialogOpen}
      onClose={() => {
        this.setState({
          copyDialogOpen: false,
        });
      }}
      onOk={() => {
        this.props.history.push({
          pathname: "/jobs-form",
          copyData: this.state.copyData
        });
      }}
    >
      <p>Which items you want to duplicate as a new job?</p>
      <p className="modal-subtitle">Job Details</p>
      <CustomCheckbox label={'Job Date'} checked={this.state.copyData.drop_off_date} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_date');
      })} />
      <CustomCheckbox label={'Job Description'} checked={this.state.copyData.drop_off_description} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_description');
      })} />
      <CustomCheckbox label={'Job Time'} checked={this.state.copyData.drop_off_time_planned} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_time_planned');
      })} />
       <CustomCheckbox label={'Order Number'} checked={this.state.copyData.order_number} onChange={((checked) => {
        this.toggleCopyData(checked, 'order_number');
      })} />
       <CustomCheckbox label={'Do Number'} checked={this.state.copyData.do_number} onChange={((checked) => {
        this.toggleCopyData(checked, 'do_number');
      })} />
       <CustomCheckbox label={'Driver Payout'} checked={this.state.copyData.driver_payout} onChange={((checked) => {
        this.toggleCopyData(checked, 'driver_payout');
      })} />

      <p className="modal-subtitle">Recipient Details</p>
      <CustomCheckbox label={'Name'} checked={this.state.copyData.drop_off_name} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_name');
      })} />
      <CustomCheckbox label={'Email'} checked={this.state.copyData.drop_off_contact_email} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_contact_email');
      })} />
      <CustomCheckbox label={'Phone No'} checked={this.state.copyData.drop_off_contact_no} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_contact_no');
      })} />
      <CustomCheckbox label={'Tracking Number'} checked={this.state.copyData.item_tracking_number} onChange={((checked) => {
        this.toggleCopyData(checked, 'item_tracking_number');
      })} />
      <CustomCheckbox label={'Company Name'} checked={this.state.copyData.drop_off_contact_name} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_contact_name');
      })} />
      <CustomCheckbox label={'Postal Code'} checked={this.state.copyData.drop_off_postal_code} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_postal_code');
      })} />
       <CustomCheckbox label={'Address'} checked={this.state.copyData.drop_off_address} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_address');
      })} />

      <p className="modal-subtitle">Additional Details</p>
      <CustomCheckbox label={'Items in this job'} checked={this.state.copyData.order_details} onChange={((checked) => {
        this.toggleCopyData(checked, 'order_details');
      })} />
      <CustomCheckbox label={'Assign Workers'} checked={this.state.copyData.drop_off_worker} onChange={((checked) => {
        this.toggleCopyData(checked, 'drop_off_worker');
      })} />
      <CustomCheckbox label={'Job Steps'} checked={this.state.copyData.job_steps} onChange={((checked) => {
        this.toggleCopyData(checked, 'job_steps');
      })} />
      <CustomCheckbox label={'Advanced Options'} checked={this.state.copyData.amount} onChange={((checked) => {
        this.toggleCopyData(checked, 'amount');
      })} />
      <CustomCheckbox label={'Documents'} checked={this.state.copyData.document} onChange={((checked) => {
        this.toggleCopyData(checked, 'document');
      })} />
      <CustomCheckbox label={'Boxes'} checked={this.state.copyData.boxes} onChange={((checked) => {
        this.toggleCopyData(checked, 'boxes');
      })} />
      <CustomCheckbox label={'Pallets'} checked={this.state.copyData.pallets} onChange={((checked) => {
        this.toggleCopyData(checked, 'pallets');
      })} />
      <CustomCheckbox label={'Cartons'} checked={this.state.copyData.cartons} onChange={((checked) => {
        this.toggleCopyData(checked, 'cartons');
      })} />
      <CustomCheckbox label={'Driver Notes'} checked={this.state.copyData.driver_notes} onChange={((checked) => {
        this.toggleCopyData(checked, 'driver_notes');
      })} />
    </CustomDialog>
  }
  /* END DIALOG */


  /* STEPPER */
  setStepper = () => {
    return <>
      <CustomGuideStepper
        activeStep={3}
        steps={[
          {
            label: 'Create Customer',
          },
          {
            label: 'Create Vehicle',
          },
          {
            label: 'Create Driver',
          },
          {
            label: 'Create Job',
          },
        ]}
        onClick={(index) => {
          if(index === 0){
            this.props.history.push('/customers');
          } else if(index === 1){
            this.props.history.push('/resources/vehicle');
          } else if(index === 2){
            this.props.history.push('/resources/transport-operator');
          } else if(index === 3){
            // this.props.history.push('/job-list');
          }
        }}
      />
      <Box pb={2} textAlign={'center'}>This is the Job list page.Let's learn some basics about this page.</Box>
      <Box textAlign={'center'}>
        <CustomButton 
          color={'primary'}
          href={'/'}
          onClick={(e) => {
              e.preventDefault();

              this.setState({
                openSkipDialog: true,
              });
          }}
        >
          Skip All
        </CustomButton>
      </Box>
    </>
  }
  /* END STEPPER */

  /* TOUR */
  setTour = () => {
    return <>
      <Tour
        steps={[
          {
            selector: '.select-dates-step',
            content: <Box>
              <h2>Select dates</h2>
              <p>Here is where you choose a range of dates to display in the job list</p>
            </Box>,
          },
          {
            selector: '.job-statuses-step',
            content: <Box>
              <h2>Job Statuses</h2>
              <p>This area is where you monitor all of your job progress in general. You can filter all job statuses by clicking on any of the statuses here.</p>
            </Box>,
          },
          {
            selector: '.job-listing-step',
            content: <Box>
              <h2>Job Listing</h2>
              <p>Like vehicles and drivers, here is where all your available jobs are are populated.You can edit the displayed columns and sort by a column.</p>
            </Box>,
          },
          {
            selector: '.new-job-step',
            stepInteraction: true,
            content: <Box>
              <h2>Create job</h2>
              <p>Now let's create a new job.</p>
            </Box>,
          },
        ]}
        isOpen={this.state.isTourOpen}
        rounded={10}
        // accentColor={'#64CCC9'}
        accentColor={'#007CDE'}
        showNumber={false}
        showNavigation={false}
        disableInteraction={true}
        // prevButton={<></>}
        // nextButton={<></>}
        lastStepNextButton={<></>}
        onRequestClose={() => {
          this.setState({
            isTourOpen: false,
          });
        }} />
    </>
  }
  /* END TOUR */

  /* SKIP DIALOG */
  skipDialog = () => {
    const { accessToken, setOnBoardingCustomer, setOnBoardingVehicle, setOnBoardingDriver, setOnBoardingJob } = this.context;

    return <CustomDialog 
      open={this.state.openSkipDialog}
      title={'End Tutorial'}
      btnCloseText={'No'}
      onClose={() => {
        this.setState({
          openSkipDialog: false,
        });
      }}
      onOk={() => {
        apiUtil.callUpdateAdminProfileApi(accessToken, (data) => {
          this.setState({
            openSkipDialog: false,
          }, () => {
            setOnBoardingCustomer(null);
            setOnBoardingVehicle(null);
            setOnBoardingDriver(null);
            setOnBoardingJob(null);

            apiUtil.toastOnBoarding('Done', 'check_circle');
            this.props.history.push('/');
          });
        });
      }}
    >
      <Box>Are you sure you want to end tutorial?</Box>
    </CustomDialog>
  }
  /* END SKIP DIALOG */

  /*START AUTO ASSIGN*/
  autoAssignApi = (callback = null) =>{
    const { accessToken } = this.context;

    let row = {};
    row.start_date = moment(this.state.startDate).format("YYYY-MM-DD");
    row.end_date = moment(this.state.endDate).format("YYYY-MM-DD");

    this.setState({
      isLoading: true
    }, () => {

      ldsApi.create('auto_assign/jobs', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.job_assigned && data.job_assigned.length > 0) ? data.job_assigned : null;

            if(result){
              let currentData = this.state.data;
              currentData.map((item, i)=>{
                 result.map((res, ii)=>{
                    if(item.id == res.id){
                      item.order_status_id = res.order_status_id;
                      item.order_status = res.order_status;

                      item.drop_off_worker_id = res.drop_off_worker_id;
                      item.drop_off_worker = res.drop_off_worker;
                      item.drop_off_worker_name = res.drop_off_worker_name;
                    }
                 }); 
              });

              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(result);
                }
              });
            } else {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });    
  }

  checkLatLong = (currentData=null, callback = null)=>{
    if(!currentData){ currentData = this.state.data; };

    let invalidLatLong = [];
    let finishCallback = [];
  
    this.setState({
      isLoading: true
    }, () => {

        currentData.filter(x => x.order_status_id == 679).map((v,k)=>{
             
            let row = {};
            row.address = v.drop_off_address; 
            this.generateLatLong(row, (data) => {
                if(data.success){
                  let isDiffLatLong = false;
                  if(v.drop_off_latitude != data.lat && v.drop_off_longitude != data.lng){
                    v.drop_off_latitude = data.lat.toString();
                    v.drop_off_longitude = data.lng.toString();
                    isDiffLatLong = true;
                  }
                  
                  let format = {};
                  format.data = [];

                  let template = {};
                  template.id = [v.id];
                  template.drop_off_latitude = v.drop_off_latitude;
                  template.drop_off_longitude = v.drop_off_longitude;
                  template.drop_off_address = v.drop_off_address;
                  format.data.push(template);

                  if(isDiffLatLong){
                    this.updateLatLong(format, (result) => {
                      if(!result){ invalidLatLong.push(v); }
                      finishCallback.push("true");

                      if(finishCallback.length == currentData.filter(x => x.order_status_id == 679).length){
                        this.showInvalidAddress(invalidLatLong);
                      }
                    });
                  }else{
                    finishCallback.push("true");

                    if(finishCallback.length == currentData.filter(x => x.order_status_id == 679).length){
                      this.showInvalidAddress(invalidLatLong);
                    }
                  }
                  
                }else{
                  invalidLatLong.push(v);
                  finishCallback.push("true");

                  if(finishCallback.length == currentData.filter(x => x.order_status_id == 679).length){
                    this.showInvalidAddress(invalidLatLong);
                  }
                }
            });
          
        });
    });  
  }

  showInvalidAddress = (invalidLatLong, callback = null)=>{
    if(invalidLatLong.length == 0){ 
      this.autoAssignApi();
    }else{
      this.setState({
                      isLoading: false, 
                      openDialogAutoAssign:true,
                      invalidAddress: invalidLatLong
                  });
    }
  }

  generateLatLong = (row, callback = null)=>{
    const { accessToken } = this.context;
    ldsApi.create('geo_functions/geocoder_address', row, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result) ? data.result : null;
          if(result){            
            callback(result);
          }else{ 
            callback(null);   
          }
        }, (error, type) => {
          callback(null)
        });
    });
  }

  updateLatLong = (row, callback = null)=>{
    const { accessToken } = this.context;
    ldsApi.create('orders/assign/order', row, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result) ? data.result : null;
          if(result){            
            callback(result);
          }else{ 
            callback(null);   
          }
        }, (error, type) => {
          callback(null)
        });
    });
  }

  /*END AUTO ASSIGN*/

  /* PDF GENERATE */ 

  generatePDF = (row) => {
    const qr = function(){return (<QRCode value={'hello world'}/>)}

    let pdf = new jsPDF("l", "mm", [62, 100]);

    let index = 0;
    let data = row?[row]:this.state.data;  
    
    data.map(function(v,k){
      if(index!= 0 ){  pdf.addPage(); }
        pdf.setFont("verdana","normal","400");
        pdf.setFontSize(10);
        pdf.text(10,7, v.order_number);  
        
        ReactDOM.render(<Barcode value={v.order_number} displayValue={false} renderer={"img"}/>, document.getElementById('barcode'));
        ReactDOM.render(<QRCode value={v.order_number} renderAs={'canvas'}/>, document.getElementById('qr'));
        let barData = document.getElementById('barcode').children[0].src;  
        let qrData = document.getElementById('qr').children[0]; 
        pdf.addImage(barData, 'PNG',10,8,50,14);
        pdf.addImage(qrData.toDataURL('image/png'), 'PNG',80,10,12,12);

        pdf.setFontSize(8);
        let contact_name = v.drop_off_contact_name? v.drop_off_contact_name :(v.company_name? v.company_name : '')
        pdf.text(10,25, "SHIP TO :");
        let package_info = JSON.parse(v.package_info)
        let total_kg = (package_info && package_info.weight) ? package_info.weight :"0.00";
        pdf.text(60,29, "Weight(Kg) : "+total_kg);

        pdf.setFont("verdana","bold","700");
        let drop_off_attention = "";
        let drop_off_contact_no = v.drop_off_contact_no?v.drop_off_contact_no:"";
        let drop_off_address = v.drop_off_address?v.drop_off_address:"";
        let drop_off_postal_code = v.drop_off_postal_code? v.drop_off_postal_code.substring(0,2):"";

        pdf.text(10,29, "");        
        pdf.text(10,33, ""+drop_off_contact_no);   
        pdf.text(10,40, ""+drop_off_address, { maxWidth: 85 });

        pdf.setFontSize(20);
        
        pdf.text(85,37, ""+drop_off_postal_code);
        
        pdf.addImage(EVFYLogo,'PNG', 10, 50, 12, 6);
        index++;
      });
      pdf.save("pdf"); 
  }

  generatePOD = ( ) => {

    let selectedRow = this.rowData;
    let ids = '';
    if(selectedRow) {
      selectedRow.map((item, i) => { ids += item.id +','; });
    } else {
      apiUtil.toast('You must select order!', 'warning', 'error');
      return
    }
    ids = ids.slice(0, -1);
    window.open(REACT_APP_LDS_API_URL + '/multiple_generate_pod.pdf?ids=' + ids + '&application_company_id=' + this.state.user.owner_info.application_company_id,'_blank');
  }
  
    /*END PDF GENERATE */
  
  /*START BATCH UPDATE*/
  batchUpdateApi = (data, callback = null) =>{
    // console.log("batchUpdateApi");

    const { accessToken } = this.context;
    // console.log("select row render");
    let row = {};
    row.data = [];
    let template = {};
    let selectedRow = this.rowData;
    let ids = [];

    if(selectedRow) selectedRow.map((item, i) => { ids.push(item.id); });

    if(!ids.length > 0) {
      apiUtil.toast('You must select order!', 'warning', 'error');
      this.setState({
        openDialogBatchUpdate:false,
        openDialogChangeStatus:false,
        openDialogJobAssign:false,
        openDialogDateChange: false,
        openBatchUpdateisLoading:false
      });
      return
    }
    
    if(!data.order_status_id && !data.drop_off_worker_id && !data.drop_off_date){
      this.setState({
        openDialogBatchUpdate:false,
        openDialogChangeStatus:false,
        openDialogJobAssign:false,
        openDialogDateChange: false,
        openBatchUpdateisLoading:false
      });
      return;
    }

    template.id = ids;
    if(data.order_status_id){ template.order_status_id = data.order_status_id; }
    if(data.drop_off_worker_id){ template.drop_off_worker_id = data.drop_off_worker_id; }
    if(data.extra_worker_ids){ template.extra_worker_ids = data.extra_worker_ids.toString(); }
    if(data.drop_off_date){ template.drop_off_date = data.drop_off_date}
    
    row.data.push(template);

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('orders/assign/order', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result[0] : null;

            if(result){
              let currentData = this.state.data;
              currentData.map((item, i)=>{
                 data.result.map((res, ii)=>{
                    if(item.id == res.id){
                      item.order_status_id = res.order_status_id;
                      item.order_status = res.order_status;

                      item.drop_off_worker_id = res.drop_off_worker_id;
                      item.drop_off_worker = res.drop_off_worker;
                      item.drop_off_worker_name = res.drop_off_worker_name;

                      item.extra_workers_ids = res.extra_workers_ids;
                      item.extra_workers = res.extra_workers;
                      item.drop_off_date = res.drop_off_date;
                    }
                 }); 
              });
              this.loadStatusApi();
              this.callReadApi(); 
              this.setState({
                isLoading: false,
                openDialogBatchUpdate:false,
                openDialogChangeStatus:false,
                openDialogJobAssign:false,
                openDialogDateChange: false,
                openBatchUpdateisLoading:false,
                data:currentData
              }, () => {
                if(callback){
                  callback(result);
                }
              });
            } else {
              this.setState({
                isLoading: false,
                openDialogBatchUpdate:false,
                openDialogChangeStatus:false,
                openDialogJobAssign:false,
                openDialogDateChange: false,
                openBatchUpdateisLoading:false
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoading: false,
              openDialogBatchUpdate:false,
              openDialogChangeStatus:false,
              openDialogJobAssign:false,
              openDialogDateChange: false,
              openBatchUpdateisLoading:false
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
    
  }
  /*END Batch Update*/

  render() {
    return <Box className="job-list-page" >
    {  this.state.autoAssign? 
    <Box className="loadingScreen">
      <CircularProgress size={35} className="circular_progress"/>
    </Box> 
   : '' }
      <StickyPageHeader isSmall={false}>
        {this.customStickyPageHeader()}
      </StickyPageHeader>
      
      <Card className={'pb-0'}>
        {this.customStatus()}
      </Card>

      <Card className={'job-listing-step'}>
        {/* {this.state.isLoading ? "isloading ...":this.customTable() } */}
        {this.customTable()}
      </Card>
      
      {this.customDialog()}
      {this.massDeleteDialog()}
      {this.customDialogBatchUpdate()}
      {this.customDialogAssign()}
      {this.unAssignDriverDialog()}
      {this.customDialogChangeStatus()}
      {this.customDialogDateChange()}
      {this.customDialogPayoutChange()}
      {this.customDialogAutoAssign()}
      {this.previewDialog()}
      {this.importDialog()}
      {/* {this.autoAssignDialog()} */}
      {this.setTour()}
      {this.skipDialog()}
      {this.columnSettingsDialog()}
      {this.copyDialog()}
	  <div id="barcode" style={{display:'none'}}></div>
      <div id="qr" style={{display:'none'}}></div>


    </Box>;
  }
}